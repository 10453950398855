import { Box, Stack, Typography, useTheme } from "@mui/material";
import { ExpirationBox } from "./RelationTableAdvanced.styled";
import { UseImage } from "components";
import RegisterIcon from "../../assets/icons/registerIcon.svg";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

const useClasses = makeStyles((theme) => ({
  cardMain: {
    padding: "2rem",
    gap: "1rem",
  },
  cardMainMobile: {
    padding: "0",
    gap: "1rem",
  },
  cardItem: {
    flexDirection: "column",
    gap: "1rem",
    border: "1px solid lightgray",
    borderRadius: "5px",
    padding: "16px",
    transition: "transform 0.3s ease, border-color 0.3s ease",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.02)",
      backgroundColor: theme.palette.primary.mainHoverBackground,
    },
  },
  cardItemHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  expirationBox: {
    textAlign: "center",
    padding: "0.5rem !important",
  },
}));

export const RelationList = ({ tableData, onClickView, IsMobile }) => {
  const classes = useClasses();
  const { t } = useTranslation();
  const rows = tableData?.map((row) => {
    return {
      ...row,
      Folio: row.Metadatos[0]?.Folio,
      Name: row.Metadatos[0]?.["NombreCompleto"],
      Environment: row.Metadatos[0]?.Ambiente,
      CreationDate: row.Metadatos[0]?.["FechaCreacion"],
      Status: row.StepperStatus[0]?.Status,
    };
  });
  return (
    <Stack className={IsMobile ? classes.cardMainMobile : classes.cardMain}>
      {rows.map((row, index) => {
        return (
          <Stack
            className={classes.cardItem}
            onClick={() => {
              onClickView(row);
            }}
          >
            <Box className={classes.cardItemHeader}>
              <Box
                className={classes.titleCard}
                gap={IsMobile ? "0.5rem" : "1.5rem"}
              >
                <UseImage src={RegisterIcon} />
                <Typography
                  variant={IsMobile ? "body1" : "h6"}
                  sx={IsMobile && { fontWeight: 600 }}
                >
                  {t("relation_tab_list_folio")}: {row?.Folio} - {row.Name}
                </Typography>
              </Box>
              <ExpirationBox
                className={classes.expirationBox}
                expirationStatus={row?.StepperStatus[0]?.SubStatus}
              >
                {row.Status}
              </ExpirationBox>
            </Box>
            <Typography>
              {t("relation_tab_list_enviroment")}: {row?.Environment}
            </Typography>
            <Typography>
              {t("relation_tab_list_date")}: {row?.CreationDate}
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};
