import React, { Fragment, useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { documentActions } from 'store/actions';
import { CustomLoadingOverlay } from 'components/CustomLoadingOverlay';
import { useTranslation } from 'react-i18next';
import { createLoadingSelector } from 'store/selectors/LoadingSelectors';
import { FormProvider, useForm } from 'react-hook-form';
import { DynamicForm } from 'components/LEGOS/DynamicForm/DynamicForm';
import { Icon } from '@iconify/react';

export const DocumentMetadataUpdate = ({ handleMode, documentSpec }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [questionGroups, setQuestionGroups] = useState();
  const formMethods = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    getDocumentQuestionsDispatch();
  }, []);

  const LOADING_STATES = ['GET_DOCUMENT_QUESTIONS', 'UPDATE_DOCUMENT_METADATA'];
  const isLoading = useSelector(createLoadingSelector(LOADING_STATES));

  const getDocumentQuestionsDispatch = async () => {
    const documentQuestions = await dispatch(
      documentActions.GetDocumentQuestions({
        File_Id: id,
        DocumentType_Id: documentSpec.DocumentType_Id,
      }),
    );
    documentQuestions[0].groupTitle = documentQuestions[0].Name;
    setQuestionGroups(documentQuestions);
  };

  const handleSaveMetadata = async (formData) => {
    const keysDocumentMetadata = Object.keys(formData);
    const documentMetadata = keysDocumentMetadata.map((el) => {
      return {
        GroupName: questionGroups[0].Name,
        Name: questionGroups[0].Questions.find((question) => question.Id === parseInt(el))?.Name,
        Value: formData[el],
        MetadataType: questionGroups[0].Questions.find((question) => question.Id === parseInt(el))
          ?.Code,
      };
    });

    const UPDATE_DOCUMENT_METADATA_PAYLOAD = {
      ObjectId: documentSpec.Document_Id,
      Metadata: documentMetadata,
    };
    await dispatch(documentActions.UpdateDocumentMetadata(UPDATE_DOCUMENT_METADATA_PAYLOAD));
    handleMode('DOCUMENT_DETAILS');
  };

  return (
    <Fragment>
      <CustomLoadingOverlay active={isLoading} text={t('general_loading_message')} />
      <Box display="flex" alignItems="center" gap={'1rem'}>
        <IconButton
          onClick={() => {
            handleMode('DOCUMENT_DETAILS');
          }}
        >
          {' '}
          <Icon icon={'mdi:arrow-back'} />{' '}
        </IconButton>
        <Typography variant={'span'}>{t('doc_metadata_update_description')}</Typography>
      </Box>
      {questionGroups && (
        <Box sx={{ display: 'grid', placeItems: 'center', gap: '2rem', width: '100%' }}>
          <FormProvider {...formMethods}>
            <DynamicForm
              questionsGroups={questionGroups}
              handleSave={handleSaveMetadata}
              handleBack={() => handleMode('DOCUMENT_DETAILS')}
              defaultValues={documentSpec?.CurrentMetadata}
            />
          </FormProvider>
        </Box>
      )}
      {/*<Box display='flex' justifyContent='center' gap='2rem' height='3rem'>*/}
      {/*  <Button sx={{borderRadius:'2rem', minWidth:'20%'}} variant='outlined' onClick={() => {handleMode('DOCUMENT_DETAILS')}}>Atras</Button>*/}
      {/*  <Button sx={{borderRadius:'2rem', minWidth:'20%'}} variant='contained' >Guardar</Button>*/}
      {/*</Box>*/}
    </Fragment>
  );
};
