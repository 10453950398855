import { DialogActions, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { InputField, LoadingButton } from 'components';
import { AlertModal } from './AlertModal';
import { FormProvider, useForm } from 'react-hook-form';
import { ModalContainer } from '../Modal.styles';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { SendNotificationUploadDocument } from 'api/document/documentRequests';
import { useTranslation } from 'react-i18next';

export const SendEmailModal = ({ open, handleClose, handleMode }) => {
  const { t } = useTranslation();
  const { data: validateActionIdData } = useSWR('ValidateActionId');
  const { trigger, isMutating } = useSWRMutation('SendNotificationUploadDocument', (url, payload) =>
    SendNotificationUploadDocument(payload),
  );

  const { data: documentStructure } = useSWR('GetDocumentalStructure');
  const formMethods = useForm({
    mode: 'onBlur',
  });
  const { control, handleSubmit, register, formState } = formMethods;

  const handleSendNotification = async (formValues) => {
    try {
      const PAYLOAD = {
        FileId: documentStructure.FileId,
        DocumentTypeId: documentStructure.DocumentType_Id,
        Url: validateActionIdData.Link,
        Comments: formValues.description,
        ToEmails: [formValues.email],
      };
      await trigger(PAYLOAD);
      handleClose();
      AlertModal(
        t('alert_email_sended_successfully_title'),
        t('alert_email_sended_successfully_message'),
        'success',
        t,
      ).then(() => handleMode('DEFAULT_DOCUMENT_VISUALIZATION'));
    } catch (e) {}
  };

  return (
    <ModalContainer open={open} onClose={handleClose}>
      <DialogTitle>{t('send_email_title')}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {t('send_email_instructions')}
      <FormProvider {...formMethods}>
        <InputField
          inputType="email"
          control={control}
          register={register}
          name="email"
          label={t('general_email')}
          validations={{ required: true }}
          required={true}
          error={!!formState.errors['email']}
          helperText={formState.errors['email']?.message}
          placeholder={t('general_insert_email')}
        />
        <InputField
          isPassword={false}
          inputType="text"
          control={control}
          register={register}
          name="description"
          label={t('general_email_description')}
          validations={{ required: true }}
          required={true}
          error={!!formState.errors['description']}
          helperText={formState.errors['description']?.message}
          placeholder={t('general_insert_email_description')}
          multiline
          minRows={4}
        />
      </FormProvider>
      <DialogActions sx={{ width: '50%', justifyContent: 'center' }}>
        <LoadingButton onHandleClick={handleSubmit(handleSendNotification)} loading={isMutating}>
          {t('general_send')}
        </LoadingButton>
      </DialogActions>
    </ModalContainer>
  );
};
