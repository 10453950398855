import styled from "styled-components";
import {
  Box,
  styled as MUIStyled,
  Card as MUICard,
  Accordion as MUAccordion,
  AccordionSummary as MUAccordionSummary,
  AccordionDetails as MUAccordionDetails,
  Typography,
  CardActions,
  Button,
  useTheme,
  IconButton,
  Grid as MUIGrid,
  TextField,
} from "@mui/material";
import IconClose from "@mui/icons-material/Close";

export const DocumentCard = MUIStyled(MUICard)(({ theme }) => ({
  height: "5rem",
  borderRadius: "0.5rem",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "2rem",
  gap: "1.5rem",
  textAlign: "center",
  "&:hover": {
    backgroundColor: `${theme.palette.primary.tableHover} !important`,
    cursor: "pointer",
  },
}));

export const Card = MUIStyled(MUICard)(({ theme }) => ({
  width: "100%",
  height: "230px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
  "&:hover": {
    backgroundColor: theme.palette.secondary.light,
    cursor: "pointer",
  },
}));

export const IconSectionsValidate = MUIStyled(Box)(({ theme }) => ({
  width: "18%",
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

export const Accordion = MUIStyled(MUAccordion)(({ theme }) => ({
  borderBottom: `solid ${theme.palette.accordion.line} 2px`,
}));

export const AccordionSummary = MUIStyled(MUAccordionSummary)(
  ({ theme }) => ({}),
);

export const AccordionDetails = MUIStyled(MUAccordionDetails)(({ theme }) => ({
  display: "flex !important",
  flexWrap: "wrap !important",
}));

export const NoDataContent = MUIStyled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "60vh",
}));

export const TitleGroup = MUIStyled(Typography)(({ theme }) => ({
  color: `${theme.palette.accordion.main} !important`,
}));

export const TextNoDataInformation = MUIStyled(Typography)(({ theme }) => ({
  color: `${theme.palette.userStyle.textInstruccions} !important`,
  fontWeight: 550,
  marginTop: theme.spacing(4),
}));

export const ImageNotFound = styled.img(({ theme }) => ({
  maxwidth: "300px",
}));

export const ImageLoadIcon = styled.img(({ theme }) => ({
  width: "20px",
  height: "20px",
  marginLeft: "2px",
  marginRight: "1px",
}));

export const IconSection = MUIStyled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  paddingRight: theme.spacing(1),
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
}));

export const ImageDoumentIcon = styled.img(({ theme }) => ({
  width: "20px",
  height: "20px",
  marginLeft: "2px",
  marginRight: "1px",
}));

export const CloseIcon = MUIStyled(IconClose)(({ theme }) => ({
  alignSelf: "flex-end !important",
  marginTop: theme.spacing(1),
  marginRight: theme.spacing(1),
  // marginLeft: "100%",
}));

export const GridContainer = MUIStyled(MUIGrid)(({ theme }) => ({
  paddingLeft: 10,
  paddingTop: 10,
  paddingBottom: 10,
  width: "100%",
  minHeight: "80vh",
}));

export const lineVisualDiv = styled.div(({ theme }) => ({
  height: '5px',
  width: '100%',
  alignSelf: 'center',
  borderBottom: `solid 2px ${theme.palette.secondary.contrastText}`
}));

export const TextFieldComment = MUIStyled(TextField)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

