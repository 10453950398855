import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    Box,
    styled,
    Typography,
    Grid,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { Alert, } from '..'
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

const DropZoneContainer = styled(Box)(({ theme }) => ({
    maxHeight: 300,
    border: `dashed 2px ${theme.palette.primary.main}`,
    borderRadius: 10,
    padding: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "&:hover": {
        opacity: 0.9,
        cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
        minHeight: 200,
    },
    height: '40vh',
}));


export const UploadDcumentsControlMultiple = (props) => {
    const [file, setfile] = useState(null);
    const [initLoad, setInitLoad] = useState(false);

    const fileLoader = useRef(null);
    const { t } = useTranslation();

    const {
        register,
        formState,
        reset,
    } = useFormContext();

    const onDrop = (files) => {
        console.log("files*******", files)
        if (files.length === 0) {
            Alert({
                icon: 0,
                title: t("alert_drag_n_drop_select_correct_type"),
            }, null, null, t);
            return null;
        } else {
            const fileName = files[0]?.name?.split(".");
            console.log('fileNamefileName', fileName)
            if (fileName[1].toString() === "pdf" || fileName[1].toString() === "zip" || fileName[1].toString() === "png" || fileName[1].toString() === "jpg") {
                setfile(files);
            } else {
                Alert({
                    icon: 0,
                    title: t("alert_drag_n_drop_select_correct_type"),
                }, null, null, t);
                return null;
            }
        }
    };

    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        onDrop,
        noClick: true,
        multiple: true,
        accept: ".pdf",
    });

    useEffect(() => {
        if (!acceptedFiles) {
            Alert({
                icon: 0,
                title: t("alert_drag_n_drop_select_correct_type"),
            }, null, null, t);
            return null;
        } else {
            console.log('acceptedFiles', acceptedFiles)
            setfile(acceptedFiles);
        }
    }, [acceptedFiles]);

    useEffect(() => {
        if (file?.length > 0) {
            console.log('files', file)
            props.onChange(file)
        } else {
            if (props.onChange !== undefined && props.onChange !== null) {
                props.onChange(null)
            }
        }
    }, [file]);

    useEffect(() => {
        if (props.onClear) {
            changeFile()
        }
    }, [props.onClear]);

    let openFileChange = (event) => {
        fileLoader.current.click()
    };

    let getFileLoaded = (event) => {
        if (event.target.files && event.target.files[0]) {
            onDrop(event.target.files)
        }
    };

    const changeFile = () => {
        setfile(null);
        reset();
    };

    useEffect(() => {
        if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Android/i)) {
            if (props.initLoadChange && fileLoader.current !== undefined && fileLoader.current !== null && !initLoad) {
                setInitLoad(true)
                openFileChange()
            }
        }
    }, [props.initLoadChange, fileLoader.current]);

    return (<Box sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center'
    }}>
        <input
            type="file"
            hidden
            ref={fileLoader}
            accept={props.Excel ? "application/xlsx" : props.Compressed === true ? "application/zip" : "image/jpeg,image/gisf,image/png,application/pdf"}
            onChange={getFileLoaded}
        />
        <Grid container spacing={2}>
            <Grid item xs={12} >
                {navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Android/i) ?
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        justifyContent: "center",
                        alignItems: "center",
                        height: '60vh',
                    }}>
                        <Button
                            onClick={openFileChange}
                            color="primary"
                            variant="outlined"
                            sx={{
                                maxWidth: 380,
                                fontWeight: "normal",
                                minHeight: 45,
                                padding: "5px 10px",
                                m: "10px auto",
                            }}
                        >
                            Selecciónalo desde tu equipo
                        </Button>
                    </Box>
                    :
                    <DropZoneContainer
                        {...getRootProps()}
                        up
                        sx={{
                            mb: 2,
                            mt: 2,
                            outline: formState.errors.pdf
                                ? "2px solid tomato"
                                : "none",
                            outlineOffset: formState.errors.pdf ? -2 : 0,
                        }}
                    >
                        <input
                            {...getInputProps()}
                            accept={".pdf"}
                            style={{ display: "none" }}
                            {...register("pdf", { required: true })}
                        />
                        <Box margin={1} display={'flex'} justifyContent="center">
                            <Icon fontSize={50} color="#A3D043" icon="clarity:upload-cloud-line" />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                            <Typography align="center" variant="body2">
                                {props.Title ? props.Title :
                                    t("general_drag_your_file")}
                            </Typography>
                            <Button
                                onClick={navigator.userAgent.match(/iPhone/i) ||
                                    navigator.userAgent.match(/Android/i) ?
                                    open : openFileChange}
                                color="primary"
                                variant="text"
                                sx={{
                                    maxWidth: 380,
                                    fontWeight: "normal",
                                    minHeight: 45,
                                    padding: "5px 10px",
                                    m: "10px auto",
                                }}
                            >
                                {t("general_upload_your_file_xlsx_button")}
                            </Button>
                        </Box>
                        {props.IsPhoto ?
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                <Button
                                    onClick={props.handleCamera}
                                    color="primary"
                                    variant="contained"
                                    style={{ width: '209px', borderRadius: '200px' }}
                                    sx={{
                                        maxWidth: 380,
                                        fontWeight: "normal",
                                        minHeight: 45,
                                        padding: "5px 10px",
                                        m: "10px auto",
                                    }}
                                >
                                    Tomar fotografía
                                </Button>
                            </Box> : null
                        }

                        {formState.errors.pdf && (
                            <Typography
                                variant="body1"
                                align="center"
                                color="tomato"
                            >
                                Documento necesario*
                            </Typography>
                        )}
                    </DropZoneContainer>
                }
            </Grid>
        </Grid>
    </Box>);
};