import { useTheme } from '@mui/styles';
import { DocumentalStructure } from 'components/configuration/DocumentalStructure';
import { ConfigDocumentMetadata } from '../../../components/configuration/ConfigDocumentMetadata';
import TabPanel from 'components/common/TabPanel';
import TabsComponent from 'components/common/Tab';
import { InformationTab } from '../../../components/configuration/TabViewsConfiguration/ProfilesView/InformationTab';
import { useTranslation } from 'react-i18next';


export const ConfigTabs = () => {
  const theme = useTheme();
  const { t } = useTranslation()

  const TABS = [
    {
      label: t("configuration_tab_header_information"),
    },
    {
      label: t("configuration_tab_header_metadata"),
    },
    {
      label: t("configuration_tab_header_document_structure"),
    },
  ];

  const render = (value) => {
    return (
      <>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <InformationTab />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <ConfigDocumentMetadata />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <DocumentalStructure />
        </TabPanel>
      </>
    );
  };

  return <TabsComponent tabs={TABS} render={render} />;
};
