import {Box as MUIBox} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBox = styled(MUIBox)(({theme}) => ({
  display: 'flex',
  borderBottom: `1px solid #969696`,
  padding: '1rem',
  alignItems: 'center',
  gap: '2rem',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
}));

export const StatusDot = styled(MUIBox)(({theme, color}) => ({
  height: ".6rem",
  width: ".6rem",
  margin: "0 .3rem",
  borderRadius: "50%",
  backgroundColor: color || '#f5f5f5',
}));

export const StyledResultBox = styled(MUIBox)(({theme}) => ({
  display: 'flex',
  padding: '1rem',
  flexDirection: "column",
  justifyContent: 'center',
  alignItems: "center",
  height: '10rem',
}));