import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    Box,
    styled,
    Typography,
    Grid,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { convertPdfToB64, } from "../../utils";
import { Alert } from '../'
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

const DropZoneContainer = styled(Box)(({ theme }) => ({
    maxHeight: 300,
    border: `dashed 2px ${theme.palette.primary.main}`,
    borderRadius: 10,
    padding: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "&:hover": {
        opacity: 0.9,
        cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
        minHeight: 200,
    },
    height: '40vh',
}));


export const UploadDcumentsControl = (props) => {
    const [file, setfile] = useState(null);
    const [fileBase64, setfileBase64] = useState(null);
    const [extensionChange, setExtensionChange] = useState(null);
    const [initLoad, setInitLoad] = useState(false);

    const fileLoader = useRef(null);
    const { t } = useTranslation();

    const {
        register,
        formState,
        reset,
    } = useFormContext();

    const onDrop = (files) => {
        if (files.length === 0) {
            Alert({
                icon: 0,
                title: t("alert_drag_n_drop_invalid_doc"),
            }, null, null, t);
            return null;
        } else {
            const extension = files[0]?.name?.slice((files[0]?.name.lastIndexOf(".") - 1 >>> 0) + 2)
            console.log("extensiondearchivo: ", extension)
            if (props.Excel === true) {
                if (extension === "xlsx") {
                    setfile(files[0]);
                } else {
                    changeFile();
                    Alert({
                        icon: 0,
                        title: t("alert_drag_n_drop_select_excel"),
                    }, null, null, t);
                    return null;
                }
            } else {
                if (props?.ExtensionsDoc && props?.ExtensionsDoc?.includes(extension)) {
                    setfile(files[0]);
                } else {
                    changeFile();
                    Alert({
                        icon: 0,
                        title: `${t("alert_drag_n_drop_select_correct_type")} ${props?.ExtensionsDoc ? props?.ExtensionsDoc : ".pdf, .png, .jpg "}`,
                    }, null, null, t);
                    return null;
                }
            }
        }
    };

    console.log("props?.ExtensionsDoc", props?.ExtensionsDoc)

    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        onDrop,
        noClick: true,
        accept: props?.ExtensionsDoc ? props?.ExtensionsDoc : props.Excel ? ".xlsx" : {
            'image/png': ['.png'],
            'image/jpg': ['.jpg'],
            'application/pdf': ['.pdf'],
        },
    });

    useEffect(() => {
        if (!acceptedFiles) {
            Alert({
                icon: 0,
                title: t("alert_no_load_file"),
            }, null, null, t);
            return null;
        } else {
            setfile(acceptedFiles[0]);
        }
    }, [acceptedFiles]);

    useEffect(() => {
        if (file) {
            async function fetchData() {
                console.log("extention**************Upload control***********: ", extention)
                try {
                    const { base64result } = await convertPdfToB64(file);
                    setfileBase64(base64result);
                } catch (error) {
                    setfileBase64(new Error("Error al convertir pdf"));
                }
            }
            const splitted = file.name.split(".");
            const extention = splitted[splitted.length - 1];
            setExtensionChange(extention)

            fetchData();
        }
    }, [file]);

    useEffect(() => {
        if (fileBase64 !== undefined && fileBase64 !== null && fileBase64 !== "") {
            props.onChange(file, fileBase64, extensionChange)
        } else {
            if (props.onChange !== undefined && props.onChange !== null) {
                props.onChange(null, null, null)
            }
        }
    }, [fileBase64]);

    useEffect(() => {
        if (props.onClear) {
            changeFile()
        }
    }, [props.onClear]);

    let openFileChange = (event) => {
        fileLoader.current.click()
    };

    let getFileLoaded = (event) => {
        if (event.target.files && event.target.files[0]) {
            onDrop(event.target.files)
        }
    };

    const changeFile = () => {
        setfile(null);
        setfileBase64(null);
        reset();
    };

    useEffect(() => {
        if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Android/i)) {
            if (props.initLoadChange && fileLoader.current !== undefined && fileLoader.current !== null && !initLoad) {
                setInitLoad(true)
                openFileChange()
            }
        }
    }, [props.initLoadChange, fileLoader.current]);

    return (<Box sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center'
    }}>
        <input
            type="file"
            hidden
            ref={fileLoader}
            accept={props?.ExtensionsDoc ? props?.ExtensionsDoc : props.Excel ? "application/xlsx" : "image/jpeg,image/gif,image/png,application/pdf"}
            onChange={getFileLoaded}
        />
        <Grid container spacing={2}>
            <Grid item xs={12} >
                {navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Android/i) ?
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        justifyContent: "center",
                        alignItems: "center",
                        height: '60vh',
                    }}>
                        <Button
                            onClick={openFileChange}
                            color="primary"
                            variant="outlined"
                            sx={{
                                maxWidth: 380,
                                fontWeight: "normal",
                                minHeight: 45,
                                padding: "5px 10px",
                                m: "10px auto",
                            }}
                        >
                            {t("select_file_from_computer")}
                        </Button>
                    </Box>
                    :
                    <DropZoneContainer
                        {...getRootProps()}
                        up
                        sx={{
                            mb: 2,
                            mt: 2,
                            outline: formState.errors.pdf
                                ? "2px solid tomato"
                                : "none",
                            outlineOffset: formState.errors.pdf ? -2 : 0,
                        }}
                    >
                        {console.log("TIPODOCUMENTO", props.ExtensionsDoc)}
                        <input
                            {...getInputProps()}
                            accept={props.ExtensionsDoc ? props?.ExtensionsDoc : props.Excel ? ".xlsx" : ".pdf"}
                            style={{ display: "none" }}
                            {...register("pdf", { required: true })}
                        />
                        <Box margin={1} display={'flex'} justifyContent="center">
                            <Icon
                                onClick={navigator.userAgent.match(/iPhone/i) ||
                                    navigator.userAgent.match(/Android/i) ?
                                    open : openFileChange}
                                fontSize={50} color="#A3D043" icon="clarity:upload-cloud-line" />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                            <Typography align="center" variant="body2">
                                {props.Title ? props.Title :
                                    t("general_drag_your_file")}
                            </Typography>
                            <Button
                                onClick={navigator.userAgent.match(/iPhone/i) ||
                                    navigator.userAgent.match(/Android/i) ?
                                    open : openFileChange}
                                color="primary"
                                variant="text"
                                sx={{
                                    maxWidth: 380,
                                    fontWeight: "normal",
                                    minHeight: 45,
                                    padding: "5px 10px",
                                    m: "10px auto",
                                }}
                            >
                                {t("general_upload_your_file_xlsx_button")}
                            </Button>
                        </Box>
                        {props.IsPhoto ?
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                <Button
                                    onClick={props.handleCamera}
                                    color="primary"
                                    variant="contained"
                                    style={{ width: '209px', borderRadius: '200px' }}
                                    sx={{
                                        maxWidth: 380,
                                        fontWeight: "normal",
                                        minHeight: 45,
                                        padding: "5px 10px",
                                        m: "10px auto",
                                    }}
                                >
                                    {t("general_take_photo")}
                                </Button>
                            </Box> : null
                        }

                        {formState.errors.pdf && (
                            <Typography
                                variant="body1"
                                align="center"
                                color="tomato"
                            >
                                {t("general_document_required")}*
                            </Typography>
                        )}
                    </DropZoneContainer>
                }
            </Grid>
        </Grid>
    </Box>);
};