import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import {
  Button,
  Typography,
  Grid,
  Box,
  Hidden,
  useTheme,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm, FormProvider } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";

import logoMet from "../../assets/MetLifeLogo.svg";
// import logoDS from "assets/DOC/Icons/DS_Logo.svg";
import ImageLogin from "../../assets/FodoMetlifeFull.jpg";
//import ImageGif from '../../assets/LoaderGif.gif'
import {
  authActions,
  notificationActions,
  validationActions,
  presentationCredentialActions,
} from "../../store/actions";
import { UseImage, Alert, InputField } from "../../components/";
import { IsDevice, obtenerDireccionIP } from "../../utils";
import QRCode from "qrcode.react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import imageLoad from "../../assets/Loading_Overlay.gif";

import { getHash } from "../../utils";
import packageJson from "../../../package.json";
import { SelectLanguage } from "../../components/SelectLanguage";
import { useTranslation } from "react-i18next";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { msalConfig } from "../../msalConfig";
import { sendLanguageMetadata } from "api/language/languageRequests";
import { getProfileData } from "api/procedures/proceduresRequests";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import {
  setAvatarAction,
  setDefaultLanguageAction,
  setNameAction,
} from "store/actions/GenericActions";

const useStyles = makeStyles((theme) => ({
  //Estílos solamente del componente "LoginComponent"
  containerImagen: {
    height: "100vh",
  },
  imagenIlustrativa: {
    height: "100%",
    width: "100%",
    backgroundRepeat: "none",
    backgroundSize: "100%",
    objectFit: "cover",
    transform: "scaleX(-1)",
  },
  imagenLogin: {
    margin: "10px",
    backgroundRepeat: "none",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  loginBox: {
    //top: '18%',
    zIndex: "800",
    top: 0,
    bottom: 0,
    margin: "auto 0",
    width: "28%",
    height: "55% !important",
    // right: '2%',
    overflow: "auto",
    position: "absolute",
    background: "#FFFFFF",
    boxShadow: "0px 4px 100px 0px rgba(138, 138, 138, 0.25)",
    borderRadius: 15,
    padding: "10px 35px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      /* height: "100%",
      position: "relative", */
    },
    "@media only screen and (min-height: 900px)": {
      //height: '55%',
    },
    "@media only screen and (max-height: 899px)": {
      //top: '8%',
      height: "70% !important",
    },
    "@media only screen and (min-height: 2501px)": {
      //top: '10%',
      // height: '60%',
    },
  },
  formInput: {
    width: "100%",
    padding: 1,
  },
  boxReponsive: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  containerForm: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  form: {
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  formAuth: {
    display: "flex",
    //justifyContent: 'space-evenly',
    flexDirection: "column",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  loader: {
    position: "absolute !important",
    top: "0 !important",
    bottom: "0 !important",
    left: "0 !important",
    right: "0 !important",
    height: "100vh !important",
    width: "100% !important",
    zIndex: 999,
  },
  buttonPrimary: {
    border: "1px solid rgba(0, 97, 160, 1) !important",
    background: "#ffffff !important",
    color: " rgba(0, 97, 160, 1) !important",
  },
}));

export const iniciarConexionSignalR = () => {
  const authenticatorUrl = process.env.REACT_APP_URL_VERIFY_CREDENTIALS;

  const connection = new HubConnectionBuilder()
    .withUrl(authenticatorUrl)
    .build();

  connection.start().catch((error) => console.error(error));

  return connection;
};

export default function LoginComponent(props) {
  const theme = useTheme();

  const formMethods = useForm({ mode: "onChange" });
  const { control, handleSubmit, register, formState } = useForm({
    mode: "onBlur",
  });
  const [seconds, setSeconds] = useState(180); // 3 minutos en segundos
  const [isActive, setIsActive] = useState(false);

  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [lat, setLat] = useState("12222");
  const [lon, setLon] = useState("22222");
  const [sendCAPTCHA, setSendCAPTCHA] = useState(false);

  const [loadView, setLoadView] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [formChange, setFormChange] = useState(false);
  const [requestCaptcha, setRequestCaptcha] = useState(false);
  const [captchaSucess, setCaptchaSucess] = useState(false);
  const [ipCurrent, setIpCurrent] = useState("");
  const [tokenSR, setIToken] = useState("");
  const [qrValueVC, setQRValue] = useState("Credenciales Verificadas");
  const [isVisible, setMostrarElemento] = useState(false);
  const [isVisibleQR, setShowQR] = useState(true);
  const [idRef, setIdRef] = useState("");

  const { i18n, t } = useTranslation();

  const classes = useStyles(theme);

  // const fieldStyles = null //mainStyles.useStylesField()
  const navigation = useNavigate();
  const { id } = useParams();
  const { IsMobile } = IsDevice();

  //Redux hooks
  const auth = useSelector((state) => state.Authentication);
  const urlVC = useSelector((state) => state.PresentationVCRemote);
  // const file = useSelector(state => state.FileInfo)
  const catpcha = useSelector((state) => state.catpchaValidation);
  const dispatch = useDispatch();
  const langCode = useSelector((state) => state.SetDefualtLanguage?.lang);
  const isAuthenticated = useIsAuthenticated();
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});
  //remover este log IMPORTANTEEEE

  const {
    data: ProfileData,
    mutate,
    isValidating,
  } = useSWR("ProfileData", getProfileData, {
    refreshInterval: 0,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateOnMount: false,
  });

  /*   const { trigger, isMutating } = useSWRMutation(
    "ProfileData",
    async (url, payload) => {
      return await getProfileData(payload)
        .then((res) => {
          console.log("Res", res);
          return res;
        })
        .catch();
    },
    {
      populateCache: true,
      revalidate: false,
    },
  ); */

  useEffect(() => {
    if (isActive && seconds > 0) {
      const timer = setTimeout(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      setIsActive(false);
    }
  }, [isActive, seconds]);

  useEffect(() => {
    obtenerDireccionIP((ip) => {
      setIpCurrent(ip);
      // console.log("Dirección IP:", ip);
    });
    const conn = iniciarConexionSignalR();
    conn.on("ReceiveMessageGroup", (data) => {
      setIToken(data);
    });
    conn.on("ReceiveMessage", (data) => {
      const objetoJson = JSON.parse(data);
      if (objetoJson.requestStatus == "request_retrieved") {
        setShowQR(false);
      } else {
        /* const AccessToken = objetoJson.Body.Token;
        localStorage.setItem('AccessToken', AccessToken); */
        //navigation('/inbox/homeComponent');
        sessionStorage.clear();
        sessionStorage.setItem(
          //Almacenar token opcion 1: SessionStorage
          "tokenMc",
          objetoJson.Body.Token,
        );
        console.log("authauth 000", objetoJson);
        /* sessionStorage.setItem(
          //Borrar al cerrar sesión
          "userName",
          `${objetoJson.Body.UserLoginData.Name} ${objetoJson.Body.UserLoginData.FatherLastName} ${objetoJson.Body.UserLoginData.MotherLastName}`,
        ); */
        // console.log("Role: ", userAuthenticate.Body.UserLoginData.SecurityLoginData.Roles[0].Name)
        sessionStorage.setItem(
          //Borrar al cerrar sesión
          "Rol",
          `${objetoJson.Body.UserLoginData.SecurityLoginData.Roles[0].Name}`,
        );
        sessionStorage.setItem(
          //Borrar al cerrar sesión
          "avatar",
          `${objetoJson.Body.UserLoginData.Metadata?.find((met) => met.Name === "Picture")?.Value}`,
        );
        dispatch(
          setAvatarAction(
            objetoJson.Body.UserLoginData.Metadata?.find(
              (met) => met.Name === "Picture",
            )?.Value || 0,
          ),
        );
        dispatch(authActions.AuthenticationQA(objetoJson));
        setFormChange(true);
      }
    });
  }, []);

  useEffect(() => {
    caching();
  }, []);

  let caching = async () => {
    dispatch(authActions.CleanAuthentication());
    let version = localStorage?.getItem("versionApp");
    // console.log("version: ", version)
    // console.log(" packageJson.version: ", packageJson.version)
    if (version != packageJson.version) {
      if ("caches" in window) {
        simulateAsyncOperation().catch((error) => {
          console.error("Caught an error:", error);
        });
        // console.log("Ejecuto reload.")
        window.location.reload(true);
      }

      //localStorage.clear();
      localStorage.setItem("versionApp", packageJson.version);
    }
  };

  function simulateAsyncOperation() {
    return new Promise((resolve, reject) => {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      resolve("Ejecucion correcta");
    });
  }
  const showErrorAuth = (messageError) => {
    if (messageError.includes("user_invalid_password"))
      return t("login_user_invalid_password");
    if (messageError.includes("user_inactive")) return t("login_user_disabled");
    if (messageError.includes("user_tempblocked_"))
      return t("login_user_blocked_1", { minutes: messageError.split("_")[2] });
    return messageError;
  };

  useEffect(() => {
    // console.log('params en login', id)
    if (auth !== undefined && auth?.loading && formChange) {
      setLoadView(auth.loading);
    } else if (auth.error !== undefined && formChange) {
      setLoadView(false);
      Alert(
        {
          icon: 0,
          title: showErrorAuth(auth.error),
        },
        () => {
          window.grecaptcha.reset();
          setIdRef("");
        },
        null,
        t,
      );
    } else {
      if (auth.items !== undefined) {
        var filterPermissions =
          auth.items?.UserLoginData?.SecurityLoginData?.Applications?.filter(
            (item) => item.Name == "MesaControl",
          );
        if (filterPermissions?.length > 0) {
          redirectLogin();
        } else if (formChange) {
          setLoadView(false);
          Alert(
            {
              icon: 0,
              title: t("user_not_permissions"),
            },
            null,
            null,
            t,
          );
        }
      }
    }
  }, [auth, formChange]); //Valor a evaluar, sí este cambia, el contenido del arrow se ejecutará

  useEffect(() => {
    if (urlVC.objeto !== undefined) {
      console.log(urlVC);
      setQRValue(urlVC?.objeto?.url);
      setLoadView(false);
    } else {
      setLoadView(false);
    }
  }, [urlVC]); //Valor a evaluar, sí este cambia, el contenido del arrow se ejecutará

  useEffect(() => {
    setLoadView(catpcha.loading);
    if (catpcha.items !== undefined && requestCaptcha) {
      setCaptchaSucess(catpcha.items.Success);
      setIdRef(catpcha.items?.ReferId);
    } else {
      setIdRef("");
    }
  }, [catpcha]);

  useEffect(() => {
    // Cuando es redireccionado a esta view por Microsoft, obtiene datos de la sesion
    // y coninua con el login a MC
    if (isAuthenticated && account) {
      handleValidateToken();
    }
  }, [isAuthenticated, account]);

  const redirectLogin = async () => {
    console.log("Redirect");
    await mutate().then(async (res) => {
      localStorage.setItem(
        "lastDocumentViewMode",
        res?.Preferences?.ViewType === "list"
          ? "DEFAULT_DOCUMENT_VISUALIZATION"
          : "ADVANCED_VISUALIZATION",
      );
      dispatch(setAvatarAction(res?.Preferences?.Picture || 0));
      const name = !!res?.PersonalData
        ? `${res?.PersonalData?.Name} ${res?.PersonalData?.FatherLastName} ${res?.PersonalData?.MotherLastName}`
        : `${auth?.items?.UserLoginData?.FullName}`;
      dispatch(setNameAction(name));
      sessionStorage.setItem("avatar", res?.Preferences?.Picture || 0);
      sessionStorage.setItem("userName", name);
      await changeLanguage(res?.Preferences?.Language);
    });

    sessionStorage.setItem('enviroment',auth.items?.enviroment ?? "OBV3");

    dispatch(notificationActions.GetNotificationsUser());
    if (formChange && id === undefined) {
      navigation("/inbox/homeComponent");
    } else if (formChange && id !== undefined) {
      navigation("/inbox/homeComponent");
    }
  };
  //Acción login
  let submitLogin = async (formData) => {
    //Login
    setLoadView(true);
    try {
      let request = {
        //Objeto a enviar a API login
        RequestDataInfo: {
          Coordinates: {
            Latitude: lat,
            Longitude: lon,
          },
          SOVersion: "2",
          PhoneBrand: "5525397878",
          PhoneModel: "vr",
          BrowserRefer: "chrome",
          IP: ipCurrent,
          MacAddress: "FC:01:7C:45:48:3F",
        },
        ReferId: idRef,
        Tenant: "",
        Username: formData["txtUser"],
        Password: formData["txtPassword"],
        PasswordHash: getHash(formData["txtPassword"]),
      };

      dispatch(authActions.Authentication(request));
    } catch (error) {
    } finally {
      /*  await trigger().then(async (res) => {
        localStorage.setItem(
          "lastDocumentViewMode",
          res.Preferences?.ViewType === "list"
            ? "BASIC_VISUALIZATION"
            : "ADVANCED_VISUALIZATION",
        );
        await changeLanguage(res.Preferences?.Language);
      }); */

      setLoadView(false);
      setFormChange(true);
    }
  };

  const changeLanguage = async (language) => {
    console.log("LANG: ", language);
    localStorage.setItem("lang", language || langCode);

    i18n.changeLanguage(language || langCode);
    await sendLanguageMetadata([
      {
        GroupName: "System Language",
        Name: "Code",
        Value: language || langCode,
      },
    ]);
    dispatch(setDefaultLanguageAction(language || langCode));
  };
  // console.log('ProfileData:::',ProfileData)
  async function OnCredentialAccess() {
    setLoadView(true);
    dispatch(presentationCredentialActions.GetPresentation(null, true));
    setQRValue("");
    const vcPresent = dispatch(
      presentationCredentialActions.GetPresentation(tokenSR),
    );
    setMostrarElemento(!isVisible);
    console.log(vcPresent);
    setSeconds(180);
    setIsActive(true);
  }

  function catpchaChange(event) {
    // console.log("captcha: ", event)
    dispatch(validationActions.GetCatpcha(event));
    setRequestCaptcha(true);
  }

  const returnLogin = () => {
    setMostrarElemento(false);
    setIsActive(false);
    setShowQR(true);
  };

  const handleLoginWithSSO = async () => {
    //llama la instancia para abrir la pagina de login de microsoft
    await instance.handleRedirectPromise();

    if (accounts.length === 0) {
      instance
        .loginRedirect({
          scopes: ["User.Read"],
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const getAccessToken = async () => {
    try {
      const response = await instance.acquireTokenSilent({
        ...msalConfig,
        account: account,
      });
      if (response.accessToken) return response.accessToken;
      return "";
    } catch (error) {
      console.log(error);
      return "";
    }
  };

  const handleValidateToken = async () => {
    //cuando Microsoft redirecciona a esta view cuando la
    //redireccion es correcta, hace login con MC
    try {
      //obtiene accessToken para validacion
      const accessToken = await getAccessToken();
      //eliminar este log
      console.log({ accessToken });
      const request = {
        Username: "",
        PasswordHash: "",
        SelfieB64: "",
        Email: account.username,
        Token: accessToken, //account.idToken,
        RequestDataInfo: {
          File_Id: 0,
          Coordinates: { Latitude: "", Longitude: "" },
          SOVersion: "",
          PhoneBrand: "",
          PhoneModel: "",
          IP: "",
          MacAddress: "",
          BrowserRefer: "",
        },
      };
      //eliminar este log
      console.log("request", { request });
      dispatch(authActions.AuthenticationSSO(request));
      setFormChange(true);
    } catch (error) {
      console.log(error);
      Alert({
        icon: "error",
        title: "Ocurrio un error",
        text: error.message,
      });
    }
  };

  return (
    <FormProvider {...formMethods} theme={theme}>
      {loadView && (
        <LoadingOverlay
          active={loadView}
          spinner
          text={t("general_loading_message")}
          className={classes.loader}
        ></LoadingOverlay>
      )}
      <Grid container>
        {/* <Hidden smDown>
          <Grid item lg={12} md={12}>
            <Box className={classes.containerImagen}>
              <UseImage src={ImageLogin} type='img' className={classes.imagenIlustrativa} />
            </Box>
          </Grid>
        </Hidden> */}
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ width: "100%", height: "100vh" }}
        >
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"flex-end"}
            sx={{
              zIndex: 700,
              position: "absolute",
              width: "100%",
              height: "100vh",
            }}
          >
            <Box
              sx={{
                pt: 3,
                pr: 6,
                position: "absolute",
                // width: '15%'
              }}
            >
              <SelectLanguage />
            </Box>
          </Stack>

          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            sx={{ width: "100%", height: "100vh" }}
          >
            <Box
              sx={{
                background:
                  "linear-gradient(to right bottom, rgba(255,255,255,1),rgb(174, 220, 202,1),rgba(137,203,240,1),rgba(255,255,255,1) )",
                // "linear-gradient(to right bottom, #c20e30,#FAC2CC, #a9a9a9,#5B5B5B)",
                width: "100%",
                height: "100vh",
              }}
            ></Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              height: "100vh",
              position: "absolute",
              zIndex: "100",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              /* boxShadow:
                "inset 18.0333px -18.0333px 18.0333px rgba(194, 194, 194, 0.1), inset -18.0333px 18.0333px 18.0333px rgba(255, 255, 255, 0.1)", */
              boxShadow:
                "inset 18.0333px -18.0333px 18.0333px rgba(194, 194, 194, 0.1), inset -18.0333px 18.0333px 18.0333px rgba(255, 255, 255, 0.1)",
              backdropFilter: "blur(18.0333px)",
            }}
          ></Box>

          <Box
            className={classes.loginBox}
            sx={IsMobile && { maxWidth: "90%" }}
          >
            <Box className={classes.containerForm}>
              {!isVisible && (
                <Box className={classes.form}>
                  <Box
                    display={"flex"}
                    justifyContent="start"
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    <Box sx={{ width: 150 }}>
                      <UseImage
                        src={logoMet /* logoDS */}
                        type="img"
                        className={classes.imagenLogin}
                      />
                    </Box>
                  </Box>
                  <Typography
                    variant="h4"
                    style={{
                      textAlign: "left",
                      padding: "0px !impotant",
                      paddingTop: "5px",
                    }}
                    gutterBottom
                  >
                    <b>{t("login_sign_in")}</b>
                  </Typography>
                  <InputField
                    inputType="text"
                    control={control}
                    register={register}
                    name="txtUser"
                    label={t("login_user")}
                    validations={{ required: true }}
                    required={true}
                    error={!!formState.errors["txtUser"]}
                    helperText={formState.errors["txtUser"]?.message}
                  />
                  <InputField
                    isPassword={true}
                    inputType="password"
                    control={control}
                    register={register}
                    name="txtPassword"
                    label={t("login_password")}
                    validations={{ required: true }}
                    required={true}
                    error={!!formState.errors["txtPassword"]}
                    helperText={formState.errors["txtPassword"]?.message}
                  />
                  <Box
                    sx={{
                      marginTop: "0.5em",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <ReCAPTCHA
                      key={i18n.language}
                      hl={i18n.language}
                      className="iframeCaptcha"
                      sitekey="6LeSrKEaAAAAAGnBnM94eRtpu1Z7PwnY3WpOyDvf"
                      tabindex={100}
                      onChange={catpchaChange}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      marginTop: "0.5em",
                    }}
                  >
                    <Box sx={{ width: "85%", py: 1 }}>
                      <Button
                        id="btnSignIn"
                        fullWidth
                        variant="contained"
                        sx={{
                          height: 48,
                          // px: 10,
                          "& .Mui-disabled": {
                            backgroundColor: "unset !important",
                          },
                        }}
                        type={captchaSucess ? "submit" : "button"}
                        className={classes.buttonDefault}
                        onClick={handleSubmit(submitLogin)}
                        disabled={idRef === ""}
                      >
                        {t("login_sign_in")}
                      </Button>
                    </Box>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      textAlign: "center",
                      marginTop: "1em",
                    }}
                  >
                    <Box style={{ width: "85%" }}>
                      <Button
                        id="btnSignInCredential"
                        fullWidth
                        variant="contained"
                        sx={{  px: 10 }}
                        className={classes.buttonPrimary}
                        type={"button"}
                        onClick={OnCredentialAccess}
                      >
                        {t("sign_credential")}
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      textAlign: "center",
                      marginTop: "1em",
                    }}
                  >
                    <Box style={{ width: "85%" }}>
                      <Button
                        id="btnSignInSSO"
                        fullWidth
                        variant="contained"
                        className={classes.buttonPrimary}
                        sx={{ px: 10 }}
                        onClick={handleLoginWithSSO}
                      >
                        {t("sign_sso")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}

              {isVisible && (
                <Box className={classes.formAuth}>
                  <Typography
                    variant="h4"
                    style={{ color: "#000000" }}
                    gutterBottom
                  >
                    {t("sign_authenticator")}
                  </Typography>
                  {isActive == false ? (
                    <Box>
                      <Typography variant="subtitle" gutterBottom>
                        {t("login_not_be_confirmed")}
                      </Typography>
                      <Box sx={{ mt: 8 }}>
                        <Button
                          fullWidth
                          variant="contained"
                          sx={{ height: 45, px: 10 }}
                          type={"button"}
                          onClick={() => returnLogin()}
                        >
                          {t("login_return")}
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    isVisibleQR && (
                      <Box>
                        <Typography variant="subtitle" gutterBottom>
                          {t("scan_qr")}
                        </Typography>
                        <Box
                          sx={{ mt: 4 }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {qrValueVC == "" ||
                          qrValueVC == null ||
                          qrValueVC == undefined ? (
                            <Box
                              sx={{
                                display: "fllex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <UseImage
                                src={logoMet /* logoDS */}
                                width={"95%"}
                              />
                            </Box>
                          ) : (
                            <QRCode size={290} value={qrValueVC} />
                          )}
                        </Box>
                      </Box>
                    )
                  )}
                  {/* && isActive == true */}
                  {!isVisibleQR && isActive == true ? (
                    <Box>
                      <Box sx={{ textAlign: "center" }}>
                        <Typography variant="subtitle" gutterBottom>
                          {t("login_confirm_access")}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ display: "flex", alignItems: "center", mt: 4 }}
                      >
                        <UseImage src={imageLoad} type="img" />
                      </Box>
                    </Box>
                  ) : null}
                  {isActive && (
                    <Box sx={{ mt: 2 }}>
                      <Button
                        fullWidth
                        variant="contained"
                        sx={{ height: 45, px: 10 }}
                        type={"button"}
                        onClick={OnCredentialAccess}
                      >
                        {t("login_user_and_pass")}
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Stack>
        <Box
          sx={(theme) => ({
            height: "35px",
            position: "absolute;",
            // backgroundColor: `${theme.palette.footerBar.main} !important`,
            width: "100%",
            color: "#FFF",
            bottom: 0,
            ["@media only screen and (max-width: 600px)"]: {
              width: "100%",
            },
            zIndex: "900",
          })}
        >
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={(theme) => ({
              width: "100%",
              color: "black",
              textAlign: "center",
              letterSpacing: "0.53px",
              margin: "inherit !important",
              marginBottom: "0px !important",
              paddingBottom: "0px !important",
            })}
          >
            {`${t("powered_by")} ${t("general_version")}: ${packageJson.version}`}
          </Typography>
        </Box>
      </Grid>
    </FormProvider>
  );
}
