export const notificationConstants = {
  NEW_PIN_REQUEST: 'NEW_PIN_REQUEST',
  NEW_PIN_SUCCESS: 'NEW_PIN_SUCCESS',
  NEW_PIN_FAILURE: 'NEW_PIN_FAILURE',

  GET_NOTIFICATIONS_USER_REQUEST: 'GET_NOTIFICATIONS_USER_REQUEST',
  GET_NOTIFICATIONS_USER_SUCCESS: 'GET_NOTIFICATIONS_USER_SUCCESS',
  GET_NOTIFICATIONS_USER_FAILURE: 'GET_NOTIFICATIONS_USER_FAILURE',

  NIP_REQUEST: 'NIP_REQUEST',
  NIP_SUCCESS: 'NIP_SUCCESS',
  NIP_FAILURE: 'NIP_FAILURE',

  SENDNIP_REQUEST: 'SENDNIP_REQUEST',
  SENDNIP_SUCCESS: 'SENDNIP_SUCCESS',
  SENDNIP_FAILURE: 'SENDNIP_FAILURE',
};
