import axios from 'axios';
import { AppUrls, } from './Globals';
import { Config } from './Config';

export const notificationRequests = {}

notificationRequests.GetNewPin = (data) => {
  return axios.post(
    AppUrls.GetNewPin,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigNoAuth()
  ).then(handleResponse)
}

notificationRequests.GetNotificationsUser = (data) => {
  return axios.post(
    AppUrls.GetByUsuarioRolNotificacionesPortal,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

notificationRequests.ReSendNip = (data) => {//Función de autenticación
  return axios.post(
    AppUrls.ResendNip,//url
    Config.SetParameters(data, '', '', ''),//Objeto a enviar al API
    Config.ConfigAuth(sessionStorage.getItem('token'))//headers
  ).then(handleResponse)//next => actions => AuthActions
}

notificationRequests.SendNip = (data) => {//Función de autenticación
  return axios.post(
    AppUrls.validatePIN,//url
    Config.SetParameters(data, '', '', ''),//Objeto a enviar al API
    Config.ConfigAuth(sessionStorage.getItem('token'))//headers
  ).then(handleResponse)//next => actions => AuthActions
}

let handleResponse = (response) => {
  console.log('RESPONSEDATA', response)
  let respObject = response.data;//BodySecurityResponse(response.data);
  if (!respObject.IsOK) {
    let responseObject = { message: respObject.Messages + ' ' + (respObject.TansactionId ? respObject.TansactionId : '') + ' ' + (respObject.statusText ? respObject.statusText : '') };
    let error = (respObject.Body || responseObject);
    return Promise.reject(error);
  }
  return respObject;
}