import {Box, styled} from "@mui/material";
import { Card as MUICard } from "@mui/material";

export const DocumentCard = styled(MUICard)(({ theme }) => ({
  height: '5rem',
  borderRadius:'0.5rem',
  display:'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '2rem',
  gap:'1.5rem',
  textAlign:'center',
  '&:hover': {
    backgroundColor: `${theme.palette.primary.tableHover} !important`,
    cursor:'pointer'
  },
}));

export const Card = styled(MUICard)(({ theme }) => ({
  width:'18rem',
  height: '20rem',
  borderRadius:'0.5rem',
  display:'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem',
  gap:'1.5rem',
  textAlign:'center',
  boxShadow:'40rem',
  '&:hover': {
    backgroundColor: `${theme.palette.primary.tableHover} !important`,
    cursor:'pointer'
  },
}));

export const ChargeStatusBox = styled(Box)(({ theme }) => ({
  width:'5rem',
  backgroundColor: `${theme.palette.primary.tableHeadLight} !important`,
  color: 'green',
  padding: '0.5rem 0',
  borderRadius: '2rem'
}));