import React, { useTransition } from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { Box, Grid, Typography, styled } from '@mui/material';
// import { theme } from '../styles'

const rows = [{
    id: '0001',
    name: 'Brayan',
    puesto: 'dev'
},
{
    id: '0002',
    name: 'Brayan',
    puesto: 'dev'
},
{
    id: '0003',
    name: 'Brayan',
    puesto: 'dev'
}]

const BoxContainer = styled(Box)(({ theme }) => ({
    height: "auto",
    width: "100%",
    justifyContent: "space-between",
    marginTop: 5,
    marginBottom: 1,
    borderBottom: "1px solid #D9D9D9",
    display: "flex",
}));

const BoxTitulo = styled(Box)(({ theme }) => ({
    wordWrap: "break-word",
    width: "40%",
    mt: 0.3,
    [theme.breakpoints.down("sm")]: {
        width: "40%",
    },
}));

const BoxTexto = styled(Box)(({ theme }) => ({
    wordWrap: "break-word",
    width: "60%",
    mt: 0.3,
    [theme.breakpoints.down("sm")]: {
        width: "60%",
    },
}));

export default function TableUsersSigners() {
    const { t } = useTransition()

    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    console.log('selected', selected)

    return (
        <div>
            { }
            <Grid container spacing={3}>
                {rows.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                        <Grid item lg={3} xs={12}>
                            <Box
                                onClick={(event) => handleClick(event, row.id)}
                                aria-checked={isItemSelected}
                                style={{
                                    p: 1,
                                    m: 0.8,
                                    border: 1.5,
                                    borderColor: "#ECECEC",
                                }}
                            >
                                <Box sx={(theme) => ({ background: theme.palette.userStyle.textInstruccions, color: 'white' })}>
                                    <Typography variant="h6" >
                                        {t("table_geid_folio")}:
                                    </Typography>
                                    <Checkbox
                                        checked={isItemSelected}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </Box>
                                <Statements title={"Nombre documental"} text={''} />
                                <Statements
                                    title={"Nombre del solicitante"}
                                    text={''}
                                />
                                <Statements
                                    title={"Fecha de creación"}
                                    text={''}
                                />
                                <Statements title={"Fecha límite"} text={''} />
                                <Statements title={"Estatus"} text={''} />
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    );
}

const Statements = (props) => {
    return (
        <BoxContainer>
            <BoxTitulo>
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    {props.title}
                </Typography>
            </BoxTitulo>
            <BoxTexto>
                <Typography variant="subtitle1" textAlign={"right"}>
                    {props.text}
                </Typography>
            </BoxTexto>
        </BoxContainer>
    );
};
