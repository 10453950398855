import { workFlowConstants } from "../constants";
import { workFlowRequests } from "../../services";

export const workFlowActions = {};

workFlowActions.GetBinnacleInfo = (data) => {
  return (dispatch) => {
    dispatch(request());
    workFlowRequests.GetBinnacleInfo(data).then(
      (binnacleInfo) => dispatch(success(binnacleInfo)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: workFlowConstants.WF_BINNACLE_REQUEST };
  }

  function success(binnacleInfo) {
    return { type: workFlowConstants.WF_BINNACLE_SUCCESS, binnacleInfo };
  }

  function failure(error) {
    return { type: workFlowConstants.WF_BINNACLE_FAILURE, error };
  }
};

workFlowActions.ContinueWorkFlow = (data, isReset = false) => {
  return async (dispatch) => {
    if (isReset) {
      dispatch(reset());
    } else {
      try {
        dispatch(request());
        const contineWFData = await workFlowRequests.ContinueWorkFlow(data);
        dispatch(success(contineWFData));
      } catch (error) {
        dispatch(failure(error.message));
      }
    }
  };

  function request() {
    return { type: workFlowConstants.CONTINUE_WF_REQUEST };
  }

  function success(contineWFData) {
    return { type: workFlowConstants.CONTINUE_WF_SUCCESS, contineWFData };
  }

  function failure(error) {
    return { type: workFlowConstants.CONTINUE_WF_FAILURE, error };
  }

  function reset() {
    console.log("FileUpdateMetadata RESET");
    return { type: workFlowConstants.CONTINUE_WF_RESET };
  }
};

workFlowActions.ReturnStage = (data) => {
  return async (dispatch) => {
    try {
      dispatch(request());
      const returnWFData = await workFlowRequests.ReturnStage(data);
      dispatch(success(returnWFData));
    } catch (error) {
      dispatch(failure(error.message));
    }
  };

  function request() {
    return { type: workFlowConstants.RETURN_STAGE_REQUEST };
  }

  function success(returnWFData) {
    return { type: workFlowConstants.RETURN_STAGE_SUCCESS, returnWFData };
  }

  function failure(error) {
    return { type: workFlowConstants.RETURN_STAGE_FAILURE, error };
  }
};

workFlowActions.RegisterStatusStage = (data) => {
  return dispatch => {
    dispatch(request())
    workFlowRequests.RegisterStatusStage(data)
      .then(
        regStatusStage => dispatch(success(regStatusStage)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: workFlowConstants.REG_STATUSSTAGE_REQUEST }
  }

  function success(regStatusStage) {
    return { type: workFlowConstants.REG_STATUSSTAGE_SUCCESS, regStatusStage }
  }

  function failure(error) {
    return { type: workFlowConstants.REG_STATUSSTAGE_FAILURE, error }
  }
}