import { Fragment, useEffect, useState } from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import { OBTableCellHead, OBTableContainer } from 'components/common/Table/Table.styled';
import { Box, CircularProgress, IconButton, Table, Tooltip } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTranslation } from 'react-i18next';

const getRowsPerPage = (height, rows) => {
  if (height < 769) return rows-2;
  if (height <= 900) return rows-1;
  return rows;
};

const MainTable = ({ rows, columns, render, loading = false, rowPerPage= 5, rowsSize= 'small', children, isDefaultPaginator = true}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(getRowsPerPage(window.innerHeight, rowPerPage));
  const [orderDirection, setOrderDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setRowsPerPage(getRowsPerPage(window.innerHeight, rowPerPage));
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChangePage = (event, newPage) => {
    console.log('newPage: ',newPage)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handlerSort = (event, property) => {
    const isAscending = valueToOrderBy === property && orderDirection === 'asc';
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? 'desc' : 'asc');
  };

  const createSortHandler = (property) => (event) => {
    handlerSort(event, property);
  };

  return (
    <Box sx={{ width: '100%', paddingTop: '1rem', position: 'relative' }}>
      <OBTableContainer loading={loading}>
        {loading ? (
          <CircularProgress
            color="inherit"
            size="2rem"
            sx={{
              position: 'absolute',
              top: '40%',
              right: '45%'
            }}
          />
        ) : null}
        <Table stickyHeader aria-label="sticky table" size={rowsSize}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <OBTableCellHead
                  key={column.id}
                  style={{ minWidth: column.minWidth, maxWidth: column.maxWidth, textAlign:'center' }}
                  onClick={createSortHandler(column.label)}
                >
                  {column.label}
                </OBTableCellHead>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <Fragment key={index}>{render(row)}</Fragment>
            ))}
          </TableBody>
        </Table>
      </OBTableContainer>{
        isDefaultPaginator ?       <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        ActionsComponent={CustomTablePaginationActions}
        labelRowsPerPage="Filas por página"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`
        }
      /> : <>{children}</>
      }

    </Box>
  );
};

function CustomTablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;
  const { t } = useTranslation()

  const handleNextPage = (event) => {
    onPageChange(event, page+1);
  };

  const handlePreviousPage = (event) => {
    onPageChange(event, page-1);
  };


  // ... (otras funciones para manejar los clicks en las flechas)

  return (
    <div style={{ flexShrink: 0, marginLeft: 2, marginRight: 2 }}>
      <Tooltip title={t("previous_page")}>
        <IconButton
          onClick={handlePreviousPage}
          disabled={page === 0}
          aria-label="first page"
        >
          <NavigateBeforeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("next_page")}>
        <IconButton
          onClick={handleNextPage}
          disabled={page === count}
          aria-label="first page"
        >
          <NavigateNextIcon />
        </IconButton>
      </Tooltip>
      {/* ... (otros botones con sus respectivos tooltips) */}
    </div>
  );
}

export default MainTable;
