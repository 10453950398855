import { Box, styled } from '@mui/material';

export const OptionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'left',
  width: '100%',
  background: theme.palette.primary.mainLyrics,
}));
