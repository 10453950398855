import { Box, Typography } from '@mui/material';
import { OptionsContainer } from './SelectOptions.styled';

const SelectOptions = ({ description, children }) => {
  return (
    <OptionsContainer>
      <Typography variant="h6" sx={{ textAlign: 'center' }}>
        {description}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </Box>
    </OptionsContainer>
  );
};

export default SelectOptions;
