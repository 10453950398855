import React, { useState } from 'react';
import { Typography, Box, Select, MenuItem, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UploadSlot from 'components/common/UploadSlot';
import { generateTemplate, getAllDocuments } from 'api/massiveFlow/massiveFlowRequests';
import { AlertModal } from 'components/common/Modals/Views/AlertModal';
import { CustomLoadingOverlay } from 'components';
import { ReactComponent as ExcelDownloadIcon } from 'assets/excelDownloadIcon.svg';
import useSWR from 'swr';

export const MassiveUploader = ({ OnSetTemplateFile }) => {
  const { t } = useTranslation();
  const [documentTypes, setDocumentTypes] = useState();
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState({});
  const { data: groupersData, isValidating } = useSWR('GetAllDocuments', () => getAllDocuments(), {
    refreshInterval: 0,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const handleSelectDocumentGroup = (event) => {
    const { value } = event.target;
    setDocumentTypes(value);
    setSelectedDocumentTypes({
      ...selectedDocumentTypes,
      DocumentalGroup_Id: value.Id,
    });
  };

  const handleSelectDocumentType = (event) => {
    const { value } = event.target;
    setSelectedDocumentTypes({
      ...selectedDocumentTypes,
      DocumentType_Id: value,
    });
  };

  if (isValidating) return <CustomLoadingOverlay active={isValidating} />;

  return (
    <Box sx={{ height: '100%' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Typography variant="span">
          {t('massive_upload_new_description_1')}
        </Typography>
        <DowloadExcelTemplate payload={selectedDocumentTypes} />
      </Box>

      <Box display="flex" flexDirection="column" gap="1rem" margin="2rem 0rem" height="90%">
        <Typography variant={'subtitle2'}>
          {t('documentation_table_view_upload_doc_grouper')}
        </Typography>
        <Select defaultValue="default" name="DocumentGroupId" onChange={handleSelectDocumentGroup}>
          <MenuItem value="default" disabled>
            {t('configuration_docStructure_add_doc_select_grouper')}
          </MenuItem>
          {groupersData?.DocumentalGroups?.map((option) => (
            <MenuItem key={option.Id} value={option}>
              {option.Name}
            </MenuItem>
          ))}
        </Select>
        <Typography variant={'subtitle2'}>{t('documentation_table_view_upload_doc')}</Typography>
        <Select
          defaultValue={'default'}
          name="DocumentGroupId"
          onChange={handleSelectDocumentType}
          disabled={!documentTypes}
        >
          <MenuItem value="default" disabled>
            {t('documentation_table_view_select_upload_doc')}
          </MenuItem>
          {documentTypes?.DocumentTypes?.map((option) => (
            <MenuItem key={option.Id} value={option.Id}>
              {option.Name}
            </MenuItem>
          ))}
        </Select>

        <Typography variant="span">{t('massive_download_new_description_2')}</Typography>
        <Box height={'30vh'}>
          <UploadSlot
            allowedExtensions={['xlsx']}
            isExcelDocument
            onSendData={(fileB64, extension) => OnSetTemplateFile({ fileB64, extension })}
            isOnlyUploadSlot
          />
        </Box>
      </Box>
    </Box>
  );
};

export function DowloadExcelTemplate({ payload }) {
  const { t } = useTranslation();

  const DowloadTemplate = async () => {
    try {
      const res = await generateTemplate(payload);
      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res}`;
      const downloadLink = document.createElement('a');
      const fileName = `${payload.DocumentalGroup_Id}-${payload.DocumentType_Id}-template.xlsx`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } catch (error) {
      AlertModal('Error', error.message, 'error', t);
    }
  };

  return (
    <Box>
      <Tooltip title={t('download_search_download_data')} placement="top">
        <IconButton
          sx={{
            width: '100%',
            fontSize: 16,
            fontWeight: 500,
            mt: 0.5,
            maxWidth: 300,
          }}
          variant="contained"
          color="primary"
          onClick={() => DowloadTemplate()}
        >
          <ExcelDownloadIcon
            style={{ filter: !(Object.keys(payload)?.length >= 2) ? 'grayscale(1)' : 'none' }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
