import { Box } from "@mui/material";
import { RoundedButton } from "../Buttons";
import { ClearableTextField } from "../common/ClearableTextField";
import { useTranslation } from "react-i18next";

export const BasicSearchFilter = ({
  formData,
  onInputChange,
  onInputClear,
  onSearch,
  onClearResults,
}) => {
  const { t } = useTranslation();
  const basicFilterSettings = {
    SearchType: "Basic",
    Search: formData.Search,
  };

  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      gap={{ xs: ".3rem", md: "2rem" }}
    >
      <ClearableTextField
        name="Search"
        value={formData.SearchFile}
        onChange={onInputChange}
        placeholder={t("search_basic_placeholder")}
        onClear={onInputClear}
        size={"small"}
      />
      <RoundedButton
        variant="contained"
        sx={{
          minWidth: "15%",
          height: "2.4rem",
          /* "&:hover": {
            backgroundColor: "red",
          }, */
        }}
        onClick={() => onSearch(basicFilterSettings)}
      >
        {t("general_search")}
      </RoundedButton>
      <RoundedButton
        variant="outlined"
        sx={{
          minWidth: "15%",
          height: "2.4rem",
          "&:hover": {
            backgroundColor: "white",
          },
        }}
        onClick={onClearResults}
      >
        {t("search_clean")}
      </RoundedButton>
    </Box>
  );
};
