import { useStyles } from 'components/NoShares';
import { Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { UseImage } from 'components';
import { Box } from '@mui/system';
import help_front_desktopImg from 'assets/INE_front.png';
import help_back_desktopImg from 'assets/INE_back.png';

export const HelpDialog = ({ sectionName, DocName, onCapture }) => {
  const localStyles = useStyles();

  useEffect(() => {
    if (sectionName === 'Anverso') {
      localStorage.setItem('TransactionId', null);
    }
  }, [sectionName]);

  const getDesktopImageSrc = () => {
    return sectionName === 'Anverso' ? help_front_desktopImg : help_back_desktopImg;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Button
        onClick={onCapture}
        variant="contained"
        sx={{ alignSelf: 'end', width: '100px', borderRadius: 0 }}
      >
        Capturar
      </Button>
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h6" paddingBottom="1rem">
          {getDataStatusCapture(sectionName, DocName)}
        </Typography>
        <p>
          A continuación deberás realizar la&nbsp;
          {getDataStatusCaptureDescription(sectionName, DocName)}
        </p>
        <p style={{ marginTop: '0.5em' }}>
          Asegurate de enfocar los datos correctos y evita sombras y reflejos.{' '}
        </p>
      </div>

      <div className={localStyles.carouselSection1}>
        <img
          alt=""
          src={getDesktopImageSrc()}
          style={{ width: '100%', height: '75%', objectFit: 'contain' }}
        />
      </div>
    </Box>
  );
};

const getDataStatusCapture = (sectionName, DocName) => {
  switch (sectionName) {
    case 'Anverso':
      return `Captura el frente de tu ${DocName}`;
    case 'Reverso':
      return `Capturar la parte trasera de tu ${DocName}`;
    default:
      return `Captura la ${sectionName} de tu ${DocName}`;
  }
};

const getDataStatusCaptureDescription = (sectionName, DocName) => {
  switch (sectionName) {
    case 'Anverso':
      return `captura del frente de tu ${DocName}`;
    case 'Reverso':
      return `captura del reverso de tu ${DocName}`;
    default:
      return `Captura la ${sectionName} de tu ${DocName}`;
  }
};
