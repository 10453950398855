import React, { useState } from "react";
import { AddDocumentForm } from "./AddDocumentForm";
import {DocumentView} from "./DocumentView";
import {DocumentMetadataView} from "./DocumentMetadata/DocumentMetadataView";
import {AddDocumentMetadataForm} from "./DocumentMetadata/AddDocumentMetadataForm";

export const DocumentalStructure = () => {
  const [mode, setMode] = useState("DOCUMENT_VIEW");
  const [selectedDocument, setSelectedDocument] = useState()
  const [selectedDocumentMetadata, setSelectedDocumentMetadata] = useState()

  const handleMode = (view) => {
    setMode(view);
  };

  const handleSelectedDocument = (document) => {
    setSelectedDocument(document);
  }

  const RENDER_COMPONENT_BY_MODE = {
    DOCUMENT_VIEW: (
      <DocumentView handleMode={handleMode} setSelectedDocument={setSelectedDocument}/>
    ),
    ADD_DOCUMENT_FORM: (
      <AddDocumentForm handleMode={handleMode} handleSelectedDocument={handleSelectedDocument}/>
    ),
    DOCUMENT_METADATA_VIEW: (
      <DocumentMetadataView handleMode={handleMode} selectedDocument={selectedDocument} setSelectedDocumentMetadata={setSelectedDocumentMetadata}/>
    ),
    ADD_DOCUMENT_METADATA_FORM: (
      <AddDocumentMetadataForm handleMode={handleMode} selectedDocumentMetadata={selectedDocumentMetadata} selectedDocument={selectedDocument}/>
    )
  }

  return (
    <>
      {RENDER_COMPONENT_BY_MODE[mode]}
    </>
  );
};
