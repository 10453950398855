import { Typography, Box, Button } from '@mui/material';
import selfie from 'assets/selfie.png';

export default function LifeTestHelp({ handleChangeCurrentStep }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Button
        onClick={() => handleChangeCurrentStep('FACE_VALIDATION')}
        variant="contained"
        sx={{ alignSelf: 'end', width: '100px', borderRadius: 0 }}
      >
        Capturar
      </Button>
      <div>
        <Typography variant="h6" borderBottom="1rem">
          Captura de fotografía
        </Typography>
        <p>A continuación deberás realizar la captura del rostro del cliente</p>
        <p>Deberás centrar su rostro en el ovalo y tomar una fotografía.</p>
      </div>

      <div>
        <img src={selfie} alt="" width="300px" height="300px" style={{ objectFit: 'contain' }} />
      </div>
    </Box>
  );
}
