import {Box as MUIBox} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBox = styled(MUIBox)(({theme}) => ({
  display: 'flex',
  borderBottom: `1px solid #0060A2`,
  padding: '1rem 0',
  alignItems: 'center',
  gap: '2rem',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
}));
