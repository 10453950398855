import { genericConstants } from "../constants/GenericConstants";

export const addDataCaptureIdentification = (data) => {
    return {
        type: genericConstants.ADD_IDENTIFICATION_DATA_SUCCESS,
        payload: data,
    };
};

export const setDefaultLanguageAction = (data) => {
    return {
        type: genericConstants.SET_DEFAULT_LANGUAGE,
        payload: data,
    };
};

export const setAvatarAction = (data) => {
    return {
        type: genericConstants.SET_AVATAR,
        payload: data,
    };
};


export const setNameAction = (data) => {
    return {
        type: genericConstants.SET_NAME,
        payload: data,
    };
};