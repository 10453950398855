import { fetcher } from 'api/fetcher';
import {
  PATH_NOTIFY_DATA,
  PATH_UPDATE_EMAILS,
  PATH_BLOCK_NOTIFICATION,
  PATH_READ_NOTIFICATION,
  SEND_NOTIFICATION_PATH
} from './urls';

export const getNotifyData = async () => await fetcher(PATH_NOTIFY_DATA, null);

export const updateEmailsNotification = async (data) => await fetcher(PATH_UPDATE_EMAILS, data);

export const blockNotifications = async (data) => await fetcher(PATH_BLOCK_NOTIFICATION, data);

export const sendNotification = async (data) => await fetcher(SEND_NOTIFICATION_PATH, data);

export const readNotification = async ({ arg: data }) =>
  await fetcher(PATH_READ_NOTIFICATION, data);
