import { Box, Typography } from '@mui/material';
// import ExpiredTokenImg from 'assets/DOC/Icons/expiredToken.svg';
import ExpiredTokenImg from 'assets/Metlife/Icons/expiredToken.png';
import { useTranslation } from 'react-i18next';

export const ExpiredToken = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'grid',
        placeContent: 'center',
        placeItems: 'center',
        height: '100vh',
        width: '100%',
        textAlign: 'center',
      }}
    >
      <img
        src={ExpiredTokenImg}
        alt=""
        style={{ width: '300px', height: '300px', objectFit: 'contain' }}
      />
      <Typography variant="h2">{t('expired_token')}</Typography>
    </Box>
  );
};
