import React from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Link,
  Button,
  styled,
  AppBar,
  Container,
  Box,
  Typography,
  useTheme,
  Divider,
  IconButton,
  Hidden,
  Badge,
} from "@mui/material";

import { UseImage } from "./UseImage";
import { useWindowDimensions } from "../utils";
// import logoDS from "assets/DOC/Icons/DS_Logo.svg";
import logoDS from "assets/MetLifeLogo.svg";
import { useTranslation } from "react-i18next";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { FileDownload } from "@mui/icons-material";
import UserCard from "./Auth/UserCard";
import { SelectLanguage } from "./SelectLanguage";
import useSWR from "swr";
import { userHasNetNotify } from "../api/procedures/proceduresRequests";

const MenuButton = styled(Link)(({ theme }) => ({
  margin: "10px 0px 10px 0px",
  borderRight: `1px solid ${theme.palette.secondary.main10}`,
  textAlign: "left",
  fontSize: 14,
  "&:hover": {
    backgroundColor: theme.palette.menuColor.main,
    color: theme.palette.menuColor.text,
  },
  height: "35px",
  // borderRadius: '7px',
  "& .MuiButton-startIcon": {
    marginBottom: 2,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 16,
  },
}));

const menuItems = [
  {
    id: "Dashboard",
    name: "Dashboard",
    key: "main_dashboard",
    idOrder: 1,
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="mage:dashboard-chart" />
      </Box>
    ),
    link: "/inbox/Charts",
  },
  {
    id: "Busqueda",
    name: "Buscar",
    key: "search",
    idOrder: 2,
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="akar-icons:search" />
      </Box>
    ),
    link: "/inbox/search",
  },
  {
    id: "Asignaciones",
    name: "Asignaciones",
    key: "main_assignments",
    idOrder: 3,
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon
          fontSize={25}
          icon="majesticons:document-award-line"
          strokeWidth={"0.5"}
        />
      </Box>
    ),
    link: "/inbox/homeComponent",
    // optionsSecundary: [{
    //   name: "Contratación normal",
    //   link: "/inbox/homeComponent/single",
    // },
    // {
    //   name: "Contratación masiva",
    //   link: "/inbox/homeComponent/masive",
    // }
    // ]
  },
  {
    id: "Alta Usuarios",
    name: "Alta Candidatos",
    key: "main_candidates",
    idOrder: 4,
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="carbon:new-tab" />
      </Box>
    ),
    link: "/inbox/new-file",
  },
  {
    id: "Configuración",
    name: "Configuración",
    key: "main_configuration",
    idOrder: 5,
    icon: (
      <Box
        style={{
          marginLeft: ".6em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={30} icon="system-uicons:settings" />
      </Box>
    ),
    link: "/inbox/config",
  },
  {
    id: "Gestión de Perfiles",
    name: "Gestión de Perfiles",
    key: "main_profile_management",
    idOrder: 6,
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="iconoir:profile-circle" />
      </Box>
    ),
    link: "/inbox/profileManagement",
  },
  {
    id: "Reportes",
    name: "Reportes",
    key: "main_reports",
    idOrder: 7,
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon icon="carbon:report-data" fontSize={25} />
      </Box>
    ),
    link: "/inbox/inboxReportsFilter",
  },
  {
    id: "Carga Masiva",
    name: "Carga Masiva",
    key: "main_massive_upload",
    idOrder: 8,
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon icon={"material-symbols-light:upload"} fontSize={25} />
      </Box>
    ),
    link: "/inbox/massiveFlow",
  },
  {
    id: "Descarga Masiva",
    name: "Descarga Masiva",
    key: "main_massive_download",
    idOrder: 9,
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon icon={"material-symbols-light:download"} fontSize={25} />
      </Box>
    ),
    link: "/inbox/massiveDownload",
  },
  {
    id: "Notificaciones",
    name: "Notificaciones",
    key: "main_notifications",
    idOrder: 10,
    icon: (
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Icon icon={"hugeicons:notification-02"} fontSize={20} />
      </Box>
    ),
    link: "/inbox/notifications",
  },
  {
    id: "Carga Masiva Documentos",
    name: "Carga Documentos",
    key: "main_documents",
    idOrder: 11,
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="solar:upload-outline" />
      </Box>
    ),
    link: "/inbox/bulkDocuments",
  },

  /*  {
     id: "Alta Usuarios Masivo",
     name: "Alta Candidatos Masivo",
     idOrder: 2,
     icon: <Box style={{ marginLeft: ".8em", marginRight: '.8em', display: "flex", alignItems: "center" }}><Icon fontSize={25} icon="heroicons:users" /></Box>,
     link: "/inbox/bulkUsers",
   }, */

  // {
  //   id: "Dashboard",
  //   name: "Dashboard",
  //   idOrder: 4,
  //   icon: <Box style={{ marginLeft: ".8em", marginRight: '.8em', display: "flex", alignItems: "center" }}><Icon fontSize={25} icon="iconoir:reports" /></Box>,
  //   link: "/inbox/dashboards",
  // },
  // {
  //   id: "Reportes",
  //   name: "Reportes",
  //   idOrder: 5,
  //   icon: <Box style={{ marginLeft: ".8em", marginRight: '.8em', display: "flex", alignItems: "center" }}><Icon fontSize={25} icon="tabler:report-analytics" /></Box>,
  //   secundary: [{
  //     name: "Contrataciones",
  //     rol: "Analista RH"
  //   },
  //   {
  //     name: "Contrataciones Mensuales por Etapa",
  //     rol: "Analista RH"
  //   },
  //   {
  //     name: "Contrataciones por Etapa",
  //     rol: "Analista RH"
  //   },
  //   {
  //     name: "Contrataciones por Analista RH",
  //     rol: "Analista RH"
  //   },
  //   {
  //     name: "Préstamos Solicitados",
  //     rol: "Nóminas"
  //   },
  //   {
  //     name: "Préstamos Mensuales por Etapa",
  //     rol: "Nóminas"
  //   },
  //   {
  //     name: "Préstamos por Etapa",
  //     rol: "Nóminas"
  //   },
  //   ]
  // }
  {
    id: "GEID",
    name: "GEID",
    key: "main_geid",
    idOrder: 12,
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="solar:document-broken" />
      </Box>
    ),
    link: "/inbox/bulkGEID",
    // optionsSecundary: [{
    //   name: "Contratación normal",
    //   link: "/inbox/homeComponent/single",
    // },
    // {
    //   name: "Contratación masiva",
    //   link: "/inbox/homeComponent/masive",
    // }
    // ]
  },
  {
    id: "Carga Masiva Metadatos",
    name: "Carga Masiva Metadatos",
    key: "main_upload_massive_metadata",
    idOrder: 13,
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="ph:user-circle-gear-light" />
      </Box>
    ),
    link: "/inbox/BulkMetadata",
    // optionsSecundary: [{
    //   name: "Contratación normal",
    //   link: "/inbox/homeComponent/single",
    // },
    // {
    //   name: "Contratación masiva",
    //   link: "/inbox/homeComponent/masive",
    // }
    // ]
  },
  {
    id: "Configuración de Perfil",
    name: "Configuración de Perfil",
    key: "main_profile_config", //Configuración de Perfil
    idOrder: 14,
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="ph:user-circle-gear-light" />
      </Box>
    ),
    link: "/inbox/userConfig",
  },
];

export const HeaderAppBar = (props) => {
  const location = useLocation();
  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();
  const theme = useTheme();
  const stateAuth = useSelector((state) => state.Authentication);
  const { t } = useTranslation();

  const { data: newNotifications } = useSWR(
    "UserHasNetNotify",
    () => userHasNetNotify(),
    {
      refreshInterval: 60000,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    },
  );

  const hideNotificationsDot = newNotifications?.NewNotifications === 0;

  let optionsHeader =
    stateAuth.items?.UserLoginData?.SecurityLoginData?.Applications[0]
      ?.Modules[0]?.Functions;
  let rolUser =
    stateAuth.items?.UserLoginData?.SecurityLoginData?.Roles[0]?.Name;

  const getNavItemActive = (menuLink) => {
    return location.pathname.includes(menuLink);
  };

  const openWindowModalReport = (name) => {
    let wihe = "width=" + width + ",height=" + height;
    if (name === "Contrataciones") {
      window.open(
        `https://reportes.docsolutions.com:444/Reports/report/METLIFE/1.Contrataciones`,
        "_blank",
        "resizable=1,screenX=1,screenY=1,left=1,top=1," + wihe,
      );
    } else if (name === "Contrataciones Mensuales por Etapa") {
      window.open(
        `https://reportes.docsolutions.com:444/Reports/report/METLIFE/2.Contrataciones%20mensuales%20por%20etapa`,
        "_blank",
        "resizable=1,screenX=1,screenY=1,left=1,top=1," + wihe,
      );
    } else if (name === "Contrataciones por Etapa") {
      window.open(
        `https://reportes.docsolutions.com:444/Reports/report/METLIFE/3.Contrataciones%20por%20etapa`,
        "_blank",
        "resizable=1,screenX=1,screenY=1,left=1,top=1," + wihe,
      );
    } else if (name === "Contrataciones por Analista RH") {
      window.open(
        `https://reportes.docsolutions.com:444/Reports/report/METLIFE/4.Contrataciones%20por%20analista%20RH`,
        "_blank",
        "resizable=1,screenX=1,screenY=1,left=1,top=1," + wihe,
      );
    } else if (name === "Préstamos Solicitados") {
      window.open(
        `https://reportes.docsolutions.com:444/Reports/report/METLIFE/5.Prestamos%20solicitados`,
        "_blank",
        "resizable=1,screenX=1,screenY=1,left=1,top=1," + wihe,
      );
    } else if (name === "Préstamos Mensuales por Etapa") {
      window.open(
        `https://reportes.docsolutions.com:444/Reports/report/METLIFE/6.Prestamos%20mensuales%20por%20etapa`,
        "_blank",
        "resizable=1,screenX=1,screenY=1,left=1,top=1," + wihe,
      );
    } else if (name === "Préstamos por Etapa") {
      window.open(
        `https://reportes.docsolutions.com:444/Reports/report/METLIFE/7.Prestamo%20por%20etapa`,
        "_blank",
        "resizable=1,screenX=1,screenY=1,left=1,top=1," + wihe,
      );
    }
  };

  const itemsOrdenados = menuItems?.sort((a, b) => a.idOrder - b.idOrder);

  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: "#FFFFFF",
        color: "black",
        maxWidth: 1400,
        border: "1px solid white",
        mx: "auto",
        height: "100%",
      }}
    >
      {/* <Container maxWidth="xl" style={{ margin: 0, padding: 0, maxWidth: '100%', height: '100%' }}> */}
      {/* <Box
          disableGutters
          style={{
            p: 0,
            m: 0,
            mr: { xs: 0, sm: 0 },
            ml: { xs: 0, sm: 0, md: 0 },
            [theme.breakpoints.up("lg")]: {
              flexDirection: "column !important",
              height: "100vh",
            },
            [theme.breakpoints.down("lg")]: {
              flexDirection: "column !important",
              height: "100vh",
            },
            [theme.breakpoints.down("md")]: {
              flexDirection: "column !important",
              height: "5vh",
            },
            [theme.breakpoints.down("sm")]: {
              height: "5vh",
            },
          }}
        > */}
      <SelectLanguage hidden />
      {/* mobile init */}
      <Hidden only={["lg", "md", "xl"]}>
        <Box
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Box>
            <IconButton onClick={props.openDrawer} size="large" color="inherit">
              <MenuIcon />
            </IconButton>
          </Box>
          <Box
            sx={{ ml: 1 }}
            display={"flex"}
            justifyContent="left"
            alignItems={"center"}
          >
            <UseImage src={logoDS} type="img" style={{ width: "78%" }} />
          </Box>
          <Box
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "right",
              alignItems: "center",
              mt: 1,
              [theme.breakpoints.down("md")]: {
                justifyContent: "end",
              },
              [theme.breakpoints.down("sm")]: {
                justifyContent: "end",
                mr: 1,
              },
            }}
          >
            <IconButton
              sx={{ width: "40px", height: "40px" }}
              onClick={() => navigate("/inbox/search")}
            >
              <Icon fontSize={18} icon="akar-icons:search" color={"#9E9E9E"} />
            </IconButton>
            <IconButton
              sx={{ width: "40px", height: "40px" }}
              onClick={() => navigate("/inbox/notifications")}
            >
              <NotificationsNoneIcon /* sx={{ width: '34px', height: '34px' }}  */
              />
            </IconButton>
          </Box>
        </Box>
      </Hidden>
      {/* mobile finis */}

      {/* web init */}
      <Hidden smDown>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: '1rem',
            height: "100px",
            width: "100%",
            // marginTop: "0.8rem",
          }}
        >
          <UseImage src={logoDS} type="img" style={{ width: "35%" }} />
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "1rem 0",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography
            variant="h6"
            style={{
              color: "#3F3F3E",
              fontWeight: 550,
              paddingLeft: "2rem",
              marginBottom: "1rem",
            }}
          >
            {t("menu")}
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              width: "100%",
              height: "500px",
              overflow: 'auto',
              [theme.breakpoints.up("lg")]: {
                flexDirection: "column !important",
              },
              [theme.breakpoints.down("lg")]: {
                flexDirection: "column !important",
              },
              [theme.breakpoints.down("md")]: {
                flexDirection: "column !important",
              },
            }}
          >
            {itemsOrdenados?.map((item, index) => {
              const menuItem = optionsHeader?.find((mi) => mi.Name === item.id);
              if (menuItem) {
                return (
                  <div key={index}>
                    {rolUser === "Empleado" &&
                    item.id === "Generar Solicitudes" ? null : (
                      <MenuButton
                        key={index}
                        disabled={item.name}
                        component={RouterLink}
                        to={item.link}
                        sx={{
                          display: "flex",
                          justifyContent: "left",
                          alignContent: "center",
                          alignItems: "center",
                          textAlign: "left",
                          // marginLeft: 2,
                          // marginTop: "1em",
                          /* marginTop: "0em",
                          marginBottom: "0em", */
                          textDecoration: "none !important",
                          borderBottom: "none",
                          fontWeight: getNavItemActive(item.link) ? 650 : 400,
                          color: getNavItemActive(item.link) ? "#FFF" : "#000",
                          backgroundColor: getNavItemActive(item.link)
                            ? theme.palette.menuColor.main
                            : "transparent",
                          "&:hover": {
                            color: "#FFF",
                          },
                          "&:active": {
                            color: "#FFF",
                          },
                          "&:focus": {
                            color: "#FFF",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            // padding: "1rem",
                            marginY: "1rem",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          {item.id === "Notificaciones" ? (
                            <Badge
                              color="error"
                              variant="dot"
                              invisible={hideNotificationsDot}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              sx={{ marginLeft: "1em", marginRight: "1em" }}
                            >
                              {item.icon}
                            </Badge>
                          ) : (
                            item.icon
                          )}
                          <span style={{ minWidth: "60px" }}>
                            {t(item.key)}
                          </span>
                        </Box>
                      </MenuButton>
                    )}
                    {item?.secundary?.map((subMenu) =>
                      subMenu.rol && subMenu.rol === rolUser ? (
                        <Box
                          style={{
                            borderBottomColor: theme.palette.menuColor.main,
                            marginLeft: "1.5em",
                          }}
                          borderBottom={getNavItemActive(subMenu.link) ? 2 : 0}
                        >
                          <MenuButton
                            key={index}
                            disabled={subMenu.name}
                            component={RouterLink}
                            to={subMenu.link}
                            onClick={() => openWindowModalReport(subMenu.name)}
                            sx={{
                              display: "flex",
                              justifyContent: "left",
                              alignContent: "center",
                              alignItems: "center",
                              textAlign: "left",
                              // marginLeft: '1em',
                              fontWeight: 400,
                              color: "#000",
                              backgroundColor: "transparent",
                              textDecoration: "none !important",
                              borderBottom: "none",
                              "&:hover": {
                                color: "#FFF",
                              },
                              "&:active": {
                                color: "#FFF",
                              },
                              "&:focus": {
                                color: "#FFF",
                              },
                            }}
                          >
                            <span
                              style={{
                                minWidth: "60px",
                                textDecoration: "none !important",
                              }}
                            >
                              {subMenu.name}
                            </span>
                          </MenuButton>
                        </Box>
                      ) : null,
                    )}
                    {item?.optionsSecundary?.map((subMenu) => (
                      <Box
                        style={{
                          borderBottomColor: theme.palette.menuColor.main,
                          marginLeft: "1.5em",
                        }}
                        borderBottom={getNavItemActive(subMenu.link) ? 2 : 0}
                      >
                        <MenuButton
                          key={index}
                          // disabled={subMenu.name}
                          component={RouterLink}
                          to={subMenu.link}
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            alignContent: "center",
                            alignItems: "center",
                            textAlign: "left",
                            // marginLeft: '1em',
                            fontWeight: 400,
                            color: "#000",
                            backgroundColor: "transparent",
                            textDecoration: "none !important",
                            borderBottom: "none",
                            "&:hover": {
                              color: "#FFF",
                            },
                            "&:active": {
                              color: "#FFF",
                            },
                            "&:focus": {
                              color: "#FFF",
                            },
                          }}
                        >
                          <span style={{ minWidth: "60px" }}>
                            {subMenu.name}
                          </span>
                        </MenuButton>
                      </Box>
                    ))}
                  </div>
                );
              }
            })}
          </Box>
          <Box sx={{height: "100px"}}>
            <Divider />
            <UserCard />
          </Box>
        </Box>
      </Hidden>
      {/* </Box> */}
      {/* </Container> */}
    </AppBar>
  );
};
