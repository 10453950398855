import Stack from '@mui/material/Stack';
import { ConfigCard } from 'components/ConfigCards';
import { Typography } from '@mui/material';
import React from 'react';
import useSWR from 'swr';
import { getConfigurations } from 'api/configuration/configurationRequests';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { CustomLoadingOverlay } from '../../CustomLoadingOverlay';
import { useTranslation } from 'react-i18next';

const ConfigSelection = ({ handleShowTabs }) => {
  const { t } = useTranslation()
  const { data: configsData, isValidating } = useSWR(
    'GetConfigurations',
    () => getConfigurations({}),
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const handleConfigButton = (configInfo) => {
    sessionStorage.setItem('configurationData', JSON.stringify(configInfo));
    handleShowTabs();
  };

  return (
    <Stack spacing={4} padding={'2rem'}>
      <CustomLoadingOverlay active={isValidating} />
      <Typography>{t("profile_management_main_instruction")}</Typography>
      <Swiper slidesPerView={3} spaceBetween={'1rem'} navigation={ true } modules={[Navigation]} /* style={{padding: '0px 30px 0px 30px', margin: "0px -30px 0px -30px"}} */ >
        {configsData?.map((config) => (
          <SwiperSlide>
            <ConfigCard configInfo={config} onClickConfig={handleConfigButton} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Stack>
  );
};

export default ConfigSelection;
