import moment from 'moment';
import { constPathRoot } from './PathPublish'
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import 'dayjs/locale/en';
import 'dayjs/locale/pt';
import 'dayjs/locale/ja';
const { createHash } = require('crypto')

export const paths = {
  home: `${constPathRoot.pathRoot}/HomeComponent`,
  // search: `${constPathRoot.pathRoot}/SearchComponent`,
  report: `${constPathRoot.pathRoot}/DashboardComponent`,
  notice: `${constPathRoot.pathRoot}/NotificationsComponent`,
  folio: 'asignacion',
  folioSearch: 'busqueda',
  userManager: `${constPathRoot.pathRoot}/UserManager`,
  blackList: `${constPathRoot.pathRoot}/BlackListComponent`,
  customers: `${constPathRoot.pathRoot}/CustomersComponent`,
  dependencies: `${constPathRoot.pathRoot}/DependenciesComponent`,
  documents: `${constPathRoot.pathRoot}/DocumentsComponent`,
  employees: `${constPathRoot.pathRoot}/EmployeesComponent`,
  newEmployees: `${constPathRoot.pathRoot}/NewEmployeesComponent`,
  RegistrationCandidates: `${constPathRoot.pathRoot}/Registration`,
  search: `${constPathRoot.pathRoot}/inbox/search`,
  homeComponent: `${constPathRoot.pathRoot}/inbox/homeComponent`,
  homeComponentSingle: `${constPathRoot.pathRoot}/inbox/homeComponent/single`,
  homeComponentMasive: `${constPathRoot.pathRoot}/inbox/homeComponent/masive`,
}

export const routes = {
  home: '/inbox/homeComponent',
  search: '/inbox/busqueda',
  report: '/inbox/dashboards',
  notice: '/NotificationsComponent',
  userManager: '/UserManager',
  blackList: '/BlackListComponent',
  customers: '/CustomersComponent',
  dependencies: '/DependenciesComponent',
  documents: '/DocumentsComponent',
  employees: '/EmployeesComponent',
  newEmployees: '/NewEmployeesComponent',
  RegistrationCandidates: '/Registration',
  busqueda: `/inbox/busqueda`,
  asignacion: `/inbox/asignacion`,
}

export const getDateHour = (dateHour) => {
  let theDate = dateHour.split("T")[0]
  let hour = dateHour.split("T")[1].split(".")[0].split(":")[0]
  let min = dateHour.split("T")[1].split(".")[0].split(":")[1]
  let seg = dateHour.split("T")[1].split(".")[0].split(":")[2]
  // let twelveHour = () => {
  //   if (hour > 11) {
  //     return hour - 12
  //   } else {
  //     return hour
  //   }
  // }
  let amOrPm = () => {
    if (hour > 12) {
      return "PM"
    } else {
      return "AM"
    }
  }
  // let formatDate = `${theDate} ${twelveHour()}:${min}:${seg} ${amOrPm()}`
  let formatDate = `${theDate} ${hour}:${min}:${seg}`

  return formatDate
}

export function getHash(string) {
  return createHash('sha256').update(string).digest('hex');
}

export function getFormatComboArray(data) {
  if (data?.length > 0) {

    let listOptions = data.map((item, index) => {
      return {
        id: item.Id,
        label: item.Name,
        value: item.Id,
        required: true
      }
    }
    )

    console.log("listOptions: --------------------------- ", listOptions)
    return listOptions
  } else {
    return []
  }
}

// export const B64toBlobAsync = async (b64Data, contentType = '', sliceSize = 512) => {
//   let atob = require('atob');
//   const byteCharacters = atob(b64Data);
//   const byteArrays = [];

//   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//     const slice = byteCharacters.slice(offset, offset + sliceSize);

//     const byteNumbers = new Array(slice.length);
//     for (let i = 0; i < slice.length; i++) {
//       byteNumbers[i] = slice.charCodeAt(i);
//     }

//     const byteArray = new Uint8Array(byteNumbers);
//     byteArrays.push(byteArray);
//   }

//   const blob = new Blob(byteArrays, { type: contentType });
//   return blob;
// }

export const convertPdfToB64 = async (file) => {
  const fileConverted = await toBase64Async(file);
  const base64result = fileConverted.split(",")[1];
  // const wordUrl = URL.createObjectURL(file);
  // return { base64result, wordUrl };
  return { base64result }
};

const toBase64Async = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const b64toUrl = async (base64Data) => {
  const r = await fetch(base64Data);
  const blob = await r.blob();
  return URL.createObjectURL(blob);
};

export const GetMimeType = (extension) => {
  if (extension === '.jpg' || extension === '.jpeg') {
    return 'data:image/jpeg;base64';
  } else if (extension === '.png') {
    return 'data:image/png;base64';
  }else if (extension === '.xml') {
    return 'data:text/xml;base64';
  } else {
    return 'data:application/pdf;base64';
  }
};

export const getBase64FileExtension = (base64String) => {
  const mimeTypes = {
    '/': 'jpeg',
    'i': 'png',
    'R': 'gif',
    'U': 'webp',
    'J': 'pdf',
    'T': 'tiff',
    'S': 'bmp',
    'Q': 'ico',
    // 'G': 'svg',
    'x': 'xlsx',
    't': 'txt',
    'P': 'xml',
    // '7': 'xml',
    'A': 'mp4'
  };
  const firstChar = base64String.charAt(0);
  console.log('firstChar', firstChar)
  return mimeTypes[firstChar] || 'unknown';
}

export const blobToBase64 = blob => {
  try {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  } catch (e) {
    console.log("blobToBase64: ", e)
  }
};

// export const B64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
//   let atob = require('atob');
//   const byteCharacters = atob(b64Data);
//   const byteArrays = [];

//   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//     const slice = byteCharacters.slice(offset, offset + sliceSize);

//     const byteNumbers = new Array(slice.length);
//     for (let i = 0; i < slice.length; i++) {
//       byteNumbers[i] = slice.charCodeAt(i);
//     }

//     const byteArray = new Uint8Array(byteNumbers);
//     byteArrays.push(byteArray);
//   }

//   const blob = new Blob(byteArrays, { type: contentType });
//   return blob;
// }

export const B64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export const basicFormatDate = (dateString) => {
  if (!dateString) return null;
  dayjs.locale('es');
  const date = dayjs(dateString);
  return date.format("DD/MM/YYYY");
};

export const formatDateByLocale = (inputDate) => {
  const formats = {
    es: 'DD [de] MMMM [del] YYYY',
    en: 'MMMM D, YYYY',
    pt: 'D [de] MMMM [de] YYYY',
    ja: 'YYYY年M月D日'
  };
  const locale = localStorage.getItem('lang') || 'en';
  dayjs.locale(locale);
  const date = dayjs(inputDate);
  return date.format(formats[locale]);
};

export function obtenerDireccionIP(callback) {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    if (xhr.status >= 200 && xhr.status < 300) {
      const respuesta = JSON.parse(xhr.responseText);
      const ip = respuesta.ip;
      callback(ip);
    } else {
      callback(null);
    }
  };
  xhr.open('GET', 'https://api.ipify.org?format=json');
  xhr.send();
}

export const IsDevice = () => {
  let IsAndroid = navigator.userAgent.match(/Android/i) ? true : false
  let IsIOS = navigator.userAgent.match(/iPhone/i) ? true : false
  let IsMobile = (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i)) ? true : false

  return { IsAndroid, IsIOS, IsMobile }
}

export const ExtractHour = (date) => {
  if (typeof date == "string") return date;
  const current = date;
  const hours = current.getHours();
  const minutes = current.getMinutes();
  const hourTemp = hours < 10 ? "0" + hours : hours;
  const minutesTemp = minutes < 10 ? "0" + minutes : minutes;

  const hourToReturn = `${hourTemp}:${minutesTemp}`;
  return hourToReturn;
};

export const Logout = () => {
  console.log("CIERRASESION");
  localStorage.clear();
  sessionStorage.clear();
  sessionStorage.removeItem("token");
  localStorage.removeItem("versionApp");
}

export const ValidarSICumpleCondiciones = (expresion, value1, value2) => {
  if (expresion.toUpperCase() == "equals".toUpperCase() || expresion == "==") {
    return value1 == value2;
  } else if (expresion.toUpperCase() == "not equals".toUpperCase() || expresion == "!=" || expresion == "<>") {
    return value1 != value2;
  } else {
    return false
  }
}

export function clearCacheData() {
  return new Promise((resolve, reject) => {
      caches.keys().then((names) => {
          names.forEach(name => {
              caches.delete(name);
          })
      });
      resolve("Ejecucion correcta")
  });
}

export const generateUniqueId = () => {
  const timestamp = Date.now(); // Obtiene el timestamp actual
  const randomNum = Math.floor(Math.random() * 10000); // Genera un número aleatorio
  return `${timestamp}-${randomNum}`;
}

export const dataUrlToFile = async (base64Data, fileName, type = "data:application/pdf;base64", mimeType = 'image/jpg') => {
  const r = await fetch(`${type},${base64Data}`);
  const blob = await r.blob();
  return new File([blob], fileName, { type: mimeType });
}

export const GenerateRandom = () => {
  const randomArray = new Uint32Array(1);
  window.crypto.getRandomValues(randomArray);
  const randomValue = randomArray[0];
  return randomValue;
}

export const ForceFormatDate = (currentDate, isGrid = false) => {
  if (currentDate == undefined || currentDate == null || currentDate == '') {
    return ''
  }
  console.log("ForceFormatDate  Date: ", currentDate)

  try {
    if (currentDate.toString()?.toUpperCase()?.includes("DD/MM/AA") || currentDate.toString()?.toUpperCase()?.includes("DD/MM/AAAA")) {
      console.log("ForceFormatDate parsedDate Date: ", currentDate, currentDate.toString()?.length)
      return `Fecha ${currentDate} inválida`;
    } else if (currentDate.toString()?.includes("/") && currentDate.toString()?.length <= 8) {
      console.log("ForceFormatDate parsedDate Date: ", currentDate, currentDate.toString()?.length)
      return `Fecha ${currentDate}(dd/MM/aa) inválida, fecha dd/MM/aaaa válida`;
    }
    else if (currentDate.toString()?.includes("/") && isGrid) {
      const [day, month, year] = currentDate.toString()?.split('/'); // Split the string by '/'
      let parsedDate = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10)); // Create a new Date object
      console.log("ForceFormatDate parsedDate Grid Date: ", parsedDate)
      let parsedDateFormat = moment(new Date(parsedDate), "DD/MM/YYYY").toISOString();
      console.log("ForceFormatDate parsedDateFormat Grid Date: ", parsedDateFormat)
      if (parsedDateFormat.toString()?.includes("-") && parsedDateFormat.toString()?.includes("T")) {
        console.log("ForceFormatDate parsedDate2--1 Grid Date: ", parsedDateFormat)
        const parsedDate = moment(parsedDateFormat, 'YYYY/MM/DD').format('DD/MM/YYYY');
        return parsedDate;
      } else {
        return parsedDateFormat;
      }
    } else if (currentDate.toString()?.includes("/")) {
      const [day, month, year] = currentDate.toString()?.split('/'); // Split the string by '/'
      const parsedDate = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10)); // Create a new Date object
      console.log("ForceFormatDate parsedDate simple Date: ", parsedDate)
      return parsedDate;
    } else if (currentDate.toString()?.includes("-") && currentDate.toString()?.includes("T")) {
      console.log("ForceFormatDate parsedDate2 Date: ", parsedDate)
      const parsedDate = moment(new Date(currentDate), "DD/MM/YYYY").toISOString();
      return parsedDate;
    } else {
      console.log("ForceFormatDate moment Date: ", moment(currentDate, "DD/MM/YYYY").toISOString())
      return moment(currentDate, "DD/MM/YYYY").toISOString();
    }
  } catch {
    console.log("ForceFormatDate Catch Date: ", currentDate)
    return new Date(currentDate)
  }
}

export const CurrencyFormat = (inputText, type = 'Currency') => {
  console.log("------***CurrencyFormat****----Entrada--:", inputText, ", type:", type)
  switch (type) {
    case 'Currency':
      console.log("------***CurrencyFormat****---Currency-Salida--:", inputText?.replace("$", "")?.replace(/\,/g, ''), ", type:", type)
      return inputText?.replace("$", "")?.replace(/\,/g, '');
    case 'CurrencyEuropean':
      let formatReturned = 0
      if (inputText?.toString()?.includes('$') && inputText?.toString()?.includes(',') && inputText?.toString()?.includes('.')) {
        formatReturned = inputText?.toString()?.replace("$", "")?.replace(/\./g, '')?.replace(",", ".");
        console.log("------***CurrencyFormat****---CurrencyEuropean-Salida-formatReturned-1--:", formatReturned, ", type:", type)
      }
      else if (inputText?.toString()?.includes('$') && inputText?.toString()?.includes('.')) {
        formatReturned = inputText?.toString()?.replace("$", "")?.replace(/\./g, '')
        console.log("------***CurrencyFormat****---CurrencyEuropean-Salida-formatReturned-2--:", formatReturned, ", type:", type)
      }
      else if (!inputText?.toString()?.includes('$') && inputText?.toString()?.includes(',')) {
        formatReturned = inputText?.toString()?.replace("$", "")?.replace(/\,/g, '.')
        console.log("------***CurrencyFormat****---CurrencyEuropean-Salida-formatReturned-3--:", formatReturned, ", type:", type)
      }
      else if (!inputText?.toString()?.includes('$') && inputText?.toString()?.includes('.')) {
        formatReturned = inputText?.toString()
        console.log("------***CurrencyFormat****---CurrencyEuropean-Salida-formatReturned-4--:", formatReturned, ", type:", type)
      } else{
        formatReturned = inputText?.toString()
        console.log("------***CurrencyFormat****---CurrencyEuropean-Salida-formatReturned-5--:", formatReturned, ", type:", type)
      }   
      return formatReturned
    default:
      console.log("------***CurrencyFormat****---default-Salida--:", inputText?.replace("$", "")?.replace(/\,/g, ''), ", type:", type)
      return inputText?.replace("$", "")?.replace(/\,/g, '');
  }
};

export const getActualDateTime = () => {
  //Obtener y dar formato a fecha y hora actuales
  let today = new Date();
  let day = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
  let month =
    today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1;
  let hours = today.getHours() < 10 ? "0" + today.getHours() : today.getHours();
  let minutes =
    today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
  let seconds =
    today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds();
  let date = day + "/" + month + "/" + today.getFullYear();
  let time = hours + ":" + minutes + ":" + seconds;

  let actualDateTime = `${date}|${time}`;
  return actualDateTime;
};

export function getOrderIndex(name) {
  switch (name) {
      case 'nombres':
          return 1
      case 'apellido paterno':
          return 2
      case 'apellido materno':
          return 3
      case 'curp':
          return 4
      case 'clave de elector':
          return 5
      case 'Número CIC':
          return 6
      case 'Número de OCR':
          return 7
      case 'anno de emisión':
          return 8
      case 'numero emision':
          return 9
      case 'anno de registro':
          return 10
      case 'vigencia':
          return 11
      case 'direccion':
          return 12
      case 'fecha de nacimiento':
          return 13
      case 'sexo':
          return 14
      case 'MetadataNombre':
          return 1
      case 'MetadataApellidoPaterno':
          return 2
      case 'MetadataApellidoMaterno':
          return 3
      case 'MetadataCURP':
          return 4
      case 'MetadataClaveElector':
          return 5
      case 'MetadataNúmeroCIC':
          return 6
      case 'MetadataNúmeroOCR':
          return 7
      case 'MetadataAñoEmisión':
          return 8
      case 'MetadataNúmeroEmisión':
          return 9
      case 'MetadataAñoRegistro':
          return 10
      case 'MetadataVigencia':
          return 11
      case 'MetadataDirección':
          return 12
      case 'MetadataFechaNacimiento':
          return 13
      default:
          return 500
  }
}