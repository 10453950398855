import { Box, Stack, Typography } from '@mui/material';
import downloadImage from 'assets/Descarga.gif';
import { useEffect } from 'react';
import { downloadBatchFile } from 'api/massiveDownload/massiveDownloadRequests';
import { AlertModal } from 'components/common/Modals/Views/AlertModal';
import { useTranslation } from 'react-i18next';
import { b64toUrl } from 'utils';

export const GeneratingZipAlert = ({ batchId, onZipSuccess }) => {
  const { t } = useTranslation();

  useEffect(() => {
    generateZipFile();
  }, []);

  const generateZipFile = async () => {
    try {
      const zipFileB64 = await downloadBatchFile(batchId);
      const zipFileUrl = await b64toUrl(
        `data:application/zip;base64,${zipFileB64}`,
      );
      onZipSuccess(zipFileUrl);
    } catch (error) {
      AlertModal('Error', error.message, 'error', t);
    }
  };

  return (
    <Box sx={{ height: '60vh', display: 'grid', placeItems: 'center' }}>
      <Stack spacing={'2rem'} display={'flex'} alignItems={'center'}>
        <Box component={'img'} src={downloadImage} sx={{maxWidth:'50%'}} />
        <Typography textAlign={'center'} variant={'h4'}>{t("generating_zip_alert_description")}</Typography>
      </Stack>
    </Box>
  );
};
