import { useCallback, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { B64toBlob } from '../../utils';
import { Box, Button } from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import layoutCameraIne from 'assets/layoutCameraIne.png';
import layoutCamerSelfie from 'assets/layoutCameraSelfie.png';

export const CustomCameraSelfie = ({
  onCapture,
  isDisplay,
  isCredential = false,
  isSelfie = false,
}) => {
  const webcamRef = useRef();
  const [photo, setPhoto] = useState(null);
  const [photob64, setPhotob64] = useState(null);

  const resetPhoto = () => setPhoto(null);

  const processBase64 = (base64) => {
    const base64Content = base64.split(',')[1];
    const blobPhoto = B64toBlob(base64Content, 'image/jpeg');
    setPhotob64(base64Content);
    setPhoto(blobPhoto);
    return blobPhoto;
  };

  const capture = useCallback(() => {
    const base64 = webcamRef.current.getScreenshot();
    if (base64) {
      processBase64(base64);
    }
  }, [webcamRef]);

  const renderWebcamOrPhoto = () => {
    if (photo) {
      return (
        <img
          src={URL.createObjectURL(photo)}
          alt="captured"
          style={{
            maxHeight: '100%',
            width: '100%',
            objectFit: 'cover',
          }}
        />
      );
    }

    return (
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        screenshotQuality={1}
        style={{
          display: isDisplay ? 'block' : 'none',
          height: '100%',
          width: '100%',
          objectFit: 'cover',
        }}
        videoConstraints={{
          facingMode: 'user',
          echoCancellation: true,
          noiseSuppression: true,
        }}
        mirrored={/iPhone|Android/i.test(navigator.userAgent)}
      />
    );
  };

  const renderButtons = () => {
    if (photo) {
      return (
        <>
          <Button variant="outlined" onClick={resetPhoto} sx={{ color: 'white' }}>
            Volver a capturar
          </Button>
          <Button variant="contained" onClick={() => onCapture(photob64)}>
            Enviar
          </Button>
        </>
      );
    }

    return (
      <Button variant="contained" onClick={capture}>
        <CameraAltOutlinedIcon />
      </Button>
    );
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
      {isCredential || isSelfie ? (
        <img
          alt=""
          src={isCredential ? layoutCameraIne : layoutCamerSelfie}
          style={{ position: 'absolute', width: '100%', height: 'calc(100% - 100px)', objectFit: 'contain' }}
        />
      ) : null}

      {renderWebcamOrPhoto()}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '2rem',
          position: 'absolute',
          top: 'calc(100% - 100px)',
          width: '100%',
          height: '100px',
          backgroundColor: 'rgba(0, 0, 0, 0.45)',
        }}
      >
        {renderButtons()}
      </Box>
    </Box>
  );
};
