import {
  Box,
  Button,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import useSWRMutation from "swr/mutation";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useState } from "react";
import { AlertModal } from "components/common/Modals/Views/AlertModal";
import { updatePasswordUser } from "api/user/userRequest";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { OTPFormPassword } from "components/LEGOS/OTP/OTPFormPassword";

const FieldBox = styled(Box)(({ theme }) => ({
  width: "100%",
}));

export const ChangePasswordView = ({ onReturn }) => {
  const userName = useSelector((state) => state.Authentication?.items)
    ?.UserLoginData?.Username;
  const { t } = useTranslation();
  const [newPass, setNewPass] = useState("");
  const [newRepeatPass, setNewRepeatPass] = useState("");
  const [openOTP, setOpenOTP] = useState(false);

  const { trigger, isMutating } = useSWRMutation(
    "UpdatePasswordUser",
    (url, payload) => updatePasswordUser(payload),
    {
      populateCache: true,
      revalidate: false,
    },
  );

  const handleSave = async () => {
    if (newPass !== newRepeatPass)
      return AlertModal(
        "Error",
        "Verifica que la nueva contraseña coincida",
        "error",
      );
    const userPasswordData = {
      UserName: userName,
      NewPassword: newPass,
    };
    try {
      await trigger(userPasswordData);
      callOTPForm();
    } catch (err) {
      AlertModal("Error", err.message, "error");
    }
  };

  const callOTPForm = () => {
    setOpenOTP(true);
  };

  return openOTP ? (
    <OTPFormPassword OnReturn={onReturn} />
  ) : (
    <Stack sx={{ flexDirection: "column" }}>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <ArrowBackOutlinedIcon
          fontSize="large"
          sx={{ "&:hover": { cursor: "pointer" } }}
          onClick={onReturn}
        />
        <Typography variant="h5" sx={{ ml: 2 }}>
          {t("login_password")}
        </Typography>
      </Stack>
      <Stack
        sx={{
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          px: 15,
          py: 4,
          gap: "1rem",
        }}
      >
        {/* <FieldBox>
          <Typography>Contraseña actual</Typography>
          <TextField
            value={currentPass}
            onChange={(e) => {
              setCurrentPass(e.target.value);
            }}
            size="small"
            variant="outlined"
            margin="dense"
            fullWidth
            placeholder="Ingresa tu contraseña actual"
          />
        </FieldBox> */}
        <FieldBox>
          <Typography>Nueva contraseña</Typography>
          <TextField
            value={newPass}
            onChange={(e) => {
              setNewPass(e.target.value);
            }}
            size="small"
            variant="outlined"
            margin="dense"
            fullWidth
            placeholder="Ingresa tu nueva contraseña"
          />
        </FieldBox>
        <Box width={'100%'} paddingLeft={'1rem'}>
          <Typography variant="body2">
            Revisa que tu contraseña cumpla con lo siguiente:
          </Typography>
          <Typography variant="body2">- Minimo 8 carácteres</Typography>
          <Typography variant="body2">- Al menos una mayúscula</Typography>
          <Typography variant="body2">- Al menos una minúscula</Typography>
          <Typography variant="body2">- Al menos un número</Typography>
        </Box>
        <FieldBox>
          <Typography>Repita la nueva contraseña</Typography>
          <TextField
            value={newRepeatPass}
            onChange={(e) => {
              setNewRepeatPass(e.target.value);
            }}
            size="small"
            variant="outlined"
            margin="dense"
            fullWidth
            placeholder="Repite tu contraseña actual"
          />
        </FieldBox>
        <Button
          variant="contained"
          sx={{ minWidth: "500px" }}
          onClick={handleSave}
        >
          Guardar cambios
        </Button>
      </Stack>
      {/* {openOTP && <OTPForm OnValidate={()=>{}}/>} */}
    </Stack>
  );
};
