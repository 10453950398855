import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { useForm, FormProvider } from "react-hook-form";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { UseImage } from "../../components";
import { UploadDcumentsControl } from "../../components/DocumentUpload/UploadDcumentsControl";
import { userActions, roleActions } from "../../store/actions";
import plantillaExcel from "../../assets/PlantillaCargaMasivaGEID.xlsx";
import img from "../../assets/DownloadExcel.png";
import { MainTableDinamyc } from "../../components/table/MainTableDinamyc";
import { FormDynamic, Alert } from "../../components";
import { getHash,  ForceFormatDate, CurrencyFormat  } from "../../utils";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  loader: {
    position: "absolute !important",
    top: "0 !important",
    bottom: "0 !important",
    left: "0 !important",
    right: "0 !important",
    height: "100vh !important",
    width: "100% !important",
    zIndex: 100000,
  },
}));

export default function UsersAdministrator(props) {
  const theme = useTheme();
  const formMethods = useForm({ mode: "onChange" });
  const classes = useStyles(theme);
  const registerUserAuthenticatorResponse = useSelector(
    (state) => state.RegisterUserAuthenticatorRoleData
  );
  const getAllUsersResponse = useSelector((state) => state.GetAllUsersData);
  const tenatDataResponse = useSelector((state) => state.GetTenantsData);
  const rolDataResponse = useSelector((state) => state.RoleAll);

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const [sendUserRegister, setSendUserRegister] = useState(false);
  const [loadView, setLoadView] = useState(null);
  const [data, setData] = useState([]);
  const [metadataForm, setMetadataForm] = useState([]);
  const [activeCaptureNew, setaAtiveCaptureNew] = useState(false);
  const [editionActive, setEditionActive] = useState(false);
  const [userSeleted, setUserSeleted] = useState(null);

  const { t } = useTranslation();

  const newData = data?.map((col) => {
    return {
      Id: col.Id,
      Name: col.Name,
      Email: col.Email,
      Username: col.Username,
      CreationDate: col.CreationDate,
    };
  });

  const columns = [
    { id: "Id", label: "Id", minWidth: 170 },
    { id: "Name", label: t('table_name'), minWidth: 170 },
    { id: "Email", label: t('table_email'), minWidth: 170 },
    { id: "Username", label: t('table_username'), minWidth: 170 },
    { id: "CreationDate", label: t('table_date'), minWidth: 170 },
  ];

  const createData = (Id, Name, Email, Username, CreationDate) => {
    return {
      Id,
      Name,
      Email,
      Username,
      CreationDate,
    };
  };

  const rows = newData?.map((r) => {
    return createData(r.Id, r.Name, r.Email, r.Username, r.CreationDate);
  });

  useEffect(() => {
    dispatch(roleActions.GetRoles({ SearchText: "" }));
    dispatch(roleActions.GetTenats({ SearchText: "" }));
    dispatch(userActions.GetAllUsers({ SearchText: "" }));
  }, []);

  useEffect(() => {
    setLoadView(getAllUsersResponse.loading);
    console.log(
      "************getAllUsersResponse---------********: ",
      getAllUsersResponse
    );
    if (
      getAllUsersResponse.items != null &&
      getAllUsersResponse.items.length > 0
    ) {
      setData(getAllUsersResponse.items);
    } else if (getAllUsersResponse.error != null) {
      setData([]);
    }
  }, [getAllUsersResponse]);

  useEffect(() => {
    console.log(
      "************rolDataResponse---------********: ",
      rolDataResponse
    );
    console.log(
      "************tenatDataResponse---------********: ",
      tenatDataResponse
    );
    console.log(
      "************tenatDataResponse.itemsOptions?.length---------********: ",
      rolDataResponse?.itemsOptions?.length > 0
    );
    console.log(
      "************tenatDataResponse?.itemsOptions?.length > 0---------********: ",
      tenatDataResponse?.itemsOptions?.length > 0
    );
    if (
      tenatDataResponse?.itemsOptions?.length > 0 &&
      rolDataResponse?.itemsOptions?.length > 0
    ) {
      setMetadataForm(null);
      let dynamcQuestion = [
        {
          DataType: "string",
          Description: "Tenant",
          Id: 9,
          Length: 500,
          Name: "Tenant",
          QuestionGroup: "Usuario",
          QuestionGroupId: 1,
          QuestionType: "Combo",
          Required: true,
          Sequence: 1,
          Visible: true,
          Options: tenatDataResponse?.itemsOptions,
        },
        {
          DataType: "string",
          Description: "Roles",
          Id: 8,
          Length: 500,
          Name: "Rol",
          QuestionGroup: "Usuario",
          QuestionGroupId: 1,
          QuestionType: "Combo",
          Required: true,
          Sequence: 1,
          Visible: true,
          Options: rolDataResponse?.itemsOptions,
        },
        {
          DataType: "string",
          Description: "Usuario",
          Id: 1,
          Length: 500,
          Name: "UserName",
          QuestionGroup: "Usuario",
          QuestionGroupId: 1,
          QuestionType: "Texto",
          Required: true,
          Sequence: 1,
          Visible: true,
        },
        /* {
          DataType: "string",
          Description: "Password",
          Id: 2,
          Length: 500,
          Name: "Password",
          QuestionGroup: "Usuario",
          QuestionGroupId: 1,
          QuestionType: "Password",
          Required: true,
          Sequence: 1,
          Visible: true,
        }, */
        {
          DataType: "string",
          Description: "Nombre",
          Id: 3,
          Length: 500,
          Name: "Name",
          QuestionGroup: "Usuario",
          QuestionGroupId: 1,
          QuestionType: "Texto",
          Required: true,
          Sequence: 1,
          Visible: true,
        },
        {
          DataType: "string",
          Description: "Segundo Nombre",
          Id: 11,
          Length: 500,
          Name: "SecondName",
          QuestionGroup: "Usuario",
          QuestionGroupId: 1,
          QuestionType: "Texto",
          Required: "",
          Sequence: 1,
          Visible: true,
        },
        {
          DataType: "string",
          Description: "Apellido Paterno",
          Id: 4,
          Length: 500,
          Name: "FatherLastName",
          QuestionGroup: "Usuario",
          QuestionGroupId: 1,
          QuestionType: "Texto",
          Required: true,
          Sequence: 1,
          Visible: true,
        },
        {
          DataType: "string",
          Description: "Apellido Materno",
          Id: 5,
          Length: 500,
          Name: "MotherLastName",
          QuestionGroup: "Usuario",
          QuestionGroupId: 1,
          QuestionType: "Texto",
          Required: "",
          Sequence: 1,
          Visible: true,
        },
        {
          DataType: "string",
          Description: "Correo",
          Id: 6,
          Length: 500,
          Name: "Email",
          QuestionGroup: "Usuario",
          QuestionGroupId: 1,
          QuestionType: "Email",
          Required: true,
          Sequence: 1,
          Visible: true,
        },
        {
          DataType: "string",
          Description: "Teléfono",
          Id: 7,
          Length: 500,
          Name: "PhoneNumber",
          QuestionGroup: "Usuario",
          QuestionGroupId: 1,
          QuestionType: "Phone",
          Required: true,
          Sequence: 1,
          Visible: true,
          RegularExpression: "ar",
        },
      ];

      // console.log("///////////dynamcQuestion//////////", dynamcQuestion)
      let objectFormat = {
        llave: 1,
        groupTitle: "Alta de usuarios",
        questions: dynamcQuestion,
      };
      console.log("///////////objectFormat//////////", objectFormat);
      setMetadataForm(objectFormat);
    }
  }, [rolDataResponse, tenatDataResponse, activeCaptureNew]);

  useEffect(() => {
    console.log(
      "------------////////////////registerUserAuthenticatorResponse////------: ",
      registerUserAuthenticatorResponse
    );
    if (registerUserAuthenticatorResponse.items == true && sendUserRegister) {
      setSendUserRegister(false);
      setLoadView(false);
      setaAtiveCaptureNew(false);
      Alert({
        icon: "success",
        title:
          editionActive == true
            ? "Usuario editado correctamente"
            : "Usuario registrado correctamente",
      }, null, null, t);
    } else if (
      registerUserAuthenticatorResponse.error != null &&
      sendUserRegister
    ) {
      setSendUserRegister(false);
      setLoadView(false);
      Alert({
        icon: "error",
        title: registerUserAuthenticatorResponse.error,
      });
    }
  }, [registerUserAuthenticatorResponse]);

  // const resetData = () => {
  //     dispatch(flujoMasivoActions.UploadBulkFile({}))
  //     dispatch(
  //         flujoMasivoActions.GetBulkFileDetails({
  //             BatchUploadDocumentId: '',
  //         })
  //     );
  //     setDocumentsUpload([])
  //     navigation('/inbox/bulkDocuments')
  // };

  const onClickData = (e) => {
    setEditionActive(true);
    let userSeleted = data.find((item) => item.Id == e.Id);
    setUserSeleted(userSeleted);
    let newArray = metadataForm.questions.map((question) => {
      switch (question.Name) {
        case "Tenant":
          return {
            ...question,
            Value: userSeleted.Metadata?.find((item) => item.Name == "Tenant")
              ?.Value,
          };
        case "Rol":
          return { ...question, Value: userSeleted.Roles[0].Id };
        case "UserName":
          return { ...question, Value: userSeleted.Username };
        case "Name":
          return { ...question, Value: userSeleted.Name };
        case "SecondName":
          return {
            ...question,
            Value: userSeleted.Metadata?.find(
              (item) => item.Name == "SecondName"
            )?.Value,
          };
        case "FatherLastName":
          return { ...question, Value: userSeleted.FatherLastName };
        case "MotherLastName":
          return { ...question, Value: userSeleted.MotherLastName };
        case "Email":
          return { ...question, Value: userSeleted.Email };
        case "PhoneNumber":
          return { ...question, Value: userSeleted.PhoneNumber };
        default:
          return question;
      }
    });
    let objectFormat = {
      llave: 1,
      groupTitle: "Alta de usuarios",
      questions: newArray,
    };
    console.log("objectFormat", objectFormat, userSeleted);
    setMetadataForm(objectFormat);
    setaAtiveCaptureNew(true);
  };

  return (
    <>
      {loadView && (
        <LoadingOverlay
          active={loadView}
          spinner
          text={t("general_loading_message")}
          className={classes.loader}
        ></LoadingOverlay>
      )}

      <Grid
        container
        alignContent="center"
        alignItems="center"
        justifyContent="center"
      >
        <Grid container spacing={2}>
          <Grid item xs={8.7}></Grid>
          <Grid item xs={3}>
            {!activeCaptureNew ? (
              <Button
                style={{
                  height: 38,
                  width: "100%",
                  margin: "1em",
                  borderRadius: "25px",
                  fontSize: 16,
                  fontWeight: 500,
                }}
                variant="contained"
                color="primary"
                onClick={() => {
                  setaAtiveCaptureNew(true);
                  setEditionActive(false);
                }}
              >
                {t("general_new")}
              </Button>
            ) : null}
          </Grid>
        </Grid>
        <Grid item lg={12} xs={12}>
          {activeCaptureNew ? (
            <Box>
              <FormDynamic
                EditMetadataCustom={metadataForm}
                handleSave={(formData, e) => {
                  let ListMetdata = [];
                  console.log("FormDynamic formData: ", formData);
                  console.log("FormDynamic metadataForm: ", metadataForm);

                  metadataForm?.questions?.forEach((itemQ) => {
                    // console.log("itemQ********************: ", itemQ)
                    ListMetdata.push({
                      GroupName: itemQ.QuestionGroup,
                      Name: itemQ.Name,
                      Value:
                        itemQ.QuestionType === "Fecha"
                          ? formData[itemQ.Id] === ""
                            ? null
                            : ForceFormatDate(formData[itemQ.Id])
                            : itemQ.QuestionType === "Currency" || itemQ.QuestionType === "CurrencyEuropean" ? CurrencyFormat(formData[itemQ.Id]?.toString(), itemQ.QuestionType)
                            : itemQ.QuestionType === "Password"
                              ? getHash(formData[itemQ.Id])
                              : formData[itemQ.Id],
                    });
                  });

                  console.log("ListMetdata: ", ListMetdata);

                  let Request = {
                    UserName: ListMetdata.find(
                      (item) => item.Name === "UserName"
                    )?.Value,
                    Password: "Nuevo1234_",
                    Name: ListMetdata.find((item) => item.Name === "Name")
                      ?.Value,
                    SecondName: ListMetdata.find(
                      (item) => item.Name === "SecondName"
                    )?.Value,
                    FatherLastName: ListMetdata.find(
                      (item) => item.Name === "FatherLastName"
                    )?.Value,
                    MotherLastName: ListMetdata.find(
                      (item) => item.Name === "MotherLastName"
                    )?.Value,
                    Email: ListMetdata.find((item) => item.Name === "Email")
                      ?.Value,
                    PhoneNumber: ListMetdata.find(
                      (item) => item.Name === "PhoneNumber"
                    )?.Value,
                    Metadata: ListMetdata,
                    RoleId: ListMetdata.find((item) => item.Name === "Rol")
                      ?.Value,
                    TenantId: ListMetdata.find((item) => item.Name === "Tenant")
                      ?.Value,
                    Active: true,
                  };

                  console.log(
                    "Request Update metadata**********************: ",
                    Request
                  );
                  if (editionActive == true) {
                    let UpdateReques = {
                      ...Request,
                      Id: userSeleted.Id,
                    };
                    console.log("UpdateReques", UpdateReques);

                    dispatch(
                      userActions.UpdateUserAuthenticatorRole(UpdateReques)
                    );
                  } else {
                    console.log("UpdateReques 01", Request);
                    dispatch(
                      userActions.RegisterUserAuthenticatorRole(Request)
                    );
                  }
                  setSendUserRegister(true);
                  setLoadView(true);
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    height: 38,
                    minWidth: 350,
                    borderRadius: "25px",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setaAtiveCaptureNew(false);
                    setEditionActive(false);
                  }}
                >
                  Cancelar
                </Button>
              </Box>
            </Box>
          ) : (
            <MainTableDinamyc
              Rows={rows}
              Columns={columns}
              OnClickData={(e) => onClickData(e)}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
