import React, { useEffect, useState } from 'react';
import { Button, Typography, Dialog, Box, styled, } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";

// import { mainStyles, theme } from "../../styles/Main.js";
import { B64toBlob } from "../../utils";
import VisorPDF from "../Visor/VisorPDF";
import { UploadDcumentsControl } from "../index";
import { useTranslation } from "react-i18next";

const ContainerCamera = styled(Box)(({ theme }) => ({
    background: "#FFFFFF",
    margin: "0px",
    marginBottom: '0px',
    gridTemplateColumns: '20% 60% 20%',
    display: 'grid !important',
    [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: '100%',
        minHeight: '90vh',
    },
    overflowY: 'hidden !important',
    height: '100vh',
}));

export const FullUploadFiles = (props) => {
    const formMethods = useForm({ mode: "onChange" });

    const [fileB64, setFileB64] = useState(null)
    const [fileUrl, setfileUrl] = useState(null);
    const [extensionCurrent, setExtensionCurrent] = useState("");
    const { t } = useTranslation();

    console.log("extensionsDoc 1", props.ExtensionsDoc)

    let handleFileChange = (file, fileB64Response, extension) => {
        console.log("handleFileChange***********************extension: ", extension, " fileB64Response: ", fileB64Response)
        setExtensionCurrent(`.${extension}`)
        if (fileB64Response == undefined || fileB64Response === null) {
            return;
        }
        else if (navigator.userAgent.match(/iPhone/i)
            && (props.DocName === "TELMEX" || props.DocName === "IZZI")) {
            props.onSendData(fileB64Response, extension)
            props.onClose()
        } else {
            setFileB64(fileB64Response)
        }
    };

    useEffect(() => {
        if (fileB64 !== undefined && fileB64 !== null && fileB64 !== "") {
            console.log("-----extensionCurrent---: ", extensionCurrent)
            let mimeType = extensionCurrent != ".pdf" ? `image/${extensionCurrent}` : 'application/pdf'
            const blob = B64toBlob(fileB64, mimeType);
            const blobUrl = URL.createObjectURL(blob);
            setfileUrl(blobUrl);
        }
    }, [fileB64]);


    const renderComponent = () => {
        if (fileB64 === null) {
            console.log("props.ExtensionsDoc 2", props.ExtensionsDoc)
            return (<UploadDcumentsControl initLoadChange={true}
                ExtensionsDoc={props.ExtensionsDoc}
                onChange={(file, fileBase64, extensionChange) => {
                    handleFileChange(file, fileBase64, extensionChange)
                }}
                onClear={fileB64 == null ? true : false} />);
        } else if (fileUrl == null) {
            return (<span>{t("general_wait")}...</span>);
        } else if (props.ExtensionsDoc?.includes(extensionCurrent) && extensionCurrent === ".pdf") {
            console.log("ACCESO 1", props.ExtensionsDoc?.includes(extensionCurrent))
            return (<VisorPDF
                pdfUrl={fileUrl}
                pdfName={"Document"}
                height={"55vh"}
            />);
        } else if (props.ExtensionsDoc?.includes(extensionCurrent) && (extensionCurrent == '.png' || extensionCurrent == '.jpg' || extensionCurrent == '.jpeg')) {
            console.log("ACCESO 2", props.ExtensionsDoc?.includes(extensionCurrent))
            return (<img style={{ width: '100%' }}
                src={fileUrl}
                alt="experiment" />);
        }
    };

    return <Dialog fullScreen onClose={props.onClose}
        open={props.open}>
        <ContainerCamera>
            <Box sx={{ backgroundColor: '#f2f2f2', display: { s: "none", sm: "block" } }}></Box>
            <Box sx={{
                padding: '0.8em 0.9em 0.5em 0.9em',
                overflowY: 'auto',
            }}>
                <FormProvider {...formMethods}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        height: '78vh',
                    }}>
                        <Typography variant="h6">
                            {t("general_upload_doc")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {t("verify_correct_document")}
                        </Typography>
                        {renderComponent()}
                    </Box>
                    <Box sx={{
                        width: '100%',
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: 'column',
                    }}>
                        {fileB64 !== null ?
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ height: 45, px: 10, maxWidth: 300 }}
                                onClick={() => {
                                    props.onSendData(fileB64)
                                    props.onClose()
                                }} >
                                {t("general_continue")}
                            </Button>
                            : null
                        }
                        {fileB64 !== null ?
                            <Button
                                fullWidth
                                variant="outlined"
                                sx={{ height: 45, px: 10, marginTop: 2, maxWidth: 300 }}
                                onClick={() => {
                                    setFileB64(null);
                                }} >
                                {t("choose_another_file")}
                            </Button> :

                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ height: 45, px: 10, marginTop: 2, maxWidth: 300 }}
                                onClick={props.onClose} >
                                {t("general_cancel")}
                            </Button>
                        }
                    </Box>
                </FormProvider>
            </Box>
            <Box sx={{ backgroundColor: '#f2f2f2', display: { s: "none", sm: "block" } }}></Box>
        </ContainerCamera>
    </Dialog>
};

