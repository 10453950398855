import { Box, Button, Stack, styled, Typography } from "@mui/material";
import {
  AlertConfirm,
  CustomLoadingOverlay,
  InputField,
  UseImage,
} from "components";
import { AlertModal } from "components/common/Modals/Views/AlertModal";
import { useStyles } from "components/NoShares";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fileActions,
  notificationActions,
  workFlowActions,
} from "store/actions";
import otp_phoneImg from "assets/OTP.png";
import useSWRMutation from "swr/mutation";
import { validatePinChnagePassword } from "api/validation/validationRequest";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const ContainerImagePIN = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignContent: "center",
  alignItems: "center",
  overflow: "hidden",
  textAlign: "center",
  justifyContent: "center",
  marginBottom: "2rem",
}));

export const OTPFormPassword = ({ OnReturn }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const formMethods = useForm({ mode: "onChange" });
  const { control, handleSubmit, register, formState } = useForm({
    mode: "onBlur",
  });
  const [loadView, setLoadView] = useState(false);
  const localStyles = useStyles();
  const [notificationActive, setNotificationActive] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [sendCodeRetries, setRetries] = useState(1);
  const [activateResend, setActivateResend] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState(60);
  const file = useSelector((state) => state.FileInfo);
  const notification = useSelector((state) => state.ReSendNip);
  const userName = useSelector((state) => state.Authentication?.items)
    ?.UserLoginData?.Username;
  const dispatch = useDispatch();

  const { trigger, isMutating } = useSWRMutation(
    "ValidatePinChnagePassword",
    (url, payload) => validatePinChnagePassword(payload),
    {
      populateCache: true,
      revalidate: false,
    },
  );

  useEffect(() => {
    if (file.items) {
      let phone = file.items.PhoneNumber && file.items.PhoneNumber;
      let email = file.items.Email && file.items.Email;
      if (file.items.PhoneNumber && file.items.Email) {
        let firstPart = phone.substring(2, 10);
        let phoneNumber2 = phone.replace(firstPart, "-XXXX-XX-");
        let firstPartE = email.substring(1, email.indexOf("@") - 1);
        let emailStr = email.replace(firstPartE, "xxxxx");
        setPhoneNumber(phoneNumber2);
        setEmail(emailStr);
      }
      // dispatch(notificationActions.ReSendNip(file.items.Id));
      dispatch(
        notificationActions.GetNewPin({
          Tenant: "",
          UserName: userName,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (timerSeconds === 0) {
      setActivateResend(true);
    }
    if (!timerSeconds) return;
    const intervalId = setInterval(() => {
      setTimerSeconds(timerSeconds - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timerSeconds]);

  useEffect(() => {
    setLoadView(notification.loading);
    if (notification.items && notificationActive) {
      setNotificationActive(false);
      setActivateResend(false);
    } else if (notification.error) {
      setNotificationActive(false);
      setActivateResend(true);
      setLoadView(false);
      AlertModal(
        {
          icon: "error",
          okbtntext: "Aceptar",
          text: notification.error,
        },
        (event) => {},
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  const handleSend = (data) => {
    setLoadView(true);
    console.log('data["txtPIN"]::', data["txtPIN"]);
    /* dispatch(
      notificationActions.SendNip({
        File_id: id,
        PIN: data["txtPIN"],
      }),
    ) */
    trigger({
      UserName: userName,
      PIN: data["txtPIN"],
    })
      .then(() => {
        enqueueSnackbar(t("alert_successful_update"), { variant: "success" });
        OnReturn();
      })
      .catch(() => {
        AlertModal("Error", "El código OTP ingresado no es correcto", "error");
      })
      .finally(() => setLoadView(false));
  };

  const handleRetry = () => {
    setTimerSeconds(60);
    if (activateResend) {
      setRetries(sendCodeRetries + 1);
      if (sendCodeRetries >= 2) {
      } else {
        // dispatch(notificationActions.ReSendNip(id));
        dispatch(
          notificationActions.GetNewPin({
            Tenant: "",
            UserName: userName,
          }),
        );
        setNotificationActive(true);
      }
    }
  };

  const handleCancel = () => {
    AlertConfirm(
      {
        icon: "warning",
        html: (
          <Box>
            <Typography
              textAlign={"center"}
              variant="body1"
              sx={{ mt: 1, textAlign: "center", maxWidth: 400, mx: "auto" }}
            >
              Seguro que quieres cancelar el proceso de cambio de contraseña?
            </Typography>
          </Box>
        ),
        okbtntext: t("general_accept"),
        notbtntext: t("general_reject"),
      },
      (result) => {
        if (result.isConfirmed) {
          OnReturn();
        }
      },
      null,
      t,
    );
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <Stack
          sx={{
            flexDirection: "column",
            gap: "1rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ContainerImagePIN>
            <UseImage className={localStyles.imgPin} src={otp_phoneImg} />
          </ContainerImagePIN>
          <Box sx={{ textAlign: "justify" }}>
            <Typography variant="subtitle1">
              Introduce el código de seguridad que hemos enviado{" "}
              <b>{phoneNumber}</b> al correo que registraste.<b>{email}</b>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ marginTop: "5px !important" }}
            >
              Si no encuentras el correo en la <b>bandeja</b> de entrada, por
              favor revisa <b>SPAM</b> o <b>correo no deseado</b>.
            </Typography>
          </Box>
          <div style={{ width: "80%" }}>
            <InputField
              inputType="PIN"
              control={control}
              register={register}
              name="txtPIN"
              label="Código de seguridad"
              validations={{ required: true }}
              required={true}
              error={!!formState.errors["txtPIN"]}
              helperText={formState.errors["txtPIN"]?.message}
            />
          </div>
          <Typography variant="caption">
            Solicitar nuevo código en {timerSeconds} segundos.
          </Typography>
          <Stack
            sx={{
              flexDirection: "column",
              gap: "1rem",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              onClick={handleSubmit(handleSend)}
              sx={{ width: "70%" }}
            >
              Validar código
            </Button>
            <Button
              variant="outlined"
              onClick={handleRetry}
              disabled={!activateResend}
              sx={{ width: "70%" }}
            >
              Reenviar código
            </Button>
            <Button
              variant="outlined"
              onClick={handleCancel}
              sx={{ width: "70%" }}
            >
              Cancelar
            </Button>
          </Stack>
          <CustomLoadingOverlay
            active={isMutating || loadView}
            text="Espere un momento por favor..."
          />
        </Stack>
      </FormProvider>
    </>
  );
};
