import React, { useEffect, useState } from 'react'
import { RecordInfo } from './RecordInfo'
import { useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import { GenericHeaderActions } from '../GenericHeaderActions'
import { useTranslation } from 'react-i18next'

export default function RecordInfoIndex() {

  const customValidFile = useSelector(state => state.CustomValidationFile)
  const record = useSelector(state => state.RecordInfo)
  const infoFile = useSelector(state => state.FileInfo)

  let folioSolicitud = infoFile.items.Folio;
  let fullName = infoFile.items.FullName

  const [infoRecordItems, setInfoRecordItems] = useState([])
  const [infoValidationFile, setInfoValidationFile] = useState([])

  const { t } = useTranslation();

  useEffect(() => {
    if (customValidFile.items !== undefined) {
      setInfoValidationFile(customValidFile.items)
    }
  }, [customValidFile])

  useEffect(() => {
    if (record.items !== undefined) {
      setInfoRecordItems(record.items)
    }
  }, [record])

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant='h5'>
        {t("request_information")}
      </Typography>
      <Typography variant='h5'>
        {fullName}
      </Typography>
      <Typography variant='h5'>
        {`${t("job_offer_folio")}: ${folioSolicitud} `}
      </Typography>
      <Box sx={{ mt: 2 }}>
        <RecordInfo data={infoRecordItems} dataValidationFile={infoValidationFile} />
      </Box>
    </Box>
  )
}
