import { Box } from '@mui/material';
import { useState } from 'react';
import { RequiredDocumentsSelector } from './RequiredDocumentsSelector';
import { OCRDocument } from './OCRDocument';
import DocumentMultiLoader from '../DocumentMultiLoader';
import { useTranslation } from 'react-i18next';
import { AlertModal } from 'components/common/Modals/Views/AlertModal';
import DocumentAutoUpload from '../DocumentAutoUpload/DocumentAutoUpload';

export const UploadRequiredDocuments = ({ onRefresh }) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState("DEFAULT_DOCUMENT_VISUALIZATION");
  const [documentSpecifications, setDocumentSpecifications] = useState();
  const [documentB64, setDocumentB64] = useState();

  const handleUploadFileSuccess = () => {
    AlertModal(
      "",
      t("documentation_upload_doc_successful_upload"),
      "success",
    ).then(() => onRefresh());
  };

  const handleSetDocumentSpec = (spec) => {
    setDocumentSpecifications(spec);
  };

  const handleSetDocumentB64 = (documentB64) => {
    setDocumentB64(documentB64);
  };

  const handleMode = (mode) => {
    setMode(mode);
  };

  const RENDER_COMPONENT_BY_MODE = {
    DEFAULT_DOCUMENT_VISUALIZATION: (
      <RequiredDocumentsSelector
        handleMode={handleMode}
        handleSetDocumentSpec={handleSetDocumentSpec}
        onRefresh={onRefresh}
      />
    ),
    // UPLOAD_METHOD_SELECTION: (
    //   <UploadMethodSelection handleMode={handleMode} documentSpecs={documentSpecifications} />
    // ),
    DOCUMENT_UPLOAD: (
      <>
        {/* <UploadRequiredDocumentDragAndDrop
        handleSetDocumentB64={handleSetDocumentB64}
        handleMode={handleMode}
      /> */}

        <DocumentMultiLoader
          handleMode={handleMode}
          handleSetDocumentB64={handleSetDocumentB64}
        />
      </>
    ),
    // CAMERA_UPLOAD: (
    //   <CameraUpload handleMode={handleMode} handleSetDocumentB64={handleSetDocumentB64} />
    // ),
    DOCUMENT_METADATA: (
      <DocumentAutoUpload
        handleMode={handleMode}
        documentB64={documentB64}
        documentSpec={documentSpecifications}
        handleUploadFileSuccess={handleUploadFileSuccess}
      />
    ),
    DOCUMENT_OCR: (
      <OCRDocument
        handleMode={handleMode}
        documentB64={documentB64}
        documentSpec={documentSpecifications}
      />
    ),
  };

  return <Box>{RENDER_COMPONENT_BY_MODE[mode]}</Box>;
};
