import React from 'react'
import { mainStyles } from '../theme/mainStyles'
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { UseImage } from './'
// import imageLoad from 'assets/DOC/img/Logo_DS.gif'
import imageLoad from 'assets/Loading_Overlay.gif'


export const CustomLoadingOverlay= (props) => {
    const classes = mainStyles.CustomLoadingOverlayStyles()

    return (<Dialog
        onClose={props.closeAction}
        open={props.active}
        className={classes.dialogGralLoad} >
        <DialogContent className={classes.DialogContent} >
            <UseImage src={imageLoad} className={classes.imgLoad} type='img' />
        </DialogContent>
        <DialogActions className={classes.DialogActions} >
            <Typography variant="subtitle2" gutterBottom >{props.text || 'Espere un momento'}</Typography>
        </DialogActions>
    </Dialog>
    )
}