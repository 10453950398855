import { Box, Typography } from "@mui/material";
// import documentMetadata from 'assets/DOC/Icons/documentMetadata.svg'
import documentMetadata from 'assets/Metlife/Icons/documentMetadata.png'
import { useTranslation } from "react-i18next";

export const DocumentHasNoMetadata = () => {
  const { t } = useTranslation()
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={'2rem'}>
      <Box component={'img'} src={documentMetadata} width={'15rem'}></Box>
      <Typography variant={'h6'}>{t("doc_has_ne_meta_title")}</Typography>
      < Typography variant={'subtitle1'} >{t("doc_has_ne_meta_description")}</Typography>
    </Box>
  )
}