import { IsDevice } from "utils";

const TabPanel = (props) => {
  const { children, value, index, style = {}, ...other } = props;
  const { IsMobile } = IsDevice();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{
        padding: IsMobile ? "0" : "3.5rem",
        ...(IsMobile && { paddingTop: "1rem", paddingBottom: "1rem" }),
        height: "calc(100% - 50px)",
        ...style,
      }}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

export default TabPanel;
