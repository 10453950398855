import { IconButton , styled } from '@mui/material';

export const OBIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: '4rem',
  minWidth: '75px',
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  '&:hover': {
    backgroundColor: theme.palette.primary.main80
  },
}));
