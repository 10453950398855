import { styled } from "@mui/material/styles";
import { Card as MUICard } from "@mui/material";

export const Card = styled(MUICard)(({ theme }) => ({
  height:'15rem',
  margin: '1rem',
  borderRadius:'1rem',
  display:'grid',
  placeItems: 'center',
  padding: '2rem',
  gap:'1.5rem',
  textAlign:'center',
  '&:hover': {
    backgroundColor: `${theme.palette.primary.tableHover} !important`,
    cursor:'pointer'
  },
}));