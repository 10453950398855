import { Box } from "@mui/material";
import styled from "styled-components";

export const GenericContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  padding: '0 2rem',
  textAlign: 'center',
  background: theme.palette?.primary?.mainLyrics
}));

export const GenericContainerForm = styled(Box)(({ theme }) => ({
  background: theme.palette?.primary?.mainLyrics,
  margin: '0px',
  marginBottom: '0px',
  overflowY: 'auto !important',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'left',
  overflowX: 'hidden !important',
  width: '100%',
  height: '100%'
}));