import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { authActions } from "store/actions";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import Avatar1 from "../../../assets/generalInfo/actor1.svg";
import Avatar2 from "../../../assets/generalInfo/actor2.svg";
import Avatar3 from "../../../assets/generalInfo/actor3.svg";
import Avatar4 from "../../../assets/generalInfo/actor4.svg";
import Avatar5 from "../../../assets/generalInfo/actor5.svg";

const UserCard = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [rolUser, setRolUser] = useState("");
  const { t } = useTranslation();
  const avatarStored = useSelector((state) => state.SetAvatar.avatar);
  const nameStored = useSelector((state) => state.SetName.name);

  useEffect(() => {
    const userVal = sessionStorage.getItem("userName");
    if (userVal !== undefined && userVal !== null && userVal !== "") {
      const nameVal = userVal; //user.split(" ")[0]
      const rolUserVal = sessionStorage.getItem("Rol");
      setName(nameVal);
      setRolUser(rolUserVal);
    }
  }, []);

  useEffect(() => {
    setName(nameStored);
  }, [nameStored]);

  const handleChange = ({ target }) => {
    const { value } = target;
    if (value === "logOut") {
      Logout();
    }
  };

  const Logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    sessionStorage.removeItem("tokenMc");
    localStorage.removeItem("versionApp");
    dispatch(authActions.CleanAuthentication());
    navigate("/auth/login");
  };

  const avatarSelected = () => {
    switch (avatarStored.toString()) {
      case "1":
        return Avatar1;
      case "2":
        return Avatar2;
      case "3":
        return Avatar3;
      case "4":
        return Avatar4;
      case "5":
        return Avatar5;
      default:
        return Avatar1;
    }
  };

  return (
    <div>
      <Box
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "1.2rem",
          height: "100px",
          width: "13%",
          padding: "0.8rem",
          position: "absolute",
          bottom: "10px",
          [theme.breakpoints.between(900, 1750)]: {
            // flexDirection: "column",
            gap: "0.5rem",
          },
          [theme.breakpoints.between(600, 900)]: {
            justifyContent: "flex-start",
            gap: "0.5rem",
          },
        }}
      >
        {/*<Avatar*/}
        {/*  style={{*/}
        {/*    color: 'primary.mainLyrics',*/}
        {/*    backgroundColor: theme.palette.primary.main,*/}
        {/*    fontWeight: 600,*/}
        {/*    width: 40,*/}
        {/*    height: 40,*/}
        {/*    fontSize: 40,*/}
        {/*    mt: 0.7,*/}
        {/*  }}*/}
        {/*  alt={sessionStorage.userName}*/}
        {/*  src={PaisSeleted(auth?.items?.WorkFlowData?.WorkFlowName)}*/}
        {/*/>*/}
        {!!!avatarStored || avatarStored.toString() === "0" ? (
          <Icon
            icon={"iconoir:profile-circle"}
            fontSize={"3rem"}
            color={theme.palette.primary.main}
            style={{
              fontWeight: 600,
              minWidth: "3rem",
              minHeight: "3rem",
            }}
          ></Icon>
        ) : (
          <img
            src={avatarSelected()}
            alt={sessionStorage.userName}
            style={{
              fontWeight: 600,
              minWidth: "2rem",
              minHeight: "2rem",
            }}
          />
        )}
        <div style={{ overflow: "hidden" }}>
          <Tooltip title={name} placement="top">
            <Typography
              sx={{
                color: theme.palette.userStyle.textDetails,
                fontWeight: "bold",
                fontSize: "13px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "150px",

                [theme.breakpoints.between(600, 900)]: {
                  textOverflow: "inherit",
                  maxWidth: "100%",
                },
              }}
            >
              {name}
            </Typography>
          </Tooltip>
          <Select
            value={rolUser}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              width: "100%",
              border: "none",
              padding: "0px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiOutlinedInput-input": {
                padding: "0px",
              },
            }}
            onChange={handleChange}
          >
            <MenuItem value={rolUser} disabled>
              {rolUser}
            </MenuItem>
            <MenuItem value="logOut">{t("log_out")}</MenuItem>
          </Select>
        </div>
      </Box>
    </div>
  );
};

export default UserCard;
