import { Box, Grid, Stack, Typography } from "@mui/material";
import { Buttons, CustomLoadingOverlay, UseImage } from "components";
import { CustomCameraSelfie } from "components/Camera/CustomCameraSelfie";
import { GenericContainer } from "components/common/GenericContainers/GenericContainer";
import { useStyles } from "components/NoShares";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { documentsRequestsAsync } from "services/DocumentsRequestsAsync";
import { documentActions, fileActions, workFlowActions } from "store/actions";
import { blobToBase64, GenerateRandom } from "utils";
import LifeTestHelp from "./LifeTestHelp";
import { CustomAlertDialog } from "components/CustomAlertDialog";
import RechazoImg from "assets/Rechazo.gif";
import CheckImg from "assets/Check.gif";
import ErrorIcon from "assets/icons/ErrorIcon.gif";
import mask from "assets/maskCaptureBlanca.png";
import maskAprobada from "assets/maskAprove.png";
import { AlertModal } from "components/common/Modals/Views/AlertModal";

const sentimientosArray = [
  {
    value: "happiness",
    sentimiento: "Felicidad",
    uid: "ec2c2a2c-2666-4574-917c-3fbc30a9d460",
  },
  {
    value: "surprise",
    sentimiento: "Sorpresa",
    uid: "c2faac89-9b73-476e-bf5c-e750620b21a8",
  },
];

const workFlowInstanceId = sessionStorage.getItem("WorkFlowInstanceId");

export const FaceValidation = ({ handleChangeCurrentStep }) => {
  const { id: folioId } = useParams();
  const [loadView, setLoadView] = useState(false); //Se muestra/oculta el gif loader
  const localStyles = useStyles(); //estílos de este componente (LoginStyles.js)
  const location = useLocation();
  const valRandom = GenerateRandom();
  const date = new Date();
  const [currentPicture, setCurrentPicture] = useState(null);
  const [captureInit, setCaptureInit] = useState(null);
  const [cameraB64, setCameraB64] = useState(null);

  const [timerSeconds, setTimerSeconds] = useState(null);
  const [faceResponse, setFaceResponse] = useState();
  const [arraySelfie, setArraySelfie] = useState([]);
  const [validateResponse, setValidateResponse] = useState(null);
  const [valueSentimiento, setValueSentimiento] = useState(null);
  const [disabledButtonCapture, setDisabledButtonCapture] = useState(false);
  const [openAlertInformation, setOpenAlertInformation] = useState(false);
  const [openHelpSelfie, setOpenHelpSelfie] = useState(true);

  const timeStamp = `${date.getFullYear()}${
    date.getMonth() + 1
  }${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;

  const file = useSelector((state) => state.FileInfo);

  const typeSeflie = file?.items?.Stage?.Properties.find(
    (type) => type.Name === "GetFaceCaptureNumber",
  );
  const typeAmbiente = file?.items?.Stage?.Properties.find(
    (type) => type.Name === "ProofOfLifeValidationType",
  );
  const dispatch = useDispatch();

  const sentAleatorio = () => {
    let rand = Math.floor(valRandom * sentimientosArray.length);
    let rValue = sentimientosArray[rand];
    setValueSentimiento(rValue);
  };

  // useEffect(() => {
  //   sentAleatorio();
  //   dispatch(
  //     fileActions.GetFileInfo({
  //       FileId: folioId,
  //       WorkFlowInstanceId: workFlowInstanceId,
  //     }),
  //   );
  //   // setLoadView(true);
  // }, []);

  useEffect(() => {
    if (timerSeconds === 0) {
      setTimerSeconds(0);
    }
    if (!timerSeconds) return;
    const intervalId = setInterval(() => {
      setTimerSeconds(timerSeconds - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timerSeconds]);

  const handleUploadPhoto = async (photoB64) => {
    let newArr = [...arraySelfie];
    newArr.push(photoB64);
    setArraySelfie(newArr);
    setCameraB64(photoB64);

    if (typeAmbiente?.Value === "" || typeAmbiente?.Value === "IDRND") {
      let request2 = {
        fileId: file.items.Id,
        uid: valueSentimiento?.uid,
        B64Data: `data:image/jpeg;base64,${photoB64}`,
        fileName: `${timeStamp}.jpg`,
      };
      localStorage.setItem("selfiePruebaVida", photoB64);
      setLoadView(true);
      handleClear();

      let response2 =
        await documentsRequestsAsync.GetFaceDetectionIDRND(request2);
      if (response2 != null && response2?.FaceLiveAproved) {
        setValidateResponse(response2);
      } else {
        setLoadView(false);
        AlertModal(
          "Error",
          "El reconocimiento facial no fue exitoso, intenta nuevamente",
          "error",
        );
        return;
      }
    } else if (typeSeflie?.Value === "2") {
      if (arraySelfie.length === 0) {
        setLoadView(true);
        let request1 = {
          fileId: file.items.Id,
          B64Data: `data:image/jpeg;base64,${photoB64}`,
          fileName: `${timeStamp}.jpg`,
        };
        handleClear();
        localStorage.setItem("selfiePruebaVida", photoB64);
        console.log("request1: ", request1);
        let response1 = await documentsRequestsAsync.GetFaceDetection(request1);
        if (response1) {
          setLoadView(false);
          setFaceResponse(response1);
        }
      } else if (arraySelfie.length === 1) {
        let request2 = {
          fileId: file.items.Id,
          uid: valueSentimiento?.uid,
          B64Data: `data:image/jpeg;base64,${photoB64}`,
          fileName: `${timeStamp}.jpg`,
          faceDetectedId: faceResponse?.FaceDetectedId,
        };
        setCaptureInit(false);
        let response2 = await documentsRequestsAsync.GetFaceFeeling(request2);
        if (response2 != null) {
          setValidateResponse({
            FeelingDetected: true,
            FaceLiveAproved: true,
          });
          RegStatusStage(file.items.Id);
        }
      }
    } else {
      let request2 = {
        fileId: file.items.Id,
        uid: valueSentimiento?.uid,
        B64Data: `data:image/jpeg;base64,${photoB64}`,
        fileName: `${timeStamp}.jpg`,
      };
      localStorage.setItem("selfiePruebaVida", photoB64);
      setLoadView(true);
      handleClear();
      let response2 = await documentsRequestsAsync.GetFaceFeeling(request2);
      if (response2 != null) {
        setValidateResponse(response2);
        RegStatusStage(file.items.Id);
      }
    }
  };

  useEffect(() => {
    if (
      validateResponse?.FeelingDetected === true ||
      validateResponse?.FaceLiveAproved === true
    ) {
      handleUploadSection();
    } else {
      // setLoadView(false);
      // handleClear();
      // setArraySelfie([]);
      // setArraySelfie([]);
      // sentAleatorio();
      // setTimeout(() => {
      //   setValidateResponse(null);
      //   setCameraB64(null);
      //   setDisabledButtonCapture(false);
      //   setOpenAlertInformation(false);
      // }, 6000);
    }
  }, [arraySelfie, validateResponse]);

  const handleCapture = () => {
    setDisabledButtonCapture(true);
    setCaptureInit(true);
  };

  const handleClear = () => {
    setCaptureInit(false);
    setCurrentPicture(null);
  };

  const handleUploadSection = () => {
    setTimeout(() => {
      setCameraB64(null);
      setDisabledButtonCapture(false);
      setOpenAlertInformation(false);
    }, 3000);

    const dataToSend = {
      B64Content: cameraB64,
      FileId: file.items.Id,
      // Actor_Id: actorId,
      SkipActor: null,
      SectionId: JSON.parse(localStorage.getItem("sections"))?.find(
        (sec) => sec.Name === "Selfie",
      )?.Id,
      Extension: ".jpg",
      Skip: false,
      SkipReason: null,
      Metadata: [
        {
          GroupName: "FaceTec",
          Name: "EnrollmentID",
          Value: `ObDS_${file.items.Id}_${timeStamp}`,
        },
      ],
      ContinueWorkFlow: false,
      Geolocation: {
        Latitude: 85,
        Longitude: 90,
      },
      DeferLoad: false,
    };

    dispatch(documentActions.CargaSeccion(dataToSend))
      .then((uploadSecData) =>
        dispatch(documentActions.GetFacialComparison(uploadSecData.Result.Id)),
      )
      .then(() => handleChangeCurrentStep("OCR_EXTRACTION"))
      .catch((err) => AlertModal("Error", err.message, "error"))
      .finally(() => setLoadView(false));
  };

  const RegStatusStage = (folioId, statusValue = "ValidaciónFacial") => {
    let request = {
      File_id: folioId,
      Status: statusValue,
    };
    dispatch(workFlowActions.RegisterStatusStage(request));
  };

  return (
    <GenericContainer
      sx={{
        paddingTop: "0px !important",
      }}
    >
      <CustomCameraSelfie
        // isSelfie
        isDisplay={true}
        onCapture={(data) => handleUploadPhoto(data)}
        Redimensionar={true}
      />
      {/* <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box className={localStyles.mascara} sx={{ position: 'relative' }}>
          <img
            src={currentPicture === null ? mask : maskAprobada}
            alt="imagenMask"
            className={localStyles.imagen}
            style={{ position: 'absolute', height: '100%' }}
          />

        </Box>
      </Box> */}

      {openAlertInformation ? (
        <CustomAlertDialog
          open={openAlertInformation}
          closeAction={() => {
            setCameraB64(null);
            setDisabledButtonCapture(false);
            setOpenAlertInformation(false);
          }}
          title={
            validateResponse?.FeelingDetected === true ||
            validateResponse?.FaceLiveAproved === true
              ? "Prueba de vida éxitosa"
              : "No se pudo verificar tu prueba de vida"
          }
          Body={
            <Stack display={"flex"} alignItems={"center"}>
              {!validateResponse?.IsOK ? (
                <div>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        m: 2,
                      }}
                    >
                      <img src={ErrorIcon} alt="ImgError" width={130} />
                    </Box>
                    <Typography variant="h6" sx={{ fontWeight: 500 }}>
                      {validateResponse.Message}
                    </Typography>
                  </Box>
                </div>
              ) : (
                <div>
                  {console.log("validateResponse 1", validateResponse)}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      m: 2,
                    }}
                  >
                    <UseImage
                      width={130}
                      src={
                        validateResponse?.FeelingDetected === true
                          ? CheckImg
                          : RechazoImg
                      }
                      type="Image"
                    />
                  </Box>
                  <Box>
                    <Typography variant="h4">Intenta nuevamente</Typography>
                    <Typography variant="h6" sx={{ fontWeight: 500 }}>
                      *Evita sombras y reflejos
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 500 }}>
                      *No uses gafas ni gorra
                    </Typography>
                  </Box>
                </div>
              )}
            </Stack>
          }
          // aceptedAction={handleContinueAction}
        />
      ) : null}

      <CustomLoadingOverlay
        active={loadView}
        text={
          loadView ? "Espere un momento por favor..." : "Extracción de datos..."
        }
      />
    </GenericContainer>
  );
};
