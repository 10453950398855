import { reportsConstants } from '../constants/index.js'

export const ReportsData = (state = {}, action) => {
    console.log('action', action)
    switch (action.type) {
        case reportsConstants.GET_DATA_REPORTS_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case reportsConstants.GET_DATA_REPORTS_SUCCESS:
            return {
                loading: false,
                items: action.product.Body,
                error: undefined
            }
        //break;
        case reportsConstants.GET_DATA_REPORTS_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        case reportsConstants.GET_DATA_REPORTS_RESET:
            return {
                loading: false,
                items: null,
                error: null
            }
        //break;
        default:
            return state
    }
}

export const GetReports = (state = {}, action) => {
    switch (action.type) {
        case reportsConstants.GET_REPORTS_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case reportsConstants.GET_REPORTS_SUCCESS:
            return {
                loading: false,
                items: action.product.Body,
                error: undefined
            }
        //break;
        case reportsConstants.GET_REPORTS_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}