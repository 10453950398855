import React, { Fragment, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import { PhotoCamera } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import actor1 from "../../assets/generalInfo/actor1.svg";
import actor2 from "../../assets/generalInfo/actor2.svg";
import actor3 from "../../assets/generalInfo/actor3.svg";
import actor4 from "../../assets/generalInfo/actor4.svg";
import actor5 from "../../assets/generalInfo/actor5.svg";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { ChangePasswordView } from "./ChangePasswordView";
import {
  Slide,
  Box,
  TextField as InputText,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  DialogActions,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const UploadButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.primary.main}`,
  "&:hover": {
    backgroundColor: theme.palette.background.paper,
  },
}));

const TextField = styled(InputText)(({ theme }) => ({
  "& .MuiOutlinedInput-input": {
    height: 25,
    padding: "10px 15px",
    [theme.breakpoints.down("sm")]: {
      height: 18,
      padding: "8px 10px",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    fontSize: 16,
    width: "100%",
    "& fieldset legend span": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
      borderRadius: 8,
    },
  },
  "& .MuiInputLabel-root": {
    color: "#000",
    position: "relative",
    transform: "none",
    fontSize: "16px",
    marginBottom: 5,
    marginTop: 5,
    display: "flex",
    alignItems: "center",
    pointerEvents: "auto",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    // fontFamily: '"Montserrat",sans-serif',
    [theme.breakpoints.down("sm")]: {
      fontWeight: 400,
      fontSize: "9px",
      lineHeight: "144%",
    },
  },
  "& label.Mui-focused": {
    color: theme.palette.primary.main,
  },
  "& label.Mui-error": {
    color: "#DC1717 !important",
    // #DC1717
  },
  "& .MuiSelect-select": {
    height: 25,
    "& .notranslate": {
      display: "block",
      margin: "2.45px 0",
    },
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC1717 !important",
  },
  "& .MuiInputBase-multiline": {
    padding: "0",
  },
  input: {
    "&::placeholder": {
      [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
        fontSize: "10px",
      },
    },
  },
  "& .MuiFormLabel-asterisk": {
    color: "#DC1717 !important",
  },
}));

const ImgStyle = {
  width: "100px",
};

function PersonalData({ formControl, onSubmit, CurrentAvatar }) {
  const { t } = useTranslation();
  const [changePassView, setChangePassView] = useState(false);
  const [openAvatar, setOpenAvatar] = useState(false);
  const [avatarSelected, setAvatarSelected] = useState(0);
  const [avatarTemp, setAvatarTemp] = useState(0);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = formControl;

  useEffect(() => {
    setAvatarSelected(CurrentAvatar);
    setAvatarTemp(CurrentAvatar);
  }, [CurrentAvatar]);

  const avatarList = [
    { id: 1, image: actor1 },
    { id: 2, image: actor2 },
    { id: 3, image: actor3 },
    { id: 4, image: actor4 },
    { id: 5, image: actor5 },
  ];

  const handleChangeView = () => {
    setChangePassView(!changePassView);
  };

  const handleOpenAvatarSelector = () => {
    setOpenAvatar(true);
  };

  const handleCloseAvatarSelector = () => {
    setOpenAvatar(false);
  };

  const handleSelectAvatar = () => {
    setAvatarTemp(avatarSelected);
    setValue("Picture", avatarSelected);
    handleCloseAvatarSelector();
  };

  return !changePassView ? (
    <Box>
      {openAvatar && (
        <Dialog
          onClose={handleCloseAvatarSelector}
          open={openAvatar}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <b>{t("profile_settings_select_avatar")}</b>
          </DialogTitle>
          <DialogContent>
            <Stack
              flexDirection={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"1rem"}
            >
              {avatarList.map((avatar) => {
                return (
                  <Box
                    key={avatar.id}
                    onClick={() => {
                      setAvatarSelected(avatar.id);
                    }}
                    sx={{
                      "&:hover": { cursor: "pointer" },
                      ...(avatarSelected == avatar.id && {
                        border: "2px solid red",
                        borderRadius: "100px",
                      }),
                    }}
                  >
                    <img src={avatar.image} style={ImgStyle} />
                  </Box>
                );
              })}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack
              sx={{
                width: "100%",
                gap: "0.5rem",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                sx={{ width: "100%" }}
                onClick={handleCloseAvatarSelector}
              >
                {t("general_cancel")}
              </Button>
              <Button
                variant="contained"
                sx={{ width: "100%" }}
                onClick={handleSelectAvatar}
              >
                {t("general_accept")}
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ maxWidth: "400px", margin: "0 auto" }}
      >
        <Box display="flex" justifyContent="center" mb={1}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <UploadButton
                color="primary"
                onClick={() => {
                  handleOpenAvatarSelector();
                }}
              >
                <EditIcon />
              </UploadButton>
            }
          >
            <Avatar
              alt="Mclovin"
              src={avatarList.find((av) => av.id == avatarTemp)?.image}
              sx={{ width: 128, height: 128 }}
            />
          </Badge>
        </Box>
        <Controller
          name="Name"
          control={control}
          rules={{ required: "Nombre es requerido" }}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label={t("general_names")}
              variant="outlined"
              margin="dense"
              fullWidth
              error={!!errors.Name}
              helperText={errors.Name ? errors.Name.message : ""}
              required
            />
          )}
        />
        <Controller
          name="FatherLastName"
          control={control}
          rules={{ required: "Apellido paterno es requerido" }}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label={t("general_fathers_lastname")}
              variant="outlined"
              margin="dense"
              fullWidth
              error={!!errors.FatherLastName}
              helperText={
                errors.FatherLastName ? errors.FatherLastName.message : ""
              }
              required
            />
          )}
        />
        <Controller
          name="MotherLastName"
          control={control}
          rules={{ required: "Apellido materno es requerido" }}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label={t("general_mothers_lastname")}
              variant="outlined"
              margin="dense"
              fullWidth
              error={!!errors.MotherLastName}
              helperText={
                errors.MotherLastName ? errors.MotherLastName.message : ""
              }
              required
            />
          )}
        />
        <Controller
          name="Email"
          control={control}
          rules={{
            required: "Correo es requerido",
            pattern: {
              value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
              message: "Correo inválido",
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label={t("general_email")}
              variant="outlined"
              margin="dense"
              fullWidth
              error={!!errors.Email}
              helperText={errors.Email ? errors.Email.message : ""}
              required
            />
          )}
        />
        <Controller
          name="Phone"
          control={control}
          rules={{
            required: "Telefono es requerido",
          }}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label={t("general_phone")}
              variant="outlined"
              margin="dense"
              fullWidth
              error={!!errors.Phone}
              helperText={errors.Phone ? errors.Phone.message : ""}
              required
            />
          )}
        />
        <Controller
          name="Password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              disabled
              size="small"
              label={t("general_password")}
              variant="outlined"
              margin="dense"
              fullWidth
              error={!!errors.Phone}
              helperText={errors.Phone ? errors.Phone.message : ""}
              InputProps={{
                endAdornment: (
                  <EditOutlinedIcon
                    sx={{ "&:hover": { cursor: "pointer" } }}
                    onClick={handleChangeView}
                  />
                ),
              }}
            />
          )}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
        >
          {t("general_save")}
        </Button>
      </Box>
    </Box>
  ) : (
    <ChangePasswordView onReturn={handleChangeView} />
  );
}

export default PersonalData;
