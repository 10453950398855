import {fetcher} from "../fetcher";

export const getSearchFile = async (PAYLOAD) =>
  await fetcher("configuration/GetSearchFile", PAYLOAD);

export const getSearchFileDynamic = async (PAYLOAD) =>
  await fetcher("procedures/GetFile", PAYLOAD);

export const getElasticSearch = async (PAYLOAD) =>
  await fetcher("elastic/Search", PAYLOAD);

export const getElasticProperties = async (PAYLOAD) =>
  await fetcher("elastic/GetProperties", PAYLOAD);