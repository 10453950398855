import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { renderToString } from "react-dom/server";
import ErrorIcon from "../assets/icons/ErrorIcon.gif";
import SuccessIcon from "../assets/icons/SuccessIcon.gif";
import WarningIcon from "../assets/icons/WarningIcon.gif";
import { useTranslation } from "react-i18next";

const MySwal = withReactContent(Swal);

export const Alert = (props, successfn, errorfn, t) => {
  //const { t } = useTranslation();
  console.log("props Alert ", props);
  return MySwal.fire({
    iconHtml: GetIconAlert(props.icon),
    title: props.title,
    text: props.text,
    html: props.html,
    showClass: {
      icon: "swal2-icon-alertNoti",
    },
    confirmButtonText: props.okbtntext ?? t("confirm"),
    customClass: {
      confirmButton: props.okbtnclass ?? "confirmButtonSweet",
      container: "backdropSweet",
      title: "tituloNotificacion",
    },
  })
    .then(successfn)
    .catch(errorfn);
};

export const AlertConfirm = (props, successfn, errorfn, t) => {
  //const { t } = useTranslation();
  console.log("props Alert Confirm ", props);
  return MySwal.fire({
    iconHtml: renderToString(GetIconAlert(props.icon)),
    title: props.title,
    text: props.text,
    html: renderToString(props.html),
    showClass: {
      icon: "swal2-icon-alertNoti",
    },
    showCancelButton: true,
    confirmButtonText: props.okbtntext ?? t("confirm"),
    cancelButtonText: props.notbtntext ?? t("genral_cancel"),
    customClass: {
      confirmButton: props.okbtnclass ?? "confirmButtonSweetConfirm",
      cancelButton: props.notbtnclass ?? "confirmButtonSweetCancel",
      container: "backdropSweet",
      title: "tituloNotificacion",
    },
  })
    .then(successfn)
    .catch(errorfn);
};

// export const AlertConfirmInput = (props, successfn, errorfn, t) => {
//   //const { t } = useTranslation();
//   console.log("props Alert AlertConfirmInput ", props);
//   return MySwal.fire({
//     iconHtml: renderToString(GetIconAlert(props.icon)),
//     title: props.title,
//     text: props.text,
//     input: "text",
//     inputAttributes: {
//       autocapitalize: "off",
//     },
//     // html: renderToString(props.html),
//     showClass: {
//       icon: "swal2-icon-alertNoti",
//     },
//     showCancelButton: true,
//     confirmButtonText: props.okbtntext ?? t("confirm"),
//     cancelButtonText: props.notbtntext ?? t("genral_cancel"),
//     customClass: {
//       confirmButton: props.okbtnclass ?? "confirmButtonSweetConfirm",
//       cancelButton: props.notbtnclass ?? "confirmButtonSweetCancel",
//       container: "backdropSweet",
//       title: "tituloNotificacion",
//     },
//   })
//     .then(successfn)
//     .catch(errorfn);
// };

export const AlertConfirmInput = ({ 
  icon, 
  title, 
  text, 
  okbtntext, 
  notbtntext, 
  okbtnclass, 
  notbtnclass 
}, successfn, errorfn, t) => {
  //const { t } = useTranslation(); // Puedes habilitar esto si usas i18n en lugar de pasar `t` como parámetro
  return Swal.fire({
    iconHtml: renderToString(GetIconAlert(icon)),
    title: title,
    text: text,
    input: "text",
    inputAttributes: {
      autocapitalize: "off",
    },
    showClass: {
      icon: "swal2-icon-alertNoti",
    },
    showCancelButton: true,
    confirmButtonText: okbtntext ?? t("confirm"),
    cancelButtonText: notbtntext ?? t("genral_cancel"),
    customClass: {
      confirmButton: okbtnclass ?? "confirmButtonSweetConfirm",
      cancelButton: notbtnclass ?? "confirmButtonSweetCancel",
      container: "backdropSweet",
      title: "tituloNotificacion",
    },
    preConfirm: (inputValue) => {
      if (!inputValue) {
        Swal.showValidationMessage(t('input_required'));
      }
      return inputValue;
    }
  })
  .then((result) => {
    if (result.isConfirmed) {
      successfn(result.value); // Devuelve el valor ingresado
    }
  })
  .catch(errorfn);
};

const GetIconAlert = (type) => {
  switch (type) {
    case "error":
      return (
        <img
          src={ErrorIcon}
          alt="IMGError"
          className="swal2-icon-content-img"
        />
      );
    case "success":
      return (
        <img
          src={SuccessIcon}
          alt="IMGSuccess"
          className="swal2-icon-content-img"
        />
      );
    default:
      return (
        <img
          src={WarningIcon}
          alt="IMGWarning"
          className="swal2-icon-content-img"
        />
      );
  }
};
