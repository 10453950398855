import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { FormDynamic } from "./FormDynamic";
import { useParams } from "react-router-dom";
import { fileActions } from "store/actions";
import { CurrencyFormat, ExtractHour, ForceFormatDate } from "utils";
import { useEffect } from "react";
import moment from "moment";
import { eachDayOfInterval } from "date-fns";

export const ActionsFormDynamic = ({ onRefresh }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const workFlowInstanceId = sessionStorage.getItem("WorkFlowInstanceId");
  const infoFile = useSelector((state) => state.FileInfo);
  const updMetadata = useSelector((state) => state.FileUpdateMetData);
  console.log("InfoFile data: ", infoFile);

  useEffect(() => {
    if (updMetadata?.items != null) {
      console.log("----peticion n---onRefresh");
      dispatch(fileActions.FileUpdateMetadata(null, true));
      onRefresh();
    } else if (updMetadata?.error != null) {
      alert(updMetadata?.error);
    }
  }, [updMetadata]);

  const handleSaveTempForm = (formData) => {
    console.log('------------------handleSaveTempForm------------------: ', formData)
    let ListMetdata = [];
    const qGroups = infoFile.items?.Stage?.QuestionGroups;

    qGroups?.forEach((meta, i) => {
      meta?.Questions.forEach((itemQ) => {
        ListMetdata.push({
          GroupName: meta?.Name,
          Name: itemQ?.Name,
          Value:
            itemQ?.QuestionType === "Fecha" ||
            itemQ.QuestionType === "FechaPrevia"
              ? formData[itemQ.Id] === "" ||
                formData[itemQ.Id] === undefined ||
                formData[itemQ.Id] === null
                ? null
                : moment(formData[itemQ.Id]).format("YYYY/MM/DD")
              : itemQ.QuestionType === "Currency"
              ? formData[itemQ.Id]
                  ?.replace("$", "")
                  ?.replace(",", "")
                  ?.replace(",", "")
              : itemQ?.QuestionType === "Hour"
              ? ExtractHour(formData[itemQ.Id])
              : formData[itemQ.Id],
          ApprovedConfidence: null,
          Confidence: null,
          MetadataType: null,
          ValueType: null,
        });
      });
    });

    let Request = {
      ObjectId: id,
      Metadata: ListMetdata,
      ContinueWorkFlow: false,
    };

    dispatch(fileActions.FileUpdateMetadata(Request));
  };

  return (
    <Box>
      <FormDynamic
        fileId={id}
        StageCurrent={infoFile?.items}
        handleUnmountForm={(data) => {
          handleSaveTempForm(data);
        }}
        handleSave={(formData, e) => {
          let ListMetdata = [];

          infoFile.items?.Stage?.QuestionGroups?.forEach((itemGroup) => {
            itemGroup?.Questions?.forEach((itemQ) => {
              ListMetdata.push({
                GroupName: itemGroup.Name,
                Name: itemQ.Name,
                Value:
                  itemQ.QuestionType === "Fecha"
                    ? formData[itemQ.Id] === ""
                      ? null
                      : ForceFormatDate(formData[itemQ.Id])
                    : itemQ.QuestionType === "Currency" ||
                      itemQ.QuestionType === "CurrencyEuropean"
                    ? CurrencyFormat(
                        formData[itemQ.Id]?.toString(),
                        itemQ.QuestionType
                      )
                    : formData[itemQ.Id],
                ApprovedConfidence: null,
                Confidence: null,
                MetadataType: null,
                ValueType: null,
              });
            });
          });

          let Request = {
            ObjectId: id,
            Metadata: ListMetdata,
            ContinueWorkFlow: true,
            WorkFlowInstanceId: workFlowInstanceId,
          };

          dispatch(fileActions.FileUpdateMetadata(Request));
        }}
      />
    </Box>
  );
};
