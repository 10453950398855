import { Box, Button, Typography } from '@mui/material';
import { getBatchFileData, uploadBatch } from 'api/massiveFlow/massiveFlowRequests';
import MainTable from 'components/common/Table/Table';
import { OBTableCell, OBTableRow } from 'components/common/Table/Table.styled';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { AlertModal } from 'components/common/Modals/Views/AlertModal';
import { CustomLoadingOverlay } from 'components/CustomLoadingOverlay';
import { useTranslation } from 'react-i18next';

export const UploadStatus = ({ handleMode }) => {
  const { t } = useTranslation()
  const { data: registeredBatchStatus } = useSWR('RegisterBatch');
  const { data, isValidating } = useSWR(
    'BatchFileData',
    () => getBatchFileData(registeredBatchStatus),
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
  const { isMutating, trigger } = useSWRMutation('UploadBatch', (url, payload) => uploadBatch(payload));

  const handleSaveBatch = () => {
    trigger(registeredBatchStatus)
      .then(() => AlertModal('Success', t("alert_massive_upload_successful"), 'success'))
      .then(() => handleMode('BULK_LOAD'))
      .catch((err) => AlertModal('Error', err.message, null, t));
  };

  const columns = [
    { id: 'Folio de carga', label: t("upload_status_table_header_charge_folio"), minWidth: 170 },
    { id: 'Folio del expediente', label: t("upload_status_table_header_record_folio"), minWidth: 170 },
    { id: 'Tipo de documento', label: t("upload_status_table_header_doc_type"), minWidth: 170 },
    { id: 'Nombre de archivo', label: t("upload_status_table_header_file_name"), minWidth: 170 },
    { id: 'Sincronizar', label: t("upload_status_table_header_status"), minWidth: 170 },
    { id: 'Sincronizar', label: t("upload_status_table_header_sync"), minWidth: 170 },
  ];
  console.log('debuggin', data, isValidating)
  const render = (row) => (
    <OBTableRow hover role="checkbox">
      <OBTableCell>{row['Folio de carga']}</OBTableCell>
      <OBTableCell>{row['Folio del expediente']}</OBTableCell>
      <OBTableCell>{row['Tipo de documento']}</OBTableCell>
      <OBTableCell>{row['Nombre de archivo']}</OBTableCell>
      <OBTableCell>{row['Sincronizar']}</OBTableCell>
      <OBTableCell>
        {row.Sincronizar === 'Pendiente' ? (
          <CheckCircleOutlineIcon color="success" />
        ) : (
          <HighlightOffIcon color="error" />
        )}
      </OBTableCell>
    </OBTableRow>
  );

  if (isValidating || isMutating) return <CustomLoadingOverlay active={isValidating || isMutating} />;

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Typography variant="span">
          {t("upload_status_description")}
        </Typography>
        <Button
          variant="contained"
          onClick={handleSaveBatch}
          disabled={data?.FileData?.some((el) => el.Sincronizar !== 'Pendiente')}
        >
          {t("general_save")}
        </Button>
      </Box>
      <MainTable columns={columns} rows={data?.FileData} render={render} />
    </div>
  );
};
