import MainTable from "components/common/Table";
import useSWR from "swr";

import { dataAdapterMiddleware } from "middlewares/swr/dataAdapterMiddleware";
import { useSnackbar } from "notistack";
import { OBTableCell, OBTableRow } from "components/common/Table/Table.styled";
import TableCell from "@mui/material/TableCell";
import { IconButton, Tooltip } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Icon } from "@iconify/react";
import { documentTableRowsAdapter } from "adapters/documentTableRowsAdapter";
import {
  deleteDocumentType,
  getDocumentType,
  updateDocumentType,
} from "api/document/documentRequests";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { EditDocumentModal } from "../DocumentalStructure/EditDocumentModal";

export const DocumentTable = ({ handleMode, setSelectedDocument }) => {
  const { t } = useTranslation();
  const configId = JSON.parse(sessionStorage.getItem("configurationData")).Id;
  const PAYLOAD = { ConfigurationID: configId };
  const { enqueueSnackbar } = useSnackbar();
  const selectedConfig = JSON.parse(
    sessionStorage.getItem("configurationData"),
  );
  const [openEdit, setOpenEdit] = useState(false);
  const [docEdit, setDocEdit] = useState(null);

  const {
    data: tableData,
    isValidating,
    mutate,
  } = useSWR("GetDocumentType", () => getDocumentType(PAYLOAD), {
    use: [dataAdapterMiddleware(documentTableRowsAdapter)],
    refreshInterval: 0,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const loading = isValidating;
  const rows = tableData?.map((row) => ({
    ...row,
    DocumentType: row.TranslatedText /* row.Name */,
    Required: row.Required,
    Workflow: selectedConfig?.Code,
  }));

  const columns = [
    {
      id: "Workflow",
      label: t("configuration_docStructure_header_workflow"),
      minWidth: 170,
    },
    {
      id: "DocumentGroup",
      label: t("configuration_docStructure_header_grouper"),
      minWidth: 170,
    },
    {
      id: "DocumentType",
      label: t("configuration_docStructure_header_doc_type"),
      minWidth: 170,
    },
    {
      id: "Required",
      label: t("configuration_docStructure_header_required"),
      maxWidth: 170,
      textAlign: "center",
    },
    {
      id: "Actions",
      label: t("configuration_docStructure_header_actions"),
      // maxWidth: 170,
      maxWidth: 250,
      textAlign: "center",
    },
  ];

  const handleDeleteClick = async (row) => {
    try {
      const DELETE_ROW_PAYLOAD = {
        DocumentTypeId: row.Id,
      };
      await deleteDocumentType(DELETE_ROW_PAYLOAD);
      await mutate();
      enqueueSnackbar(t("alert_successful_delete"), { variant: "success" });
    } catch (error) {}
  };

  const handleEditClick = (row) => {
    setDocEdit(row);
    setOpenEdit(true);
  };

  const handleEditSuccess = async () => {
    await mutate();
    setOpenEdit(false);
  };

  const handleCancelEdit = () => {
    setOpenEdit(false);
  };

  const handleCheckboxChange = async (checked, row) => {
    try {
      const UPDATE_ROW_PAYLOAD = {
        DocumentTypeId: row.Id,
        DocumentName: row.Name,
        Required: checked,
      };
      await updateDocumentType(UPDATE_ROW_PAYLOAD);
      await mutate();
      enqueueSnackbar(t("alert_successful_update"), { variant: "success" });
    } catch (error) {}
  };

  const render = (row) => (
    <OBTableRow hover role="grid" loading={loading.toString()}>
      <OBTableCell>{row.Workflow}</OBTableCell>
      <OBTableCell>{row.DocumentalGroupTranslate}</OBTableCell>
      <OBTableCell>{row.TranslatedDescription}</OBTableCell>
      <OBTableCell>
        <Checkbox
          checked={row.Required}
          onChange={(event, checked) => handleCheckboxChange(checked, row)}
        />
      </OBTableCell>
      <OBTableCell>
        {handleMode && (
          <Tooltip
            title={t("configuration_docStructure_metadata_see")}
            placement={"top"}
          >
            <IconButton
              onClick={() => {
                setSelectedDocument(row);
                handleMode("DOCUMENT_METADATA_VIEW");
              }}
            >
              <Icon icon={"solar:hashtag-square-outline"} />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={t("general_delete")} placement={"top"}>
          <IconButton onClick={() => handleDeleteClick(row)}>
            <Icon icon={"ph:trash"} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("general_edit")} placement={"top"}>
          <IconButton onClick={() => handleEditClick(row)}>
            <Icon icon={"clarity:edit-line"} />
          </IconButton>
        </Tooltip>
      </OBTableCell>
    </OBTableRow>
  );

  return (
    <>
      {openEdit && (
        <EditDocumentModal
          Open={openEdit}
          DataToEdit={docEdit}
          OnEdit={handleEditSuccess}
          OnCancel={handleCancelEdit}
        />
      )}
      <MainTable
        rows={rows}
        rowPerPage={8}
        columns={columns}
        render={render}
        loading={loading}
      />
    </>
  );
};
