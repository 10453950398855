import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { ImageLoadIcon } from "./StatusRenderer.styled";
import loadDoc from "./../../../assets/loadDoc.svg";

const RENDER_COMPONENT_STATUS = {
  pending: <CircleIcon style={{ color: "#FEF250" }} />,
  approved: <CheckCircleIcon style={{ color: "#28A745" }} />,
  rejected: <CancelIcon style={{ color: "red" }} />,
  default: null, // Componente vacío por defecto
};

const StatusRenderer = ({ status }) => {
  return RENDER_COMPONENT_STATUS[status] || RENDER_COMPONENT_STATUS.default;
};

export default StatusRenderer;
