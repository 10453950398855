import { Typography } from '@mui/material';
import UploadSlot from 'components/common/UploadSlot';
import React from 'react';
import { useTranslation } from 'react-i18next';

const MobileUploaderDrag = ({ requiredDocument, handleCaptureUploadDocument }) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography variant='h6'>{t("mobile_uploader_drag")} {requiredDocument}</Typography>
      <UploadSlot
        allowedExtensions={['pdf', 'png', 'jpg', 'jpeg']}
        onSendData={(fileb64, extension) => handleCaptureUploadDocument({ fileb64, extension })}
      />
    </>
  );
};

export default MobileUploaderDrag;
