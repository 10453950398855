import { documentConstants } from '../constants/index.js'
import {
    reorderDocumentalGroups
} from '../../utils'

export const DownloadDocument = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.DOCUMENTS_SUCCESS:
            return {
                ...state,
                items: action.payload,
            };
        default:
            return state;
    }
};

export const GetVersionedDocument = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.VERSIONED_DOCUMENT_SUCCESS:
            return {
                ...state,
                items: action.payload,
            };
       case documentConstants.CLEAN_VERSIONED_DOCUMENT:
                return {
                    ...state,
                    items: null
                }
        default:
            return state;
    }
};

export const DownloadVersionedDocument = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.DOWNLOAD_VERSIONED_DOCUMENT_SUCCESS:
            return {
                ...state,
                items: action.document.Body,
            };
        default:
            return state;
    }
};

export const DeleteDocument = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.DELETE_DOCUMENT_SUCCESS:
            return {
                ...state,
                items: action.document.Body,
            };
        default:
            return state;
    }
};

export const GetStatePendingDocuments = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.GET_STAGE_PENDING_DOCUMENTS_SUCCESS:
            return {
                ...state,
                items: action.document.Body,
            };
        default:
            return state;
    }
};

export const UpdateDocumentMetadata = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.UPDATE_DOCUMENT_METADATA_SUCCESS:
            return {
                ...state,
                items: action.document.Body,
            };
        default:
            return state;
    }
};

export const GetDocumentQuestions = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.GET_DOCUMENT_QUESTIONS_SUCCESS:
            return {
                ...state,
                items: action.document.Body,
            };
        default:
            return state;
    }
};

export const GetDocumentalStructure = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.GET_DOCUMENTAL_STRUCTURE_SUCCESS:
            return {
                ...state,
                items: action.document.Body,
            };
        default:
            return state;
    }
};

export const Document = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.DOCUMENT_FILE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case documentConstants.DOCUMENT_FILE_SUCCESS:
            return {
                loading: false,
                items: action.document.data,
                itemsURL: action.document.url,
                type: action.document.type,
                extension: action.document.extension,
                error: undefined
            }
        //break;
        case documentConstants.DOCUMENT_FILE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const BioDocument = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.DOCUMENT_BIO_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case documentConstants.DOCUMENT_BIO_SUCCESS:
            return {
                loading: false,
                items: action.bioDocument.data,
                itemsURL: action.bioDocument.url,
                type: action.bioDocument.type,
                extension: action.bioDocument.extension,
                error: undefined
            }
        //break;
        case documentConstants.DOCUMENT_BIO_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}
export const DocumentUpdateMetData = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.DOCUMENT_UPDATEMETADATA_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break; //break;
        case documentConstants.DOCUMENT_UPDATEMETADATA_SUCCESS:
            return {
                loading: false,
                items: action.docUpdateData.Body,
                error: undefined
            }
        //break;
        case documentConstants.DOCUMENT_UPDATEMETADATA_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const UploadSections = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.UPLOAD_SECTION_REQUEST:
            return { loading: true, items: undefined, error: undefined };
        // //break;
        case documentConstants.UPLOAD_SECTION_SUCCESS:
            return {
                loading: false,
                items: action.uploadSecData.Body,
                error: undefined,
            };
        // //break;
        case documentConstants.UPLOAD_SECTION_FAILURE:
            return { loading: false, items: undefined, error: action.error };
        // //break;
        default:
            return state;
    }
};

export const CargaDocWithSecData = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.CARGADOC_WITH_SECTION_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            };
        // //break;
        case documentConstants.CARGADOC_WITH_SECTION_SUCCESS:
            return {
                loading: false,
                items: action.cargaDocData.Body,
                error: undefined
            };
        // //break;
        case documentConstants.CARGADOC_WITH_SECTION_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            };
        // //break;
        default:
            return state;
    }
}

export const GetDocumentsGroupByRol = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.GET_DOCUMENTS_BY_ROL_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            };
        case documentConstants.GET_DOCUMENTS_BY_ROL_SUCCESS:
            return {
                loading: false,
                items: action.documents.Body,
                itemsReorder: reorderDocumentalGroups(action?.documents?.Body.Groups),
                error: undefined
            };
        case documentConstants.GET_DOCUMENTS_BY_ROL_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            };
        default:
            return state;
    }
}

export const RegisterMetadaData = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.REGISTER_DATA_REQUEST:
            return { loading: true, items: undefined, error: undefined };
        // //break;
        case documentConstants.REGISTER_DATA_SUCCESS:
            return {
                loading: false,
                items: action.metaData.Body,
                error: undefined,
            };
        // //break;
        case documentConstants.REGISTER_DATA_FAILURE:
            return { loading: false, items: undefined, error: action.error };
        // //break;
        default:
            return state;
    }
};

export const OcrData = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.GET_OCRDATA_REQUEST:
            return { loading: true, items: undefined, error: undefined };
        // //break;
        case documentConstants.GET_OCRDATA_SUCCESS:
            return {
                loading: false,
                items: action.ocrData.Body,
                error: undefined,
            };
        // //break;
        case documentConstants.GET_OCRDATA_FAILURE:
            return { loading: false, items: undefined, error: action.error };
        // //break;
        default:
            return state;
    }
};


// ------------------------------------------------------------------

export const GetDocumentGeneratorSelection = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.GET_DOCUMENT_GENERATOR_SELECTION_REQUEST:
            return { loading: true, items: undefined, error: undefined };

        case documentConstants.GET_DOCUMENT_GENERATOR_SELECTION_SUCCESS:
            return { loading: false, items: action.cargaDocData.Body, error: undefined };

        case documentConstants.GET_DOCUMENT_GENERATOR_SELECTION_FAILURE:
            return { loading: false, items: undefined, error: action.error };

        default:
            return state;
    }
}

export const RegisterDocumentGeneratorSelection = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.REGISTER_DOCUMENT_GENERATOR_REQUEST:
            return { loading: true, items: undefined, error: undefined };

        case documentConstants.REGISTER_DOCUMENT_GENERATOR_SUCCESS:
            return { loading: false, items: action.cargaDocData.Body, error: undefined };

        case documentConstants.REGISTER_DOCUMENT_GENERATOR_FAILURE:
            return { loading: false, items: undefined, error: action.error };

        default:
            return state;
    }
}

export const DownloadDocumentGeneratorSelectionContent = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.DONWLOAD_DOCUMENT_GENERATOR_REQUEST:
            return { loading: true, items: undefined, error: undefined };

        case documentConstants.DONWLOAD_DOCUMENT_GENERATOR_SUCCESS:
            return { loading: false, items: action.cargaDocData.Body, error: undefined };

        case documentConstants.DONWLOAD_DOCUMENT_GENERATOR_FAILURE:
            return { loading: false, items: undefined, error: action.error };

        default:
            return state;
    }
}

export const ProcessDocumentGeneratorSelection = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.RPOCESS_DOCUMENT_GENERATOR_REQUEST:
            return { loading: true, items: undefined, error: undefined };

        case documentConstants.RPOCESS_DOCUMENT_GENERATOR_SUCCESS:
            return { loading: false, items: action.cargaDocData.Body, error: undefined };

        case documentConstants.RPOCESS_DOCUMENT_GENERATOR_FAILURE:
            return { loading: false, items: undefined, error: action.error };

        default:
            return state;
    }
}

export const FacialComparison = (state = {}, action) => {
    switch (action.type) {
      case documentConstants.FACIAL_COMPARISON_REQUEST:
        return { loading: true, items: undefined, error: undefined };
  
      case documentConstants.FACIAL_COMPARISON_SUCCESS:
        return { loading: false, items: action.facialResult.Body, error: undefined };
  
      case documentConstants.FACIAL_COMPARISON_FAILURE:
        return { loading: false, items: undefined, error: action.error };
  
      default:
        return state;
    }
  };

  export const ValIdentityData = (state = {}, action) => {
    switch (action.type) {
      case documentConstants.VALIDATEDENTITY_DATA_REQUEST:
        return { loading: true, items: undefined, error: undefined };
  
      case documentConstants.VALIDATEDENTITY_DATA_SUCCESS:
        return { loading: false, items: action.valIndetityData.Body, error: undefined };
  
      case documentConstants.VALIDATEDENTITY_DATA_FAILURE:
        return { loading: false, items: undefined, error: action.error };
  
      default:
        return state;
    }
  };
  
  export const GetValIdentityData = (state = {}, action) => {
    switch (action.type) {
      case documentConstants.GETVALIDATEDENTITY_DATA_REQUEST:
        return { loading: true, items: undefined, error: undefined };
  
      case documentConstants.GETVALIDATEDENTITY_DATA_SUCCESS:
        return { loading: false, items: action.getValIndetityData.Body, error: undefined };
  
      case documentConstants.GETVALIDATEDENTITY_DATA_FAILURE:
        return { loading: false, items: undefined, error: action.error };
  
      default:
        return state;
    }
  };

  export const RegisteredData = (state = {}, action) => {
    switch (action.type) {
      case documentConstants.REGISTERTWO_DATA_REQUEST:
        return { loading: true, items: undefined, error: undefined };
  
      case documentConstants.REGISTERTWO_DATA_SUCCESS:
        return { loading: false, items: action.registeredData.Body, error: undefined };
  
      case documentConstants.REGISTERTWO_DATA_FAILURE:
        return { loading: false, items: undefined, error: action.error };
  
      default:
        return state;
    }
  };

  export const AutographSign = (state = {}, action) => {
    switch (action.type) {
      case documentConstants.AUTOGRAPH_SIGN_REQUEST:
        return { loading: true, items: undefined, error: undefined };
  
      case documentConstants.AUTOGRAPH_SIGN_SUCCESS:
        return { loading: false, items: action.response.Body, error: undefined };
  
      case documentConstants.AUTOGRAPH_SIGN_FAILURE:
        return { loading: false, items: undefined, error: action.error };
      case documentConstants.AUTOGRAPH_SIGN_RESET:
        return {
          loading: false,
          items: undefined,
          error: undefined
        }
  
      default:
        return state;
    }
  };