import { styled } from "@mui/material/styles";
import {Box, TableCell, TableRow} from "@mui/material";
import {ExpirationStatusColorsMapping} from "mappings/ExpirationStatusColorsMapping";

export const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  backgroundColor: `${theme.palette.primary.tableHeadLight} !important`,
  color: `${theme.palette.primary.tableHeadLightText} !important`,
}));

export const TableRowCustom = styled(TableRow)(({ theme }) => ({
  // '&:hover': {
  //   backgroundColor: `${theme.palette.primary.tableHover} !important`,
  //   cursor:'pointer'
  // },
}));

export const ExpirationBox = styled(Box)(({ theme, expirationStatus  }) => ({
  backgroundColor: `${theme.palette.primary.tableHeadLight} !important`,
  color: ExpirationStatusColorsMapping[expirationStatus?.toUpperCase()] || 'grey',
  padding: '0.5rem 0',
  borderRadius: '2rem'
}));


