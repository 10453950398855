import { Stack, Typography } from '@mui/material';
// import { ReactComponent as ZipFileReadyIcon } from 'assets/DOC/Icons/zipFileReady.svg';
import  ZipFileReadyIcon from 'assets/Metlife/Icons/zipFileReady.png';
import { UseImage } from 'components';
import { RoundedButton } from 'components/Buttons';
import { useTranslation } from 'react-i18next';

export const DownloadZipView = ({ zipFileUrl, handleMode }) => {
  const { t } = useTranslation()

  const handleDownloadClick = () => {
    const link = document.createElement('a');
    link.href = zipFileUrl;
    link.download = `BatchFile.zip`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleMode('DOWNLOAD_BATCH_DATA');
  };

  return (
    <Stack spacing={'8rem'}>
      <Typography>
        {t("zip_download_description_1")}
      </Typography>
      <Stack spacing={'2rem'} alignItems={'center'}>
        <UseImage src={ZipFileReadyIcon} />
        <RoundedButton variant={'contained'} onClick={handleDownloadClick}>
          {t("zip_download_description_2")}
        </RoundedButton>
      </Stack>
    </Stack>
  );
};
