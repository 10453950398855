import { Config } from 'services/Config';

export const fetcher = (url, data, HEADERS_CONFIG = Config.ConfigAuth()) => {
  return apiFetch(url, data, HEADERS_CONFIG);
};

export const mutationFetcher = (url, { arg }, HEADERS_CONFIG = Config.ConfigAuth()) => {
  return apiFetch(url, arg, HEADERS_CONFIG);
};

const apiFetch = (url, data, HEADERS_CONFIG) => {
  return fetch(`${process.env.REACT_APP_DSONBOARDING_API}${url}`, {
    method: 'POST',
    headers: HEADERS_CONFIG.headers,
    body: JSON.stringify({ Body: data }),
  })
    .then((response) => response.json())
    .then(handleResponse);
};

const handleResponse = (response) => {
  const { IsOK, Messages, TansactionId, statusText, Body } = response;

  if (!IsOK) {
    const errorMessage = `${Messages} ${TansactionId || ''} ${statusText || ''}`.trim();
    const error = Body || { message: errorMessage };
    return Promise.reject(error);
  }

  return response.Body;
};
