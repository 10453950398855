import { Box, Typography } from '@mui/material';
import SuccessUploadment from 'assets/DOC/img/Exito.png';
import { useTranslation } from 'react-i18next';

export const MobileSuccessUpload = () => {
  const { t } = useTranslation()
  return (
    <>
      <div>
        <img src={SuccessUploadment} alt="" width="300px" height="300px" />
      </div>
      <Box>
        <Typography variant="h6" sx={{ paddingBottom: '1.5rem' }}>
          {t("mobile_uploader_success_upload")}
        </Typography>
      </Box>
    </>
  );
};
