import { styled } from "@mui/material/styles";
import { TableCell, TableRow } from "@mui/material";

export const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  backgroundColor: `${theme.palette.primary.tableHeadLight} !important`,
  color: `${theme.palette.primary.tableHeadLightText} !important`,
}));

export const TableRowCustom = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: `${theme.palette.primary.tableHover} !important`,
    cursor:'pointer'
  },
}));
