import { Box, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import React from 'react'
import { UseImage } from './UseImage'
import img from '../assets/Busqueda_folio.png'
import { useTranslation } from 'react-i18next'

export const useStyles = makeStyles((theme) => ({
    imageNotFound: {
        maxWidth: 300
    },
    imgPin: {
        width: '50%'
    }
}))

export default function NoShares() {
    const { t } = useTranslation();
    const classes = useStyles()

    return (
        <div>
            <Grid item lg={12} xs={12}>
                <Box display={'flex'} justifyContent='center' m={5}>
                    <UseImage src={img} type='img' className={classes.imageNotFound} />
                </Box>
                <Box textAlign={'center'} m={5}>
                    <Typography variant='h5'>
                        {t("no_actions_assignments")}
                    </Typography>
                </Box>
            </Grid>
        </div>
    )
}
