export const documentTableRowsAdapter = (data) => {
  return data?.DocumentGroups?.flatMap(
    ({ Name, Id, TranslatedDescription, Document }) => {
      return Document.map((document) => ({
        ...document,
        DocumentGroup: Name,
        DocumentGroupId: Id,
        DocumentalGroupTranslate: TranslatedDescription
      }));
    },
  );
};
