import React from 'react';
import protect from '../../utils/protect';
import NotificationsTemplate from 'components/notifications/NotificationsTemplate';

function Notifications(props) {
  return (
    <div>
        <NotificationsTemplate />
    </div>
  );
}

export default protect(Notifications);
