import { ModalContainer } from "../Modal.styles";
import { Button, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { MetadataForm } from "../../../configuration/ConfigDocumentMetadata/MetadataForm";
import React, { useEffect, useState } from "react";
import useSWRMutation from "swr/mutation";
import { mutationFetcher } from "../../../../api/fetcher";
import { Config } from "../../../../services/Config";
import { mutate } from "swr";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export const EditMetadataModal = ({ open, handleClose, initialData }) => {
  const { t } = useTranslation()
  const configID = 13;
  const [formData, setFormData] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {

    setFormData({
      MetadataId: initialData?.MetadataId || "",
      ConfigurationID: configID,
      GroupId: initialData?.GroupId || "",
      TagCapture: initialData?.TagCapture || "",
      DataType: initialData?.DataType || "",
      Requeried: initialData?.Requeried,
    })
  }, [initialData]);

  const { isMutating: isUpdateMutating, trigger: triggerUpdate } =
    useSWRMutation(
      "/configuration/UpdateMetadata",
      (URL, UPDATE_PAYLOAD) =>
        mutationFetcher(URL, UPDATE_PAYLOAD, Config.ConfigAuth()),
      {
        populateCache: true,
        revalidate: false,
        onSuccess: () => {
          enqueueSnackbar(t("alert_successful_update"), { variant: 'success' });
          mutate("GetMetadata");
        },
      },
    );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSendForm = async () => {
    await triggerUpdate(formData);
    handleClose();
  };

  const isButtonDisabled = Object.entries(formData).filter(value => value !== false).some(
    ([key, value]) => key !== 'Requeried' && !value
  );

  return (
    <ModalContainer open={open} onClose={handleClose}>
      <DialogTitle>{t("configuration_metadata_edit_title")}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <MetadataForm
        formData={formData}
        onChange={handleChange}
        onSendForm={handleSendForm}
      />
      <Button
        sx={{ borderRadius: "2rem", minWidth: "20%" }}
        variant={"contained"}
        onClick={handleSendForm}
        disabled={isButtonDisabled}
      >
        {t("general_save")}
      </Button>
    </ModalContainer>
  );
};
