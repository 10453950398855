import React from "react";
import { Card as MUICard, CardContent, Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { IsDevice } from "utils";

const Card = ({ selected, onCardClick, children, isFilter }) => {
  const theme = useTheme();
  const { IsMobile } = IsDevice();
  return (
    <MUICard
      sx={{
        minWidth: IsMobile ? 110 : 250,
        width: IsMobile && isFilter ? "30%" : "100%",
        marginBottom: IsMobile ? 1 : 3,
        border: selected ? `2px solid ${theme.palette.primary.main}` : "",
        backgroundColor: selected
          ? theme.palette.primary.mainHoverBackground
          : "",
        transition: "transform 0.3s ease, border-color 0.3s ease",
        cursor: "pointer",
        "&:hover": {
          transform: "scale(1.02)",
          backgroundColor: theme.palette.primary.mainHoverBackground,
        },
        ...(IsMobile && isFilter && { mb: 0.5 }),
        minHeight: "50px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          gap: "1rem",
          justifyContent: "space-between",
          alignItems: "center",
          width: '100%',
          ...(IsMobile && { width: "99%" }),
          ...(IsMobile &&
            isFilter && { py: "0px !important", px: "4px !important" }),
        }}
        onClick={onCardClick}
      >
        {children}
      </CardContent>
    </MUICard>
  );
};

const CardIcon = ({ icon, IsMobile, isFilter }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      marginRight: isFilter && IsMobile ? 1 : 2,
    }}
  >
    {icon}
  </Box>
);

const CardTitle = ({ children, IsMobile }) => (
  <Typography variant={IsMobile ? "subtitle1" : "h6"}>{children}</Typography>
);

const CardSubtitle = ({ children, variant }) => (
  <Typography variant={variant ?? "subtitle1"} color="textSecondary">
    {children}
  </Typography>
);

const CardDescription = ({ children }) => (
  <Box>
    <Typography variant="body2">{children}</Typography>
  </Box>
);

Card.Icon = CardIcon;
Card.Title = CardTitle;
Card.Subtitle = CardSubtitle;
Card.Description = CardDescription;

export default Card;
