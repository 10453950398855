export const DataTypes = [
  {
    Label: 'datatype_text',
    Type: 'Texto',
  },
  {
    Label: 'datatype_date',
    Type: 'Fecha',
  },
  {
    Label: 'datatype_previous_date',
    Type: 'FechaPrevia',
  },
  {
    Label: 'datatype_currency',
    Type: 'Currency',
  },
  {
    Label: 'datatype_combo',
    Type: 'Combo',
  },
  {
    Label: 'datatype_number',
    Type: 'Number',
  },
];
