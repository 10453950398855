import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Location from 'assets/DOC/gifts/LocationIcon.gif';

const locationMessage =
  'Estamos recolectando tu geolocalización para obtener tu ubicación en tiempo real';
const checkboxLabel = '*He leído y autorizo el presente documento';

export const SignAuthorization = ({ handleAcceptDocument, checkState, handleCheckState }) => {
  const handleCheckboxChange = (e) => handleCheckState(e.target.checked);

  return (
    <>
      <div>
        <img style={{ objectFit: 'cover' }} alt="Gif de Localizacion" src={Location} />
      </div>
      <Typography variant="subtitle" sx={{ textAlign: 'center' }}>
        {locationMessage}
      </Typography>
      <FormControlLabel
        value="TextCheck"
        control={<Checkbox color="primary" onChange={handleCheckboxChange} value="TextCheck" />}
        label={<Typography variant="subtitle2">{checkboxLabel}</Typography>}
        labelPlacement="end"
      />
      <Button
        variant="contained"
        disabled={!checkState}
        onClick={handleAcceptDocument}
        sx={{ width: '80%' }}
      >
        {checkState ? 'Aceptar' : 'Continuar'}
      </Button>
    </>
  );
};
