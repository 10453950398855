import { useMemo } from 'react';
import useSWR from 'swr';
import { useGetDocumentLinks } from './useGetDocumentLinks';
import { getDocumentalStructure, getValidateActionId } from 'api/document/documentRequests';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fileActions } from 'store/actions';

const OPTIONS_CONFIGURATION = {
  refreshInterval: 0,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  onErrorRetry: () => {},
};

export const useValidateDocument = (actionId, handleMode) => {
  const workFlowInstanceId = sessionStorage.getItem('WorkFlowInstanceId');
  const {id} = useParams()
  const location = useLocation();
  const navigate = useNavigate();
  const { blobUrl, linkCode, validateIdOnSuccess } = useGetDocumentLinks();
  const dispatch = useDispatch()

  const successId = (data) => {
    validateIdOnSuccess(data);
    sessionStorage.setItem('tokenMc', data.JToken);
  };

  const { data: validatedActionId, isLoading: isLoadingActionId } = useSWR(
    'ValidateActionId',
    () => getValidateActionId(PAYLOAD),
    {
      ...OPTIONS_CONFIGURATION,
      onSuccess: successId,
      onError: async () => {
        const pathname = location.pathname;
        if (pathname.includes('inbox/asignacion')) {
          await dispatch(
            fileActions.GetFileInfo({
              FileId: id,
              WorkFlowInstanceId: workFlowInstanceId,
            }),
          );
          handleMode('DEFAULT_DOCUMENT_VISUALIZATION');
          return;
        }
        navigate('/expiredActionId');
      },
      refreshInterval: 5000,
    },
  );

  const { data: documentStructure, isLoading: isLoadingDocumentStructure } = useSWR(
    validatedActionId ? 'GetDocumentalStructure' : null,
    () => getDocumentalStructure(PAYLOAD.ActionId),
    OPTIONS_CONFIGURATION,
  );

  const PAYLOAD = useMemo(
    () => ({
      ActionId: actionId,
    }),
    [actionId],
  );

  return {
    blobUrl,
    linkCode,
    fileId: documentStructure?.FileId,
    docTypeId: documentStructure?.DocumentType_Id,
    Description: documentStructure?.DocumentType?.Description,
    isLoadingActionId,
    isLoadingDocumentStructure,
  };
};
