import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  DialogActions,
} from "@mui/material";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import {
  getDocumentTypeGroup,
  insertDocumentType,
  registerNewGrouper,
} from "api/document/documentRequests";
import { useTranslation } from "react-i18next";
import { AddMetadataToDocModal } from "components/common/Modals/Views/AddMetadataToDocModal";
import { fetcher } from "api/fetcher";
import { Config } from "../../../services/Config";
import { makeStyles } from "@mui/styles";
import { Alert, CustomLoadingOverlay } from "components";

export const AddDocumentForm = ({ handleMode, handleSelectedDocument }) => {
  const { t } = useTranslation();
  const classes = useClasses();
  const [showActionModal, setShowActionModal] = useState(false);

  const [openNewGrouper, setOpenNewGrouper] = useState(false);
  const [newGrouper, setNewGrouper] = useState("");
  const configId = JSON.parse(sessionStorage.getItem("configurationData")).Id;
  const documentCategoryId = JSON.parse(
    sessionStorage.getItem("configurationData"),
  )?.DocumentalCategoryId;
  // const [selectedWorkflow, setSelectedWorkflow] = useState("");

  const [formData, setFormData] = useState({
    DocumentGroupId: "",
    DocumentName: "",
    Required: "true",
  });

  const PAYLOAD = { DocumentCategory: documentCategoryId };
  const WORKFLOWS_PAYLOAD = { ConfigurationID: configId };

  const {
    data: groupersData,
    isValidating: loadingGroupers,
    mutate,
  } = useSWR("GetDocumentTypeGroup", () => getDocumentTypeGroup(PAYLOAD), {
    refreshInterval: 0,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const { data: workflowData, isValidating: loadingWF } = useSWR(
    "/configuration/GetMetadataGroup",
    (URL) => fetcher(URL, WORKFLOWS_PAYLOAD, Config.ConfigAuth()),
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const {
    isMutating: loadingRegisterGrouper,
    trigger: triggerRegisterGrouper,
  } = useSWRMutation(
    "RegisterNewGrouper",
    (url, payload) => registerNewGrouper(payload),
    {
      populateCache: true,
      revalidate: false,
    },
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (value === "newDoc") {
      handleOpenNewGrouper();
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOpenNewGrouper = () => {
    setOpenNewGrouper(true);
  };

  const handleCloseNewGrouper = () => {
    setNewGrouper("");
    setOpenNewGrouper(false);
  };
  const handleAddedSuccess = async () => {
    await mutate();
    setOpenNewGrouper(false);
  };
  const handleAddGrouper = async () => {
    //Send new Grouper
    try {
      await triggerRegisterGrouper({
        // FileId: 0,
        DocumentalCategoryId: documentCategoryId,
        DocumentalGroupName: newGrouper,
      });
      Alert(
        { icon: "success", title: "Agregado correctamente" },
        handleAddedSuccess,
        null,
        t,
      );
    } catch (error) {
      Alert({ icon: "error", title: "Hubo un error" }, null, null, t);
    }
  };

  const handleSendForm = async () => {
    try {
      const responseNewDoc = await insertDocumentType({
        ...formData,
        ConfigurationId: configId,
      });
      handleSelectedDocument({
        DocumentGroup: responseNewDoc.Name,
        Id: responseNewDoc.Document[0].Id,
        Name: responseNewDoc.Document[0].Name,
      });
      setShowActionModal(true);
    } catch (error) {}
  };

  const handleModalContinue = () => {
    handleMode("DOCUMENT_VIEW");
  };

  const handleModalAdd = () => {
    handleMode("ADD_DOCUMENT_METADATA_FORM");
  };

  console.log("formData:::", newGrouper);

  const isButtonDisabled = Object.values(formData)
    .filter((value) => value !== false)
    .some((value) => !value);

  return (
    <Box>
      <CustomLoadingOverlay
        active={loadingRegisterGrouper || loadingGroupers || loadingWF}
        text={t("general_loading_message")}
      />

      {openNewGrouper && (
        <Dialog
          onClose={handleCloseNewGrouper}
          open={openNewGrouper}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography variant="h6">
              {t("configuration_documental_structure_add_grouper_title")}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Stack
              flexDirection={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"1rem"}
              sx={{ width: "40vw", p: 2 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignitems: "flex-start",
                  justifyContent: "flex-start",
                  width: "100%",
                  gap: "1rem",
                }}
              >
                <Typography>{t("configuration_documental_structure_add_grouper_grouper_name")}</Typography>
                <TextField onChange={(e) => setNewGrouper(e.target.value)} placeholder={t("configuration_documental_structure_add_grouper_grouper_name")} />
              </Box>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack
              sx={{
                width: "100%",
                gap: "1rem",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                p: 2,
              }}
            >
              <Button
                variant="outlined"
                sx={{ width: "100%" }}
                disabled={loadingRegisterGrouper}
                onClick={handleCloseNewGrouper}
              >
                {t("general_cancel")}
              </Button>
              <Button
                variant="contained"
                sx={{ width: "100%" }}
                disabled={loadingRegisterGrouper}
                onClick={handleAddGrouper}
              >
                {t("general_accept")}
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      )}

      <AddMetadataToDocModal
        open={showActionModal}
        onLeftClick={handleModalContinue}
        onRightClick={handleModalAdd}
      />
      <Typography variant="span">
        {t("configuration_docStructure_add_doc_instruction")}
      </Typography>
      <Box display="flex" flexDirection="column" gap="1rem" margin="2rem 0rem">
        <Typography variant={"subtitle2"}>
          {t("configuration_docStructure_add_metadata_workflow")}
        </Typography>
        <TextField value={workflowData?.[0]?.Description} disabled />
        <Typography variant={"subtitle2"}>
          {t("configuration_docStructure_add_metadata_grouper")}
        </Typography>
        <Select
          defaultValue={"default"}
          name="DocumentGroupId"
          onChange={handleChange}
        >
          <MenuItem value="default" disabled>
            {t("configuration_docStructure_add_doc_select_grouper")}
          </MenuItem>
          {groupersData?.DocumentGroups?.map((option) => (
            <MenuItem key={option.Id} value={option.Id}>
              {option.Name}
            </MenuItem>
          ))}
          <MenuItem value={"newDoc"}>{t("Nuevo...")}</MenuItem>
        </Select>
        <Typography variant={"subtitle2"}>{t("text_type_table")}</Typography>
        <TextField
          name="DocumentName"
          value={formData["DocumentName"]}
          onChange={handleChange}
          placeholder={t("configuration_docStructure_add_doc_insert_doc_type")}
        />
        <Typography variant={"subtitle2"}>
          {t("configuration_docStructure_add_metadata_is_field_mandatory")}
        </Typography>
        <RadioGroup
          row
          name="Required"
          defaultValue="top"
          onChange={handleChange}
          value={formData["Required"]}
        >
          <FormControlLabel
            value={true}
            control={<Radio />}
            label={t("general_yes")}
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label={t("general_no")}
          />
        </RadioGroup>
        <Box display="flex" justifyContent="center" gap="2rem" height="3rem">
          <Button
            sx={{ borderRadius: "2rem", minWidth: "20%" }}
            variant="outlined"
            onClick={() => {
              handleMode("DOCUMENT_VIEW");
            }}
          >
            {t("general_back")}
          </Button>
          <Button
            sx={{ borderRadius: "2rem", minWidth: "20%" }}
            variant="contained"
            onClick={handleSendForm}
            disabled={isButtonDisabled}
          >
            {t("general_save")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const useClasses = makeStyles((theme) => ({
  stackMain: {
    flexDirection: "column",
    width: "100%",
    gap: "1rem",
  },
}));
