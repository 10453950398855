import { forwardRef, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { documentActions, fileActions, workFlowActions } from 'store/actions';
import { getNameDocINE, identityValFunctions } from '../IdentityValFunctions';
import {
  GenericContainer,
  GenericContainerForm,
} from 'components/common/GenericContainers/GenericContainer';
import {
  Box,
  Dialog,
  Divider,
  Grid,
  styled,
  Typography,
  TextField as InputText,
  Button,
} from '@mui/material';
import { Buttons, CustomLoadingOverlay, InputField } from 'components';
import { InfoOutlined } from '@mui/icons-material';
import { IMaskInput } from 'react-imask';
import { FormatDateArg } from '../utils/FunctionsDates';
import { getOrderIndex } from 'utils';
import { AlertModal } from 'components/common/Modals/Views/AlertModal';

const TextField = styled(InputText)(({ theme }) => ({
  padding: 0,
  margin: '8px 0px',
  [theme.breakpoints.down('sm')]: {
    // height: 18,
    margin: '8px 0px',
  },
  '& .MuiInput-root': {
    display: 'flex !important',
    flexDirection: 'column !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    borderRadius: '0px',
    border: `1px solid ${theme.palette.primary.border} !important`,
    height: 25,
  },
  '&& ::before': {
    borderBottomColor: theme.palette.primary.border,
    borderBottom: `0px solid ${theme.palette.primary.border} !important`,
    paddingLeft: '10px',
  },
  '&& ::after': {
    borderRadius: '0px',
    borderBottomColor: theme.palette.primary.border,
    border: `2px solid ${theme.palette.primary.border} !important`,
  },
  '& .MuiInputLabel-animated': {
    paddingLeft: '10px',
    color: `${theme.palette.primary.border} !important`,
  },
  '& .MuiFormLabel-root.mui-focused': {
    color: `${theme.palette.primary.border} !important`,
    paddingLeft: '10px',
  },
  '& .MuiInputBase-input': {
    paddingLeft: '10px',
  },
}));

const workFlowInstanceId = sessionStorage.getItem('WorkFlowInstanceId');

export const ExtractionForm = (props) => {
  const formMethods = useForm();
  const { id: folioId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [fields, setFields] = useState([]);
  const [metadatos, setMetadatos] = useState([]);
  const [metaToSend, setMetaToSend] = useState([]);
  const [loadView, setLoadView] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);
  const [helpType, setHelpType] = useState(false);

  const [docName, setDocName] = useState('');
  const [docCurrentId, setDocCurrentId] = useState(0);
  const [selfieMetadata, setSelfieMetadata] = useState(null);
  const [loadStatusStage, setLoadStatusStage] = useState(false);
  const [minutiaeCapture, setMinutiaeCapture] = useState('0');
  const [displayValidationResults, setDisplayValidationResults] = useState(true); // se pone true falta investigar esta variable
  const [errorValidacionPorcentaje, setErrorValidacionPorcentaje] = useState(false);
  const [loadValIdentityData, setLoadValIdentityData] = useState(false);
  const [loadFileInfo, setLoadFileInfo] = useState(false);
  const [loadRegisterData, setLoadRegisterData] = useState(false);
  const [loadIdentityData, setLoadIdentityData] = useState(false);
  const [isEvaluationIsMinutiaeCorrect, setIsEvaluationIsMinutiaeCorrect] = useState(false);
  const [metadataCurrent, setMetadataCurrent] = useState(false);
  const [loadMetadataCurrent, setLoadMetadataCurrent] = useState(false);
  const [properties, setProperties] = useState(null);
  // const documentIdCurrent = location.state.documetId;

  const file = useSelector((state) => state.FileInfo);
  const dataResponse = useSelector((state) => state.RegisteredData);
  const validaIdentityData = useSelector((state) => state.GetValIdentityData);
  const getOcrExtract = useSelector((state) => state.OcrData);
  const registroStatusStage = useSelector((state) => state.RegStatusStage);
  const valIdentityData = useSelector((state) => state.ValIdentityData);
  const [addDinamycQuestion, setAddDinamycQuestion] = useState(true);
  const flujoUser = sessionStorage.getItem('FlujoUser');
  const facialComparisonState = useSelector((state) => state.FacialComparison);
  // const facialComparisonState = useMemo(() => ({ items: json.Body }), []);
  console.log(facialComparisonState);

  useEffect(() => {
    const docnameCurrent = facialComparisonState.items?.DocumentData.Name;
    const documentId = facialComparisonState.items?.DocumentData.Id;
    const selfieMeta = facialComparisonState.items?.DocumentData.Metadata;
    // const meetadataValue =

    setLoadView(true);
    // dispatch(fileActions.GetFileInfo(folioId));
    setLoadFileInfo(true);
    // let meetadataValue = location.state.metadataExtraccion;
    // let docnameCurrent = location.state.docName;
    setDocName(docnameCurrent);

    // if (meetadataValue != null && meetadataValue?.length > 0) {
    //   setMetadataCurrent(meetadataValue);
    //   setLoadMetadataCurrent(true);
    // } else if (
    //   documentId &&
    //   (localStorage.getItem('TransactionId') == 'null' ||
    //     localStorage.getItem('TransactionId') === undefined ||
    //     localStorage.getItem('TransactionId') === null)
    // ) {
    //   let request = {
    //     DocumentId: documentId,
    //   };
    //   dispatch(documentsActions.GetValidateIdentityData(request));
    //   setLoadIdentityData(true);
    // } else {
    let requestOcr = {
      File_id: folioId,
      DocumentType_Id: null,
      TransactionId: localStorage.getItem('TransactionId'),
    };
    dispatch(documentActions.GetOcrData(requestOcr));
    setLoadView(true);
    // }
    setSelfieMetadata(selfieMeta);
    setDocCurrentId(documentId || file?.itemNameDocEvidence?.Id);

    // setIsEvaluationIsMinutiaeCorrect(location.state.isEvaluationMinutiaeCorrect);
  }, []);
  useEffect(() => {
    setLoadView(file.loading);
    if (
      file.items != undefined &&
      file.items != null &&
      file.items?.Stage != undefined &&
      loadFileInfo
    ) {
      setLoadFileInfo(false);
      if (docName === undefined || docName === null || docName == '' || docName.length == 0) {
        setDocName(file.itemNameDoc?.Name);
      }
      let properties = getNameDocINE(null, file.items.Stage);
      setProperties(properties);

      // let minCapture = properties?.find((element) => element.Name == 'MinutiaeCapture');
      // setMinutiaeCapture(
      //   minCapture?.Value === undefined || minCapture?.Value === null || minCapture?.Value == ''
      //     ? '0'
      //     : minCapture?.Value,
      // );
    } else if (file.error != undefined && file.error != null && loadFileInfo) {
      setLoadFileInfo(false);
      AlertModal('Error', file.error, 'error');
    }
  }, [file]);

  // useEffect(() => {
  //   if (selfieMetadata != undefined && selfieMetadata != null) {
  //     let valueFacialId = selfieMetadata?.find((item) => item.Name == 'Validación facial');

  //     let valueValidacionFacialFind =
  //       valueFacialId?.Value === undefined ||
  //       valueFacialId?.Value === null ||
  //       valueFacialId?.Value == ''
  //         ? false
  //         : valueFacialId.Value.toLowerCase() === 'true';

  //     if (valueValidacionFacialFind == false) {
  //       setLoadRegisterData(false);
  //       setErrorValidacionPorcentaje(true);
  //     }
  //   }
  // }, [selfieMetadata]);

  // useEffect(() => {
  //   if (
  //     facialResult.items != undefined &&
  //     facialResult.items != null &&
  //     (selfieMetadata === undefined || selfieMetadata === null)
  //   ) {
  //     setSelfieMetadata(facialResult.items.DocumentData?.Metadata);
  //     setDocCurrentId(facialResult.items.DocumentData?.Id || file?.itemNameDocEvidence?.Id);
  //   }
  // }, [facialResult.items, selfieMetadata]);

  // useEffect(() => {
  //   if (errorValidacionPorcentaje) {
  //     console.log('errorValidacionPorcentaje', errorValidacionPorcentaje);
  //     AlertConfirm(
  //       {
  //         icon: 'error',
  //         html: (
  //           <Box>
  //             <Typography
  //               textAlign={'center'}
  //               variant="body1"
  //               sx={{ mt: 1, textAlign: 'center', maxWidth: 400, mx: 'auto' }}
  //             >
  //               La comparación facial fue incorrecta, por favor reintenta nuevamente.
  //             </Typography>
  //           </Box>
  //         ),
  //         okbtntext: 'Aceptar',
  //         notbtntext: 'Salir',
  //       },
  //       (result) => {
  //         if (result.isConfirmed) {
  //           reinicio();
  //         } else {
  //           reinicio();
  //         }
  //       },
  //     );
  //   }
  // }, [errorValidacionPorcentaje]);

  const firstRegVal = (regString, value, index) => {
    let re = new RegExp(regString);
    let result = re.test(value);
    return result;
  };

  const sendFormData = (formData) => {
    setLoadView(true);
    setLoadRegisterData(true);
    const generalMetadata = metadatos.map((meta, i) => {
      return {
        GroupName: 'MetadataINE/IFE',
        Name: meta.Name,
        Value: formData[meta.Name],
      };
    });

    const dataToregister = {
      DocumentId: docCurrentId,
      Metadata: generalMetadata,
      Geolocation: {
        Latitude: '11111111111',
        Longitude: '2222222222',
      },
    };
    dispatch(documentActions.RegisterDataTwo(dataToregister))
      .then(() =>
        dispatch(
          workFlowActions.ContinueWorkFlow({
            File_Id: folioId,
            WorkFlowInstanceId: workFlowInstanceId,
          }),
        ),
      )
      .then(() =>
        dispatch(
          fileActions.GetFileInfo({
            FileId: folioId,
            WorkFlowInstanceId: workFlowInstanceId,
          }),
        ),
      );
  };

  // useEffect(() => {
  //   if (metaToSend.length > 0) {
  //     const dataToregister = {
  //       DocumentId: docCurrentId,
  //       Metadata: metaToSend,
  //       Geolocation: {
  //         Latitude: '11111111111',
  //         Longitude: '2222222222',
  //       },
  //     };
  //     dispatch(documentsActions.RegisterDataTwo(dataToregister));
  //     setLoadRegisterData(true);
  //   }
  // }, [metaToSend]);

  useEffect(() => {
    if (docName != '') {
      setFields(identityValFunctions.getNewFieldNames(docName));
    } else if (
      file.itemNameDoc?.Name != undefined &&
      file.itemNameDoc?.Name != null &&
      file.itemNameDoc?.Name != ''
    ) {
      setDocName(file.itemNameDoc?.Name);
    }
  }, [docName]);

  useEffect(() => {
    if (dataResponse.items && loadRegisterData) {
      setLoadRegisterData(false);
      dispatch(
        workFlowActions.RegisterStatusStage({
          File_id: folioId,
          Status: 'Inicializado',
        }),
      );
      setLoadStatusStage(true);
    }
    if (dataResponse.error != undefined && loadRegisterData) {
      setLoadView(false);
      setLoadRegisterData(false);
      AlertModal('Error', dataResponse.error, 'error');
    }
  }, [dataResponse]);

  useEffect(() => {
    setLoadView(validaIdentityData.loading);

    if (fields.length > 0) {
      let newMetaData = null;

      if (getOcrExtract.items && localStorage.getItem('TransactionId')) {
        setLoadView(false);
        newMetaData = fields.map((data) => {
          let newObjFind = getOcrExtract.items.Metadatos?.find(
            (itemGet) => itemGet.Name.toUpperCase() == data.OnlineFieldName.toUpperCase(),
          );

          if (data.Name === 'sexo') {
            return {
              GroupName:
                newObjFind?.GroupName != undefined &&
                newObjFind?.GroupName != null &&
                newObjFind?.GroupName != ''
                  ? newObjFind?.GroupName
                  : docName == 'INE / IFE'
                  ? 'INE'
                  : docName == 'DNI'
                  ? 'Información Personal'
                  : docName,
              Value: newObjFind?.Value,
              Name: data.FieldQuestionName,
              Label: 'Sexo',
              Required: true,
            };
          } else {
            return {
              GroupName:
                newObjFind?.GroupName != undefined &&
                newObjFind?.GroupName != null &&
                newObjFind?.GroupName != ''
                  ? newObjFind?.GroupName
                  : docName == 'INE / IFE'
                  ? 'INE'
                  : docName == 'DNI'
                  ? 'Información Personal'
                  : docName,
              Value:
                data.QuestionType === 'Fecha'
                  ? FormatDateArg(
                      data.QuestionType === 'Fecha' &&
                        newObjFind?.Value !== undefined &&
                        newObjFind?.Value !== null &&
                        newObjFind?.Value !== ''
                        ? newObjFind.Value
                        : '',
                      flujoUser,
                    )
                  : newObjFind?.Value,
              RegVal: firstRegVal(data.RegexValidation, newObjFind?.Value),
              Name: data.FieldQuestionName,
              Label: data.FieldName,
              Regex: data.RegexValidation,
              Sequence: getOrderIndex(data.FieldQuestionName),
              Required: true,
              QuestionType: data.QuestionType === undefined ? 'Text' : data.QuestionType,
            };
          }
        });
      }
      if (newMetaData != null) {
        let orderArray = newMetaData.sort((a, b) => a.Sequence - b.Sequence);
        const newArray = orderArray.filter(
          (objeto) => objeto.Value != null && objeto.Value != '' && objeto.Value != undefined,
        );
        console.log('newArrayData', newArray);
        if (newArray.length > 0) {
          setMetadatos(orderArray);
          setLoadView(false);
        } else {
          // Alert(
          //   {
          //     //     loadStatusStage
          //     //   ) {
          //     //     setLoadStatusStage(false);
          //     //     let nextComponent = null;
          //     //     if (
          //     //       minutiaeCapture == '0' ||
          //     //       minutiaeCapture == 0 ||
          //     //       (isEvaluationIsMinutiaeCorrect != undefined &&
          //     //         isEvaluationIsMinutiaeCorrect != null &&
          //     //         isEvaluationIsMinutiaeCorrect === true)
          //     //     ) {
          //     //       if (displayValidationResults == false) {
          //     icon: 'error',
          //     text: 'No pudimos extraer tu información correctamente. Por favor, toma de nuevo las fotografías.',
          //     okbtntext: 'Aceptar',
          //   },
          //   () => {
          //     // reinicio();
          //   },
          // );
        }
      }
    }
    // }
  }, [fields, validaIdentityData, getOcrExtract]);

  useEffect(() => {
    if (
      registroStatusStage.items != undefined &&
      registroStatusStage.items != null &&
      loadStatusStage
    ) {
      setLoadStatusStage(false);
      // let nextComponent = null;

      // if (!displayValidationResults) {
      dispatch(
        documentActions.ValidateIdentityData({
          DocumentId: docCurrentId,
        }),
      );
      setLoadValIdentityData(true);
      // } else {
      //   nextComponent = 'ExternalValidations';
      //   coreFunctions.DoNextStage({ navigate, nextComponent, State: { folioId, docName } });
      // }
    }
  }, [registroStatusStage]);

  useEffect(() => {
    console.log('----....valIdentityData.items ....----: ', valIdentityData.items);
    if (
      valIdentityData.items != undefined &&
      valIdentityData.items != null &&
      loadValIdentityData
    ) {
      setLoadView(false);
      setLoadValIdentityData(false);
      let resultStage = valIdentityData.items.Stage;
      if (
        valIdentityData.items?.Result?.IdentityValidationResult?.EvaluationIsFaceCorrect === false
      ) {
        setErrorValidacionPorcentaje(true);
      } else if (resultStage != undefined && resultStage != null) {
        let stageName = resultStage.Name;
        let stageId = resultStage.Id;
        let stage = resultStage;

        // let nextComponent = coreFunctions.GetLegoNext(stageName);
        // coreFunctions.DoNextStage({
        //   navigate,
        //   nextComponent,
        //   State: { stageName, stageId, stage, folioId },
        // });
      } else {
        console.log('valIdentityData-----error-------- resultStage: ', resultStage);
      }
    } else if (
      valIdentityData.error != undefined &&
      valIdentityData.error != null &&
      loadValIdentityData
    ) {
      setLoadView(false);
      setLoadValIdentityData(false);
      AlertModal('Error', valIdentityData.error, 'error');
    }
  }, [valIdentityData]);

  useEffect(() => {
    if (
      metadatos.length > 0 &&
      file.items != undefined &&
      file.items != null &&
      addDinamycQuestion
    ) {
      let newArr = [...metadatos];
      file.items.Stage?.QuestionGroups[0]?.Questions?.forEach((data) => {
        let valueRegex =
          data.Name == 'Teléfono'
            ? '[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}'
            : data.Name == 'Correo electrónico'
            ? /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            : data.Name == 'RFC'
            ? /^[A-ZÑ&]{3,4}\d{6}(?:[A-Z\d]{3})?$/
            : null;

        let dataItem = {
          GroupName: data.QuestionGroup,
          RegVal: true,
          Name: data.Name,
          Label: data.Description,
          Regex: valueRegex,
          Sequence: data.Sequence,
          Required: data.Required,
        };
        newArr.push(dataItem);
      });
      console.log('PASOAQUI 1', newArr);
      const newArray = newArr.filter(
        (objeto) => objeto.Value != null && objeto.Value != '' && objeto.Value != undefined,
      );
      if (newArray.length > 0) {
        setMetadatos(newArr);
        setAddDinamycQuestion(false);
        setLoadView(false);
      } else {
        // Alert(
        //   {
        //     icon: 'error',
        //     text: 'No pudimos extraer tu información correctamente. Por favor, toma de nuevo las fotografías.',
        //     okbtntext: 'Aceptar',
        //   },
        //   () => {
        //     reinicio();
        //   },
        // );
      }
    }
  }, [file, metadatos]);

  // const reinicio = () => {
  //   dispatch(
  //     workFlowActions.RegisterStatusStage({
  //       File_id: folioId,
  //       Status: 'Inicializado',
  //     }),
  //   );

  //   let nextComponent = coreFunctions.GetLegoNext('Validación de identidad');
  //   coreFunctions.DoNextStage({ navigate, nextComponent, State: { folioId } });

  // };

  return (
    <GenericContainer>
      <FormProvider {...formMethods}>
        <DynamicQuestion
          folioId={folioId}
          data={metadatos}
          documentName={docName}
          selfieData={selfieMetadata}
          clickSubmit={sendFormData}
        />
      </FormProvider>
      {/* <HelpForm inputName={helpType} closeHelp={closeHelp} open={openHelp} /> */}

      {loadView ? (
        <CustomLoadingOverlay active={loadView} text="Espere un momento por favor..." />
      ) : null}
    </GenericContainer>
  );
};

const DynamicQuestion = (props) => {
  const validateINEFront =
    localStorage.getItem('validateINEFront') != undefined &&
    localStorage.getItem('validateINEFront') != null &&
    localStorage.getItem('validateINEFront') != ''
      ? JSON.parse(localStorage.getItem('validateINEFront'))
      : null;
  const cargaDocWithSecResponse = useSelector(
    (state) => state.CargaDocWithSecData?.items?.DocumentLivenessResponseData,
  );
  const [open, setOpen] = useState(false);
  const [typeData, setTypeData] = useState('');
  const [validateFacialOk, setValidateFacialOk] = useState(false);
  const [validateSimilitud, setValidateSimilitud] = useState(null);

  const { control, formState, handleSubmit, register, setValue } = useForm({ mode: 'onBlur' });
  const handleClose = () => setOpen(!open);

  useEffect(() => {
    if (props.data?.length > 0) {
      props.data?.forEach((item) => {
        if (item.Value != null && item.Value != '') {
          setValue(item.Name, item.Value);
        }
      });
    }
  }, [props.data]);

  useEffect(() => {
    if (props.selfieData?.length > 0) {
      // console.log("carga de datos2")
      props.selfieData?.forEach((item) => {
        if (item.Value != null && item.Value != '') {
          setValue(item.Name, item.Value);
        }
      });

      let valueFacialId = props.selfieData?.find((item) => item.Name == 'Validación facial');
      let valueSimilitud = props.selfieData?.find((item) => item.Name == 'Reconocimiento facial');
      console.log(
        '*****--------------Validación facial-------*************: ',
        valueFacialId,
        valueSimilitud,
      );
      setValidateSimilitud(
        valueSimilitud.Value.includes('Sin') || valueSimilitud.Value.includes('sin') ? false : true,
      );
      let valueValidacionFacialFind =
        valueFacialId?.Value === undefined ||
        valueFacialId?.Value === null ||
        valueFacialId?.Value == ''
          ? false
          : valueFacialId.Value.toLowerCase() === 'true';
      setValidateFacialOk(valueValidacionFacialFind);
      console.log(
        '************----valueValidacionFacialFind---------*****----Compare-2----********************: ',
        valueValidacionFacialFind,
      );
    }
  }, [props.selfieData]);

  const limiteDatos = (type) => {
    switch (type) {
      case 'Año de registro':
        return 4;
      case 'Año de emisión':
        return 4;
      case 'Número de emisión':
        return 2;
      case 'Año de registro':
        return 4;
      case 'Vigencia':
        return 4;
      case 'CURP':
        return 18;
      case 'RFC':
        return 13;
      default:
        break;
    }
  };

  return (
    <>
      <GenericContainerForm>
        <Box display="flex" justifyContent="space-between" padding="0 1rem">
          <Typography variant="subtitle1">Verifica que la información sea correcta.</Typography>
          <Button variant="contained" onClick={handleSubmit(props.clickSubmit)}>
            Aceptar
          </Button>
        </Box>

        {props.data.map((input, index) => {
          if (input.QuestionType === 'Fecha') {
            return (
              <InputField
                inputType="date"
                register={register}
                control={control}
                name={`${input.Name}`}
                label={input.Label}
                validations={{ required: input.Required }}
                error={!!formState.errors[`${input.Name}`]}
                helperText={formState.errors[`${input.Name}`]?.message}
                required={input.Required}
                onSelect={(e) => {
                  console.log('Evento de selecxcion de fecha: ', e);
                  setValue(`${input.Name}`, e);
                }}
                disabled={input.Disabled}
              />
            );
          } else {
            // console.log("input*********: ", input)
            return input.Label === 'OCR' ||
              input.Label === 'CIC' ||
              input.Label === 'Año de emisión' ||
              input.Label === 'Número de emisión' ||
              input.Label === 'Año de registro' ||
              input.Label === 'Vigencia' ||
              input.Label === 'Clave de elector' ||
              // || input.Label === 'CURP'
              input.Label == 'Teléfono' ||
              input.Label == 'Correo electrónico' ||
              input.Label == 'RFC' ||
              input.Label == 'Fecha de nacimiento' ||
              input.Label == 'Teléfono' ? (
              props.documentName == 'Pasaporte' ? (
                <Controller
                  control={control}
                  name={input.Name}
                  render={({ field: { ref, ...restFields } }) => (
                    <TextField
                      {...restFields}
                      {...register(input.Name, {
                        required: false,
                        setValueAs: (value) => value?.toUpperCase(),
                      })}
                      variant="outlined"
                      required={false}
                      // className={localStyles.inputs}
                      error={!!formState.errors[input.Name]}
                      type="text"
                      autoComplete="new-password"
                      label={input.Label}
                      // label={
                      //     <Box sx={{ display: "flex", alignItems: "center" }}>
                      //         {input.Label}
                      //     </Box>
                      // }
                      placeholder={input.Label}
                      defaultValue={input.Value}
                      helperText={formState.errors[input.Name]?.message}
                      inputProps={{
                        style: { textTransform: 'uppercase' },
                        outline: 'none',
                        autoComplete: 'new-password',
                        form: {
                          autoComplete: 'off',
                        },
                      }}
                    />
                  )}
                />
              ) : input.Label === 'OCR' ? (
                <Controller
                  control={control}
                  name={input.Name}
                  render={({ field: { ref, ...restFields } }) => (
                    <TextField
                      {...restFields}
                      {...register(input.Name, {
                        required: 'Este campo requiere al menos 13 caracteres',
                        validate: (value) => {
                          return (
                            new RegExp(input.Regex).test(value) || `${input.Label} dato inválido.`
                          );
                        },
                        setValueAs: (value) => value?.toUpperCase(),
                      })}
                      // className={localStyles.inputs}
                      error={!!formState.errors[input.Name]}
                      type="text"
                      required={true}
                      variant="outlined"
                      autoComplete="new-password"
                      label={input.Label}
                      placeholder={input.Label}
                      defaultValue={input.Value}
                      helperText={formState.errors[input.Name]?.message}
                      InputProps={{
                        inputProps: {
                          maxLength: 13,
                          style: { textTransform: 'uppercase' },
                          outline: 'none',
                          autoComplete: 'new-password',
                          form: {
                            autoComplete: 'off',
                          },
                        },
                        outline: 'none',
                        autoComplete: 'new-password',
                        form: {
                          autoComplete: 'off',
                        },
                        endAdornment: (
                          <InfoOutlined
                            sx={{ ml: 1, fontSize: 15 }}
                            onClick={() => {
                              setTypeData('OCR');
                              handleClose();
                            }}
                          />
                        ),
                      }}
                    />
                  )}
                />
              ) : input.Label === 'Número de emisión' ? (
                <Controller
                  control={control}
                  name={input.Name}
                  render={({ field: { ref, ...restFields } }) => (
                    <TextField
                      {...restFields}
                      {...register(input.Name, {
                        required: 'Requerido',
                        validate: (value) => {
                          return (
                            new RegExp(input.Regex).test(value) || `${input.Label} dato inválido.`
                          );
                        },
                        setValueAs: (value) => value?.toUpperCase(),
                      })}
                      // className={localStyles.inputs}
                      error={!!formState.errors[input.Name]}
                      type="text"
                      required={true}
                      variant="outlined"
                      autoComplete="new-password"
                      label={input.Label}
                      placeholder={input.Label}
                      defaultValue={input.Value}
                      helperText={formState.errors[input.Name]?.message}
                      InputProps={{
                        inputProps: {
                          maxLength: 2,
                          style: { textTransform: 'uppercase' },
                          outline: 'none',
                          autoComplete: 'new-password',
                          form: {
                            autoComplete: 'off',
                          },
                        },
                        outline: 'none',
                        autoComplete: 'new-password',
                        form: {
                          autoComplete: 'off',
                        },
                        endAdornment: (
                          <InfoOutlined
                            sx={{ ml: 1, fontSize: 15 }}
                            onClick={() => {
                              setTypeData('Emision');
                              handleClose();
                            }}
                          />
                        ),
                      }}
                    />
                  )}
                />
              ) : input.Label === 'Teléfono' ? (
                <Controller
                  control={control}
                  name={input.Name}
                  render={({ field: { ref, ...restFields } }) => (
                    <TextField
                      {...restFields}
                      {...register(input.Name, {
                        required: 'Requerido',
                        minLength: {
                          value: 10,
                          message: 'Número de teléfono a 10 digitos. ej. 5567883730',
                        },
                        setValueAs: (value) => value?.toUpperCase(),
                      })}
                      // className={localStyles.inputs}
                      error={!!formState.errors[input.Name]}
                      type="text"
                      required={true}
                      variant="outlined"
                      label={input.Label}
                      placeholder={input.Label}
                      autoComplete="new-password"
                      defaultValue={input.Value}
                      helperText={formState.errors[input.Name]?.message}
                      InputProps={{
                        // inputComponent: TextMaskCustom,
                        inputProps: {
                          maxLength: 10,
                          style: { textTransform: 'uppercase' },
                          outline: 'none',
                          autoComplete: 'new-password',
                          form: {
                            autoComplete: 'off',
                          },
                        },
                        outline: 'none',
                        autoComplete: 'new-password',
                        form: {
                          autoComplete: 'off',
                        },
                      }}
                    />
                  )}
                />
              ) : input.Label === 'Fecha de Nacimiento' ? (
                <Controller
                  control={control}
                  name={input.Name}
                  render={({ field: { ref, ...restFields } }) => (
                    <TextField
                      variant="outlined"
                      label={input.Label}
                      type="text"
                      defaultValue={input.Value}
                      // className={localStyles.inputs}
                      inputRef={ref}
                      {...restFields}
                      {...register(input.Name, {
                        required: 'Requerido',
                        // minLength: { value: 10, message: 'Número de teléfono a 10 digitos. ej. 5567883730' },
                        setValueAs: (value) => value?.toUpperCase(),
                      })}
                      placeholder={'DD/MM/AAAA'}
                      error={!!formState.errors[input.Name]}
                      required={true}
                      autoComplete="new-password"
                      helperText={formState.errors[input.Name]?.message}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{ inputComponent: TextMaskCustom }}
                    />
                  )}
                />
              ) : input.Label === 'CIC' && input.Value === null ? null : input.Label === 'CIC' ? (
                <Controller
                  control={control}
                  name={input.Name}
                  render={({ field: { ref, ...restFields } }) => (
                    <TextField
                      {...restFields}
                      {...register(input.Name, {
                        required: 'Requerido',
                        validate: (value) => {
                          return (
                            new RegExp(input.Regex).test(value) ||
                            `${input.Label} requiere al menos 9 caracteres.`
                          );
                        },
                        setValueAs: (value) => value?.toUpperCase(),
                      })}
                      // className={localStyles.inputs}
                      error={!!formState.errors[input.Name]}
                      type="text"
                      required={true}
                      variant="outlined"
                      start
                      label={input.Label}
                      placeholder={input.Label}
                      defaultValue={input.Value}
                      helperText={formState.errors[input.Name]?.message}
                      InputProps={{
                        inputProps: {
                          maxLength: 9,
                          style: { textTransform: 'uppercase' },
                          outline: 'none',
                          autoComplete: 'new-password',
                          form: {
                            autoComplete: 'off',
                          },
                        },
                        outline: 'none',
                        autoComplete: 'new-password',
                        form: {
                          autoComplete: 'off',
                        },
                        endAdornment: (
                          <InfoOutlined
                            sx={{ ml: 1, fontSize: 15 }}
                            onClick={() => {
                              setTypeData('CIC');
                              handleClose();
                            }}
                          />
                        ),
                      }}
                    />
                  )}
                />
              ) : (
                <Controller
                  control={control}
                  name={input.Name}
                  render={({ field: { ref, ...restFields } }) => (
                    <TextField
                      {...restFields}
                      {...register(input.Name, {
                        required: 'Requerido',
                        validate: (value) => {
                          if (input?.Label === 'RFC') return;
                          return (
                            new RegExp(input.Regex).test(value) ||
                            `${input.Label} dato inválidoooo. ${
                              input.Label == 'Correo electrónico' ? 'ej. ejemplo@ejemplo.com' : ''
                            }`
                          );
                        },
                        setValueAs: (value) => value?.toUpperCase(),
                      })}
                      // className={localStyles.inputs}
                      required={true}
                      error={!!formState.errors[input.Name]}
                      type="text"
                      autoComplete="new-password"
                      variant="outlined"
                      label={input.Label == 'Vigencia' ? 'Año de Vigencia' : input.Label}
                      placeholder={input.Label == 'Vigencia' ? 'Año de Vigencia' : input.Label}
                      defaultValue={input.Value}
                      helperText={formState.errors[input.Name]?.message}
                      InputProps={{
                        inputProps: {
                          maxLength: limiteDatos(input.Label),
                          style: { textTransform: 'uppercase' },
                          outline: 'none',
                          autoComplete: 'new-password',
                          form: {
                            autoComplete: 'off',
                          },
                        },
                        outline: 'none',
                        autoComplete: 'new-password',
                        form: {
                          autoComplete: 'off',
                        },
                      }}
                    />
                  )}
                />
              )
            ) : (
              <Controller
                control={control}
                name={input.Name}
                render={({ field: { ref, ...restFields } }) => (
                  <TextField
                    {...restFields}
                    {...register(input.Name, {
                      required: 'Requerido',
                      setValueAs: (value) => value?.toUpperCase(),
                    })}
                    required={true}
                    // className={localStyles.inputs}
                    error={!!formState.errors[input.Name]}
                    type="text"
                    variant="outlined"
                    autoComplete="off"
                    label={input.Label}
                    placeholder={input.Label}
                    defaultValue={input.Value}
                    helperText={formState.errors[input.Name]?.message}
                    InputProps={{
                      inputProps: {
                        style: { textTransform: 'uppercase' },
                        outline: 'none',
                        autoComplete: 'new-password',
                        form: {
                          autoComplete: 'off',
                        },
                      },
                      outline: 'none',
                      autoComplete: 'new-password',
                      form: {
                        autoComplete: 'off',
                      },
                    }}
                  />
                )}
              />
            );
          }
        })}

        <Box sx={{ mb: 1, mt: 2 }}>
          <Divider style={{ backgroundColor: '#A4A4A4' }} light={true} />
        </Box>
        {validateINEFront != null && cargaDocWithSecResponse?.DocLiveAproved != null ? (
          <>
            {console.log(cargaDocWithSecResponse)}
            <TextField
              // className={localStyles.inputs}
              style={
                validateINEFront?.DocLiveAproved === true
                  ? { backgroundColor: '#CDEAD6' }
                  : { backgroundColor: '#F8D1D5' }
              }
              type="text"
              variant="outlined"
              autoComplete="off"
              label={'Verificación documental delantera'}
              value={validateINEFront?.DocLiveResult}
            />
            <TextField
              // className={localStyles.inputs}
              style={
                validateINEFront?.DocLiveAproved === true
                  ? { backgroundColor: '#CDEAD6' }
                  : { backgroundColor: '#F8D1D5' }
              }
              type="text"
              variant="outlined"
              autoComplete="off"
              label={'Confiaza de verificación delantera'}
              value={validateINEFront?.DocLivePersentaje}
            />
            <TextField
              // className={localStyles.inputs}
              style={
                cargaDocWithSecResponse?.DocLiveAproved === true
                  ? { backgroundColor: '#CDEAD6' }
                  : { backgroundColor: '#F8D1D5' }
              }
              type="text"
              variant="outlined"
              autoComplete="off"
              label={'Verificación documental trasera'}
              value={cargaDocWithSecResponse?.DocLiveResult}
            />
            <TextField
              // className={localStyles.inputs}
              style={
                cargaDocWithSecResponse?.DocLiveAproved === true
                  ? { backgroundColor: '#CDEAD6' }
                  : { backgroundColor: '#F8D1D5' }
              }
              type="text"
              variant="outlined"
              autoComplete="off"
              label={'Confiaza de verificación trasera'}
              value={cargaDocWithSecResponse?.DocLivePersentaje}
            />
          </>
        ) : cargaDocWithSecResponse?.DocLiveAproved != null ? (
          <>
            <TextField
              // className={localStyles.inputs}
              style={
                cargaDocWithSecResponse?.DocLiveAproved === true
                  ? { backgroundColor: '#CDEAD6' }
                  : { backgroundColor: '#F8D1D5' }
              }
              type="text"
              variant="outlined"
              autoComplete="off"
              label={'Verificación documental'}
              value={cargaDocWithSecResponse?.DocLiveResult}
            />
            <TextField
              // className={localStyles.inputs}
              style={
                cargaDocWithSecResponse?.DocLiveAproved === true
                  ? { backgroundColor: '#CDEAD6' }
                  : { backgroundColor: '#F8D1D5' }
              }
              type="text"
              variant="outlined"
              autoComplete="off"
              label={'Confiaza de verificación'}
              value={cargaDocWithSecResponse?.DocLivePersentaje}
            />
          </>
        ) : null}

        {props.data.length > 0 &&
          props.selfieData?.map((item, index) => {
            if (
              item.Name == 'EnrollmentID' ||
              item.Name == 'Fecha validación facial' ||
              item.Name == 'Validación facial'
            ) {
              return '';
            } else if (item.Name == 'Reconocimiento facial') {
              return (
                <Controller
                  control={control}
                  name={item.Name}
                  render={({ field: { ref, ...restFields } }) => (
                    <Box sx={{ width: '100%' }}>
                      <Typography>{item.Name}</Typography>
                      <TextField
                        style={
                          item.Value.includes('Sin') || item.Value.includes('sin')
                            ? { backgroundColor: GetColor(false) }
                            : { backgroundColor: GetColor(validateFacialOk) }
                        }
                        name={item.Name}
                        fullWidth
                        type="text"
                        variant="outlined"
                        autoComplete="off"
                        label={''}
                        value={item.Value}
                        disabled={true}
                      />
                    </Box>
                  )}
                />
              );
            } else if (item.Name == 'Similitud selfie vs ID') {
              return (
                <Controller
                  control={control}
                  name={item.Name}
                  render={({ field: { ref, ...restFields } }) => (
                    <Box sx={{ width: '100%' }}>
                      <Typography>{item.Name}</Typography>
                      <TextField
                        style={{ backgroundColor: GetColor(validateSimilitud) }}
                        name={item.Name}
                        fullWidth
                        type="text"
                        variant="outlined"
                        autoComplete="off"
                        label={''}
                        value={item.Value}
                        disabled={true}
                      />
                    </Box>
                  )}
                />
              );
            }
          })}
      </GenericContainerForm>

      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Grid container justifyContent="center">
          {typeData === 'Emision' ? null : (
            <Box sx={{ display: 'flex', textAlign: 'justify', p: 2 }}>
              <Typography>
                {typeData === 'CIC'
                  ? 'Este dato consta de 9 dígitos y lo puedes encontrar al reverso de tu credencial de elector ubicado en la parte inferior izquierda, debajo de la firma y huella digital, después del código IDMEX.'
                  : 'El número OCR consta de 13 dígitos y lo puedes encontrar al reverso de tu credencial de elector. En credenciales del 2008, está ubicado en la parte izquierda; en credenciales de 2013 o posteriores, está ubicado en la parte inferior derecha, debajo de la firma del Secretario Ejecutivo del INE.'}
              </Typography>
            </Box>
          )}
          <Box sx={{ p: 2 }}>
            {/* {typeData === 'CIC' ? (
              <img
                style={{ width: '100%', justifyContent: 'center' }}
                src={HelpCIC}
                alt={'Ayuda de CIC'}
              />
            ) : typeData === 'Emision' ? (
              <img style={{ width: '100%' }} src={HelpEmision} alt={'Ayuda de Emision'} />
            ) : (
              <img style={{ width: '100%' }} src={HelpOCR} alt={'Ayuda de OCR'} />
            )} */}
          </Box>
        </Grid>
      </Dialog>
    </>
  );
};

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  return (
    <IMaskInput
      {...props}
      mask="00/00/0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      overwrite
    />
  );
});

const GetColor = (validateFacialOk) => {
  if (validateFacialOk) {
    return '#CDEAD6';
  } else {
    return '#F8D1D5';
  }
};
