import React from 'react'
import { Dialog, Typography } from '@mui/material'

import { mainStyles } from '../theme/mainStyles'

export const CustomAlertMaterial= (props) => {
    const classes = mainStyles.customDialogStyles()

    return (
        <Dialog onClose={(event, reason) => {
            console.log("(event, reason)*****: ", event, reason)
            if (reason && reason == "backdropClick")
                return;
            props.closeAction()
        }} open={props.open}>
            <div className={classes.dialogCenter}>
                <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.title}>
                    {props.title}
                </Typography>
                <Typography
                    variant="subtitle2"
                    gutterBottom
                    className={classes.dialogBody}>
                    {props.body}
                </Typography>
                <div className={classes.btnContainer}>
                    {/* <mainStyles.CustomDynamicLink
                        text_size={12}
                        text_color={theme.palette.primary.main}
                        text={props.leftButtonText}
                        type='button'
                        className={classes.leftButton}
                        click={props.leftButtonAction} />
                    <mainStyles.CustomDynamicLink
                        text_size={12}
                        text_color={theme.palette.primary.main}
                        text={props.rightButtonText}
                        type='button'
                        className={classes.rightButton}
                        click={props.rightButtonAction} /> */}
                </div>
            </div>
        </Dialog>
    )
}