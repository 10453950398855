import { useTheme } from "@emotion/react";
import { Box, Tab, Tabs, Tooltip, IconButton } from "@mui/material";
import {
  getNotifyData,
  readNotification,
} from "api/notifications/notificationsRequests";
import Card from "components/common/Card";
import TabPanel from "components/common/TabPanel";
import { useStyles } from "components/NoShares";
import { useEffect, useState } from "react";
import useSWR from "swr";
import AssignedTasks from "assets/notificationsIcons/Asignados.png";
import DocumentsBeExpired from "assets/notificationsIcons/PorVencer.png";
import ExpiredDocuments from "assets/notificationsIcons/Vencidos.png";
import { NotificationsModal } from "components/common/Modals/Views/NotificationsModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReadedNotificationIcon from "assets/notificationsIcons/Notificaciónleida.png";
import AttendedNotificationIcon from "assets/notificationsIcons/Notificaciónatendida.png";
import PausedNotificationIcon from "assets/notificationsIcons/Detenciondenotificacion.png";
import RecordCustody from "assets/notificationsIcons/ExpedienteResguardo.png";
import HistoricalRecord from "assets/notificationsIcons/ExpedienteHistorico.png";
import GeneralNotificationIcon from "assets/notificationsIcons/NotificacionesGeneral.png";
import { CustomLoadingOverlay } from "components/CustomLoadingOverlay";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
import { AlertModal } from "components/common/Modals/Views/AlertModal";
import { useNavigate } from "react-router-dom";
import { getPermissions } from "../../api/permission/permissionRequests";
import { useSelector } from "react-redux";
import { IsDevice } from "utils";

const ICONS = {
  "Tarea sin Atender": AssignedTasks,
  "Documento por Vencer": DocumentsBeExpired,
  "Documento Vencido": ExpiredDocuments,
  "En Resguardo": RecordCustody,
  Historico: HistoricalRecord,
  General: GeneralNotificationIcon,
};

const NotificationCard = ({
  type,
  icon,
  title,
  subtitle,
  handleClick,
  selected,
  IsMobile,
}) => (
  <Card
    onCardClick={() => handleClick(type)}
    selected={selected === type}
    isFilter
  >
    <Box
      sx={{
        display: "flex",
        gap: IsMobile ? "0rem" : "1rem",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <img
        src={icon}
        alt={type}
        width={IsMobile ? "15px" : "40px"}
        height={IsMobile ? "15px" : "40px"}
        style={{
          objectFit: "contain",
          ...(IsMobile && { marginRight: "8px" }),
        }}
      />
      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Card.Title IsMobile={IsMobile}>{title || 0}</Card.Title>
        <Card.Subtitle variant={"subtitle1"}>{subtitle}</Card.Subtitle>
      </Box>
    </Box>
  </Card>
);

const NotificationList = ({ filteredData, handleOpenModal, IsMobile }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const stateAuth = useSelector((state) => state.Authentication);
  const userId = stateAuth.items?.UserLoginData?.Id;

  const PERMISSIONS_PAYLOAD = {
    User_Id: userId,
    DocumentalGroup_Id: null,
    AllByTenant: false,
  };

  const onNotificationClick = async (el) => {
    if (["Documento por Vencer", "Documento Vencido"].includes(el.Type)) {
      const documentPermissions = await getPermissions(PERMISSIONS_PAYLOAD);
      const permissions = documentPermissions?.PermissionGroups?.find(
        (documentPermission) =>
          documentPermission.DocumentalGroup_Id === el.DocumentalGroup_Id,
      )?.Permissions;
      localStorage.setItem(
        "selectedViewDocument",
        JSON.stringify(el.Document_Id),
      );
      console.log("lleando---selectedDocumentPermissions----3: ",permissions, documentPermissions?.PermissionGroups)
      localStorage.setItem(
        "selectedDocumentPermissions",
        JSON.stringify(permissions),
      );
      sessionStorage.setItem("documentationTabInitialView", "DOCUMENT_DETAILS");
      sessionStorage.setItem("initialFileTab", 3);
    }
    sessionStorage.setItem("WorkFlowInstanceId", el.WorkFlowInstance_Id);
    return navigate(`/inbox/asignacion/${el.File_Id}`);
  };

  return (
    <>
      {filteredData?.map((el) => (
        <Card key={el.Notify_Id} onCardClick={() => onNotificationClick(el)}>
          <Box
            display="flex"
            gap={IsMobile ? "0rem" : "1rem"}
          >
            <img
              src={ICONS[el?.Type]}
              alt=""
              width="40px"
              height="40px"
              style={{
                objectFit: "contain",
                ...(IsMobile && { marginRight: "12px" }),
              }}
            />
            <div>
              <Card.Title>
                {el?.Folio} -{el?.Nombre}
              </Card.Title>
              <Card.Description>{el.Message}</Card.Description>
              {IsMobile && <Card.Subtitle>{el.Tiempo}</Card.Subtitle>}
            </div>
            {!IsMobile && <Card.Subtitle>{el.Tiempo}</Card.Subtitle>}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            gap={IsMobile ? "0" : "1rem"}
            sx={
              IsMobile && {
                flexDirection: "row",
                justifyContent: "flex-end",
                minWidth: "20%",
              }
            }
          >
            {el?.ReadUser && (
              <Tooltip title={t("notifications_list_read")} placement="top">
                <img
                  src={ReadedNotificationIcon}
                  alt=""
                  style={{ objectFit: "contain" }}
                />
              </Tooltip>
            )}
            {el?.AttendUser && (
              <Tooltip title={t("notifications_list_served")} placement="top">
                {" "}
                <img
                  src={AttendedNotificationIcon}
                  alt=""
                  style={{ objectFit: "contain" }}
                />
              </Tooltip>
            )}
            {el?.Block && (
              <Tooltip
                title={t("notifications_list_shipping_stopped")}
                placement="top"
              >
                <img
                  src={PausedNotificationIcon}
                  alt=""
                  style={{ objectFit: "contain" }}
                />
              </Tooltip>
            )}
            <Tooltip title={t("notifications_list_visualize")} placement="top">
              {" "}
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  handleOpenModal(el);
                }}
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Card>
      ))}
    </>
  );
};

const NotificationsTemplate = () => {
  const { t } = useTranslation();
  const { IsMobile } = IsDevice();
  const { data, mutate, isValidating } = useSWR("NotifyData", getNotifyData, {
    refreshInterval: 0,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { trigger, isMutating } = useSWRMutation(
    "ReadNotification",
    (url, payload) => readNotification(payload),
  );

  const TAB_LABELS = [
    t("notifications_header_new"),
    t("notifications_header_unread"),
    t("notifications_header_All"),
  ]; //For translations

  const [tabValue, setTabValue] = useState(1);
  const [filteredData, setFilteredData] = useState(null);
  const [selectedType, setSelectedType] = useState();
  const [selectedNotification, setSelectedNotification] = useState();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const localStyles = useStyles(theme);

  const handleFilteredData = (value) => {
    if (!value || value === "General") {
      setFilteredData(data.NotifyData[0]);
      setSelectedType(null);
      return;
    }
    const filteredNotifications = data.NotifyData[0]?.Solicitudes?.filter(
      (el) => el.Type === value,
    );
    setSelectedType(value);
    setFilteredData((prevValue) => ({
      ...prevValue,
      Solicitudes: filteredNotifications,
    }));
  };

  const handleTabChange = (event, newValue) => setTabValue(newValue);

  const handleOpenModal = async (value) => {
    try {
      await trigger(value.Notify_Id);
      await mutate();
      setSelectedNotification(value.Notify_Id);
      setOpen(true);
    } catch (error) {
      AlertModal("Error", error.message, null, t);
    }
  };

  useEffect(() => {
    if (data) {
      handleFilteredData(selectedType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div style={{ padding: IsMobile ? "0" : "2rem" }}>
      <CustomLoadingOverlay active={isValidating || isMutating} />
      <Box
        sx={{
          display: "flex",
          // flexDirection: "row",
          // justifyContent: "space-between",
          gap: IsMobile ? "0.5rem" : "1rem",
          // width: "75%",
          padding: 1,
          overflowX: "hidden",
          ...(IsMobile && { flexFlow: "row" }),
          flexWrap: "wrap",
          "& > *": {
            flex: "1 1 calc(33.33% - 1rem)",
          },
        }}
      >
        <NotificationCard
          type="Tarea sin Atender"
          icon={AssignedTasks}
          title={filteredData?.TareasAsignadas}
          subtitle={t("notifications_assigned_task")}
          handleClick={handleFilteredData}
          selected={selectedType}
          IsMobile={IsMobile}
        />
        <NotificationCard
          type="Documento por Vencer"
          icon={DocumentsBeExpired}
          title={filteredData?.DocumentosPorVencer}
          subtitle={t("notifications_docs_due")}
          handleClick={handleFilteredData}
          selected={selectedType}
          IsMobile={IsMobile}
        />
        <NotificationCard
          type="Documento Vencido"
          icon={ExpiredDocuments}
          title={filteredData?.DocumentosVencidos}
          subtitle={t("notifications_expired_docs")}
          handleClick={handleFilteredData}
          selected={selectedType}
          IsMobile={IsMobile}
        />
        <NotificationCard
          type="En Resguardo"
          icon={RecordCustody}
          title={filteredData?.EnResguardo}
          subtitle={t("notifications_safe_record")}
          handleClick={handleFilteredData}
          selected={selectedType}
          IsMobile={IsMobile}
        />
        <NotificationCard
          type="Historico"
          icon={HistoricalRecord}
          title={filteredData?.Historico}
          subtitle={t("notifications_historical_record")}
          handleClick={handleFilteredData}
          selected={selectedType}
          IsMobile={IsMobile}
        />
        <NotificationCard
          type="General"
          icon={GeneralNotificationIcon}
          title={data?.NotifyData[0]?.Solicitudes?.length}
          subtitle={t("notifications_all_notifications")}
          handleClick={handleFilteredData}
          selected={selectedType === null ? "General" : null}
          IsMobile={IsMobile}
        />
      </Box>

      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="secondary"
        textColor="black"
        sx={{ borderBottom: "1px solid #D6D6D6" }}
      >
        {TAB_LABELS.map((tabLabel, index) => (
          <Tab
            key={index}
            label={tabLabel}
            classes={{
              root: localStyles.subTabRoot,
              selected: localStyles.selectedSubTab,
            }}
          />
        ))}
      </Tabs>

      <TabPanel
        value={tabValue}
        index={0}
        style={{ padding: "0", paddingTop: "1rem" }}
      >
        <NotificationList
          filteredData={filteredData?.Solicitudes?.filter((el) => el?.New)}
          handleOpenModal={handleOpenModal}
          IsMobile={IsMobile}
          t={t}
        />
      </TabPanel>

      <TabPanel
        value={tabValue}
        index={1}
        style={{ padding: "0", paddingTop: "1rem" }}
      >
        <NotificationList
          filteredData={filteredData?.Solicitudes?.filter(
            (el) => !el?.AttendUser,
          )}
          handleOpenModal={handleOpenModal}
          IsMobile={IsMobile}
          t={t}
        />
      </TabPanel>

      <TabPanel
        value={tabValue}
        index={2}
        style={{ padding: "0", paddingTop: "1rem" }}
      >
        <NotificationList
          filteredData={filteredData?.Solicitudes}
          handleOpenModal={handleOpenModal}
          IsMobile={IsMobile}
          t={t}
        />
      </TabPanel>

      {open && (
        <NotificationsModal
          open={open}
          mutate={mutate}
          notificationId={selectedNotification}
          handleClose={() => setOpen(false)}
        />
      )}
    </div>
  );
};

export default NotificationsTemplate;
