import { Box, Button, Typography } from '@mui/material';
import { getBatchData } from 'api/massiveFlow/massiveFlowRequests';
import MainTable from 'components/common/Table/Table';
import { OBTableCell, OBTableRow } from 'components/common/Table/Table.styled';
import { CustomLoadingOverlay } from 'components/CustomLoadingOverlay';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import NoTableData from 'assets/Seccion_vacia.png';

export const BulkLoad = ({ handleMode }) => {
  const { data, isValidating } = useSWR('BatchData', () => getBatchData(), {
    refreshInterval: 0,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const { t } = useTranslation();

  const columns = [
    { id: 'Folio de carga', label: t('massive_upload_table_header_upload_folio'), minWidth: 170 },
    {
      id: 'Nombre de archivo ZIP',
      label: t('massive_upload_table_header_zip_name'),
      minWidth: 170,
    },
    { id: 'Fecha de carga', label: t('massive_upload_table_header_upload_date'), minWidth: 170 },
    { id: 'Tipo de documento', label: t('massive_upload_table_header_doc_type'), minWidth: 170 },
  ];

  const render = (row) => (
    <OBTableRow hover role="checkbox">
      <OBTableCell>{row['Folio de carga']}</OBTableCell>
      <OBTableCell>{row['Nombre de archivo ZIP']}</OBTableCell>
      <OBTableCell>{row['Fecha de carga']}</OBTableCell>
      <OBTableCell>{row['Tipo de documento']}</OBTableCell>
    </OBTableRow>
  );

  if (isValidating) return <CustomLoadingOverlay active={isValidating} />;

  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Typography variant="span">{t('massive_upload_description')}</Typography>
        <Button variant="contained" onClick={() => handleMode('MASSIVE_UPLOADER')}>
          {t('massive_upload_new_upload')}
        </Button>
      </Box>

      {data?.BatchData ? (
        <MainTable columns={columns} rows={data?.BatchData} render={render} />
      ) : (
        <>
          <Typography variant="h5" textAlign="center" paddingTop="5rem">
            Actualmente no hay cargas masivas disponibles
          </Typography>
          <img src={NoTableData} alt="" style={{width: '500px', height: '500px', objectFit: 'contain'}}/>
        </>
      )}
    </Box>
  );
};
