import { Box } from "@mui/material";
import { uploadSectionGeneral } from "api/document/documentRequests";
import { CustomLoadingOverlay } from "components/CustomLoadingOverlay";
import useSWRMutation from "swr/mutation";
import { useEffect } from "react";
import {
  getOcrExtraction,
  uploadDocumentWithSection,
} from "api/extraction/extractionRequests";
import { AlertModal } from "components/common/Modals/Views/AlertModal";
import useSWR from "swr";

const DocumentAutoUpload = ({
  documentB64,
  documentSpec,
  handleUploadFileSuccess,
  actionId,
  handleMode,
}) => {
  const { data: documentStructure } = useSWR("GetDocumentalStructure");
  const fileId = documentStructure.FileId;

  const { isMutating: loadingGetOcr, trigger: triggerGetOcr } = useSWRMutation(
    "OcrExtraction",
    (url, payload) => getOcrExtraction(payload),
    { populateCache: true, revalidate: false },
  );

  const {
    isMutating: loadingUploadWithSection,
    trigger: triggerUploadDocumentWithSection,
  } = useSWRMutation(
    "DocumentUploadWithSection",
    (url, payload) => uploadDocumentWithSection(payload),
    { populateCache: true, revalidate: false },
  );

  const {
    isMutating: loadingUploadDocument,
    trigger: triggerUploadSectionGeneral,
  } = useSWRMutation(
    "UploadSectionGeneral",
    (url, payload) => uploadSectionGeneral(payload),
    {
      revalidate: false,
      onSuccess: handleUploadFileSuccess,
    },
  );

  const IS_DOCUMENT_WITH_OCR =
    documentSpec?.Properties?.find((e) => e.Name === "OcrCapture")?.Value ===
    "1";
  const IS_MUTATION_IN_PROGRESS =
    loadingGetOcr || loadingUploadWithSection || loadingUploadDocument;

  const UPLOAD_SECTION_PAYLOAD = {
    B64Content: documentB64.fileb64 || null,
    FileId: fileId,
    Actor_Id: null,
    SkipActor: null,
    SectionId: documentSpec.SectionId,
    Extension: `.${documentB64.extension}`,
    Skip: false,
    SkipReason: null,
    Metadata: null,
    ContinueWorkFlow: false,
    Geolocation: null,
    DeferLoad: false,
    ActionId: actionId,
  };

  const askforTransactionId = async () => {
    const requestUpload = {
      UploadSectionRequestData: {
        B64Content: documentB64.fileb64,
        FileId: fileId,
        Actor_Id: null,
        SkipActor: null,
        SectionId: documentSpec.SectionId,
        Extension: `.${documentB64.extension}` || ".jpg",
        Skip: false,
        SkipReason: null,
        Metadata: null,
        ContinueWorkFlow: false,
        Geolocation: null,
        DeferLoad: false,
        OmitirMergeVideo: null,
        OmitirRemovebg: null,
        AsignarEstatusCarga: null,
      },
      File_id: fileId,
      Carga: {
        Referencia: fileId.toString(),
        TransactionId: null,
        EsBolqueFin: true,
        TipoDocumento: documentSpec.Name,
        Seccion: "Documento",
        Imagenes: [
          {
            B64: documentB64.fileb64,
            Extension: `.${documentB64.extension}`
              ? `.${documentB64.extension}`
              : ".jpg",
          },
        ],
        Metadatos: null,
      },
    };

    const requestOCR = {
      File_id: fileId,
      DocumentType_Id: documentSpec.DocumentType_Id,
      TransactionId: null,
    };

    try {
      const response = await triggerUploadDocumentWithSection(requestUpload);
      await triggerGetOcr({
        ...requestOCR,
        TransactionId: response?.TransactionId,
      });
    } catch (err) {
      AlertModal("Error", err.message, "error");
    }
  };

  const getQuestionGroups = async () => {
    try {
      if (IS_DOCUMENT_WITH_OCR) {
        await askforTransactionId();
        handleMode("DOCUMENT_OCR");
        return;
      }
      return await triggerUploadSectionGeneral(UPLOAD_SECTION_PAYLOAD);
    } catch (err) {
      AlertModal("Error", err.message, "error");
    }
  };

  useEffect(() => {
    getQuestionGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: "grid", gap: "2rem", width: "100%" }}>
      {IS_MUTATION_IN_PROGRESS ? (
        <CustomLoadingOverlay active={IS_MUTATION_IN_PROGRESS} />
      ) : null}
    </Box>
  );
};

export default DocumentAutoUpload;
