import { Outlet } from 'react-router-dom';
import React, { useState } from 'react';
import { Footer } from '../../components/Footer';
import { Box, styled, useTheme } from '@mui/material';
import { HeaderAppBar } from '../../components/HeaderAppBar';
import { Header } from '../../components';
import { Drawer } from '../../components/Drawer';
import { useSelector } from 'react-redux';
import { SummaryComponent } from 'chatbot';
import { chatbotImgs } from 'mappings/ChatbotImageMapping';
import { IsDevice } from 'utils';
import { useTranslation } from 'react-i18next';

const OutletWrapper = styled(Box)(({ theme }) => ({
  background: '#FFFFFF',
  boxShadow: '0px 18px 53px rgba(0, 0, 0, 0.14)',
  borderRadius: '12px',
  margin: '15px',
  overflow: 'auto',
  // border: '1px solid blue',
  // padding:"0.5em", //"1.5em",
  // backgroundColor: "#CACCCE",
  [theme.breakpoints.up('lg')]: {
    margin: '10px 15px 5px 15px',
    height: '100vh',
  },
  [theme.breakpoints.down('md')]: {
    margin: '10px 10px',
  },
  [theme.breakpoints.down('sm')]: {
    margin: '10px 0px',
    // margin: "0px",
    padding: '0.6em',
    borderRadius: '10px 10px 0px 0px',
    boxShadow: 'none',
    marginBottom: 0,
  },
  // overflowY: "auto !important",
}));

const MarginPrincipal = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: '0px 45px 0px 0px',
    display: 'grid !important',
    gridTemplateColumns: '13% 87%',
    gridAutoRows: '100vh !important',
    overflowY: 'hidden !important',
    '&::-webkit-scrollbar': {
      width: '0',
      background: 'transparent',
    },
    overflowX: 'hidden',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '100%',
    },
  },
}));

const RootStyle = styled(Box)(({ theme }) => ({
  backgroundColor: '#F7F8FA',
  width: '100%',
  minHeight: 'calc(100vh - 120px)',
  margin: '0px auto',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem 2rem 2rem 2rem',
}));

export default function Home() {
  const theme = useTheme();
  const [drawer, setdrawer] = useState(false);
  const { i18n } = useTranslation();
  const openDrawer = () => setdrawer(true);
  const closeDrawer = () => setdrawer(false);
  const { IsMobile } = IsDevice();
  const documentReference = useSelector(
    (state) => state.GetVersionedDocument?.items?.CurrentVersion,
  );
  const userData = useSelector(
    (state) => state.Authentication.items.UserLoginData,
  );
  const fileInfo = useSelector((state) => state.FileInfo);

  return (
    <MarginPrincipal>
      <HeaderAppBar open={drawer} openDrawer={openDrawer} />
      <RootStyle sx={IsMobile && { padding: 0 }}>
        <Box>
          <Header />
        </Box>
        <OutletWrapper>
          <Outlet />
        </OutletWrapper>
        {/*<Footer />*/}
      </RootStyle>
      <Drawer open={drawer} onClose={closeDrawer} />
      {documentReference?.ActiveChat ? (
        <SummaryComponent
          language={i18n.language}
          WorkFlowId={fileInfo?.items?.WorkFlow?.Id}
          palette={theme.palette}
          reference={documentReference?.IAReference}
          name={userData.Name}
          userName={userData.Username}
          imgs={chatbotImgs}
        />
      ) : null}
    </MarginPrincipal>
  );
}
