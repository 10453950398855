import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  getDocumentTypeGroup,
  updateDocumentData,
} from "api/document/documentRequests";
import { CustomLoadingOverlay } from "components/CustomLoadingOverlay";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

export const EditDocumentModal = ({ Open, DataToEdit, OnEdit, OnCancel }) => {
  const { t } = useTranslation();
  const documentCategoryId = JSON.parse(
    sessionStorage.getItem("configurationData"),
  )?.DocumentalCategoryId;

  const PAYLOAD = { DocumentCategory: documentCategoryId };
  const { data: groupersData, isValidating } = useSWR(
    "GetDocumentTypeGroup",
    () => getDocumentTypeGroup(PAYLOAD),
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const { isMutating: loadingUpdateDocument, trigger: triggerUpdateDocument } =
    useSWRMutation(
      "UpdateDocumentData",
      (url, payload) => updateDocumentData(payload),
      {
        populateCache: true,
        revalidate: false,
        onSuccess: () => OnEdit(),
      },
    );

  const [formData, setFormData] = useState({
    DocumentalGroupId: DataToEdit?.DocumentGroupId,
    // DocumentalGroupDescription: DataToEdit?.DocumentGroup,
    DocumentTypeId: DataToEdit?.DocumentTypeId,
    DocumentTypeDescription: DataToEdit?.TranslatedDescription,
  });

  console.log("formData::", DataToEdit);

  const handleChange = (event) => {
    const { name, value } = event.target;
    let docDescription = "";
    if (name === "DocumentalGroupId" && value != DataToEdit?.DocumentGroupId) {
      docDescription = groupersData?.DocumentGroups?.find(
        (doc) => doc.Id === value,
      )?.TranslatedText;
    }

    setFormData({
      ...formData,
      [name]: value,
      ...(docDescription !== "" && {
        DocumentalGroupDescription: docDescription,
      }),
    });
  };

  const handleSave = async () => {
    console.log("send data:", formData);
    await triggerUpdateDocument(formData);
  };

  return (
    <>
      <CustomLoadingOverlay
        active={loadingUpdateDocument || isValidating}
        text={t("general_loading_message")}
      />
      <Dialog open={Open} onClose={OnCancel}>
        <DialogTitle>{t("configuration_documental_structure_edit_title")}</DialogTitle>
        <DialogContent sx={{ minWidth: "40vw" }}>
          <Stack gap={"1rem"}>
            <Box>
              <Typography variant={"subtitle2"}>
                {t("text_type_table")}
              </Typography>
              <TextField
                fullWidth
                name="DocumentTypeDescription"
                value={formData["DocumentTypeDescription"]}
                onChange={handleChange}
                placeholder={t(
                  "configuration_docStructure_add_doc_insert_doc_type",
                )}
              />
            </Box>
            <Box>
              <Typography variant={"subtitle2"}>
                {t("configuration_docStructure_add_metadata_grouper")}
              </Typography>
              <Select
                fullWidth
                defaultValue={ formData["DocumentalGroupId"] }
                name="DocumentalGroupId"
                onChange={handleChange}
              >
                {/* <MenuItem value="default" disabled>
                {t("configuration_docStructure_add_doc_select_grouper")}
              </MenuItem> */}
                {groupersData?.DocumentGroups?.map((option) => (
                  <MenuItem key={option.Id} value={option.Id}>
                    {option.TranslatedText}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outlined"
            onClick={OnCancel}
            sx={{ minWidth: "40%" }}
          >
            {t("general_cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{ minWidth: "40%" }}
          >
            {t("general_accept")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
