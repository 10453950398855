import axios from 'axios';

import { AppUrls } from 'services/Globals';
import { Config } from 'services/Config';
import { templates } from 'utils/OCRTemplates';

export const identityValFunctions = {};

identityValFunctions.getNewFieldNames = (name) => {
  const fields = templates
    .filter((doc) => doc.DocumentType === name)
    .map((docSet) => docSet.Fields);
  return fields.reduce((a, b) => [...a, ...b], []);
};

identityValFunctions.docRequest = async (docId) => {
  let handleResponse = (response) => {
    let respObject = response.data;
    if (!respObject.IsOK) {
      let responseObject = {
        message:
          respObject.Messages +
          ' ' +
          (respObject.TansactionId ? respObject.TansactionId : '') +
          ' ' +
          (respObject.statusText ? respObject.statusText : ''),
      };
      let error = respObject.Body || responseObject;
      return Promise.reject(error);
    } else {
      console.log('response', respObject);
      return respObject;
    }
  };

  return axios
    .post(AppUrls.DownloadSection, Config.SetParameters(docId, '', '', ''), Config.ConfigAuth())
    .then(handleResponse);
};

export function getNameDocINE(data, dataStage) {
  // console.log(".????????????..........listDocument----??????: ", data, " --dataStage- ", dataStage)
  let listDocumentFilter = dataStage?.DocumentalCategory?.DocumentalGroups.filter((item) =>
    item.DocumentTypes.some((itemDoc) => itemDoc.Document != undefined),
  );
  // console.log(".????????????..........listDocumentFilter--some--??????: ", listDocumentFilter)
  let objectDocument = listDocumentFilter?.map(
    (item) => item.DocumentTypes?.filter((itemDoc) => itemDoc.Document != undefined)[0],
  )[0];
  console.log('.????????????..........objectDocument111114----??????: ', objectDocument);

  let nameFInd = objectDocument?.Document?.Name;
  // console.log("listDocument----: ", nameFInd, " --- ")
  let docNameVale = data
    ?.find((item) => item.Name != 'Formato autogenerado')
    ?.Documents?.find(
      (item) =>
        item.Name ===
        (nameFInd != null && nameFInd != undefined && nameFInd != '' ? nameFInd : 'INE / IFE'),
    )?.Name;
  console.log('getNameDocs: ', docNameVale);
  return {
    Name: docNameVale === undefined || docNameVale === null ? nameFInd : docNameVale,
    DocumentId: objectDocument?.Document?.Id,
    Properties: objectDocument?.Properties,
    Sections: objectDocument?.Sections,
  };
}
