// import { ReactComponent as ProcessConfigIcon } from 'assets/DOC/Icons/processConfig.svg'
import  ProcessConfigIcon  from 'assets/Metlife/Icons/processConfig.png'
import React from "react";
import {Box, Typography} from "@mui/material";
import {Card} from "./ConfigCards.styled";
import { UseImage } from 'components';

export const ConfigCard = ({configInfo, onClickConfig}) => {

  return(
    <Card onClick={() => onClickConfig(configInfo)}>
        <Box sx={{fill: "blue !important"}}><UseImage src={ProcessConfigIcon} style={{width: '7em'}}/></Box>
        <Typography variant={'h4'}>{configInfo.Description}</Typography>
        {/*<Typography variant={'span'}>{configInfo.description}</Typography>*/}
    </Card>
  );
}