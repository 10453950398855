import { fileConstants } from "../constants";
import { fileRequests } from "../../services";

export const fileActions = {};

fileActions.GetFileInfo = (data) => {
  return async (dispatch) => {
    if (!!!data) {
      dispatch(reset());
      return;
    }
    dispatch(request());
    try{
      const fileInfo = await fileRequests.GetFileInfo(data)
      dispatch(success(fileInfo))
    }catch(error){
      dispatch(failure(error.message))
    }
  };

  function request() {
    return { type: fileConstants.FILE_INFO_REQUEST };
  }

  function success(fileInfo) {
    return { type: fileConstants.FILE_INFO_SUCCESS, fileInfo };
  }

  function failure(error) {
    return { type: fileConstants.FILE_INFO_FAILURE, error };
  }

  function reset() {
    return { type: fileConstants.FILE_INFO_RESET };
  }
};

fileActions.GetSearchByUser = (data) => {
  return (dispatch) => {
    dispatch(request());
    fileRequests.GetSearchByUser(data).then(
      (userSearch) => dispatch(success(userSearch)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: fileConstants.FILE_SEARCH_USER_REQUEST };
  }

  function success(userSearch) {
    return { type: fileConstants.FILE_SEARCH_USER_SUCCESS, userSearch };
  }

  function failure(error) {
    return { type: fileConstants.FILE_SEARCH_USER_FAILURE, error };
  }
};

fileActions.GetUserPaginationSearch = (data) => {
  return (dispatch) => {
    dispatch(request());
    fileRequests.GetUserPaginationSearch(data).then(
      (userSearch) => dispatch(success(userSearch)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: fileConstants.FILE_SEARCH_USER_REQUEST };
  }

  function success(userSearch) {
    return { type: fileConstants.FILE_SEARCH_USER_SUCCESS, userSearch };
  }

  function failure(error) {
    return { type: fileConstants.FILE_SEARCH_USER_FAILURE, error };
  }
};

fileActions.GetInfoBySearch = (data) => {
  return (dispatch) => {
    dispatch(request());
    fileRequests.GetInfoBySearch(data).then(
      (searchInfo) => dispatch(success(searchInfo)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: fileConstants.FILE_SEARCH_REQUEST };
  }

  function success(searchInfo) {
    return { type: fileConstants.FILE_SEARCH_SUCCESS, searchInfo };
  }

  function failure(error) {
    return { type: fileConstants.FILE_SEARCH_FAILURE, error };
  }
};

fileActions.GetRecordInfo = (data) => {
  return (dispatch) => {
    dispatch(request());
    fileRequests.GetRecordInfo(data).then(
      (recordInf) => dispatch(success(recordInf)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: fileConstants.FILE_RECORD_REQUEST };
  }

  function success(recordInf) {
    return { type: fileConstants.FILE_RECORD_SUCCESS, recordInf };
  }

  function failure(error) {
    return { type: fileConstants.FILE_RECORD_FAILURE, error };
  }
};

fileActions.GetActorsInfo = (data) => {
  return (dispatch) => {
    dispatch(request());
    fileRequests.GetActorsInfo(data).then(
      (actors) => dispatch(success(actors)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: fileConstants.FILE_ACTORS_REQUEST };
  }

  function success(actors) {
    return { type: fileConstants.FILE_ACTORS_SUCCESS, actors };
  }

  function failure(error) {
    return { type: fileConstants.FILE_ACTORS_FAILURE, error };
  }
};

fileActions.GetActorInfo = (data) => {
  return (dispatch) => {
    dispatch(request());
    fileRequests.GetActorInfo(data).then(
      (actor) => dispatch(success(actor)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: fileConstants.FILE_ACTOR_REQUEST };
  }

  function success(actor) {
    return { type: fileConstants.FILE_ACTOR_SUCCESS, actor };
  }

  function failure(error) {
    return { type: fileConstants.FILE_ACTOR_FAILURE, error };
  }
};

fileActions.GenerateFolio = (data) => {
  return (dispatch) => {
    dispatch(request());
    fileRequests.GenerateFolio(data).then(
      (folio) => dispatch(success(folio)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: fileConstants.FILE_FOLIO_REQUEST };
  }

  function success(folio) {
    return { type: fileConstants.FILE_FOLIO_SUCCESS, folio };
  }

  function failure(error) {
    return { type: fileConstants.FILE_FOLIO_FAILURE, error };
  }
};

fileActions.FileUpdateMetadata = (data, isReset = false) => {
  return async (dispatch) => {
    if (isReset) {
      dispatch(reset());
    } else {
      try {
        dispatch(request());
        const folioUpdateData = await fileRequests.FileUpdateMetadata(data);
        dispatch(success(folioUpdateData));
      } catch (error) {
        dispatch(failure(error.message));
      }
    }
  };

  function request() {
    return { type: fileConstants.FILE_UPDATEMETADATA_REQUEST };
  }

  function success(folioUpdateData) {
    return { type: fileConstants.FILE_UPDATEMETADATA_SUCCESS, folioUpdateData };
  }

  function failure(error) {
    return { type: fileConstants.FILE_UPDATEMETADATA_FAILURE, error };
  }

  function reset() {
    return { type: fileConstants.FILE_UPDATEMETADATA_RESET };
  }
};

fileActions.GenerateFolioHiring = (data) => {
  return async (dispatch) => {
    dispatch(request());
    try {
      const folioGenData = await fileRequests.GenerateFolioHiring(data);
      dispatch(success(folioGenData));
      return folioGenData;
    } catch (error) {
      dispatch(failure(error.message));
    }
  };

  function request() {
    return { type: fileConstants.FILE_GENERATEFOLIOHIRING_REQUEST };
  }

  function success(folioGenData) {
    return {
      type: fileConstants.FILE_GENERATEFOLIOHIRING_SUCCESS,
      folioGenData,
    };
  }

  function failure(error) {
    return { type: fileConstants.FILE_GENERATEFOLIOHIRING_FAILURE, error };
  }
};

fileActions.AsignateCurrentUser = (data) => {
  return (dispatch) => {
    dispatch(request());
    fileRequests.AsignateCurrentUser(data).then(
      (dataUserCurent) => dispatch(success(dataUserCurent)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: fileConstants.GET_ASIGNATE_CURRENTUSER_REQUEST };
  }

  function success(dataUserCurent) {
    console.log(dataUserCurent);
    return {
      type: fileConstants.GET_ASIGNATE_CURRENTUSER_SUCCESS,
      dataUserCurent,
    };
  }

  function failure(error) {
    return { type: fileConstants.GET_ASIGNATE_CURRENTUSER_FAILURE, error };
  }
};

fileActions.GetFileEditCustomMetadata = (data) => {
  return (dispatch) => {
    dispatch(request());
    fileRequests.GetFileEditCustomMetadata(data).then(
      (dataUserCurent) => dispatch(success(dataUserCurent)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: fileConstants.GET_FILE_CUSTOMMETADTA_REQUEST };
  }

  function success(dataUserCurent) {
    console.log(dataUserCurent);
    return {
      type: fileConstants.GET_FILE_CUSTOMMETADTA_SUCCESS,
      dataUserCurent,
    };
  }

  function failure(error) {
    return { type: fileConstants.GET_FILE_CUSTOMMETADTA_FAILURE, error };
  }
};

fileActions.GetEstatusProceso = (data) => {
  return (dispatch) => {
    dispatch(request());
    fileRequests.GetEstatusProceso(data).then(
      (dataUserCurent) => dispatch(success(dataUserCurent)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: fileConstants.GET_STATUS_PROCESO_REQUEST };
  }

  function success(dataUserCurent) {
    console.log(dataUserCurent);
    return { type: fileConstants.GET_STATUS_PROCESO_SUCCESS, dataUserCurent };
  }

  function failure(error) {
    return { type: fileConstants.GET_STATUS_PROCESO_FAILURE, error };
  }
};

fileActions.FileUpdateMetadataAndRefresh = (data) => {
  return async (dispatch) => {
    try {
      dispatch(request());
      const folioUpdateData = await fileRequests.FileUpdateMetadata(data);
      dispatch(
        fileActions.GetFileInfo({
          FileId: data.ObjectId,
          WorkFlowInstanceId: sessionStorage.getItem("WorkFlowInstanceId"),
        }),
      );
      dispatch(success(folioUpdateData));
    } catch (error) {
      dispatch(failure(error.message));
    }
  };

  function request() {
    return { type: fileConstants.FILE_UPDATEMETADATA_REQUEST };
  }

  function success(folioUpdateData) {
    return { type: fileConstants.FILE_UPDATEMETADATA_SUCCESS, folioUpdateData };
  }

  function failure(error) {
    return { type: fileConstants.FILE_UPDATEMETADATA_FAILURE, error };
  }
};
