import { Config } from 'services/Config';

const { fetcher } = require('api/fetcher');

export const createDocumentActionId = async ({ arg: PAYLOAD }) =>
  await fetcher('document/CreateActionId', PAYLOAD, Config.ConfigAuth());

export const getValidateActionId = async (PAYLOAD) =>
  await fetcher('document/ValidateActionId', PAYLOAD, Config.ConfigNoAuth());

export const getDocumentalStructure = async (PAYLOAD) =>
  await fetcher('document/GetDocumentalStructure', PAYLOAD, Config.ConfigAuth());

export const uploadSectionGeneral = async ({ arg: PAYLOAD }) =>
  await fetcher('document/UploadSectionGeneral', PAYLOAD, Config.ConfigAuth());

export const getDocumentQuestions = async ({ arg: PAYLOAD }) =>
  await fetcher('document/GetDocumentQuestions', PAYLOAD, Config.ConfigAuth());

export const registerNewGrouper = async ({ arg: PAYLOAD }) =>
  await fetcher('document/RegisterDocumentation', PAYLOAD, Config.ConfigAuth());

export const SendNotificationUploadDocument = async ({ arg }) => {
  await fetcher('notification/SendNotificationUploadDocument', arg);
};

export const updateDocumentData = async ({ arg: PAYLOAD }) =>
  await fetcher('configuration/UpdateDocumentData', PAYLOAD, Config.ConfigAuth());

export const getDocumentType = async (PAYLOAD) =>
  await fetcher('configuration/GetDocumentType', PAYLOAD);

export const getDocumentTypeGroup = async (PAYLOAD) =>
  await fetcher('configuration/GetDocumentTypeGroup', PAYLOAD);

export const insertDocumentType = async (PAYLOAD) =>
  await fetcher('configuration/InsertDocumentType', PAYLOAD);

export const updateDocumentType = async (PAYLOAD) =>
  await fetcher('configuration/UpdateDocumentType', PAYLOAD);

export const deleteDocumentType = async (PAYLOAD) =>
  await fetcher('configuration/DeleteDocumentType', PAYLOAD);

export const getDocumentMetadata = async (PAYLOAD) =>
  await fetcher('configuration/GetDocumentMetadata', PAYLOAD);

export const getDocumentMetadataGroup = async (PAYLOAD) =>
  await fetcher('configuration/GetDocumentMetadataGroup', PAYLOAD);

export const insertDocumentMetadata = async (PAYLOAD) =>
  await fetcher('configuration/InsertDocumentMetadata', PAYLOAD);

export const updateDocumentMetadata = async (PAYLOAD) =>
  await fetcher('configuration/UpdateDocumentMetadata', PAYLOAD);

export const deleteDocumentMetadata = async (PAYLOAD) =>
  await fetcher('configuration/DeleteDocumentMetadata', PAYLOAD);
