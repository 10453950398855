import { Box, Button, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Alert, UseImage, UploadDcumentsControl } from '../../../components'
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import img from '../../../assets/DownloadExcel.png';
import { useDispatch, useSelector } from 'react-redux';
import { fileMasiveRequests } from '../../../services';
import { fileMasiveActions } from '../../../store/actions';
import { TableDataUsers } from './TableDataUsers';
import { useTranslation } from 'react-i18next';

export default function UploadDocumentInformation(props) {
    const dispatch = useDispatch();
    const dataUsers = useSelector((state) => state.GetUsersInformationMetadadaMasive);
    const [loadView, setloadView] = useState(false);
    const [dataUsersTable, setDataUsersTable] = useState();
    const formMethods = useForm({ mode: "onChange" });
    const navigation = useNavigate();
    const [documentsUpload, setDocumentsUpload] = useState();

    const { t } = useTranslation();

    let handleFileChange = async (FileContent, fileB64Response, extension) => {
        setDocumentsUpload(FileContent);
        try {
            dispatch(fileMasiveActions.GetUsersInformationMetadadaMasive({
                "BatchUpload_Id": props.dataGroup.Id,
                "StatusProceso": "Captura de datos por HR Analyst",
                "FileContentB64": fileB64Response
            }))
        } catch (error) {
            console.log(error)
        }
    };

    const dowloadDocument = async () => {
        const responseDoc = await fileMasiveRequests.DownloadDocumentContent(props.dataGroup.Id);
        console.log('responseDoc', responseDoc)
        const url = window.URL.createObjectURL(new Blob([responseDoc]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Template Datos Usuarios.xlsx");
        document.body.appendChild(link);
        link.click()
    }

    useEffect(() => {
        if (dataUsers?.items) {
            console.log('DATOS')
            setloadView(false);
            setDataUsersTable(dataUsers?.items?.Result)
        }
        if (dataUsers?.error) {
            setloadView(false);
        }
    }, [dataUsers.items])

    const sendDataMasive = () => {
        dispatch(fileMasiveActions.UploadUsersInformationMetadadaMasive({
            BatchMetadataId: dataUsers?.items?.BatchMetadataUpload_Id
        }));
        Alert({
            icon: "success",
            title: "Datos guardados correctamente",
        }, null, null, t);
        setloadView(false);
        navigation('/inbox/homeComponent')
    }

    useEffect(() => {
        setDataUsersTable();
    }, [])

    return (
        <>{dataUsersTable?.length > 0 ? (
            <Grid>
                <TableDataUsers data={dataUsersTable} />
                <Grid container display={'flex'} justifyContent='center' spacing={2}>
                    <Grid item lg={3} xs={12}>
                        <Button style={{
                            height: 38,
                            width: '100%',
                            margin: '1em',
                            borderRadius: '25px',
                            fontSize: 16,
                            fontWeight: 500
                        }} variant='contained' color="primary" onClick={() => sendDataMasive()}>
                            {t("general_continue")}
                        </Button>
                    </Grid>
                    <Grid item lg={3} xs={12}>
                        <Button style={{
                            height: 38,
                            width: '100%',
                            margin: '1em',
                            borderRadius: '25px',
                            fontSize: 16,
                            fontWeight: 500
                        }} variant='outlined' color="primary" onClick={() => setDataUsersTable([])}>
                            {t("edit_file")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
            : (
                <Grid container alignContent='center' alignItems='center' justifyContent='center'>
                    <Grid item lg={11} xs={12}>
                        <Box textAlign={'center'}>
                            <Typography variant='h6'>
                                Para capturar la información de contratación de uno o más empleados, puedes hacerlo mediante la descarga del Excel, el cual tendrá la relación de usuarios que han llegado hasta esta etapa, completa la información y carga nuevamente el Excel para avanzar a los siguientes pasos.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={1} xs={12}>
                        <Box>
                            <Tooltip title="Descargar el template de usuarios" placement="top">
                                <IconButton xs={{ width: 60 }} onClick={() => dowloadDocument()}>
                                    <UseImage src={img} type='img' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                    <Grid item lg={10} xs={12}>
                        <FormProvider {...formMethods}>
                            <UploadDcumentsControl
                                Excel={true}
                                Title={t("general_upload_your_file_xlsx")}
                                onChange={handleFileChange}
                            />
                        </FormProvider>
                    </Grid>
                    <Grid lg={5} xs={12}>
                        <Box display={'flex'} justifyContent='center'>
                            <Button style={{
                                height: 38,
                                width: '100%',
                                margin: '1em',
                                borderRadius: '25px',
                                fontSize: 16,
                                fontWeight: 500
                            }} variant='outlined' color="primary" onClick={() => props.setActualizacionMasiva(false)}>
                                {t("general_cancel")}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </>
    )
}
