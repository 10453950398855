import { Button, Grid, Typography, Box, styled, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { generateUniqueId } from "../utils";

export const Buttons = (props) => {
  const { t } = useTranslation()
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      sx={{
        mt: `${props.marginTop == undefined ? 10 : props.marginTop}px`,
        mb: `${props.marginBottom == undefined ? 1 : props.marginBottom}px`,
      }}
    >
      <Grid
        item
        xs={12}
        sm={8}
        sx={{ marginTop: '2px', textAlign: 'center', paddingTop: '2px !important', zIndex: 100 }}
      >
        <Button
          id={`btnConfirm_${generateUniqueId()}`}
          fullWidth
          variant="contained"
          sx={{ height: 45, px: 10, maxWidth: 300 }}
          onClick={props.onClickConfirm}
          disabled={props.disabledConfirm}
        >
          {props.ConfirmButtonText == undefined ? t('general_continue') : props.ConfirmButtonText}
        </Button>
      </Grid>
      {props.onClickCancel != undefined ? (
        <Grid
          item
          xs={12}
          sm={8}
          sx={{ marginTop: '2px', textAlign: 'center', paddingTop: '2px !important', zIndex: 100 }}
        >
          <Button
            id={`btnCancel_${generateUniqueId()}`}
            fullWidth
            variant="outlined"
            sx={{ height: 45, px: 10, maxWidth: 300 }}
            onClick={props.onClickCancel}
            disabled={props.disabledCancel}
          >
            {props.CancelButtonText == undefined ? t("general_cancel") : props.CancelButtonText}
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};

export const LinkButton = (props) => {
  return (
    <Box
      sx={{
        p: 1,
        textAlign: props.textAlign == undefined ? 'right' : props.textAlign,
        width: '90%',
        zIndex: 100,
        mb: `${props.marginBottom == undefined ? 1 : props.marginBottom}px`,
      }}
    >
      <Link id={`btnLink_${generateUniqueId()}`}
      style={{ textDecoration: 'none' }} to={props.LinkButtonUrl}>
        <Typography variant="body2" color="primary" sx={{ width: '100%', textAlign: 'center' }}>
          {props.LinkButtonText}
        </Typography>
      </Link>
    </Box>
  );
};

export const ButtonsLined = (props) => {
  const { t } = useTranslation()
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      sx={{
        mt: `${props.marginTop == undefined ? 10 : props.marginTop}px`,
        mb: `${props.marginBottom == undefined ? 1 : props.marginBottom}px`,
      }}
    >
      <Grid item xs={6} sm={6} sx={{ zIndex: 100 }}>
        <Button
          id={`btnConfirm_${generateUniqueId()}`}
          fullWidth
          variant="contained"
          sx={{ height: 35, px: 5 }}
          onClick={props.onClickConfirm}
          disabled={props.disabledConfirm}
        >
          {props.ConfirmButtonText == undefined ? t("general_continue") : props.ConfirmButtonText}
        </Button>
      </Grid>
      {props.onClickCancel != undefined ? (
        <Grid item xs={6} sm={6} sx={{ zIndex: 100 }}>
          <Button
            id={`btnCancel_${generateUniqueId()}`}
            fullWidth
            variant="outlined"
            sx={{ height: 35, px: 5 }}
            onClick={props.onClickCancel}
            disabled={props.disabledCancel}
          >
            {props.CancelButtonText == undefined ? t("general_cancel") : props.CancelButtonText}
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};

export const LoadingButton = ({ loading, children, onHandleClick }) => {
  const handleClick = () => {
    onHandleClick();
  };
  return (
    <Button onClick={handleClick} variant="contained" fullWidth disabled={loading}>
      {loading && (
        <CircularProgress
          color="inherit"
          size="1.4rem"
          sx={{
            marginRight: '0.5rem',
          }}
        />
      )}
      {children}
    </Button>
  );
};

export const GenericButtonList = styled(Box)(({ theme }) => ({
  width: '100% !important',
  padding: '16px',
  gap: '16px',
  minHeight: '56px',
  background: theme.palette.primary.lightContrast,
  border: `1px solid ${theme.palette.primary.contrastText}`,
  borderRadius: '8px',
  marginTop: '16px',
  '&:focus': {
    outline: 'none !important',
    background: theme.palette.primary.main80,
    border: `1px solid ${theme.palette.primary.main80}`,
  },
  '&:hover': {
    outline: 'none !important',
    background: theme.palette.primary.main60,
    border: `1px solid ${theme.palette.primary.main60}`,
  },
}));

export const RoundedButton = styled(Button)(({ theme }) => ({
  borderRadius: '2rem',
}));
