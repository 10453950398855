import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { BasicSearchFilter } from "../BasicSearchFilter";
import { getElasticSearch } from "api/files/filesRequests";
import { ElasticResultList } from "./ElasticResultList";
import { CustomLoadingOverlay } from "../../CustomLoadingOverlay";
import { useNavigate } from "react-router-dom";
import useSWR, { mutate } from "swr";
import { useTranslation } from "react-i18next";
import { StatusDot, StyledResultBox } from "./ElasticSerch.styled";
import { ResultCounterText } from "./ResultCounterText";

export const ElasticSearchIndex = () => {
  const { t } = useTranslation();
  const [loadingResults, setLoadingResults] = useState(false);
  const [searched, setSearched] = useState("");
  const [formData, setFormData] = useState({
    SearchFile: "",
    FullName: "",
    Folio: "",
    GEID: "",
    Area: "",
    JobPosition: "",
    Date: "",
    Status: "",
  });
  const navigate = useNavigate();

  const SEARCH_PAYLOAD = {
    SearchTerm: "",
  };

  const { data, error } = useSWR(
    "GetElasticSearch",
    (URL) => getElasticSearch(SEARCH_PAYLOAD),
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  //console.log({ searchResults: data });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputClear = (name) => {
    setFormData({
      ...formData,
      [name]: "",
    });
  };

  const handleClearAllFields = () => {
    setFormData(
      Object.fromEntries(Object.keys(formData).map((key) => [key, ""]))
    );
  };

  const handleSearch = async (filterSettings) => {
    try {
      setLoadingResults(true);
      setSearched(filterSettings?.SearchFile || "searched");
      SEARCH_PAYLOAD.SearchTerm = filterSettings?.SearchFile || "";
      await mutate("GetElasticSearch", getElasticSearch(SEARCH_PAYLOAD), false);
    } catch (error) {
    } finally {
      setLoadingResults(false);
    }
  };

  const handleClearResults = async () => {
    try {
      setLoadingResults(true);
      setSearched("");
      handleClearAllFields();
      await mutate("GetElasticSearch", undefined, false);
    } catch (error) {
    } finally {
      setLoadingResults(false);
    }
  };

  const handleResultClick = (result) => {
    sessionStorage.setItem("WorkFlowInstanceId", result.WorkFlowInstance_Id);
    if (result.ItemResult !== "EXP") {
      navigate(`/inbox/asignacion/${result.FileId}?tab=4&docId=${result.DocumentId}`);
    } else {
      navigate(`/inbox/asignacion/${result.FileId}`);
    }
  };

  React.useEffect(() => {
    return () => {
      handleClearResults();
    };
  }, []);

  return (
    <Box padding={{ xs: "0", md: "2rem" }}>
      <CustomLoadingOverlay active={loadingResults} />
      <BasicSearchFilter
        formData={formData}
        onInputChange={handleInputChange}
        onInputClear={handleInputClear}
        onSearch={handleSearch}
        onClearResults={handleClearResults}
      />
      <ResultCounterText result={data} />
      <Box
        display="flex"
        justifyContent={{ xs: "space-between", md: "flex-end" }}
        margin="1rem 0"
      >
        <Box
          display="flex"
          alignItems="center"
          margin={{ xs: 0, md: "0 .6rem" }}
        >
          <StatusDot color="#43A122" />
          <Typography>{t("search_result_list_current")}</Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          margin={{ xs: 0, md: "0 .6rem" }}
        >
          <StatusDot color="#FCC915" />
          <Typography>{t("search_result_list_upcoming")}</Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          margin={{ xs: 0, md: "0 .6rem" }}
        >
          <StatusDot color="#FE2727" />
          <Typography>{t("search_result_list_expired")}</Typography>
        </Box>
      </Box>
      <ElasticResultContainer
        data={data}
        error={error}
        searched={searched}
        handleResultClick={handleResultClick}
      />
    </Box>
  );
};

const ElasticResultContainer = ({
  data,
  error,
  searched,
  handleResultClick,
}) => {
  const { t } = useTranslation();

  if (!searched && !data?.length) return null;
  if (error && !data?.length) return <StyledResultBox>{error.message}</StyledResultBox>;
  if (!data?.length) return <StyledResultBox>{t("search_no_results")}</StyledResultBox>;

  return <ElasticResultList searchResults={data} searched={searched} onResultClick={handleResultClick} />
};