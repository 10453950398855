import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Typography} from "@mui/material";
import dayjs from "dayjs";
import { formatDateByLocale } from 'utils';

export const MainDocumentMetadataTable = ({docMetadata}) => {

  if (!docMetadata) return null;

  const tableRows = docMetadata.map((metadata) => {
    const value = metadata.Value;
    return {
      Name: metadata.QuestionDescription || metadata.Name,
      Value: dayjs(value).isValid() && isNaN(Number(value)) ? formatDateByLocale(value) : value,
    };
  });

  return (
    <TableContainer>
      <Table size="small">
        <TableBody>
          {tableRows.map((row) => (
            <TableRow>
              <TableCell sx={{ border: '1px solid lightgrey', borderRadius:'10px' }} width="50%">
                <Typography variant="p">{row.Name}</Typography>
              </TableCell>
              <TableCell sx={{ border: '1px solid lightgrey' }} width="50%">
                <Typography variant="span">{row.Value}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};