import React, { useCallback } from "react";
import { TextField as InputText, IconButton, styled } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const TextField = styled(InputText)(({ theme }) => ({

  "& .MuiOutlinedInput-input": {
    // height: 25,
    padding: "10px 15px",
    [theme.breakpoints.down("sm")]: {
      height: 18,
      padding: "8px 10px",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 0,
    fontSize: 14,
    width: "100%",
    "& fieldset legend span": {
      display: "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black", // Color del borde cuando está enfocado
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
      borderRadius: 0,
    },
  },
  "& .MuiInputLabel-root": {
    color: "#000",
    position: "relative",
    transform: "none",
    fontSize: "16px",
    // marginBottom: 7,
    // marginTop: 0,
    display: "flex",
    alignItems: "center",
    pointerEvents: "auto",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    fontFamily: '"UniversNextforHSBC-Regular", sans-serif',
    [theme.breakpoints.down("sm")]: {
      fontWeight: 400,
      fontSize: "9px",
      lineHeight: "144%",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black", // Color del borde cuando está enfocado
    },
  },
  /* "& label.Mui-focused": {
    color: theme.palette.primary.main,
  }, */
  "& label.Mui-error": {
    // color: "#A8000B !important",
    color: "#000 !important",
    // #A8000B
  },
  input: {
    "&::placeholder": {
      [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
        fontSize: "10px",
      },
    },
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A8000B !important",
    backgroundColor: "#F9F2F3",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    // color: '#A8000B !important',
    color: "#000 !important",
  },
  "& .MuiInputLabel-root.Mui-error": {
    // color: '#A8000B !important',
    color: "#000 !important",
  },
  "& .MuiFormLabel-root.Mui-error": {
    // color: '#A8000B !important', // Color de la etiqueta en caso de error
    color: "#000 !important",
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    backgroundColor: "#F9F2F3 !important",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A8000B !important",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-input": {
    backgroundColor: "transparent !important", // Fondo del texto transparente
  },
}));

export const ClearableTextField = ({
  name,
  type = "text",
  placeholder = "",
  value,
  onChange,
  onClear,
  ...props
}) => {
  const handleClear = useCallback(() => {
    onClear(name);
  }, [name, onClear]);

  return (
    <TextField
      name={name}
      fullWidth
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      InputProps={{
        endAdornment: value && (
          <IconButton size="small" onClick={handleClear}>
            <ClearIcon />
          </IconButton>
        ),
      }}
      {...props}
    />
  );
};
