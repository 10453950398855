import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Box, Typography, Button } from "@mui/material";
import { fileActions, productActions } from "../../store/actions";
import protect from "../../utils/protect";
import {
  FormDynamic,
  Alert,
  InformationalMessageComponent,
  CustomLoadingOverlay,
} from "../../components";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import InboxRegistrationsCandidates from "./InboxRegistrationsCandidates";
import { useTranslation } from "react-i18next";
import { createLoadingSelector } from "../../store/selectors/LoadingSelectors";
import { translatedQuestionsAdapter } from "adapters/translatedQuestionsAdapter";

function RegistrationCandidatesComponent(props) {
  const navigation = useNavigate();
  const [listControls, setListControls] = useState(null);
  const [isRegister, setIsRegister] = useState(null);
  const [dataCurrent, setDataCurrent] = useState(null);
  const [loadingButton, setLoadingButton] = useState(true);
  const [tenantActive, setTenantActive] = useState(false);

  const { t } = useTranslation();

  const productData = useSelector((state) => state.ProductHiringData);
  const generateFolioData = useSelector(
    (state) => state.GenerateFolioHiringData
  );
  const selectedLanguage = useSelector((state) => state.SetDefualtLanguage);
  const dispatch = useDispatch();

  const LOADING_STATES = ["FILE_GENERATEFOLIOHIRING"];
  const isLoading = useSelector(createLoadingSelector(LOADING_STATES));

  useEffect(() => {
    console.log();
    dispatch(productActions.GetProducts(selectedLanguage.lang));
  }, [selectedLanguage]);

  useEffect(() => {
    if (productData.items !== undefined) {
      // console.log("productData--- Items-----------: ", productData.items)
      if (productData.items.length == 1) {
        translatedQuestionsAdapter(productData.items[0].QuestionDefinitions);
        setListControls(productData.items[0]);
        setTenantActive(false);
      } else {
        setListControls(productData.items);
        setTenantActive(true);
      }
    } else if (productData.error !== undefined) {
      console.log(productData.error);
      // AlertCustom({
      //   icon: 0,
      //   title: productData.error,
      // });
    }
  }, [productData]);

  useEffect(() => {
    if (generateFolioData.items !== undefined && isRegister) {
      // console.log("generateFolioData--- Items-----------: ", generateFolioData)
      setDataCurrent(generateFolioData.items);
      setListControls(null);
      setIsRegister(false);
      setLoadingButton(!loadingButton);
      Alert(
        {
          icon: "success",
          text: t("successful_registration"),
        },
        null,
        null,
        t
      );
      navigation("/inbox/homeComponent");

      // dispatch(productActions.GetProductHiring(""))
    } else if (generateFolioData.error !== undefined && isRegister) {
      setIsRegister(false);
      setLoadingButton(!loadingButton);
      Alert(
        {
          icon: 0,
          text: generateFolioData.error,
        },
        null,
        null,
        t
      );
    }
  }, [generateFolioData]);

  const handleSaveFile = async (formData, e) => {
    let newListMetdata = [];

    listControls.QuestionDefinitions.forEach((itemMetadata, i) => {
      /* newListMetdata.push({
        GroupName: itemMetadata.QuestionGroup,
        Name: itemMetadata.Name,
        Value: formData[itemMetadata.Id],
      }); */
      if (itemMetadata.Name == "Primer Nombre") {
        newListMetdata.push({
          GroupName: itemMetadata.QuestionGroup,
          Name: "Name",
          Value: formData[itemMetadata.Id],
        });
      } else if (itemMetadata.Name == "Segundo Nombre") {
        newListMetdata.push({
          GroupName: itemMetadata.QuestionGroup,
          Name: "SecondName",
          Value: formData[itemMetadata.Id],
        });
      } else if (itemMetadata.Name == "Primer Apellido") {
        newListMetdata.push({
          GroupName: itemMetadata.QuestionGroup,
          Name: "FatherLastName",
          Value: formData[itemMetadata.Id],
        });
      } else if (itemMetadata.Name == "Segundo Apellido") {
        newListMetdata.push({
          GroupName: itemMetadata.QuestionGroup,
          Name: "MotherLastName",
          Value: formData[itemMetadata.Id],
        });
      } else if (itemMetadata.Name == "Correo electrónico") {
        newListMetdata.push({
          GroupName: itemMetadata.QuestionGroup,
          Name: "Email",
          Value: formData[itemMetadata.Id],
        });
      } else if (itemMetadata.Name == "Teléfono de Contacto") {
        newListMetdata.push({
          GroupName: itemMetadata.QuestionGroup,
          Name: "PhoneNumber",
          Value: formData[itemMetadata.Id],
        });
      } else {
        newListMetdata.push({
          GroupName: itemMetadata.QuestionGroup,
          Name: itemMetadata.Name,
          Value: formData[itemMetadata.Id],
        });
      }
    });

    let requestData = {
      // Name: formData[listControls.QuestionDefinitions?.find(item => item.Name == "Primer Nombre")?.Id],
      // SecondName: formData[listControls.QuestionDefinitions?.find(item => item.Name == "Segundo Nombre")?.Id],
      // FatherLastName: formData[listControls.QuestionDefinitions?.find(item => item.Name == "Primer Apellido")?.Id],
      // MotherLastName: formData[listControls.QuestionDefinitions?.find(item => item.Name == "Segundo Apellido")?.Id],
      // Email: formData[listControls.QuestionDefinitions?.find(item => item.Name == "Correo electrónico")?.Id],
      // PhoneNumber: formData[listControls.QuestionDefinitions?.find(item => item.Name == "Teléfono de Contacto")?.Id],//formData["Teléfono"],
      Metadata: newListMetdata?.length > 0 ? newListMetdata : null,
      ProductId: listControls.Id,
    };
    
    // console.log('RESULT:',formData,requestData)

    const data = await dispatch(fileActions.GenerateFolioHiring(requestData));
    sessionStorage.setItem(
      "WorkFlowInstanceId",
      JSON.stringify(data?.Body?.WorkFlow?.WorkflowInstance?.Id)
    );
    setLoadingButton(!loadingButton);
    setIsRegister(true);
    navigation(`/inbox/asignacion/${data?.Body?.Result?.Id}`);
  };

  const handleResset = (e) => {
    // console.log("Ejecuta action resset:")
    setDataCurrent(null);
    Alert(
      {
        icon: "success",
        text: t("alert_activated_user"),
      },
      null,
      null,
      t
    );
    dispatch(productActions.GetProductHiring(""));
  };

  return (
    <div>
      {productData.loading ? (
        <CustomLoadingOverlay
          active={productData.loading}
          text={t("general_loading_message")}
        />
      ) : listControls !== null ? (
        <>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {console.log("listControls", listControls)}
            {/* {listControls.Id == 4 && listControls.Name == "Contratación Colombia Estándar" ? null : ( */}
            <Box sx={{ m: 1 }}>
              {/*<Button*/}
              {/* id="btnCandidateMsv" */}
              {/*  variant="contained"*/}
              {/*  startIcon={<Icon fontSize={25} icon="ph:users-three" />}*/}
              {/*  sx={{ height: 45, px: 5, maxWidth: 300 }}*/}
              {/*  onClick={() => {*/}
              {/*    navigation('/inbox/bulkUsers', { state: { ProductId: listControls.Id, WorkFlowName: listControls.Name } })*/}
              {/*  }} >*/}
              {/*  {t("candidates_massive")}*/}
              {/*</Button>*/}
            </Box>
            {tenantActive == true ? (
              <Box sx={{ m: 1 }}>
                <Button
                  id="btnCandidatesReturn"
                  variant="outlined"
                  startIcon={<Icon fontSize={25} icon="ph:users-three" />}
                  sx={{ height: 45, px: 5, maxWidth: 300 }}
                  onClick={() => setListControls(productData.items)}
                >
                  {t("login_return")}
                </Button>
              </Box>
            ) : null}
          </Box>
          <FormDynamic
            TextButton={t("general_continue")}
            loadingButton={isLoading}
            DataControls={listControls}
            handleSave={handleSaveFile}
          />
        </>
      ) : listControls !== null && listControls.length > 1 ? (
        <InboxRegistrationsCandidates
          Data={listControls}
          setListControls={setListControls}
        />
      ) : null}
    </div>
  );
}

export default protect(RegistrationCandidatesComponent);
