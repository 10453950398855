import { Box, Stack, Typography } from '@mui/material';
import { CustomLoadingOverlay, RoundedButton } from 'components';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { getDownloadBatchData } from 'api/massiveDownload/massiveDownloadRequests';
import { OBTableCell, OBTableRow } from 'components/common/Table/Table.styled';
import React from 'react';
import MainTable from 'components/common/Table';
import { formatDateByLocale } from 'utils';

export const DownloadBatchData = ({ handleMode }) => {

  const { t } = useTranslation();

  const { data: batchData, isValidating } = useSWR('GetDownloadBatchData', () => getDownloadBatchData(), {
    refreshInterval: 0,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const columns = [
    { id: "Folio de descarga", label: t("massive_download_table_header_download_folio"), minWidth: 170 },
    { id: "Tipo de documento", label: t("massive_download_table_header_doc_type"), minWidth: 170 },
    { id: "Fecha de descarga", label: t("massive_download_table_header_download_date"), minWidth: 170 },
    { id: "Estatus del documento", label: t("massive_download_table_header_doc_status"), maxWidth: 170, textAlign: "center", },
  ];

  const handleNewCharge = () => {
    handleMode('DETAILS_FORMAT_UPLOAD');
  }

  const render = (row) => (
    <OBTableRow hover role="checkbox">
      <OBTableCell>{row['Folio de descarga']}</OBTableCell>
      <OBTableCell>{row['Tipo de documento']}</OBTableCell>
      <OBTableCell>{formatDateByLocale(row['Fecha de descarga'])}</OBTableCell>
      <OBTableCell>{t(row['Estatus del documento'])}</OBTableCell>
    </OBTableRow>
  );

  return (
    <Stack spacing={'2rem'}>
      <CustomLoadingOverlay active={isValidating}></CustomLoadingOverlay>
      <Box display={'flex'} gap={'3rem'}>
        <Typography variant={'span'}>
          {t("massive_download_description")}
        </Typography>
        <RoundedButton variant={'contained'} sx={{ minWidth: '15%', ml: 'auto' }} onClick={handleNewCharge}>{t("massive_download_new_download")}</RoundedButton>
      </Box>
      <MainTable
        rows={batchData?.DownloadBatch}
        columns={columns}
        render={render}
        rowsSize={'small'}
        rowPerPage={10}
      />
    </Stack>
  );
};
