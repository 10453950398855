import React, { useState } from 'react';
import { FaceValidation } from './Steps/FaceValidation';
import { DocumentValidation } from './Steps/DocumentValidation';
import LifeTestHelp from './Steps/LifeTestHelp';
import { ExtractionForm } from './Steps/OCRValidation';

const IdentityValidation = () => {
  const [currentStep, setCurrentStep] = useState('DOCUMENT_VALIDATION');

  const handleChangeCurrentStep = (step) => setCurrentStep(step);

  const IDENTITY_VALIDATION_STEPS = {
    DOCUMENT_VALIDATION: <DocumentValidation handleChangeCurrentStep={handleChangeCurrentStep} />,
    FACE_VALIDATION_INSTRUCTIONS: (
      <LifeTestHelp handleChangeCurrentStep={handleChangeCurrentStep} />
    ),
    FACE_VALIDATION: <FaceValidation handleChangeCurrentStep={handleChangeCurrentStep} />,
    OCR_EXTRACTION: <ExtractionForm />,
  };
  return <>{IDENTITY_VALIDATION_STEPS[currentStep]}</>;
};

export default IdentityValidation;
