import {
  Box,
  Button,
  ButtonGroup,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { RelationTableAdvanced } from "components/relations/RelationTableAdvanced";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { OBIconButton } from "components/common/Buttons/IconButton/IconButton.styled";
// import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import {
  exportRelationFile,
  getRelationsFile,
} from "api/relations/relationsRequest";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RelationList } from "components/relations/RelationList";
import { CustomLoadingOverlay } from "components/CustomLoadingOverlay";
import NoDataRelationsTab from "../../../assets/NoDataRelationsTab.png";
import { UseImage } from "components";
import { IsDevice } from "utils";
import { makeStyles } from "@mui/styles";

const BtnCustom = styled(Button)(({ theme }) => ({
  borderRadius: "0px !important",
  "&:hover": {
    background: "rgba(191, 191, 191, 0.21)",
  },
}));

const useClasses = makeStyles((theme) => ({
  stackMain: {
    flexDirection: "column",
    width: "100%",
    gap: "1rem",
  },
  stackHeader: {
    flexDirection: "row !important",
    justifyContent: "space-between !important",
    alignItems: "flex-start !important",
  },
  boxTitle: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  boxButtonExport: {
    /*  display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": { cursor: "pointer" }, */
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    gap: "1rem",
  },
  boxIconExport: {
    border: "1.5px solid #4E7FC8",
    padding: "0.5rem !important",
    borderRadius: 100,
    color: "#4E7FC8",
  },
  stackBody: {
    gap: "1rem",
    paddingTop: "16px",
  },
  stackNoData: {
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    height: "100%",
  },
  imageNoData: {
    width: "20rem",
  },
  boxButtonsView: {
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },
}));

export const RelationsTab = ({ onReturn }) => {
  const classes = useClasses();
  const { IsMobile } = IsDevice();
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const fileInfo = useSelector((state) => state.FileInfo?.items);
  const [viewMode, setViewMode] = useState(
    IsMobile ? "LIST_VIEW" : "TALBE_VIEW",
  );
  const PAYLOAD = {
    Parameter: "File_Id",
    Value: id,
  };

  const {
    data: RelationsData,
    isValidating,
    mutate,
  } = useSWR("GetRelationsFiles", () => getRelationsFile(PAYLOAD), {
    refreshInterval: 0,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

/*   const { isMutating, trigger } = useSWRMutation(
    "ExportRelationFile",
    (url, payload) => exportRelationFile(payload),
  ); */

  const handleRedirectAssigment = (row) => {
    navigate(`/inbox/asignacion/${row.File_Id}`);
    sessionStorage.setItem("WorkFlowInstanceId", row.WorkflowInstanceId);
    onReturn({ id: row.File_Id, wf: row.WorkflowInstanceId });
  };

  const handleChangeView = (viewMode) => {
    setViewMode(viewMode);
  };

  const handleExportExcel = async () => {
    const request = { Body: { Data: RelationsData } };
    const url =
      "https://ob-api.techhub.mx/OBTechhubDev/WebApi/api/relationFile/GenerateExcelFromRelationFile";

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.blob())
      .then((blob) => {
        console.log("Response excel:", blob);
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${t("general_relations")}-${RelationsData?.[0]?.Folio}.xlsx`);

        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error al descargar el archivo:", error);
      });
    /* try {
      await trigger(request).then((asd)=>{console.log('ASD:::', asd)});
      console.log("Rdy", isMutating);
    } catch (error) {
      console.log("Error", error);
    } */
  };

  /*   const handleExporExcel = () => {
    ticketRequest
      .ExportTicketExcel({ File_Id: infoFile?.items?.Id })
      .then((response) => {
        console.log("Response excel:", response);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Seguimiento.xlsx");

        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.error("Error al descargar el archivo:", error));
  }; */

  const handleUpdate = async () => {
    console.log('Refresh page')
    await mutate();
  };

  const RENDER_COMPONENT_BY_MODE = {
    LIST_VIEW: (
      <RelationList
        tableData={RelationsData?.[0]?.Relacion}
        onClickView={handleRedirectAssigment}
        IsMobile={IsMobile}
      />
    ),
    TALBE_VIEW: (
      <RelationTableAdvanced
        tableData={RelationsData?.[0]?.Relacion}
        onClickView={handleRedirectAssigment}
      />
    ),
  };

  return (
    <>
      <CustomLoadingOverlay
        active={isValidating}
        text={t("general_loading_message")}
      />
      {RelationsData?.[0]?.Relacion.length > 0 ? (
        <Stack className={classes.stackMain}>
          <Stack className={classes.stackHeader}>
            <Box classes={classes.boxTitle}>
              <Typography variant="h5">{t("relation_tab_title")}</Typography>
              <Typography variant="body">
                {t("relation_tab_description")}{/* {fileInfo?.Folio} -  */}{fileInfo?.FullName}
              </Typography>
            </Box>
            {!IsMobile && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                gap="1rem"
              >
                <Tooltip title={t("general_export")} placement="top" arrow>
                  <OBIconButton onClick={handleExportExcel}>
                    <FileDownloadOutlinedIcon />
                  </OBIconButton>
                </Tooltip>
                <Tooltip title={t("request_refresh")} placement="top" arrow>
                  <OBIconButton onClick={handleUpdate}>
                    <CachedOutlinedIcon />
                  </OBIconButton>
                </Tooltip>
              </Box>
            )}
          </Stack>
          <Stack className={classes.stackBody}>
            {!IsMobile && (
              <ButtonsView
                onChangeView={handleChangeView}
                currentView={viewMode}
                t={t}
              />
            )}
            {RENDER_COMPONENT_BY_MODE[viewMode]}
          </Stack>
        </Stack>
      ) : (
        <Stack className={classes.stackNoData}>
          <UseImage src={NoDataRelationsTab} className={classes.imageNoData} />
          <Typography>{t("relation_tab_no_data")}</Typography>
        </Stack>
      )}
    </>
  );
};

const ButtonsView = ({ onChangeView, currentView, t }) => {
  const classes = useClasses();
  return (
    <Box className={classes.boxButtonsView}>
      <ButtonGroup variant="outlined" color={"borderBlue"/* "borderRed" */}>
        <Tooltip title={t("general_table")} placement="top" arrow>
          <BtnCustom
            variant="outlined"
            onClick={() => {
              onChangeView("TALBE_VIEW");
            }}
            sx={
              currentView === "TALBE_VIEW" && {
                backgroundColor: "rgba(191, 191, 191, 0.21)",
              }
            }
          >
            <Icon icon={"fluent:table-28-regular"} fontSize="1.5rem" />
          </BtnCustom>
        </Tooltip>
        <Tooltip title={t("general_list")} placement="top" arrow>
          <BtnCustom
            variant="outlined"
            onClick={() => {
              onChangeView("LIST_VIEW");
            }}
            sx={
              currentView === "LIST_VIEW" && {
                backgroundColor: "rgba(191, 191, 191, 0.21)",
              }
            }
          >
            <Icon icon={"material-symbols:menu"} fontSize="1.5rem" />
          </BtnCustom>
        </Tooltip>
      </ButtonGroup>
    </Box>
  );
};
