import { Box, Typography } from '@mui/material';
import { registerBatch } from 'api/massiveFlow/massiveFlowRequests';
import { AlertModal } from 'components/common/Modals/Views/AlertModal';
import UploadSlot from 'components/common/UploadSlot';
import { CustomLoadingOverlay } from 'components/CustomLoadingOverlay';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSWRMutation from 'swr/mutation';

export const ZipUploader = ({ handleMode, templateFile }) => {
  const { t } = useTranslation()
  const { isMutating, trigger } = useSWRMutation(
    'RegisterBatch',
    (url, payload) => registerBatch(payload),
    {
      populateCache: true,
      onSuccess: () => handleMode('UPLOAD_STATUS'),
    },
  );

  const handleSendTemplateFiles = async (fileB64) => {
    try {
      const PAYLOAD = {
        ZipName: 'Test',
        ZipExtension: '.zip',
        ZipBase64: fileB64,
        TemplateBase64: templateFile.fileB64,
      };
      await trigger(PAYLOAD);
    } catch (err) {
      AlertModal('Error', err.message, null, t);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem', height: '100%' }}>
      <CustomLoadingOverlay active={isMutating} />
      <Typography variant="span">
        {t("zip_uploader_description")}
      </Typography>

      <UploadSlot
        allowedExtensions={['zip']}
        onSendData={(fileB64, extensionCurrent) => handleSendTemplateFiles(fileB64)}
      />
    </Box>
  );
};
