import { fetcher } from 'api/fetcher';
import {
  GET_BATCH_DATA_PATH,
  GET_ALL_DOCUMENTS_PATH,
  GENERATE_TEMPLATE_PATH,
  GET_BATCH_FILE_DATA_PATH,
  REGISTER_BATCH_PATH,
  UPLOAD_BATCH_PATH,
} from './urls';

export const getBatchData = async (PAYLOAD) => await fetcher(GET_BATCH_DATA_PATH, PAYLOAD);

export const getAllDocuments = async () => await fetcher(GET_ALL_DOCUMENTS_PATH, null);

export const generateTemplate = async (PAYLOAD) => await fetcher(GENERATE_TEMPLATE_PATH, PAYLOAD);

export const registerBatch = async ({ arg: PAYLOAD }) =>
  await fetcher(REGISTER_BATCH_PATH, PAYLOAD);

export const getBatchFileData = async (PAYLOAD) => await fetcher(GET_BATCH_FILE_DATA_PATH, PAYLOAD);

export const uploadBatch = async ({ arg: PAYLOAD }) => await fetcher(UPLOAD_BATCH_PATH, PAYLOAD);
