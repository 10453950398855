import {
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from '@mui/material';
import { RoundedButton } from '../../../Buttons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from 'mappings/LanguageMappings';
import { Icon } from '@iconify/react';

const ModalContainer = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    '& .MuiDialog-paper': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      gap: '2rem',
      alignItems: 'center',
      minWidth: '20%',
      maxWidth: '300px',
      padding: '2rem',
    },
    '& .MuiDialogContent-root': {
      flex: 'none',
    },
  },
}));

export const MobileUploadLanguageModal = ({ open, onClose }) => {
  const [selectedLanguage, setSelectedLanguage] = useState('es');
  const { i18n, t } = useTranslation();

  const handleLanguageChange = (e) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
    setSelectedLanguage(lang_code);
  };

  return (
    <ModalContainer open={open}>
      <FormControl>
        <FormLabel>
          <Typography textAlign={'left'}>
            {t('mobile_select_language_modal_instructions')}
          </Typography>
        </FormLabel>
        <RadioGroup value={selectedLanguage} onChange={handleLanguageChange}>
          {LANGUAGES.map((language) => (
            <FormControlLabel
              value={language.code}
              control={<Radio />}
              label={
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                  <Icon
                    icon={language.icon}
                    fontSize="2rem"
                    style={{ borderRadius: '3rem', border: '1px solid gray' }}
                  />
                  <Typography>{language.label}</Typography>
                </div>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
      <RoundedButton variant="contained" onClick={onClose} sx={{ minWidth: '10rem' }}>
        {t('mobile_select_language_modal_continue')}
      </RoundedButton>
    </ModalContainer>
  );
};
