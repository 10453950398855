import ConfigSelection from 'components/configuration/ConfigSelection';
import { useState } from 'react';

export const Configuration = ({ children }) => {
  const [isConfigurationSelected, setIsConfigurationSelected] = useState(false);

  // const handleStorageChange = () => {
  //   const configurationData = sessionStorage.getItem('configurationData');
  //   setIsConfigurationSelected(configurationData);
  // };
  // window.addEventListener('storage', handleStorageChange);

  const handleShowTabs = () => setIsConfigurationSelected(true);

  if (!isConfigurationSelected) return <ConfigSelection handleShowTabs={handleShowTabs} />;
  return <>{children}</>;
};
