export const workFlowConstants = {
  WF_BINNACLE_REQUEST: 'WF_BINNACLE_REQUEST',
  WF_BINNACLE_SUCCESS: 'WF_BINNACLE_SUCCESS',
  WF_BINNACLE_FAILURE: 'WF_BINNACLE_FAILURE',

  CONTINUE_WF_REQUEST: 'CONTINUE_WF_REQUEST',
  CONTINUE_WF_SUCCESS: 'CONTINUE_WF_SUCCESS',
  CONTINUE_WF_FAILURE: 'CONTINUE_WF_FAILURE',
  CONTINUE_WF_RESET: 'CONTINUE_WF_RESET',
  

  REG_STATUSSTAGE_REQUEST: 'REG_STATUSSTAGE_REQUEST',
  REG_STATUSSTAGE_SUCCESS: 'REG_STATUSSTAGE_SUCCESS',
  REG_STATUSSTAGE_FAILURE: 'REG_STATUSSTAGE_FAILURE',
}
