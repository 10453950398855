import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  insertDocumentMetadata,
  updateDocumentMetadata,
} from "api/document/documentRequests";
import { useTranslation } from "react-i18next";
import { DataTypes } from 'components/configuration/ConfigDocumentMetadata/dataTypes';
export const AddDocumentMetadataForm = ({
  handleMode,
  selectedDocument,
  selectedDocumentMetadata,
}) => {

  const { t } = useTranslation()

  const [formData, setFormData] = useState({
    DocumentTypeId: selectedDocument.Id,
    TagCapture: selectedDocumentMetadata?.TagCapture || "",
    TagType: selectedDocumentMetadata?.TagType || "",
    Required: selectedDocumentMetadata?.Required,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSendForm = async () => {
    try {
      const payload = selectedDocumentMetadata
        ? { MetadataId: selectedDocumentMetadata.MetadataId, ...formData }
        : formData;

      selectedDocumentMetadata
        ? await updateDocumentMetadata(payload)
        : await insertDocumentMetadata(payload);

      handleMode("DOCUMENT_METADATA_VIEW");
    } catch (error) { }
  };

  const isButtonDisabled = Object.values(formData)
    .filter(value => value !== false)
    .some(value => !value);

  return (
    <Box>
      <Typography variant="span">
        {t("configuration_docStructure_add_doc_metadata_instruction")}
      </Typography>
      <Box display="flex" flexDirection="column" gap="1rem" margin="2rem 0rem">
        <Typography variant={"subtitle2"}>{t("configuration_docStructure_add_metadata_grouper")}</Typography>
        <TextField value={selectedDocument.DocumentGroup} disabled />
        <Typography variant={"subtitle2"}>{t("configuration_docStructure_add_metadata_doc_type")}</Typography>
        <TextField value={selectedDocument.Name} disabled />
        <Typography variant={"subtitle2"}>{t("configuration_docStructure_add_metadata_field_label")}</Typography>
        <TextField
          name="TagCapture"
          value={formData["TagCapture"]}
          onChange={handleChange}
          placeholder={t("configuration_docStructure_add_metadata_enter_label")}
        />
        <Typography variant={"subtitle2"}>{t("configuration_docStructure_add_metadata_data_type")}</Typography>
        <Select
          defaultValue={formData?.TagType || "default"}
          name="TagType"
          onChange={handleChange}
        >
          <MenuItem value="default" disabled>
            {t("configuration_docStructure_add_metadata_select_data_type")}
          </MenuItem>
          {DataTypes?.map((option) => (
            <MenuItem key={option.Type} value={option.Type}>
              {t(option.Label)}
            </MenuItem>
          ))}
        </Select>
        <Typography variant={"subtitle2"}>
          {t("configuration_docStructure_add_doc_metadata_is_field_mandatory")}
        </Typography>
        <RadioGroup
          row
          name="Required"
          value={formData['Required']}
          onChange={handleChange}
        >
          <FormControlLabel value={true} control={<Radio />} label={t("general_yes")} />
          <FormControlLabel value={false} control={<Radio />} label={t("general_no")} />
        </RadioGroup>
        <Box display="flex" justifyContent="center" gap="2rem" height="3rem">
          <Button
            sx={{ borderRadius: "2rem", minWidth: "20%" }}
            variant="outlined"
            onClick={() => {
              handleMode("DOCUMENT_METADATA_VIEW");
            }}
          >
            {t("general_back")}
          </Button>
          <Button
            sx={{ borderRadius: "2rem", minWidth: "20%" }}
            variant="contained"
            onClick={handleSendForm}
            disabled={isButtonDisabled}
          >
            {t("general_save")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
