import React from 'react';
import { SignAuthorization } from './Steps/SignAuthorization';
import { SignHelp } from './Steps/SignHelp';
import { SignPad } from './Steps/SignPad';
import { useSign } from 'hooks/LEGOS/Sign/useSign';
import OTPForm from '../OTP/OTPForm';
import DocumentViewer from 'components/common/DocumentViewer';
import { Box, Typography } from '@mui/material';
import { signStyles } from './Sign.styles';

const Sign = () => {
  const {
    currentDocumentId,
    mode,
    checkState,
    handleSetMode,
    handleCheckState,
    handleAcceptDocument,
  } = useSign();

  const TYPE_COMPONENT = {
    DOCUMENT_SIGN: (
      <SignAuthorization
        handleAcceptDocument={handleAcceptDocument}
        checkState={checkState}
        handleCheckState={handleCheckState}
      />
    ),
    SIGN_HELP: <SignHelp handleSetMode={handleSetMode} />,
    CANVAS_SIGN: <SignPad handleSetMode={handleSetMode} />
  };

  return (
    <Box sx={signStyles.container}>
      <Typography variant="h4" component="h4">
        Lee y autoriza el documento para continuar.
      </Typography>

      <Box sx={signStyles.contentWrapper}>
        <Box sx={signStyles.documentViewer}>
          <DocumentViewer documentId={currentDocumentId} />
        </Box>
        <Box sx={signStyles.componentWrapper}>{TYPE_COMPONENT[mode]}</Box>
      </Box>
    </Box>
  );
};

export default Sign;
