import React, { useEffect, useState, useMemo } from 'react';
import { useTheme, Typography, Dialog, Box, IconButton, Button } from '@mui/material';
import { CustomNativeCamera, UseImage, CustomAlertHelpDialog } from 'components';
import { useTranslation } from 'react-i18next';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
// import { ReactComponent as RequiredDocument } from 'assets/DOC/Icons/RequiredDocuments.svg';
import RequiredDocument from 'assets/Metlife/Icons/RequiredDocuments.png';
import maskCaptureDocumentImage from 'assets/maskCaptureDocument.png';
import styled from '@emotion/styled';
import { blobToBase64 } from 'utils';
import { HelpDialog } from 'components/common/HelpDialog/HelpDialog';

const ContainerCamera = styled(Box)(({ theme }) => ({
  background: '#FFFFFF',
  marginBottom: '0px',
  overflowY: 'hidden',
  minHeight: theme.breakpoints.down('sm') ? '90vh' : 'auto',
}));

export const FullCameraCapture = ({ open, onClose, onSendData, sectionName, DocName, capture }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [photoTaken, setPhotoTaken] = useState(false);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [showHelp, setShowHelp] = useState(true);

  const handleCapture = (blob) => setPhotoPreview(blob);

  const handleAccept = () => {
    if (photoPreview) {
      blobToBase64(photoPreview).then((res) => {
        const result = res.replace('data:image/jpeg;base64,', '');
        onSendData(result, 'jpeg');
        resetCamera();
      });
    }
  };

  const resetCamera = () => {
    setPhotoTaken(false);
    setPhotoPreview(null);
  };

  const controlCameraCustom = useMemo(
    () => (
      <CustomNativeCamera
        FacingMode="environment"
        isDisplay={!photoPreview}
        Mirrored={false}
        width={navigator.userAgent.match(/iPhone|Android/i) ? 700 : 1100}
        onCapture={handleCapture}
        CaptureInit={photoTaken}
      />
    ),
    [photoTaken, photoPreview],
  );

  useEffect(() => {
    resetCamera();
  }, [open]);

  return (
    <Dialog fullScreen onClose={onClose} open={open}>
      <ContainerCamera>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          {photoTaken && photoPreview && (
            <img
              src={URL.createObjectURL(photoPreview)}
              alt="preview"
              style={{
                height: '97vh',
                marginBottom: '13%',
                paddingBottom: '3%',
                maxHeight: '100vh',
              }}
            />
          )}

          {controlCameraCustom}

          {navigator.userAgent.match(/iPhone|Android/i) && !photoPreview && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                height: '88vh',
                width: '100%',
                top: '-10px',
                zIndex: 10,
              }}
            >
              <UseImage src={maskCaptureDocumentImage} />
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.primary.backG,
            position: 'sticky',
            bottom: 0,
            paddingBottom: 2,
            zIndex: 120,
          }}
        >
          {photoTaken ? (
            <>
              <IconButton title={t('upload_doc_recapture')} onClick={resetCamera}>
                <ClearIcon sx={{ width: 35, height: 35, color: theme.palette.primary.main }} />
              </IconButton>
              <IconButton title={t('general_accept')} onClick={handleAccept}>
                <CheckIcon sx={{ width: 35, height: 35, color: theme.palette.primary.main }} />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton title={t('general_cancel')} onClick={onClose}>
                <ClearIcon sx={{ width: 35, height: 35, color: theme.palette.primary.main }} />
              </IconButton>
              <IconButton title={t('upload_doc_capture')} onClick={() => setPhotoTaken(true)}>
                <PhotoCameraIcon
                  sx={{ width: 35, height: 35, color: theme.palette.primary.main }}
                />
              </IconButton>
            </>
          )}
        </Box>
      </ContainerCamera>

      {/* {showHelp && sectionName !== undefined ? (
        <HelpDialog
          open={showHelp}
          closeAction={() => {
            setShowHelp(false);
          }}
          sectionName={sectionName}
          DocName={DocName}
          capture={capture}
          onCapture={() => {
            setShowHelp(false);
          }}
        />
      ) : (
        <HelpDialogGeneric
          open={showHelp}
          closeAction={() => {
            setShowHelp(false);
          }}
          sectionName={sectionName}
          DocName={DocName}
          capture={capture}
          onCapture={() => {
            setShowHelp(false);
          }}
        />
      )} */}
    </Dialog>
  );
};

const HelpDialogGeneric = ({ open, closeAction, onCapture }) => {
  const { t } = useTranslation();

  return (
    <CustomAlertHelpDialog
      open={open}
      closeAction={closeAction}
      body={
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle1" align="justify">
            {t('upload_doc_instructions_3_1')}
          </Typography>
          <Typography variant="subtitle1" align="justify">
            {t('upload_doc_instructions_3_2')}
          </Typography>
          <Box sx={{ marginTop: 4 }}>
            <UseImage src={RequiredDocument} />
          </Box>
        </Box>
      }
      Buttons={
        <Button fullWidth variant="contained" sx={{ height: 45, px: 10 }} onClick={onCapture}>
          {t('general_continue')}
        </Button>
      }
    />
  );
};
