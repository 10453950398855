export const templates = [
  {
    "Id": 2,
    "DocumentType": "INE / IFE",
    "TemplateName": "INE_ANVERSO",
    "NextExtractionTemplateReference": "INE_REVERSO",
    "FrameHeight": 54,
    "FrameWidth": 86,
    "TotalFields": 11,
    "FieldTolerance": 11,
    "ManualCapture": false,
    "HelpTag": "Encuadra la parte <b>delantera</b> de la identificación.",
    "Retries": 5,
    "Timer": 20,
    "Version": 1,
    "Fields": [
      {
        "Id": 11,
        "FieldName": "Nombre(s)",
        "FieldType": "nombre",
        "FieldQuestionName": "MetadataNombre",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{3,30}",
        "OnlineFieldName": "Nombre(s)",
        "OnlineFieldNameOCR": "nombres",
        "Version": 1,
        "Anchors": [
          {
            "Id": 19,
            "RegexLabel": "NOMBRE",
            "RegexField": null,
            "LeftPosition": -45,
            "TopPosition": 6.55,
            "Width": 35,
            "Height": 2.8,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 14,
        "FieldName": "Apellido paterno",
        "FieldType": "apellido paterno",
        "FieldQuestionName": "MetadataApellidoPaterno",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{0,30}",
        "OnlineFieldName": "Apellido paterno",
        "OnlineFieldNameOCR": "apellido paterno",
        "Version": 1,
        "Anchors": [
          {
            "Id": 22,
            "RegexLabel": "PATERNO",
            "RegexField": null,
            "LeftPosition": -45,
            "TopPosition": 1.3,
            "Width": 35,
            "Height": 2.75,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 15,
        "FieldName": "Apellido materno",
        "FieldType": "apellido materno",
        "FieldQuestionName": "MetadataApellidoMaterno",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{0,30}",
        "OnlineFieldName": "Apellido materno",
        "OnlineFieldNameOCR": "apellido materno",
        "Version": 1,
        "Anchors": [
          {
            "Id": 23,
            "RegexLabel": "MATERNO",
            "RegexField": null,
            "LeftPosition": -45,
            "TopPosition": 2.82,
            "Width": 35,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 13,
        "FieldName": "Fecha de nacimiento",
        "FieldType": "fecha de nacimiento",
        "FieldQuestionName": "MetadataFechaNacimiento",
        "RegexValidation": "[0-9]{2}\\/[0-9]{2}\\/[0-9]{4}",
        "OnlineFieldName": "Fecha de nacimiento",
        "OnlineFieldNameOCR": "fecha de nacimiento",
        "Version": 1,
        "Anchors": [
          {
            "Id": 21,
            "RegexLabel": "FECHA DE NACIMIENTO",
            "RegexField": null,
            "LeftPosition": 2.4,
            "TopPosition": 1,
            "Width": 13,
            "Height": 2.85,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 16,
        "FieldName": "Dirección",
        "FieldType": "direccion",
        "FieldQuestionName": "MetadataDirección",
        "RegexValidation": "[A-Z 0-9,ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,155}",
        "OnlineFieldName": "Dirección",
        "OnlineFieldNameOCR": "direccion",
        "Version": 1,
        "Anchors": [
          {
            "Id": 24,
            "RegexLabel": "DIRECCION",
            "RegexField": null,
            "LeftPosition": -45,
            "TopPosition": 10.3,
            "Width": 48,
            "Height": 8.6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 17,
        "FieldName": "Clave de elector",
        "FieldType": "clave de elector",
        "FieldQuestionName": "MetadataClaveElector",
        "RegexValidation": "[A-Z]{6}[0-9]{8}[A-Z]{1}[0-9]{3}",
        "OnlineFieldName": "Clave de elector",
        "OnlineFieldNameOCR": "clave de elector",
        "Version": 1,
        "Anchors": [
          {
            "Id": 25,
            "RegexLabel": "CLAVELA",
            "RegexField": null,
            "LeftPosition": -24.25,
            "TopPosition": 18.58,
            "Width": 24,
            "Height": 2.75,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 18,
        "FieldName": "CURP",
        "FieldType": "curp",
        "FieldQuestionName": "MetadataCURP",
        // "RegexValidation": "[A-Z]{1}[A-Z]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}",
        "RegexValidation": /^([A-Z]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM](AS|BC|BS|CC|CL|CM|CS|CH|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[A-Z]{3}[0-9A-Z]\d)$/i,
        "OnlineFieldName": "CURP",
        "OnlineFieldNameOCR": "curp",
        "Version": 1,
        "Anchors": [
          {
            "Id": 26,
            "RegexLabel": "CURP",
            "RegexField": null,
            "LeftPosition": -34.79,
            "TopPosition": 21.6,
            "Width": 26,
            "Height": 2.75,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 19,
        "FieldName": "Año de registro",
        "FieldType": "anio de registro",
        "FieldQuestionName": "MetadataAñoRegistro",
        "RegexValidation": "[0-9]{4}",
        "OnlineFieldName": "Año de registro",
        "OnlineFieldNameOCR": "anno de registro",
        "Version": 1,
        "Anchors": [
          {
            "Id": 27,
            "RegexLabel": "REGISTRO",
            "RegexField": null,
            "LeftPosition": 7,
            "TopPosition": 20.6,
            "Width": 5.75,
            "Height": 4,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 20,
        "FieldName": "Año de emisión",
        "FieldType": "fecha de expedicion",
        "FieldQuestionName": "MetadataAñoEmisión",
        "RegexValidation": "[0-9]{4}",
        "OnlineFieldName": "Año de emisión",
        "OnlineFieldNameOCR": "anno de emisión",
        "Version": 1,
        "Anchors": [
          {
            "Id": 28,
            "RegexLabel": "EMISION",
            "RegexField": null,
            "LeftPosition": -13.9,
            "TopPosition": 26.5,
            "Width": 6.5,
            "Height": 3.9,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 21,
        "FieldName": "Vigencia",
        "FieldType": "vigencia",
        "FieldQuestionName": "MetadataVigencia",
        "RegexValidation": "[0-9]{4}",
        "OnlineFieldName": "Vigencia",
        "OnlineFieldNameOCR": "vigencia",
        "Version": 1,
        "Anchors": [
          {
            "Id": 29,
            "RegexLabel": "VIGENCIA",
            "RegexField": null,
            "LeftPosition": 0,
            "TopPosition": 26.8,
            "Width": 8.2,
            "Height": 4.7,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 22,
        "FieldName": "Número de emisión",
        "FieldType": "numero emision",
        "FieldQuestionName": "MetadataNúmeroEmisión",
        "RegexValidation": "[0-9]{2}",
        "OnlineFieldName": "Número de emisión",
        "OnlineFieldNameOCR": "numero emision",
        "Version": 1,
        "Anchors": [
          {
            "Id": 30,
            "RegexLabel": "Emisión",
            "RegexField": null,
            "LeftPosition": 11.95,
            "TopPosition": 21,
            "Width": 5.4,
            "Height": 4,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "RFC",
        "FieldQuestionName": "MetadataRFC",
        "RegexValidation": null,
        "OnlineFieldName": "MetadataRFC",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "maxLength": 100
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "[LlIi1][o0O][cCgG][a4A][lLiI1][i1LlI]? ?[dD][4Aa]?[dD]?",
        "RegexField": "[fFpP]?[e3E][CcNnOo0][hHRr]? ?[aA4]? ?[dD0Ow]?[e3E]? ?[NnWw][&]?[a4A][cCgG]?[a-zzA-Z0-9]{1,8}",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },

  {
    "Id": 6,
    "DocumentType": "Pasaporte",
    "TemplateName": "Pasaporte",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 65,
    "FrameWidth": 95,
    "TotalFields": 10,
    "FieldTolerance": 8,
    "ManualCapture": false,
    "HelpTag": "Coloque el pasaporte dentro de los márgenes",
    "Retries": 3,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 11,
        "FieldName": "Nombre",
        "FieldQuestionName": "MetadataNombre",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{3,30}",
        // "OnlineFieldNameOCR": "MetadataNombre",
        "OnlineFieldName": "nombre",
        "OnlineFieldNameOCR": "nombre",
        "Version": 1,
        "Anchors": [
          {
            "Id": 19,
            "RegexLabel": "NOMBRE",
            "RegexField": null,
            "LeftPosition": 20,
            "TopPosition": 12.2,
            "Width": 30,
            "Height": 2.8,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 12,
        "FieldName": "Apellido Paterno",
        "FieldQuestionName": "MetadataApellidoPaterno",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{0,90}",
        // "OnlineFieldNameOCR": "MetadataApellidoPaterno",
        "OnlineFieldName": "apaterno",
        "OnlineFieldNameOCR": "apaterno",
        "Version": 1,
        "Anchors": [
          {
            "Id": 19,
            "RegexLabel": "APELLIDOPATERNO",
            "RegexField": null,
            "LeftPosition": 20,
            "TopPosition": 8.5,
            "Width": 25,
            "Height": 2.8,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 12,
        "FieldName": "Apellido Materno",
        "FieldQuestionName": "MetadataApellidoMaterno",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{0,90}",
        // "OnlineFieldNameOCR": "MetadataApellidoMaterno",
        "OnlineFieldName": "amaterno",
        "OnlineFieldNameOCR": "amaterno",
        "Version": 1,
        "Anchors": [
          {
            "Id": 19,
            "RegexLabel": "APELLIDOMATERNO",
            "RegexField": null,
            "LeftPosition": 35,
            "TopPosition": 8.5,
            "Width": 35,
            "Height": 2.8,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 18,
        "FieldName": "CURP",
        "FieldQuestionName": "MetadataCURP",
        "RegexValidation": "[A-Z]{1}[A-Z]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}",
        // "OnlineFieldNameOCR": "MetadataCURP",
        "OnlineFieldName": "curp",
        "OnlineFieldNameOCR": "curp",
        "Version": 1,
        "Anchors": [
          {
            "Id": 26,
            "RegexLabel": "CURP",
            "RegexField": null,
            "LeftPosition": 52.1,
            "TopPosition": 19.1,
            "Width": 29.9,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 18,
        "FieldName": "Fecha de Caducidad",
        "FieldQuestionName": "MetadataFechaCaducidad",
        "RegexValidation": "[0-9]{1,2}[\\/ ]?[0-9]{1,2}[\\/ ]?[0-9]{2,4}",
        // "OnlineFieldNameOCR": "MetadataFechaCaducidad",
        "OnlineFieldName": "fvencimiento",
        "OnlineFieldNameOCR": "fvencimiento",
        "Version": 1,
        "Anchors": [
          {
            "Id": 20,
            "RegexLabel": "CADUCIDAD",
            "RegexField": null,
            "LeftPosition": 23.3,
            "TopPosition": 29.4,
            "Width": 19,
            "Height": 2.7,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 14,
        "FieldName": "Fecha de Nacimiento",
        "FieldQuestionName": "MetadataFechaNacimiento",
        "RegexValidation": "[0-9]{1,2}[\\/ ]?[0-9]{1,2}[\\/ ]?[0-9]{2,4}",
        // "OnlineFieldNameOCR": "MetadataFechaNacimiento",
        "OnlineFieldName": "fnacimiento",
        "OnlineFieldNameOCR": "fnacimiento",
        "Version": 1,
        "Anchors": [
          {
            "Id": 19,
            "RegexLabel": "FECHADENACIMIENTO",
            "RegexField": null,
            "LeftPosition": 20,
            "TopPosition": 19.3,
            "Width": 35,
            "Height": 2.8,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 15,
        "FieldName": "Número de Pasaporte",
        "FieldQuestionName": "MetadataPasaporteNo",
        "RegexValidation": "[A-Za-z 0-9 ñÑ]{3,12}",
        // "OnlineFieldNameOCR": "MetadataPasaporteNo",
        "OnlineFieldName": "noPasaporte",
        "OnlineFieldNameOCR": "noPasaporte",
        "Version": 1,
        "Anchors": [
          {
            "Id": 20,
            "RegexLabel": "NOPASAPORTE",
            "RegexField": null,
            "LeftPosition": 75.7,
            "TopPosition": 4,
            "Width": 15,
            "Height": 4,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 15,
        "FieldName": "Nacionalidad",
        "FieldQuestionName": "MetadataNacionalidad",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{0,90}",
        // "OnlineFieldNameOCR": "MetadataNacionalidad",
        "OnlineFieldName": "nacionalidad",
        "OnlineFieldNameOCR": "nacionalidad",
        "Version": 1,
        "Anchors": [
          {
            "Id": 20,
            "RegexLabel": "MetadataNacionalidad",
            "RegexField": null,
            "LeftPosition": 23.2,
            "TopPosition": 22.2,
            "Width": 10,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "PASAPORTE",
        "RegexField": "[pP][aA4][s5S][a4A][Pp][o0O][rR]?[tT][e3E] ?[a-zA-Z0-9 ]{1,25}",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      },
      {
        "Id": 4,
        "RegexLabel": "PASAPORTE",
        "RegexField": "[pP][aA4][s5S][a4A][Pp][o0O][rR]?[tT][e3E]",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ],
    "VerticalImage": false
  },
  {
    "Id": 7,
    "DocumentType": "Constancia INE",
    "TemplateName": "Constancia INE",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 60,
    "FrameWidth": 95,
    "TotalFields": 10,
    "FieldTolerance": 9,
    "ManualCapture": false,
    "HelpTag": "Coloque la constancia dentro de los márgenes",
    "Retries": 3,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 11,
        "FieldName": "Nombre",
        "FieldQuestionName": "MetadataNombre",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{0,30}",
        "Version": 1,
        "Anchors": [
          {
            "Id": 19,
            "RegexLabel": "NOMBRE",
            "RegexField": null,
            "LeftPosition": 6,
            "TopPosition": 17.8,
            "Width": 30,
            "Height": 2,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 14,
        "FieldName": "Apellido Paterno",
        "FieldQuestionName": "MetadataApellidoPaterno",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{0,30}",
        "Version": 1,
        "Anchors": [
          {
            "Id": 22,
            "RegexLabel": "PATERNO",
            "RegexField": null,
            "LeftPosition": 6,
            "TopPosition": 19,
            "Width": 30,
            "Height": 2,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 15,
        "FieldName": "Apellido Materno",
        "FieldQuestionName": "MetadataApellidoMaterno",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{0,30}",
        "Version": 1,
        "Anchors": [
          {
            "Id": 23,
            "RegexLabel": "MATERNO",
            "RegexField": null,
            "LeftPosition": 6,
            "TopPosition": 21,
            "Width": 30,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 14,
        "FieldName": "Clave de Elector",
        "FieldQuestionName": "MetadataClaveElector",
        "RegexValidation": "[a-zA-z0-9]{18}",
        "Version": 1,
        "Anchors": [
          {
            "Id": 19,
            "RegexLabel": "CLAVEELECTOR",
            "RegexField": null,
            "LeftPosition": 7,
            "TopPosition": 24.5,
            "Width": 35,
            "Height": 2.6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 15,
        "FieldName": "Número de Emisión",
        "FieldQuestionName": "MetadataEmision",
        "RegexValidation": "[0-9 ]{2}$",
        "Version": 1,
        "Anchors": [
          {
            "Id": 20,
            "RegexLabel": "EMISION",
            "RegexField": null,
            "LeftPosition": 7,
            "TopPosition": 27,
            "Width": 10,
            "Height": 2.5,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 16,
        "FieldName": "Número de OCR",
        "FieldQuestionName": "MetadataOCR",
        "RegexValidation": "[Z0-9 ]{13,14}",
        "Version": 1,
        "Anchors": [
          {
            "Id": 20,
            "RegexLabel": "OCR",
            "RegexField": null,
            "LeftPosition": 6,
            "TopPosition": 29.5,
            "Width": 20,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 17,
        "FieldName": "CIC",
        "FieldQuestionName": "MetadataCIC",
        "RegexValidation": "[Z0-9 ]{8,9}",
        "Version": 1,
        "Anchors": [
          {
            "Id": 20,
            "RegexLabel": "CIC",
            "RegexField": null,
            "LeftPosition": 6,
            "TopPosition": 33,
            "Width": 25,
            "Height": 2.5,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 18,
        "FieldName": "Año de Registro",
        "FieldQuestionName": "MetadataRegistry",
        "RegexValidation": "[0-9 ]{4}$",
        "Version": 1,
        "Anchors": [
          {
            "Id": 20,
            "RegexLabel": "REGISTRO",
            "RegexField": null,
            "LeftPosition": 6,
            "TopPosition": 37,
            "Width": 25,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 19,
        "FieldName": "Fecha de Emisión",
        "FieldQuestionName": "MetadataFechaEmision",
        "RegexValidation": "[A-Za-z0-9 ]{17,28}$",
        "Version": 1,
        "Anchors": [
          {
            "Id": 20,
            "RegexLabel": "EMISIONFE",
            "RegexField": null,
            "LeftPosition": 7,
            "TopPosition": 39,
            "Width": 20,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 20,
        "FieldName": "Vigencia",
        "FieldQuestionName": "MetadataVigencia",
        "RegexValidation": "[A-Za-z0-9 ]{15,28}$",
        "Version": 1,
        "Anchors": [
          {
            "Id": 20,
            "RegexLabel": "VIGENCIA",
            "RegexField": null,
            "LeftPosition": 7,
            "TopPosition": 42.1,
            "Width": 20,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "[Cc][0oO]?[nN][Ss5][tT7][aA4][nN][cC][iI1l][aA4] ?[Dd][iI1l][gG6][i1Il][tT7][aA4][lLiI] ?[pP][a4A][rR][aA] ?[iI1l][dD][eE3][nN][tT7][a-zA-Z0-9óòÓÒ]{5,10}",
        "RegexField": "[Cc][0oO]?[nN][Ss5][tT7][aA4][nN][cC][iI1l][aA4] ?[Dd][iI1l][gG6][i1Il][tT7][aA4][lLiI] ?[pP][a4A][rR][aA] ?[iI1l][dD][eE3][nN][tT7][a-zA-Z0-9óòÓÒ]{5,10}",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },
  {
    "Id": 9,
    "DocumentType": "CURP",
    "TemplateName": "Documento CURP",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 48,
    "FrameWidth": 98,
    "TotalFields": 5,
    "FieldTolerance": 5,
    "ManualCapture": false,
    "HelpTag": "Capture el Frente del documento",
    "Retries": 1,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 46,
        "FieldName": "CURP",
        "FieldQuestionName": "MetadataCURP",
        "RegexValidation": "[A-Z]{1}[A-Z]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 57,
            "RegexLabel": "CLAVE",
            "RegexField": null,
            "LeftPosition": -40,
            "TopPosition": -13.5,
            "Width": 40,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 47,
        "FieldName": "Nombre",
        "FieldQuestionName": "MetadataNombre",
        "RegexValidation": "[A-Z0-9 ]{3,50}",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 58,
            "RegexLabel": "NOMBRE",
            "RegexField": null,
            "LeftPosition": -40,
            "TopPosition": -7,
            "Width": 60,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Fecha de inscripción",
        "FieldQuestionName": "MetadataFechaInscripcion",
        "RegexValidation": "[0-9]{2}\\/[0-9]{2}\\/[0-9]{4}",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "INSCRIPCION",
            "RegexField": null,
            "LeftPosition": -40,
            "TopPosition": 1,
            "Width": 20,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 49,
        "FieldName": "Folio",
        "FieldQuestionName": "MetadataFolio",
        "RegexValidation": "[0-9]{7,10}",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 60,
            "RegexLabel": "FOLIO",
            "RegexField": null,
            "LeftPosition": -20,
            "TopPosition": 1,
            "Width": 20,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 50,
        "FieldName": "Entidad de Registro",
        "FieldQuestionName": "MetadataEntidadRegistro",
        "RegexValidation": "[A-Z0-9 ]{3,30}",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 61,
            "RegexLabel": "REGISTRO",
            "RegexField": null,
            "LeftPosition": -1,
            "TopPosition": 1,
            "Width": 20,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "[e3E]?[Nn][tT][i1]?[dD]?[aA4]?[dD]? ?[dD]?[e3E]? ?[rR][e3E][6gG]?[a-zzA-Z0-9]{1,8}",
        "RegexField": "[e3E]?[Nn][tT][i1]?[dD]?[aA4]?[dD]? ?[dD]?[e3E]? ?[rR][e3E][6gG]?[a-zzA-Z0-9]{1,8}",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },
  {
    "Id": 10,
    "DocumentType": "CFE",
    "TemplateName": "comprobante_anverso",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 55,
    "FrameWidth": 85,
    "TotalFields": 1,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Capture el Frente del documento",
    "Retries": 1,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 48,
        "FieldName": "Calle",
        "FieldQuestionName": "Calle",
        "RegexValidation": null,
        "OnlineFieldName": "Calle",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 2,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Calle",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Número",
        "FieldQuestionName": "Número",
        "RegexValidation": null,
        "OnlineFieldName": "Número",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 3,
        "maxLength": 10,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Número",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Código Postal",
        "FieldQuestionName": "Código Postal",
        "RegexValidation": null,
        "OnlineFieldName": "Código Postal",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 4,
        "maxLength": 5,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Código Postal",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Colonia",
        "FieldQuestionName": "Colonia",
        "RegexValidation": null,
        "OnlineFieldName": "Colonia",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 5,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Colonia",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Ciudad",
        "FieldQuestionName": "Ciudad",
        "RegexValidation": null,
        "OnlineFieldName": "Ciudad",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Ciudad",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Municipio",
        "FieldQuestionName": "Municipio",
        "RegexValidation": null,
        "OnlineFieldName": "Municipio",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 7,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Municipio",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Estado",
        "FieldQuestionName": "Estado",
        "RegexValidation": null,
        "OnlineFieldName": "Estado",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 8,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Estado",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Dirección",
        "FieldQuestionName": "Dirección",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,155}",
        "OnlineFieldName": "Dirección",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Dirección",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Nombre",
        "FieldQuestionName": "Nombre",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,50}",
        "OnlineFieldName": "Nombre",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Nombre",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Periodo",
        "FieldQuestionName": "Periodo",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Periodo",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Periodo",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Referencia",
        "FieldQuestionName": "Referencia",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Referencia",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Referencia",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Fecha",
        "FieldQuestionName": "Fecha",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Fecha",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Fecha",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Total",
        "FieldQuestionName": "Total",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Total",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Total",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "RegexField": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },
  {
    "Id": 11,
    "DocumentType": "TELMEX",
    "TemplateName": "comprobante_anverso",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 55,
    "FrameWidth": 85,
    "TotalFields": 1,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Capture el Frente del documento",
    "Retries": 1,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 48,
        "FieldName": "Teléfono",
        "FieldQuestionName": "Teléfono",
        "RegexValidation": "[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}",
        "OnlineFieldName": "Teléfono",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "maxLength": 10,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Teléfono",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Calle",
        "FieldQuestionName": "Calle",
        "RegexValidation": null,
        "OnlineFieldName": "Calle",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 2,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Calle",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Número",
        "FieldQuestionName": "Número",
        "RegexValidation": null,
        "OnlineFieldName": "Número",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 3,
        "maxLength": 10,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Número",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Código Postal",
        "FieldQuestionName": "Código Postal",
        "RegexValidation": null,
        "OnlineFieldName": "Código Postal",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 4,
        "maxLength": 5,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Código Postal",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Colonia",
        "FieldQuestionName": "Colonia",
        "RegexValidation": null,
        "OnlineFieldName": "Colonia",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 5,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Colonia",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Ciudad",
        "FieldQuestionName": "Ciudad",
        "RegexValidation": null,
        "OnlineFieldName": "Ciudad",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Ciudad",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Municipio",
        "FieldQuestionName": "Municipio",
        "RegexValidation": null,
        "OnlineFieldName": "Municipio",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 7,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Municipio",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Estado",
        "FieldQuestionName": "Estado",
        "RegexValidation": null,
        "OnlineFieldName": "Estado",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 8,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Estado",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Dirección",
        "FieldQuestionName": "Dirección",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,155}",
        "OnlineFieldName": "direccion",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Dirección",
            "RegexField": null,
            "LeftPosition": -5,
            "TopPosition": 8,
            "Width": 30,
            "Height": 8,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Nombre",
        "FieldQuestionName": "Nombre",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,50}",
        "OnlineFieldName": "nombre",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Dirección",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Fecha",
        "FieldQuestionName": "Fecha",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Fecha",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Fecha",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Referencia",
        "FieldQuestionName": "Referencia",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,50}",
        "OnlineFieldName": "Referencia",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Referencia",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Total",
        "FieldQuestionName": "Total",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,50}",
        "OnlineFieldName": "Total",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Total",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "[T]?[E]?[L]?[E3]?[Fr]?[O0]?[N]?[OoD0]?[Ss]? [D]?[E]? [M]?[E]?[X0O]?[XIO]?[Cc]?[0Oo]? ?[Ss5]?.?[AB4]?.?[B8]?.? ?[d]?[e]? ?[CG]?.?[vV].?",
        "RegexField": "[T]?[E]?[L]?[E3]?[Fr]?[O0]?[N]?[OoD0]?[Ss]? [D]?[E]? [M]?[E]?[X0O]?[XIO]?[Cc]?[0Oo]? ?[Ss5]?.?[AB4]?.?[B8]?.? ?[d]?[e]? ?[CG]?.?[vV].?",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },
  {
    "Id": 12,
    "DocumentType": "Cédula",
    "TemplateName": "Cedula_Anverso",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 54,
    "FrameWidth": 86,
    "TotalFields": 7,
    "FieldTolerance": 7,
    "ManualCapture": false,
    "HelpTag": "Capture el Frente del documento",
    "Retries": 1,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 47,
        "FieldName": "Nombres",
        "FieldQuestionName": "MetadataNombre",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{3,30}",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 57,
            "RegexLabel": "NOMBRES",
            "RegexField": null,
            "LeftPosition": 0,
            "TopPosition": 13.5,
            "Width": 40,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 46,
        "FieldName": "Apellido Paterno",
        "FieldQuestionName": "MetadataApellidoPaterno",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{0,30}",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 57,
            "RegexLabel": "APELLIDOS",
            "RegexField": null,
            "LeftPosition": 0,
            "TopPosition": 10,
            "Width": 40,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 46,
        "FieldName": "Apellido Materno",
        "FieldQuestionName": "MetadataApellidoMaterno",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{0,30}",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 57,
            "RegexLabel": "APELLIDOMATERNO",
            "RegexField": null,
            "LeftPosition": 10,
            "TopPosition": 10,
            "Width": 40,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Cédula",
        "FieldQuestionName": "MetadataCedula",
        "RegexValidation": "[A-Z0-9ñÑíóÓÍéÉ.]{7,30}$",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 57,
            "RegexLabel": "CEDULA",
            "RegexField": null,
            "LeftPosition": 15,
            "TopPosition": 6,
            "Width": 30,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 49,
        "FieldName": "CURP",
        "FieldQuestionName": "MetadataCURP",
        "RegexValidation": "[A-Z]{1}[A-Z]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 57,
            "RegexLabel": "CURP",
            "RegexField": null,
            "LeftPosition": -25,
            "TopPosition": 32.2,
            "Width": 35,
            "Height": 4,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 17,
        "FieldName": "Estudios",
        "FieldQuestionName": "MetadataEstudios",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{3,150}",
        "Version": 1,
        "Anchors": [
          {
            "Id": 20,
            "RegexLabel": "ESTUDIOS",
            "RegexField": null,
            "LeftPosition": 4,
            "TopPosition": 16,
            "Width": 45,
            "Height": 11,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 17,
        "FieldName": "Fecha de Expedición",
        "FieldQuestionName": "MetadataEmisión",
        "RegexValidation": "[0-9]{1,2}[\\/ ]?[0-9]{1,2}[\\/ ]?[0-9]{2,4}",
        "Version": 1,
        "Anchors": [
          {
            "Id": 20,
            "RegexLabel": "EXPEDICIÓN",
            "RegexField": null,
            "LeftPosition": 29.5,
            "TopPosition": 36,
            "Width": 25,
            "Height": 4,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": " ?[dD8][iI1Ll]?[rR8]?[eE]?[cC][cC]?[iI1LloO]?[0OoóÓ][nNrR] ?[gG6Ss][3eEtT][nyYN][3eE][rR8][4aA][1lLiI] ?[dD][e3E] ?[Pp9][rR][o0O][fF][3eE][sS5][iI1lL][o0O][nN][3eE][5sS]? ?",
        "RegexField": " ?[dD8][iI1Ll]?[rR8]?[eE]?[cC][cC]?[iI1LloO]?[0OoóÓ][nNrR] ?[gG6Ss][3eEtT][nyYN][3eE][rR8][4aA][1lLiI] ?[dD][e3E] ?[Pp9][rR][o0O][fF][3eE][sS5][iI1lL][o0O][nN][3eE][5sS]? ?",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },
  {
    "Id": 14,
    "DocumentType": "Cédula",
    "TemplateName": "Cedula_Anverso_V2",
    "NextExtractionTemplateReference": "Cedula_Reverso_V2",
    "FrameHeight": 54,
    "FrameWidth": 86,
    "TotalFields": 2,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Capture el Frente del documento",
    "Retries": 8,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 48,
        "FieldName": "Cédula",
        "FieldQuestionName": "MetadataCedula",
        "RegexValidation": "[A-Z0-9ñÑíóÓÍéÉ.]{7,13}$",
        "Version": 1,
        "Anchors": [
          {
            "Id": 57,
            "RegexLabel": "CEDULA",
            "RegexField": null,
            "LeftPosition": -15,
            "TopPosition": -2,
            "Width": 30,
            "Height": 4,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 49,
        "FieldName": "Fecha de expedición",
        "FieldQuestionName": "MetadataAñoEmisión",
        "RegexValidation": "[0-9]{4}",
        "Version": 1,
        "Anchors": [
          {
            "Id": 57,
            "RegexLabel": "EMISION",
            "RegexField": null,
            "LeftPosition": 10,
            "TopPosition": 20,
            "Width": 30,
            "Height": 4,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": " ?[sS5][3Ee0OoUu][pPRrsS] ?",
        "RegexField": " ?[sS5][3Ee0OoUu][pPrRsS] ?",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ],
    "VerticalImage": true
  },
  {
    "Id": 15,
    "DocumentType": "Cédula",
    "TemplateName": "Cedula_Reverso_V2",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 54,
    "FrameWidth": 86,
    "TotalFields": 6,
    "FieldTolerance": 5,
    "ManualCapture": true,
    "HelpTag": "Capture el reverso del documento",
    "Retries": 1,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 41,
        "FieldName": "Nombre",
        "FieldQuestionName": "MetadataNombre",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{3,30}",
        "Version": 1,
        "Anchors": [
          {
            "Id": 30,
            "RegexLabel": "NOMBRE",
            "RegexField": null,
            "LeftPosition": 0,
            "TopPosition": 9.5,
            "Width": 45,
            "Height": 2.5,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 3,
        "FieldName": "Apellido Paterno",
        "FieldQuestionName": "MetadataApellidoPaterno",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{0,30}",
        "Version": 1,
        "Anchors": [
          {
            "Id": 11,
            "RegexLabel": "APELLIDO PATERNO",
            "RegexField": null,
            "LeftPosition": 0,
            "TopPosition": 11.5,
            "Width": 30,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 4,
        "FieldName": "Apellido Materno",
        "FieldQuestionName": "MetadataApellidoMaterno",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{0,30}",
        "Version": 1,
        "Anchors": [
          {
            "Id": 12,
            "RegexLabel": "APELLIDO MATERNO",
            "RegexField": null,
            "LeftPosition": 0,
            "TopPosition": 13.5,
            "Width": 30,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 4,
        "FieldName": "CURP",
        "FieldQuestionName": "MetadataCURP",
        "RegexValidation": "[A-Z]{1}[A-Z]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}",
        "Version": 1,
        "Anchors": [
          {
            "Id": 12,
            "RegexLabel": "CURP",
            "RegexField": null,
            "LeftPosition": 11.9,
            "TopPosition": 18.7,
            "Width": 57.4,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 4,
        "FieldName": "Estudios",
        "FieldQuestionName": "MetadataEstudios",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{3,90}",
        "Version": 1,
        "Anchors": [
          {
            "Id": 12,
            "RegexLabel": "ESTUDIOS",
            "RegexField": null,
            "LeftPosition": 5,
            "TopPosition": 36,
            "Width": 60,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 5,
        "RegexLabel": " ?[sS5][3Ee][cC][Rr][e3E][tT7][a4A][rR8][iíÍIìÌ1lLi][a4A] ?[dD][3eE] ?[3eE][dD][uU][cC][a4A][cC][iI1l][óò0OÓ][nN] [Pp][úÚ][bB][lL1I][iIlL1][cC][a4A] ?",
        "RegexField": " ?[sS5][3Ee][cC][Rr][e3E][tT7][a4A][rR8][iíÍIìÌ1lLi][a4A] ?[dD][3eE] ?[3eE][dD][uU][cC][a4A][cC][iI1l][óò0OÓ][nN] [Pp][úÚ][bB][lL1I][iIlL1][cC][a4A] ?",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": 90,
        "Version": 1
      }
    ],
    "VerticalImage": true
  },
  {
    "Id": 13,
    "DocumentType": "Cédula",
    "TemplateName": "Cedula_Reverso",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 54,
    "FrameWidth": 86,
    "TotalFields": 2,
    "FieldTolerance": 2,
    "ManualCapture": true,
    "HelpTag": "Capture el reverso del documento",
    "Retries": 1,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 41,
        "FieldName": "MRZ",
        "FieldQuestionName": "MetadataMRZ",
        "RegexValidation": "[0-9]{9}",
        "Version": 1,
        "Anchors": [
          {
            "Id": 30,
            "RegexLabel": "IDMEX",
            "RegexField": null,
            "LeftPosition": -1,
            "TopPosition": -1,
            "Width": 75,
            "Height": 15,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }

    ],
    "Anchors": [
      {
        "Id": 5,
        "RegexLabel": " ?[1Ii] ?D ?M ?[3eE] ?X ?[a-zA-z0-9<< ]{5,90} ?",
        "RegexField": " ?[1Ii] ?D ?M ?[3eE] ?X ?[a-zA-z0-9<< ]{5,90} ?",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": 90,
        "Version": 1
      },
      {
        "Id": 6,
        "RegexLabel": "IDMEX",
        "RegexField": "IDMEX",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": 90,
        "Version": 1
      }
    ]
  },
  {
    "Id": 16,
    "DocumentType": "Cédula",
    "TemplateName": "Cedula_Anverso_V3",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 62,
    "FrameWidth": 83,
    "TotalFields": 7,
    "FieldTolerance": 6,
    "ManualCapture": false,
    "HelpTag": "Capture el Frente del documento",
    "Retries": 1,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 48,
        "FieldName": "Nombre",
        "FieldQuestionName": "MetadataNombre",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{3,30}",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 57,
            "RegexLabel": "NOMBRE",
            "RegexField": null,
            "LeftPosition": 1,
            "TopPosition": 6.4,
            "Width": 21,
            "Height": 2.3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Apellido Paterno",
        "FieldQuestionName": "MetadataApellidoPaterno",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{0,30}",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 57,
            "RegexLabel": "APELLIDO PATERNO",
            "RegexField": null,
            "LeftPosition": 25,
            "TopPosition": 6.4,
            "Width": 22,
            "Height": 2.3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Apellido Materno",
        "FieldQuestionName": "MetadataApellidoMaterno",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{0,30}",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 57,
            "RegexLabel": "APELLIDO MATERNO",
            "RegexField": null,
            "LeftPosition": 42,
            "TopPosition": 6.4,
            "Width": 22,
            "Height": 2.3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 49,
        "FieldName": "CURP",
        "FieldQuestionName": "MetadataCURP",
        "RegexValidation": "[A-Z]{1}[A-Z]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}",
        "Version": 1,
        "Anchors": [
          {
            "Id": 26,
            "RegexLabel": "CURP",
            "RegexField": null,
            "LeftPosition": 43,
            "TopPosition": -3.4,
            "Width": 26,
            "Height": 2.3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "CEDULA",
        "FieldQuestionName": "MetadataCedula",
        "RegexValidation": "[A-Z0-9ñÑíóÓÍéÉ.]{7,30}$",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 57,
            "RegexLabel": "CEDULA",
            "RegexField": null,
            "LeftPosition": 40,
            "TopPosition": -7.2,
            "Width": 30,
            "Height": 2.3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Estudios",
        "FieldQuestionName": "MetadataEstudios",
        "RegexValidation": "[A-Z 0-9ñÑíóÓÍéÉ.]{7,150}$",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 57,
            "RegexLabel": "ESTUDIOS",
            "RegexField": null,
            "LeftPosition": 0.7,
            "TopPosition": 15.5,
            "Width": 42,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Fecha de Expedición",
        "FieldQuestionName": "MetadataAñoEmisión",
        "RegexValidation": "[0-9]{2}\\/[0-9]{2}\\/[0-9]{4}",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 57,
            "RegexLabel": "EXPEDICION",
            "RegexField": null,
            "LeftPosition": 0.7,
            "TopPosition": 26.5,
            "Width": 42,
            "Height": 3,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": " ?[Cc][éÉeE][dD][uU][lL1Ii][a4A] ?[Pp9][rR][oO0][fF7][eE3][sS5][iI1Ll][0Oo][nN][4Aa][lLiI1] ?[e3E][lLiI1][e3E][cC][tT7][rR8][òóÓ0][nN][iI1lL][cC][4aA] ?",
        "RegexField": " ?[Cc][éÉeE][dD][uU][lL1Ii][a4A] ?[Pp9][rR][oO0][fF7][eE3][sS5][iI1Ll][0Oo][nN][4Aa][lLiI1] ?[e3E][lLiI1][e3E][cC][tT7][rR8][òóÓ0][nN][iI1lL][cC][4aA] ?",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ],
    "VerticalImage": true
  },

  {
    "Id": 5,
    "DocumentType": "INE / IFE",
    "TemplateName": "INE_REVERSO",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 54,
    "FrameWidth": 86,
    "TotalFields": 2,
    "FieldTolerance": 2,
    "ManualCapture": false,
    "HelpTag": "Capture el <b>Reverso</b> de la identificación",
    "Retries": 3,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 41,
        "FieldName": "CIC",
        "FieldType": "cic",
        "FieldQuestionName": "MetadataNúmeroCIC",
        "RegexValidation": "[0-9]{9}",
        "OnlineFieldName": "Número CIC",
        "OnlineFieldNameOCR": "Número CIC",
        "Version": 1,
        "Anchors": [
          {
            "Id": 30,
            "RegexLabel": "IDMEX",
            "RegexField": null,
            "LeftPosition": -1,
            "TopPosition": -1,
            "Width": 75,
            "Height": 4.5,
            "ReferenceFieldPosition": "5,14",
            "Version": 1
          }
        ]
      },
      {
        "Id": 41,
        "FieldName": "OCR",
        "FieldType": "ocr",
        "FieldQuestionName": "MetadataNúmeroOCR",
        "RegexValidation": "[0-9]{13}",
        "OnlineFieldName": "Número de OCR",
        "OnlineFieldNameOCR": "Número de OCR",
        "Version": 1,
        "Anchors": [
          {
            "Id": 30,
            "RegexLabel": "IDMEX",
            "RegexField": null,
            "LeftPosition": -1,
            "TopPosition": -1,
            "Width": 75,
            "Height": 4.5,
            "ReferenceFieldPosition": "17,30",
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 5,
        "RegexLabel": " ?[1Ii] ?D ?M ?[3eE] ?X ?[a-zA-z0-9<< ]{5,90} ?",
        "RegexField": " ?[1Ii] ?D ?M ?[3eE] ?X ?[a-zA-z0-9<< ]{5,90} ?",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": 90,
        "Version": 1
      }
    ]
  },
  {
    "Id": 17,
    "DocumentType": "INE FAST CATPURE",
    "TemplateName": "IFE_ANVERSO_ONLINE",
    "NextExtractionTemplateReference": "IFE_REVERSO_ONLINE",
    "FrameHeight": 54,
    "FrameWidth": 86,
    "TotalFields": 1,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Encuadra la parte <b>delantera</b> de la identificación.",
    "Retries": 5,
    "Timer": 20,
    "Version": 1,
    "Anchors": [
      {
        "Id": 1,
        "RegexLabel": " ?[eE3]D[4aA]D ?",
        "RegexField": " ?N[0oO]M[8Bb]R[3eE] ?",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      },
      {
        "Id": 2,
        "RegexLabel": " ?[eE3][dD][4aA][dD] ?",
        "RegexField": " ?[A-Za-z][0oO]?[mM][8Bb][rR]?[3eE] ?",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ],
    "Fields": [
      {
        "Id": 1,
        "FieldName": "Nombre",
        "FieldType": "nombre",
        "FieldQuestionName": "MetadataNombre",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{3,30}",
        "Version": 1,
        "Anchors": [
          {
            "Id": 9,
            "RegexLabel": "NOMBRE",
            "RegexField": null,
            "LeftPosition": -1,
            "TopPosition": 5,
            "Width": 35,
            "Height": 12,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }
    ]
  },
  {
    "Id": 18,
    "DocumentType": "INE FAST CATPURE",
    "TemplateName": "INE_ANVERSO_ONLINE",
    "NextExtractionTemplateReference": "INE_REVERSO_ONLINE",
    "FrameHeight": 54,
    "FrameWidth": 86,
    "TotalFields": 1,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Encuadra la parte <b>delantera</b> de la identificación.",
    "Retries": 5,
    "Timer": 20,
    "Version": 1,
    "Fields": [
      {
        "Id": 11,
        "FieldName": "Nombre(s)",
        "FieldType": "nombre",
        "FieldQuestionName": "MetadataNombre",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{3,30}",
        "OnlineFieldName": "nombres",
        "Version": 1,
        "Anchors": [
          {
            "Id": 19,
            "RegexLabel": "NOMBRE",
            "RegexField": null,
            "LeftPosition": -45,
            "TopPosition": 6.55,
            "Width": 35,
            "Height": 5,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "[LlIi1][o0O][cCgG][a4A][lLiI1][i1LlI]? ?[dD][4Aa]?[dD]?",
        "RegexField": "[fFpP]?[e3E][CcNnOo0][hHRr]? ?[aA4]? ?[dD0Ow]?[e3E]? ?[NnWw][&]?[a4A][cCgG]?[a-zzA-Z0-9]{1,8}",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },
  {
    "Id": 19,
    "DocumentType": "INE FAST CATPURE",
    "TemplateName": "INE_ANVERSO_ONLINE",
    "NextExtractionTemplateReference": "INE_REVERSO_ONLINE",
    "FrameHeight": 54,
    "FrameWidth": 86,
    "TotalFields": 1,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Encuadra la parte <b>delantera</b> de la identificación.",
    "Retries": 3,
    "Timer": 20,
    "Version": 1,
    "Fields": [
      {
        "Id": 11,
        "FieldName": "Nombre",
        "FieldType": "nombre",
        "FieldQuestionName": "MetadataNombre",
        "RegexValidation": "[A-Z ñÑ.a-z áéíóúÁÉÍÓÚÜ]{3,30}",
        "Version": 1,
        "Anchors": [
          {
            "Id": 19,
            "RegexLabel": "NOMBRE",
            "RegexField": null,
            "LeftPosition": -0.5,
            "TopPosition": -20.86,
            "Width": 35,
            "Height": 5.5,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "[a-z A-Z .ñÑ 0-9]{0,15}? ?[fFpP]?[e3E][CcNnOo0][hHRr]? ?[aA4]? ?[dD0O]?[e3E]? ?[Nn][&]?[a4A][cCgG]?[a-zzA-Z0-9]{1,8}",
        "RegexField": "[a-z A-Z .ñÑ 0-9]{0,15}? ?[fFpP]?[e3E][CcNnOo0][hHRr]? ?[aA4]? ?[dD0O]?[e3E]? ?[NnWw][&]?[a4A][cCgG]?[a-zzA-Z0-9]{1,8}",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },
  {
    "Id": 20,
    "DocumentType": "INE FAST CATPURE",
    "TemplateName": "IFE_REVERSO_ONLINE",
    "NetxExtractionTemplateReference": null,
    "FrameHeight": 54,
    "FrameWidth": 86,
    "TotalFields": 1,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Encuadra la parte <b>trasera</b> de la identificación.",
    "Retries": 3,
    "Timer": 20,
    "Version": 1,
    "Fields": [
      {
        "Id": 16,
        "FieldName": "OCR",
        "FieldType": "ocr",
        "FieldQuestionName": "MetadataNúmeroOCR",
        "RegexValidation": "[0-9]{13}",
        "OnlineFieldName": "OCR",
        "OnlineFieldNameOCR": "Número de OCR",
        "Version": 1,
        "Anchors": [
          {
            "Id": 20,
            "RegexLabel": "OCR",
            "RegexField": null,
            "LeftPosition": -5,
            "TopPosition": -7,
            "Width": 4.5,
            "Height": 28,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 5,
        "RegexLabel": "[3Ee][l1L]? ?[Tt7][iI1Tm][tT]?[uU][l1LI]?[a4A]?[rR]? ?[3Ee][sS5][tT7][4A] ?[0Oo][bB8][lLIUu]?[iI1]?[gG6][a4A][DodO0][0Oo] ?[a4A]? ?[Nn][0oO][Tt7][lLiI1]?[Hh-]?",
        "RegexField": "[3Ee][l1L]? ?[Tt7][iI1Tm][tT]?[uU][l1LI]?[a4A]?[rR]? ?[3Ee][sS5][tT7][4A] ?[0Oo][bB8][lLIUu]?[iI1]?[gG6][a4A][DodO0][0Oo] ?[a4A]? ?[Nn][0oO][Tt7][lLiI1]?[Hh-]?",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },
  {
    "Id": 21,
    "DocumentType": "INE FAST CATPURE",
    "TemplateName": "INE_REVERSO_ONLINE",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 54,
    "FrameWidth": 86,
    "TotalFields": 1,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Capture el <b>Reverso</b> de la identificación",
    "Retries": 3,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 41,
        "FieldName": "CIC",
        "FieldType": "cic",
        "FieldQuestionName": "MetadataNúmeroCIC",
        "RegexValidation": "[0-9]{9}",
        "OnlineFieldName": "Número CIC",
        "OnlineFieldNameOCR": "Número CIC",
        "Version": 1,
        "Anchors": [
          {
            "Id": 30,
            "RegexLabel": "IDMEX",
            "RegexField": null,
            "LeftPosition": -1,
            "TopPosition": -1,
            "Width": 75,
            "Height": 4.5,
            "ReferenceFieldPosition": "5,14",
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 5,
        "RegexLabel": " ?[1Ii] ?D ?M ?[3eE] ?X ?[a-zA-z0-9<< ]{5,90} ?",
        "RegexField": " ?[1Ii] ?D ?M ?[3eE] ?X ?[a-zA-z0-9<< ]{5,90} ?",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": 90,
        "Version": 1
      }
    ]
  },
  {
    "Id": 10,
    "DocumentType": "Luz",
    "TemplateName": "comprobante_anverso",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 55,
    "FrameWidth": 85,
    "TotalFields": 1,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Capture el Frente del documento",
    "Retries": 1,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 48,
        "FieldName": "Dirección",
        "FieldQuestionName": "MetadataDirección",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,155}",
        "OnlineFieldName": "direccion",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Dirección",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Nombre",
        "FieldQuestionName": "MetadataNombre",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,50}",
        "OnlineFieldName": "nombre",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Dirección",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Periodo",
        "FieldQuestionName": "MetadataPeriodo",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "periodo",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Dirección",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Número de Servicio",
        "FieldQuestionName": "MetadataServicio",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "noservicio",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Dirección",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "RegexField": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },
  {
    "Id": 11,
    "DocumentType": "Teléfono",
    "TemplateName": "comprobante_anverso",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 55,
    "FrameWidth": 85,
    "TotalFields": 1,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Capture el Frente del documento",
    "Retries": 1,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 48,
        "FieldName": "Dirección",
        "FieldQuestionName": "MetadataDirección",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,155}",
        "OnlineFieldName": "direccion",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Dirección",
            "RegexField": null,
            "LeftPosition": -5,
            "TopPosition": 8,
            "Width": 30,
            "Height": 8,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Nombre",
        "FieldQuestionName": "MetadataNombre",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,50}",
        "OnlineFieldName": "nombre",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Dirección",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Periodo",
        "FieldQuestionName": "MetadataPeriodo",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "periodo",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Dirección",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Teléfono",
        "FieldQuestionName": "MetadataTelefono",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,50}",
        "OnlineFieldName": "telefono",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Dirección",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "[T]?[E]?[L]?[E3]?[Fr]?[O0]?[N]?[OoD0]?[Ss]? [D]?[E]? [M]?[E]?[X0O]?[XIO]?[Cc]?[0Oo]? ?[Ss5]?.?[AB4]?.?[B8]?.? ?[d]?[e]? ?[CG]?.?[vV].?",
        "RegexField": "[T]?[E]?[L]?[E3]?[Fr]?[O0]?[N]?[OoD0]?[Ss]? [D]?[E]? [M]?[E]?[X0O]?[XIO]?[Cc]?[0Oo]? ?[Ss5]?.?[AB4]?.?[B8]?.? ?[d]?[e]? ?[CG]?.?[vV].?",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },
  {
    "Id": 21,
    "DocumentType": "Tarjeta de crédito",
    "TemplateName": "Tarjeta de crédito",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 55,
    "FrameWidth": 85,
    "TotalFields": 1,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Capture el documento",
    "Retries": 1,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 48,
        "FieldName": "Número de tarjeta",
        "FieldQuestionName": "numerotarjeta",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,155}",
        "OnlineFieldName": "numerotarjeta",
        "OnlineFieldNameOCR": "MetadataNúmeroTarjeta",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Número de tarjeta",
            "RegexField": null,
            "LeftPosition": -5,
            "TopPosition": 8,
            "Width": 30,
            "Height": 8,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Nombre",
        "FieldQuestionName": "nombre",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,50}",
        "OnlineFieldName": "nombre",
        "OnlineFieldNameOCR": "MetadataNombre",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 2,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Nombre",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Vigencia",
        "FieldQuestionName": "vigencia",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "vigencia",
        "OnlineFieldNameOCR": "MetadataVigencia",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 3,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Vigencia",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "[T]?[E]?[L]?[E3]?[Fr]?[O0]?[N]?[OoD0]?[Ss]? [D]?[E]? [M]?[E]?[X0O]?[XIO]?[Cc]?[0Oo]? ?[Ss5]?.?[AB4]?.?[B8]?.? ?[d]?[e]? ?[CG]?.?[vV].?",
        "RegexField": "[T]?[E]?[L]?[E3]?[Fr]?[O0]?[N]?[OoD0]?[Ss]? [D]?[E]? [M]?[E]?[X0O]?[XIO]?[Cc]?[0Oo]? ?[Ss5]?.?[AB4]?.?[B8]?.? ?[d]?[e]? ?[CG]?.?[vV].?",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },
  {
    "Id": 22,
    "DocumentType": "Licencia de conducir",
    "TemplateName": "Licencia de conducir",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 55,
    "FrameWidth": 85,
    "TotalFields": 1,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Capture el documento",
    "Retries": 1,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 48,
        "FieldName": "Nombre",
        "FieldQuestionName": "nombre",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,155}",
        "OnlineFieldName": "nombre",
        "OnlineFieldNameOCR": "MetadataNombre",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Nombre",
            "RegexField": null,
            "LeftPosition": -5,
            "TopPosition": 8,
            "Width": 30,
            "Height": 8,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Apellido paterno",
        "FieldQuestionName": "apaterno",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,50}",
        "OnlineFieldName": "apaterno",
        "OnlineFieldNameOCR": "MetadataApellidoPaterno",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 2,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Apellido paterno",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Apellido materno",
        "FieldQuestionName": "amaterno",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "amaterno",
        "OnlineFieldNameOCR": "MetadataApellidoMaterno",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 3,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Apellido materno",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "CURP",
        "FieldQuestionName": "curp",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "curp",
        "OnlineFieldNameOCR": "MetadataCURP",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 4,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "CURP",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Número de licencia",
        "FieldQuestionName": "nolicencia",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "nolicencia",
        "OnlineFieldNameOCR": "MetadataNúmeroLicencia",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 5,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Número de licencia",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Fecha de expedición",
        "FieldQuestionName": "fexpedicion",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "fexpedicion",
        "OnlineFieldNameOCR": "MetadataFechaExpedición",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Fecha de expedición",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Fecha de vencimiento",
        "FieldQuestionName": "fvencimiento",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "fvencimiento",
        "OnlineFieldNameOCR": "MetadataFechaVencimiento",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 7,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Fecha de vencimiento",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "[T]?[E]?[L]?[E3]?[Fr]?[O0]?[N]?[OoD0]?[Ss]? [D]?[E]? [M]?[E]?[X0O]?[XIO]?[Cc]?[0Oo]? ?[Ss5]?.?[AB4]?.?[B8]?.? ?[d]?[e]? ?[CG]?.?[vV].?",
        "RegexField": "[T]?[E]?[L]?[E3]?[Fr]?[O0]?[N]?[OoD0]?[Ss]? [D]?[E]? [M]?[E]?[X0O]?[XIO]?[Cc]?[0Oo]? ?[Ss5]?.?[AB4]?.?[B8]?.? ?[d]?[e]? ?[CG]?.?[vV].?",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },
  {
    "Id": 10,
    "DocumentType": "IZZI",
    "TemplateName": "comprobante_anverso",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 55,
    "FrameWidth": 85,
    "TotalFields": 1,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Capture el Frente del documento",
    "Retries": 1,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 48,
        "FieldName": "Teléfono",
        "FieldQuestionName": "Teléfono",
        "RegexValidation": "[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}",
        "OnlineFieldName": "Teléfono",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "maxLength": 10,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Teléfono",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Calle",
        "FieldQuestionName": "Calle",
        "RegexValidation": null,
        "OnlineFieldName": "Calle",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 2,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Calle",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Número",
        "FieldQuestionName": "Número",
        "RegexValidation": null,
        "OnlineFieldName": "Número",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 3,
        "maxLength": 10,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Número",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Código Postal",
        "FieldQuestionName": "Código Postal",
        "RegexValidation": null,
        "OnlineFieldName": "Código Postal",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 4,
        "maxLength": 5,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Código Postal",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Colonia",
        "FieldQuestionName": "Colonia",
        "RegexValidation": null,
        "OnlineFieldName": "Colonia",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 5,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Colonia",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Ciudad",
        "FieldQuestionName": "Ciudad",
        "RegexValidation": null,
        "OnlineFieldName": "Ciudad",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "maxLength": 50,
        "Sequence": 6,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Ciudad",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Municipio",
        "FieldQuestionName": "Municipio",
        "RegexValidation": null,
        "OnlineFieldName": "Municipio",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 7,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Municipio",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Estado",
        "FieldQuestionName": "Estado",
        "RegexValidation": null,
        "OnlineFieldName": "Estado",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 8,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Estado",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Cuenta",
        "FieldQuestionName": "Cuenta",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,155}",
        "OnlineFieldName": "Cuenta",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Dirección",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Dirección",
        "FieldQuestionName": "Dirección",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,50}",
        "OnlineFieldName": "Dirección",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Dirección",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Fecha",
        "FieldQuestionName": "Fecha",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Fecha",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Fecha",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Referencia",
        "FieldQuestionName": "Referencia",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Referencia",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Referencia",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Nombre",
        "FieldQuestionName": "Nombre",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Nombre",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Nombre",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Total",
        "FieldQuestionName": "Total",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Total",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Total",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "RegexField": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },
  {
    "Id": 10,
    "DocumentType": "TOTALPLAY",
    "TemplateName": "comprobante_anverso",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 55,
    "FrameWidth": 85,
    "TotalFields": 1,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Capture el Frente del documento",
    "Retries": 1,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 48,
        "FieldName": "Teléfono",
        "FieldQuestionName": "Teléfono",
        "RegexValidation": "[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}",
        "OnlineFieldName": "Teléfono",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "maxLength": 10,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Teléfono",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Calle",
        "FieldQuestionName": "Calle",
        "RegexValidation": null,
        "OnlineFieldName": "Calle",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 2,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Calle",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Número",
        "FieldQuestionName": "Número",
        "RegexValidation": null,
        "OnlineFieldName": "Número",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 3,
        "maxLength": 10,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Número",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Código Postal",
        "FieldQuestionName": "Código Postal",
        "RegexValidation": null,
        "OnlineFieldName": "Código Postal",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 4,
        "maxLength": 5,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Código Postal",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Colonia",
        "FieldQuestionName": "Colonia",
        "RegexValidation": null,
        "OnlineFieldName": "Colonia",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 5,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Colonia",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Ciudad",
        "FieldQuestionName": "Ciudad",
        "RegexValidation": null,
        "OnlineFieldName": "Ciudad",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Ciudad",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Municipio",
        "FieldQuestionName": "Municipio",
        "RegexValidation": null,
        "OnlineFieldName": "Municipio",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 7,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Municipio",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Estado",
        "FieldQuestionName": "Estado",
        "RegexValidation": null,
        "OnlineFieldName": "Estado",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 8,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Estado",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Cuenta",
        "FieldQuestionName": "Cuenta",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,155}",
        "OnlineFieldName": "Cuenta",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Dirección",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Dirección",
        "FieldQuestionName": "Dirección",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,50}",
        "OnlineFieldName": "Dirección",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Dirección",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Fecha",
        "FieldQuestionName": "Fecha",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Fecha",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Fecha",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Referencia",
        "FieldQuestionName": "Referencia",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Referencia",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Referencia",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Nombre",
        "FieldQuestionName": "Nombre",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Nombre",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Nombre",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Total",
        "FieldQuestionName": "Total",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Total",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Total",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "RegexField": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },
  {
    "Id": 10,
    "DocumentType": "TELCEL",
    "TemplateName": "comprobante_anverso",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 55,
    "FrameWidth": 85,
    "TotalFields": 1,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Capture el Frente del documento",
    "Retries": 1,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 48,
        "FieldName": "Teléfono",
        "FieldQuestionName": "Teléfono",
        "RegexValidation": "[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}",
        "OnlineFieldName": "Teléfono",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "maxLength": 10,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Teléfono",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Calle",
        "FieldQuestionName": "Calle",
        "RegexValidation": null,
        "OnlineFieldName": "Calle",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 2,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Calle",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Número",
        "FieldQuestionName": "Número",
        "RegexValidation": null,
        "OnlineFieldName": "Número",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 3,
        "maxLength": 10,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Número",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Código Postal",
        "FieldQuestionName": "Código Postal",
        "RegexValidation": null,
        "OnlineFieldName": "Código Postal",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 4,
        "maxLength": 5,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Código Postal",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Colonia",
        "FieldQuestionName": "Colonia",
        "RegexValidation": null,
        "OnlineFieldName": "Colonia",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 5,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Colonia",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Ciudad",
        "FieldQuestionName": "Ciudad",
        "RegexValidation": null,
        "OnlineFieldName": "Ciudad",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Ciudad",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Municipio",
        "FieldQuestionName": "Municipio",
        "RegexValidation": null,
        "OnlineFieldName": "Municipio",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 7,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Municipio",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Estado",
        "FieldQuestionName": "Estado",
        "RegexValidation": null,
        "OnlineFieldName": "Estado",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 8,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Estado",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Cuenta",
        "FieldQuestionName": "Cuenta",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,155}",
        "OnlineFieldName": "Cuenta",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Dirección",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Dirección",
        "FieldQuestionName": "Dirección",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,50}",
        "OnlineFieldName": "Dirección",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Dirección",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Fecha",
        "FieldQuestionName": "Fecha",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Fecha",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Fecha",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Referencia",
        "FieldQuestionName": "Referencia",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Referencia",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Referencia",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Nombre",
        "FieldQuestionName": "Nombre",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Nombre",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Nombre",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Total",
        "FieldQuestionName": "Total",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Total",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Total",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "RegexField": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },
  {
    "Id": 10,
    "DocumentType": "MOVISTAR",
    "TemplateName": "comprobante_anverso",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 55,
    "FrameWidth": 85,
    "TotalFields": 1,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Capture el Frente del documento",
    "Retries": 1,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 48,
        "FieldName": "Teléfono",
        "FieldQuestionName": "Teléfono",
        "RegexValidation": "[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}",
        "OnlineFieldName": "Teléfono",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "maxLength": 10,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Teléfono",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Calle",
        "FieldQuestionName": "Calle",
        "RegexValidation": null,
        "OnlineFieldName": "Calle",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 2,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Calle",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Número",
        "FieldQuestionName": "Número",
        "RegexValidation": null,
        "OnlineFieldName": "Número",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 3,
        "maxLength": 10,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Número",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Código Postal",
        "FieldQuestionName": "Código Postal",
        "RegexValidation": null,
        "OnlineFieldName": "Código Postal",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 4,
        "maxLength": 5,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Código Postal",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Colonia",
        "FieldQuestionName": "Colonia",
        "RegexValidation": null,
        "OnlineFieldName": "Colonia",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 5,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Colonia",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Ciudad",
        "FieldQuestionName": "Ciudad",
        "RegexValidation": null,
        "OnlineFieldName": "Ciudad",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Ciudad",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Municipio",
        "FieldQuestionName": "Municipio",
        "RegexValidation": null,
        "OnlineFieldName": "Municipio",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 7,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Municipio",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Estado",
        "FieldQuestionName": "Estado",
        "RegexValidation": null,
        "OnlineFieldName": "Estado",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 8,
        "maxLength": 50,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Estado",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Cuenta",
        "FieldQuestionName": "Cuenta",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,155}",
        "OnlineFieldName": "Cuenta",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Dirección",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Dirección",
        "FieldQuestionName": "Dirección",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,50}",
        "OnlineFieldName": "Dirección",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Dirección",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Fecha",
        "FieldQuestionName": "Fecha",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Fecha",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Fecha",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Referencia",
        "FieldQuestionName": "Referencia",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Referencia",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Referencia",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Nombre",
        "FieldQuestionName": "Nombre",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Nombre",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Nombre",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Total",
        "FieldQuestionName": "Total",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Total",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Total",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "RegexField": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },
  {
    "Id": 10,
    "DocumentType": "ATT",
    "TemplateName": "comprobante_anverso",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 55,
    "FrameWidth": 85,
    "TotalFields": 1,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Capture el Frente del documento",
    "Retries": 1,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 48,
        "FieldName": "Teléfono",
        "FieldQuestionName": "Teléfono",
        "RegexValidation": "[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}",
        "OnlineFieldName": "Teléfono",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "maxLength": 10,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Teléfono",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Calle",
        "FieldQuestionName": "Calle",
        "RegexValidation": null,
        "OnlineFieldName": "Calle",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 2,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Calle",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Número",
        "FieldQuestionName": "Número",
        "RegexValidation": null,
        "OnlineFieldName": "Número",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 3,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Número",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Código Postal",
        "FieldQuestionName": "Código Postal",
        "RegexValidation": null,
        "OnlineFieldName": "Código Postal",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 4,
        "maxLength": 5,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Código Postal",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Colonia",
        "FieldQuestionName": "Colonia",
        "RegexValidation": null,
        "OnlineFieldName": "Colonia",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 5,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Colonia",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Ciudad",
        "FieldQuestionName": "Ciudad",
        "RegexValidation": null,
        "OnlineFieldName": "Ciudad",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Ciudad",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Municipio",
        "FieldQuestionName": "Municipio",
        "RegexValidation": null,
        "OnlineFieldName": "Municipio",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 7,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Municipio",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Estado",
        "FieldQuestionName": "Estado",
        "RegexValidation": null,
        "OnlineFieldName": "Estado",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 8,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Estado",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Cuenta",
        "FieldQuestionName": "Cuenta",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,155}",
        "OnlineFieldName": "Cuenta",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Dirección",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Dirección",
        "FieldQuestionName": "Dirección",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,50}",
        "OnlineFieldName": "Dirección",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Dirección",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Fecha",
        "FieldQuestionName": "Fecha",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Fecha",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Fecha",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Referencia",
        "FieldQuestionName": "Referencia",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Referencia",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Referencia",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Nombre",
        "FieldQuestionName": "Nombre",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Nombre",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Nombre",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Total",
        "FieldQuestionName": "Total",
        "RegexValidation": "[A-Z 0-9,()ñÑ.a-z/áéíóúÁÉÍÓÚÜ-]{3,80}",
        "OnlineFieldName": "Total",
        "Version": 1,
        "Editable": true,
        "Visible": false,
        "Sequence": 1,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Total",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "RegexField": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },
  {
    "Id": 10,
    "DocumentType": "DNI",
    "TemplateName": "DNI",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 55,
    "FrameWidth": 85,
    "TotalFields": 1,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Capture el Frente del documento",
    "Retries": 1,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 48,
        "FieldName": "Nombre",
        "FieldQuestionName": "Nombre",
        "RegexValidation": "[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}",
        "OnlineFieldName": "Nombre",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "maxLength": 10,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Nombre",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Apellido",
        "FieldQuestionName": "Apellido",
        "RegexValidation": null,
        "OnlineFieldName": "Apellido",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 2,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Apellido",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Nacionalidad",
        "FieldQuestionName": "Nacionalidad",
        "RegexValidation": null,
        "OnlineFieldName": "Nacionalidad",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 3,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Nacionalidad",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Fecha de Nacimiento",
        "FieldQuestionName": "Fecha de Nacimiento",
        "RegexValidation": null,
        "OnlineFieldName": "Fecha de Nacimiento",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 4,
        "maxLength": 5,
        "QuestionType": "Fecha",
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Fecha de Nacimiento",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1,
            "QuestionType": "Fecha",
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Fecha de Vencimiento",
        "FieldQuestionName": "Fecha de Vencimiento",
        "RegexValidation": null,
        "OnlineFieldName": "Fecha de Vencimiento",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 5,
        "maxLength": 100,
        "QuestionType": "Fecha",
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Fecha de Vencimiento",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1,
            "QuestionType": "Fecha",
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Ciudad",
        "FieldQuestionName": "Ciudad",
        "RegexValidation": null,
        "OnlineFieldName": "Ciudad",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Ciudad",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "CUIL",
        "FieldQuestionName": "CUIL",
        "RegexValidation": null,
        "OnlineFieldName": "CUIL",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 7,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "CUIL",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      },
      {
        "Id": 48,
        "FieldName": "Documento",
        "FieldQuestionName": "Documento",
        "RegexValidation": null,
        "OnlineFieldName": "Documento",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 8,
        "maxLength": 100,
        "Anchors": [
          {
            "Id": 59,
            "RegexLabel": "Documento",
            "RegexField": null,
            "LeftPosition": -1.3,
            "TopPosition": 5.9,
            "Width": 30,
            "Height": 6,
            "ReferenceFieldPosition": null,
            "Version": 1
          }
        ]
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "RegexField": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },
  {
    "Id": 10,
    "DocumentType": "Cédula de Ciudadanía",
    "TemplateName": "Cédula de Ciudadanía",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 55,
    "FrameWidth": 85,
    "TotalFields": 1,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Capture el Frente del documento",
    "Retries": 1,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 48,
        "FieldName": "Nombres",
        "FieldQuestionName": "Nombres",
        "RegexValidation": "[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}",
        "OnlineFieldName": "Nombres",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "maxLength": 10
      },
      {
        "Id": 48,
        "FieldName": "Apellidos",
        "FieldQuestionName": "Apellidos",
        "RegexValidation": null,
        "OnlineFieldName": "Apellidos",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 2,
        "maxLength": 100
      },
      {
        "Id": 48,
        "FieldName": "Fecha de Nacimiento",
        "FieldQuestionName": "Fecha de Nacimiento",
        "RegexValidation": null,
        "OnlineFieldName": "Fecha de Nacimiento",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 4,
        "maxLength": 5,
        "QuestionType": "Fecha",
      },
      {
        "Id": 48,
        "FieldName": "Sexo",
        "FieldQuestionName": "Sexo",
        "RegexValidation": null,
        "OnlineFieldName": "Sexo",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "maxLength": 100
      },
      {
        "Id": 48,
        "FieldName": "Lugar de Nacimiento",
        "FieldQuestionName": "Lugar de Nacimiento",
        "RegexValidation": null,
        "OnlineFieldName": "Lugar de Nacimiento",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 7,
        "maxLength": 100
      },
      {
        "Id": 48,
        "FieldName": "Número",
        "FieldQuestionName": "Numero",
        "RegexValidation": null,
        "OnlineFieldName": "Numero",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 8,
        "maxLength": 100
      },
      {
        "Id": 48,
        "FieldName": "Fecha de Expedición",
        "FieldQuestionName": "Fecha de Expedición",
        "RegexValidation": null,
        "OnlineFieldName": "Fecha de Expedición",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 8,
        "maxLength": 100,
        "QuestionType": "Fecha",
      },
      {
        "Id": 48,
        "FieldName": "Lugar de Expedición",
        "FieldQuestionName": "Lugar de Expedición",
        "RegexValidation": null,
        "OnlineFieldName": "Lugar de Expedición",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 8,
        "maxLength": 100
      },
      {
        "Id": 48,
        "FieldName": "Tipo de Sangre",
        "FieldQuestionName": "Tipo de Sangre",
        "RegexValidation": null,
        "OnlineFieldName": "Tipo de Sangre",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 8,
        "maxLength": 100
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "RegexField": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },
  {
    "Id": 10,
    "DocumentType": "Cédula de Identidad",
    "TemplateName": "Cédula de Identidad",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 55,
    "FrameWidth": 85,
    "TotalFields": 1,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Capture el Frente del documento",
    "Retries": 1,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 48,
        "FieldName": "Nombres",
        "FieldQuestionName": "Nombres",
        "RegexValidation": "[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}",
        "OnlineFieldName": "Nombres",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "maxLength": 10
      },
      {
        "Id": 48,
        "FieldName": "Apellidos",
        "FieldQuestionName": "Apellidos",
        "RegexValidation": null,
        "OnlineFieldName": "Apellidos",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 2,
        "maxLength": 100
      },
      {
        "Id": 48,
        "FieldName": "Fecha de Nacimiento",
        "FieldQuestionName": "Fecha de Nacimiento",
        "RegexValidation": null,
        "OnlineFieldName": "Fecha de Nacimiento",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 4,
        "maxLength": 5,
        // "QuestionType": "Fecha",
      },
      {
        "Id": 48,
        "FieldName": "Fecha de Vencimiento",
        "FieldQuestionName": "Fecha de Vencimiento",
        "RegexValidation": null,
        "OnlineFieldName": "Fecha de Vencimiento",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 5,
        "maxLength": 100,
        // "QuestionType": "Fecha",
      },
      // {
      //   "Id": 48,
      //   "FieldName": "Numero de Documento",
      //   "FieldQuestionName": "SexNumero de Documentoo",
      //   "RegexValidation": null,
      //   "OnlineFieldName": "Numero de Documento",
      //   "Version": 1,
      //   "Editable": true,
      //   "Visible": true,
      //   "Sequence": 6,
      //   "maxLength": 100
      // },
      /* {
        "Id": 48,
        "FieldName": "Nacionalidad",
        "FieldQuestionName": "Nacionalidad",
        "RegexValidation": null,
        "OnlineFieldName": "Nacionalidad",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 7,
        "maxLength": 100
      }, */
      {
        "Id": 48,
        "FieldName": "RUT",
        "FieldQuestionName": "RUN",
        "RegexValidation": null,
        "OnlineFieldName": "RUN",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 8,
        "maxLength": 100
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "RegexField": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },
  {
    "Id": 10,
    "DocumentType": "Cédula de Identidad UY",
    "TemplateName": "Cédula de Identidad UY",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 55,
    "FrameWidth": 85,
    "TotalFields": 1,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Capture el Frente del documento",
    "Retries": 1,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 48,
        "FieldName": "Nombre",
        "FieldQuestionName": "Nombre",
        "RegexValidation": "[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}",
        "OnlineFieldName": "Nombre",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "maxLength": 10
      },
      {
        "Id": 48,
        "FieldName": "Apellido",
        "FieldQuestionName": "Apellido",
        "RegexValidation": null,
        "OnlineFieldName": "Apellido",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 2,
        "maxLength": 100
      },
      {
        "Id": 48,
        "FieldName": "Fecha de Nacimiento",
        "FieldQuestionName": "Fecha de Nacimiento",
        "RegexValidation": null,
        "OnlineFieldName": "Fecha de Nacimiento",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 4,
        "maxLength": 5,
        "QuestionType": "Fecha",
      },
      {
        "Id": 48,
        "FieldName": "Fecha de Vencimiento",
        "FieldQuestionName": "Fecha de Vencimiento",
        "RegexValidation": null,
        "OnlineFieldName": "Fecha de Vencimiento",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 5,
        "maxLength": 100,
        "QuestionType": "Fecha",
      },
      {
        "Id": 48,
        "FieldName": "Numero de Documento",
        "FieldQuestionName": "SexNumero de Documentoo",
        "RegexValidation": null,
        "OnlineFieldName": "Numero de Documento",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "maxLength": 100
      },
      {
        "Id": 48,
        "FieldName": "Nacionalidad",
        "FieldQuestionName": "Nacionalidad",
        "RegexValidation": null,
        "OnlineFieldName": "Nacionalidad",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 7,
        "maxLength": 100
      },
     /*  {
        "Id": 48,
        "FieldName": "RUN",
        "FieldQuestionName": "RUN",
        "RegexValidation": null,
        "OnlineFieldName": "RUN",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 8,
        "maxLength": 100
      } */
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "RegexField": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  },
  {
    "Id": 10,
    "DocumentType": "Cédula Profesional",
    "TemplateName": "Cédula Profesional",
    "NextExtractionTemplateReference": null,
    "FrameHeight": 55,
    "FrameWidth": 85,
    "TotalFields": 1,
    "FieldTolerance": 1,
    "ManualCapture": false,
    "HelpTag": "Capture el Frente del documento",
    "Retries": 1,
    "Timer": 15,
    "Version": 1,
    "Fields": [
      {
        "Id": 48,
        "FieldName": "Nombres",
        "FieldQuestionName": "Nombre(s)",
        "RegexValidation": "[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}",
        "OnlineFieldName": "Nombre(s)",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 1,
        "maxLength": 10
      },
      {
        "Id": 48,
        "FieldName": "Apellido Paterno",
        "FieldQuestionName": "Primer Apellido",
        "RegexValidation": null,
        "OnlineFieldName": "Primer Apellido",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 2,
        "maxLength": 100
      },
      {
        "Id": 48,
        "FieldName": "Apellido Materno",
        "FieldQuestionName": "Segundo Apellido",
        "RegexValidation": null,
        "OnlineFieldName": "Segundo Apellido",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 2,
        "maxLength": 100
      },
      {
        "Id": 48,
        "FieldName": "Fecha",
        "FieldQuestionName": "Fecha",
        "RegexValidation": null,
        "OnlineFieldName": "Fecha",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 5,
        "maxLength": 100,
      },
      {
        "Id": 48,
        "FieldName": "Número de Cédula Profesional",
        "FieldQuestionName": "Número de Cédula Profesional",
        "RegexValidation": null,
        "OnlineFieldName": "Número de Cédula Profesional",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 7,
        "maxLength": 100
      },
      {
        "Id": 48,
        "FieldName": "Clave",
        "FieldQuestionName": "Clave",
        "RegexValidation": null,
        "OnlineFieldName": "Clave",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 4,
        "maxLength": 5,
      },
      {
        "Id": 48,
        "FieldName": "Clave Institución",
        "FieldQuestionName": "Clave Institución",
        "RegexValidation": null,
        "OnlineFieldName": "Clave Institución",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "maxLength": 100
      },
      {
        "Id": 48,
        "FieldName": "Tipo",
        "FieldQuestionName": "Tipo",
        "RegexValidation": null,
        "OnlineFieldName": "Tipo",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "maxLength": 100
      },
      {
        "Id": 48,
        "FieldName": "Foja",
        "FieldQuestionName": "Foja",
        "RegexValidation": null,
        "OnlineFieldName": "Foja",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "maxLength": 100
      },
      {
        "Id": 48,
        "FieldName": "Libro",
        "FieldQuestionName": "Libro",
        "RegexValidation": null,
        "OnlineFieldName": "Libro",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "maxLength": 100
      },
      {
        "Id": 48,
        "FieldName": "Nombre del Programa",
        "FieldQuestionName": "Nombre del Programa",
        "RegexValidation": null,
        "OnlineFieldName": "Nombre del Programa",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "maxLength": 100
      },
      {
        "Id": 48,
        "FieldName": "Entidad Federativa",
        "FieldQuestionName": "Entidad Federativa",
        "RegexValidation": null,
        "OnlineFieldName": "Entidad Federativa",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "maxLength": 100
      },
      {
        "Id": 48,
        "FieldName": "Número",
        "FieldQuestionName": "Número",
        "RegexValidation": null,
        "OnlineFieldName": "Número",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "maxLength": 100
      },
      {
        "Id": 48,
        "FieldName": "Hora",
        "FieldQuestionName": "Hora",
        "RegexValidation": null,
        "OnlineFieldName": "Hora",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "maxLength": 100
      },
      {
        "Id": 48,
        "FieldName": "CURP",
        "FieldQuestionName": "CURP",
        "RegexValidation": null,
        "OnlineFieldName": "CURP",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "maxLength": 100
      },
      {
        "Id": 48,
        "FieldName": "Denominación",
        "FieldQuestionName": "Denominación",
        "RegexValidation": null,
        "OnlineFieldName": "Denominación",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "maxLength": 100
      },
      {
        "Id": 48,
        "FieldName": "RFC",
        "FieldQuestionName": "RFC",
        "RegexValidation": null,
        "OnlineFieldName": "RFC",
        "Version": 1,
        "Editable": true,
        "Visible": true,
        "Sequence": 6,
        "maxLength": 100
      }
    ],
    "Anchors": [
      {
        "Id": 3,
        "RegexLabel": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "RegexField": "[Cc]?[aOo0][Mm][i1l]?[sS5]?[i1l]?[dó0á]?[Nn] [Ff][eEo][dD][e3E][r][a][liLI]? ?[dD]?[e3]? ?[E3e][lI][ec]?[a-zzA-Z0-9]{1,9}",
        "LeftPosition": "54",
        "TopPosition": "10",
        "Width": null,
        "Height": null,
        "ReferenceFieldPosition": null,
        "Version": 1
      }
    ]
  }
]

