import { useRef } from "react";
import { IconButton, Dialog, styled, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/styles";
import actionsTutorial from "assets/Datos.mp4";

export const ModalContainer = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    "& .MuiDialog-paper": {
      display: "flex",
      flexDirection: "column",
      gap: "2rem",
      alignItems: "center",
      minWidth: "60vw",
      padding: "2rem",
    },
  },
}));

export const VideoTutorialModal = ({ open, onClose }) => {
  const theme = useTheme();
  const videoRef = useRef(null);
  const env = sessionStorage.getItem('enviroment');
  const handleLoadedMetadata = () => {
    if (videoRef.current) videoRef.current.playbackRate = 0.5;
  };

  return (
    <ModalContainer open={open}>
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: "1rem",
          top: "1rem",
          color: theme.palette.primary.main,
        }}
      >
        <CloseIcon fontSize={"large"} />
      </IconButton>
      <Typography variant={"h6"}>Instrucciones de uso</Typography>
      <video
        width="100%"
        controls
        autoPlay
        ref={videoRef}
        onLoadedMetadata={handleLoadedMetadata}
      >
        <source src={videoTutorial[env/*  !== "undefined" ? env : "OBV3" */ ]} type="video/mp4" />
      </video>
    </ModalContainer>
  );
};

const videoTutorial = {
  METLIFE: actionsTutorial,
  OBV3: actionsTutorial,
  SANTANDER: actionsTutorial,
};
