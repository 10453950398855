import { GenericButtonList } from 'components';
import React from 'react';
import { GenericStatusElement } from '../../GenericStatusElement/GenericStatusElement';

const SelectOptionsItem = ({ label, icon, handleOption }) => {
  return (
    <GenericButtonList onClick={handleOption}>
      <GenericStatusElement text={label} icon={icon} noArrow />
    </GenericButtonList>
  );
};

export default SelectOptionsItem;
