import {
  Box,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableCell from "@mui/material/TableCell";
import { Icon } from "@iconify/react";
import {
  ExpirationBox,
  TableCellHead,
  TableRowCustom,
} from "./MainDocAdvancedTable.styled";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/en";
import "dayjs/locale/pt";
import "dayjs/locale/ja";
import useSWR from "swr";
import { getPermissions } from "api/permission/permissionRequests";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatDateByLocale, IsDevice } from "utils";

const formats = {
  es: "[Cargado el] DD [de] MMMM [del] YYYY",
  en: "[Loaded on] MMMM D, YYYY",
  pt: "[Carregado em] D [de] MMMM [de] YYYY",
  ja: "YYYY年M月D日",
};

const formatDate = (inputDate) => {
  const locale = localStorage.getItem("MTdefaultLang") ?? "en";
  dayjs.locale(locale);
  const date = dayjs(inputDate);
  return date.format(formats[locale] || formats["en"]);
};

export const MainDocAdvancedTableMobile = ({ tableData, onClickView }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { IsMobile } = IsDevice();
  const [orderDirection, setOrderDirection] = useState("");
  const [valueToOrderBy, setValueToOrderBy] = useState("");
  const stateAuth = useSelector((state) => state.Authentication);
  const userId = stateAuth.items?.UserLoginData?.Id;

  const PAYLOAD = {
    User_Id: userId,
    DocumentalGroup_Id: null,
    AllByTenant: false,
  };

  const { data: documentPermissions } = useSWR(
    "PermissionsByRol",
    () => getPermissions(PAYLOAD),
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const columns = [
    {
      id: "GrouperName",
      label: t("main_doc_advenced_table_grouper"),
      minWidth: 170,
    },
    { id: "Name", label: t("main_doc_advenced_table_doc_name"), minWidth: 170 },
    {
      id: "ExpirationDate",
      label: t("main_doc_advenced_table_effective_date"),
      minWidth: 170,
    },
    {
      id: "ExpirationStatus",
      label: t("main_doc_advenced_table_status"),
      minWidth: 170,
    },
  ];

  const rows = tableData.map((row) => {
    return {
      ...row,
      GrouperName: row.grouperName,
      Name: row.Name,
      ExpirationDate: row?.Validity?.ValidityDate
        ? formatDateByLocale(row?.Validity?.ValidityDate)
        : t("main_doc_advenced_table_no_caducity"),
      ExpirationStatus:
        row?.Validity?.Status ?? t("document_details_without_caducity"),
      GlobalStatus: row?.Validity?.GlobalStatus,
      Permissions: documentPermissions?.PermissionGroups?.find(
        (documentPermission) =>
          documentPermission.DocumentalGroupName === row.grouperName
      )?.Permissions,
    };
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handlerSort = (event, property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };

  const createSortHandler = (property) => (event) => {
    handlerSort(event, property);
  };

  if (!documentPermissions) return null;

  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableBody>
            {rows
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRowCustom
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    onClick={() => {
                      onClickView(row);
                    }}
                  >
                    <TableCell sx={{ borderBottom: "none", p: 1, py: 2 }}>
                      <CustomAssigmentCardMobile data={row} columns={columns} />
                    </TableCell>
                  </TableRowCustom>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        count={tableData?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
      />
    </>
  );
};

const MainStack = styled(Stack)(({ theme }) => ({
  border: "1px solid lightgray",
  borderRadius: "10px",
  minHeight: "12em",
  padding: "18px",
  paddingLeft: "24px",
  paddingRight: "24px",
  userSelect: "none",
}));
const BoxRow = styled(Box)(({ theme }) => ({
  borderBottom: "1px solid lightgray",
  paddingTop: "4px",
  paddingBottom: "4px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));
const TypographyColumn = styled(Typography)(({ theme }) => ({
  maxWidth: "50%",
  textAlign: "left",
  fontWeight: 550,
}));
const TypographyData = styled(Typography)(({ theme }) => ({
  maxWidth: "50%",
  textAlign: "right",
}));

const CustomAssigmentCardMobile = ({ data, columns }) => {
  return (
    <MainStack>
      {columns?.map((col) => {
        return col.id == "folio" ? (
          <Box sx={{ borderBottom: "1px solid lightgray", pb: 1 }}>
            <Typography variant="h5">{data.folio}</Typography>
          </Box>
        ) : (
          <BoxRow>
            <TypographyColumn>{col.label ?? ""}</TypographyColumn>
            <TypographyData variant="body1">{data[col.id]}</TypographyData>
          </BoxRow>
        );
      })}
    </MainStack>
  );
};
