const PATH_NOTIFY_DATA = 'notify/GetNotifyData';

const PATH_UPDATE_EMAILS = 'notify/UpdateEmails';

const PATH_BLOCK_NOTIFICATION = 'notify/BlockNotification';

const PATH_READ_NOTIFICATION = 'notify/ReadNotification';

const SEND_NOTIFICATION_PATH = 'notify/SendNotification';

export { PATH_NOTIFY_DATA, PATH_UPDATE_EMAILS, PATH_BLOCK_NOTIFICATION, PATH_READ_NOTIFICATION, SEND_NOTIFICATION_PATH };
