import { Box, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableCell from "@mui/material/TableCell";
import { Icon } from "@iconify/react";
import {
  ExpirationBox,
  TableCellHead,
  TableRowCustom,
} from "./MainDocAdvancedTable.styled";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/en";
import "dayjs/locale/pt";
import "dayjs/locale/ja";
import useSWR from "swr";
import { getPermissions } from "api/permission/permissionRequests";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatDateByLocale } from "utils";

const formats = {
  es: "[Cargado el] DD [de] MMMM [del] YYYY",
  en: "[Loaded on] MMMM D, YYYY",
  pt: "[Carregado em] D [de] MMMM [de] YYYY",
  ja: "YYYY年M月D日",
};

const formatDate = (inputDate) => {
  const locale = localStorage.getItem("lang");
  dayjs.locale(locale);
  const date = dayjs(inputDate);
  return date.format(formats[locale] || formats["en"]);
};

export const MainDocAdvancedTable = ({
  tableData,
  onClickView,
  onClickDownload,
  onClickUpload,
  onClickDelete,
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderDirection, setOrderDirection] = useState("");
  const [valueToOrderBy, setValueToOrderBy] = useState("");
  const stateAuth = useSelector((state) => state.Authentication);
  const userId = stateAuth.items?.UserLoginData?.Id;

  const PAYLOAD = {
    User_Id: userId,
    DocumentalGroup_Id: null,
    AllByTenant: false,
  };

  const { data: documentPermissions } = useSWR(
    "PermissionsByRol",
    () => getPermissions(PAYLOAD),
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  console.log('tableData', tableData)

  const columns = [
    { id: 'grouperName', label: t("main_doc_advenced_table_grouper_name"), minWidth: 170, textAlign:'left' },
    { id: 'Name', label: t("main_doc_advenced_table_doc_name"), minWidth: 170, textAlign:'left' },
    // { id: 'GrouperName', label: t("main_doc_advenced_table_grouper"), minWidth: 170, textAlign:'left' },
    { id: 'ExpirationDate', label: t("main_doc_advenced_table_effective_date"), minWidth: 170, textAlign:'center' },
    { id: 'ExpirationStatus', label: t("main_doc_advenced_table_status"), minWidth: 170, textAlign:'center' },
    { id: 'actions', label: t("main_doc_advenced_table_actions"), minWidth: 200, textAlign:'center'},
  ];

  const rows = tableData.map((row) => {
    return {
      ...row,
      GrouperName: row.grouperDescription,
      Name: row.Name,
      ExpirationDate: row?.Validity?.ValidityDate
        ? formatDateByLocale(row?.Validity?.ValidityDate)
        : t("main_doc_advenced_table_no_caducity"),
      ExpirationStatus:
        row?.Validity?.Status ?? t("document_details_without_caducity"),
      GlobalStatus: row?.Validity?.GlobalStatus,
      Permissions: documentPermissions?.PermissionGroups?.find(
        (documentPermission) =>
          documentPermission.DocumentalGroupName === row.grouperName,
      )?.Permissions,
    };
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handlerSort = (event, property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };

  const createSortHandler = (property) => (event) => {
    handlerSort(event, property);
  };

  if (!documentPermissions) return null;

  return (
    <>
      <TableContainer
        sx={{
          width: "100%",
          borderRadius: "1rem",
          border: "1px solid #CCCCCC",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCellHead
                  key={column.id}
                  style={{
                    minWidth: column.minWidth,
                    textAlign: column.textAlign,
                  }}
                  onClick={createSortHandler(column.label)}
                >
                  {column.label}
                </TableCellHead>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
              const uploadPermission = row.Permissions?.[1].Status === '1';
              const downloadPermission = row.Permissions?.[2].Status === '1';
              const deletePermission = row.Permissions?.[3].Status === '1';
              return (
                <TableRowCustom
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                  onClick={() => {
                    onClickView(row);
                  }}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.textAlign}>
                        {column.id === 'ExpirationStatus'
                          ? null
                          : column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                          {column.id === "ExpirationStatus" && (
                            <ExpirationBox
                              textAlign={"center"}
                              expirationStatus={row.GlobalStatus}
                            >
                              {row.ExpirationStatus}
                            </ExpirationBox>
                          )}
                          {column.id === "actions" && (
                            <>
                              {uploadPermission && (
                                <Tooltip
                                  title={t("general_load")}
                                  placement="top"
                                >
                                  <IconButton
                                    aria-label="upload"
                                    color="primary"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      onClickUpload(row);
                                    }}
                                  >
                                    <Icon
                                      icon="solar:file-send-linear"
                                      color={"black"}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}

                              {downloadPermission && (
                                <Tooltip
                                  title={t("general_download")}
                                  placement="top"
                                >
                                  <IconButton
                                    aria-label="download"
                                    color="primary"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      onClickDownload(row);
                                    }}
                                  >
                                    <Icon
                                      icon="solar:file-download-linear"
                                      color={"black"}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {deletePermission && (
                                <Tooltip
                                  title={t("general_delete")}
                                  placement="top"
                                >
                                  <IconButton
                                    aria-label="delete"
                                    color="primary"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      onClickDelete(row);
                                    }}
                                  >
                                    <Icon
                                      icon="ph:trash-light"
                                      fontSize={"1.7rem"}
                                      color={"black"}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </>
                          )}
                          {column.id === "GrouperName" && (
                            <Box style={{ color: "gray" }}>
                              {formatDate(row.CreationDate)}
                            </Box>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRowCustom>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        count={tableData?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
      />
    </>
  );
};
