import Stack from "@mui/material/Stack";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React from "react";
import { Icon } from "@iconify/react";
import useSWR from "swr";
import { DocumentMetadataTable } from "../../Tables/DocumentMetadataTable";
import { RoundedButton } from "components/Buttons";
import { DocumentHasNoMetadata } from "./DocumentHasNoMetadata";
import { getDocumentMetadata } from "../../../../api/document/documentRequests";
import { useTranslation } from "react-i18next";

export const DocumentMetadataView = ({ handleMode, selectedDocument, setSelectedDocumentMetadata }) => {
  const { t } = useTranslation()
  const configId = JSON.parse(sessionStorage.getItem("configurationData")).Id;

  const PAYLOAD = {
    ClasificationId: configId,
    DocumentTypeId: selectedDocument?.Id
  };

  const {
    data: tableData,
    isValidating,
    mutate,
  } = useSWR("GetDocumentMetadata", () => getDocumentMetadata(PAYLOAD), {
    refreshInterval: 0,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return (
    <Stack spacing={'2rem'}>
      <Box display='flex' justifyContent='space-between' >
        <Box display='flex' alignItems='center' gap={'1rem'}>
          <IconButton onClick={() => handleMode('DOCUMENT_VIEW')}>
            <Icon icon={'mdi:arrow-back'} />
          </IconButton>
          <Typography variant="h6" textAlign={'start'}>
            {selectedDocument.Name}
          </Typography>
        </Box>
        <RoundedButton sx={{ minWidth: "20%" }} variant="contained" onClick={() => {
          setSelectedDocumentMetadata(null);
          handleMode("ADD_DOCUMENT_METADATA_FORM");
        }}>
          {t("configuration_metadata_add_metadata")}
        </RoundedButton>
      </Box>
      {tableData?.Metadatas?.length > 0
        ? <>
          <Typography variant="span">
            {t("configuration_metadata_instruction")}
          </Typography>
          <DocumentMetadataTable selectedDocument={selectedDocument} handleMode={handleMode} setSelectedDocumentMetadata={setSelectedDocumentMetadata} />
        </>
        : <DocumentHasNoMetadata />}
    </Stack>
  );
}