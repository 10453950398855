//The difference between ActotMVisual and ModalVisual are the conditions to show the approbal buttons
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  CircularProgress,
  // Card,
  Grid,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Paper,
  OutlinedInput,
  IconButton,
  useTheme,
} from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
import {
  FormProvider,
  useFormContext,
  Controller,
  useForm,
} from "react-hook-form";
import CancelIcon from "@mui/icons-material/Cancel";
import { ArrowDropDown, FiberManualRecord } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { makeStyles } from "@mui/styles";
import Parser from "html-react-parser";

// import { useStyles } from "./ModalStyles";
// import { mainStyles } from "../../theme/mainStyles";
// import { CustomAlert } from "../CustomAlert";
// import { addSpaceToString } from "./modalsFunctions";

// import ine from "../../assets/id.svg";
// import passport from "../../assets/passport.svg";
// import curp from "../../assets/CURP.svg";
// import comprobante from "../../assets/ComprobanteGen.svg";
// import consentimiento from "../../assets/consentimiento.svg";
// import contrato from "../../assets/contrato.svg";
// import privacidad from "../../assets/privacidad.svg";
// import ingresos from "../../assets/ingresos.svg";
// import nom from "../../assets/NOM.svg";
// import ubicacion from "../../assets/ubicacion.svg";
import docGenerico from "../../../assets/docGenerico.svg";
// import evAudio from "../../assets/evAudio.svg";
// import evVideo from "../../assets/evVideo.svg";
// import frames from "../../assets/frames.svg";
import procesandoVideo from "../../../assets/procesando_video.gif";

import loadDoc from "../../../assets/loadDoc.svg";
import {
  documentActions,
  validationActions,
  fileActions,
} from "../../../store/actions";
import { paths } from "../../../utils";
import {
  UseImage,
  CustomLoadingOverlay,
  AlertConfirm,
  AlertConfirmInput,
  CustomAlert,
} from "../../index";
import { documentRequests } from "../../../services";
import { TableRowControl } from "./../../table"; //"../../../table";
import { Alert } from "../../";
import { useTranslation } from "react-i18next";

import {
  Card,
  IconSection,
  ImageLoadIcon,
  ImageDoumentIcon,
  CloseIcon,
  GridContainer,
  lineVisualDiv,
  TextFieldComment,
} from "./DocumentApproval.styled";
import StatusRenderer from "./../DocBasicView/StatusRenderer";
import VisorPDF from "components/Visor/VisorPDF";

const useClasses = makeStyles((theme) => ({
  modalEditable: {
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,
    width: "100%",
    minHeight: "75vh",
  },
  inputText: {
    marginTop: 15,
    /*   display: "block", */
    "& .MuiOutlinedInput-input": {
      height: 25,
      padding: "10px 15px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 12,
      borderColor: "red",
      fontSize: 14,
      "& fieldset legend span": {
        display: "none",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#000",
      position: "relative",
      transform: "none",
      fontSize: 14,
      marginBottom: 8,
      marginTop: 5,
      display: "flex",
      alignItems: "center",
      pointerEvents: "auto",
      fontWeight: 500,
    },
    "& label.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
  outlinedText: {
    marginTop: 15,
    borderRadius: 12,
    fontSize: 14,
    "& .MuiOutlinedInput-input": {
      height: 25,
      padding: "10px 15px",
    },
  },
  accSummary: {
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
  },
  tableHead: {
    backgroundColor: "#AAA",
    "& th": {
      color: "#FFF",
    },
  },
  errorInput: {
    "& fieldset": {
      border: `2px solid red`,
    },
    "& .MuiFormLabel-root": {
      color: "red",
    },
  },
  indicator: {
    color: "red",
  },
  capitalize: {
    textTransform: "capitalize !important",
  },
}));

export const DocumentModalViewer = ({ docInfo, statusCurrent }) => {
  const formMethods = useForm();
  const { control, handleSubmit } = formMethods;

  const infoFile = useSelector((state) => state.FileInfo);
  const fileId = useSelector((state) => state.FileInfo.items.Id);
  const postDocumentValidationState = useSelector(
    (state) => state.DocumentValidation,
  );
  const statusVideo = useSelector(
    (state) =>
      state.BiometricData.items?.Face?.Selfie?.ComparisonVsVideo?.StatusVideo,
  );
  const workFlowInstanceId = sessionStorage.getItem("WorkFlowInstanceId");

  const [openDocFileApproval, setOpenDocFileApproval] = useState(false);
  const [openExtRejection, setOpenExtRejection] = useState(false);
  const [inputComment, setInputComment] = useState("");
  const [openDocFileRejection, setOpenDocFileRejection] = useState(false);
  const [approved, setApproved] = useState(false);

  const dispatch = useDispatch();

  const theme = useTheme();

  const [openViewer, setOpenViewer] = useState(false);
  const [extApproved, setExtApproved] = useState(false);
  const [groups, setGroups] = useState([]);
  const [openExtApproval, setOpenExtApproval] = useState(false);
  const [comment, setComment] = useState("");
  const [isApprovedDocument, setIsApprovedDocument] = useState(false);

  const [openEditableModal, setopenEditableModal] = useState(false);
  const [documentInfo, setdocumentInfo] = useState(null);
  const [loading, setloading] = useState(false);

  const document = useSelector((state) => state.Document);
  const extDocValidation = useSelector((state) => state.DocExternalVal); // to validate docs without affecting the file

  let path = window.location.pathname;

  const { t } = useTranslation();

  useEffect(() => {
    if (openDocFileApproval) {
      AlertConfirm(
        {
          icon: "warning",
          html: (
            <Box>
              <Typography
                textAlign={"center"}
                variant="body1"
                sx={{ mt: 1, textAlign: "center", maxWidth: 400, mx: "auto" }}
              >
                {t("approve_document_confirm")}
              </Typography>
            </Box>
          ),
          okbtntext: t("general_accept"),
          notbtntext: t("general_cancel"),
        },
        (result) => {
          if (result.isConfirmed) {
            let objRequest = {
              Document_Id: docInfo.id,
              Approved: true,
              Comments: "",
              ValidateFileComplete: true,
              WorkFlowInstanceId: workFlowInstanceId,
            };
            console.log("objRequest 0", objRequest);
            dispatch(validationActions.PostDocumentValidation(objRequest));
            setApproved(true);
            dispatch(fileActions.AsignateCurrentUser({ File_Id: fileId }));
          } else if (result.dismiss === "cancel" && result.isDismissed) {
          }
          setOpenDocFileApproval(false);
        },
        null,
        t,
      );
    }
  }, [openDocFileApproval, docInfo, fileId, t]);

  useEffect(() => {
    if (openExtApproval) {
      AlertConfirm(
        {
          icon: "warning",
          html: (
            <Box>
              <Typography
                textAlign={"center"}
                variant="body1"
                sx={{ mt: 1, textAlign: "center", maxWidth: 400, mx: "auto" }}
              >
                {t("approve_document_confirm")}
              </Typography>
            </Box>
          ),
          okbtntext: t("general_accept"),
          notbtntext: t("general_cancel"),
        },
        (result) => {
          if (result.isConfirmed) {
            setExtApproved(true);
            dispatch(
              validationActions.SendDocExternalVal({
                Document_Id: docInfo.id,
                Approved: true,
                Comments: "",
                ValidateFileComplete: false,
              }),
            );
            handleClose();
          } else if (result.dismiss === "cancel" && result.isDismissed) {
          }
          setOpenExtApproval(false);
        },
        null,
        t,
      );
    }
  }, [openExtApproval]);

  const handleAcceptDocument = async (formData) => {
    const generalMetadata = documentInfo.docInfo.metaData?.map((meta, i) => {
      return {
        ...meta,
        Value: formData[meta.Name],
      };
    });
    let body = {
      Metadata: [...generalMetadata],
    };
    const nameTables = documentInfo.metadataTables.map((el) => el.Name);
    const tablesKeys = nameTables.map((nameTab, i) => {
      let aux = [];
      Object.keys(formData).forEach((keyform) => {
        if (keyform.startsWith(nameTab)) {
          const fields = keyform.split("-");
          aux = [
            ...aux,
            {
              tableName: fields[0],
              fieldName: fields[1],
              order: +fields[2],
              fieldValue: formData[keyform],
            },
          ];
        }
      });
      return aux;
    });
    console.log("-->", tablesKeys);

    const groups = tablesKeys.map((tab) => {
      let aux = [];
      tab.forEach((t) => {
        aux[t.order] = [...(aux[t.order] ?? []), t];
      });
      return aux;
    });

    groups.forEach((row, i) => {
      const rows = row.map((r) => {
        return r.map((el) => ({ TextValue: el.fieldValue, Confidence: 1.0 }));
      });
      const table = {
        Columns: JSON.parse(documentInfo.metadataTables[i].Value).Columns,
        Rows: rows,
      };
      body = {
        ...body,
        Metadata: [
          ...body.Metadata,
          {
            ...documentInfo.metadataTables[i],
            Value: JSON.stringify(table),
          },
        ],
      };
    });

    body = {
      ...body,
      ObjectId: docInfo.id,
      Metadata: [...body.Metadata, ...documentInfo.metadataExtra],
      ContinueWorkFlow: false,
    };

    console.log(body);
    try {
      setloading(true);
      const response = await documentRequests.updateMetadata(body);
      console.log(response);
      const responseValidate = await documentRequests.validateDocument({
        Document_Id: docInfo.id,
        Approved: true,
        Comments: "",
        ValidateFileComplete: false,
      });
      console.log(responseValidate);
      setopenEditableModal(false);
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  const indicatorNotApprovedConfidence = () => {
    return (
      documentInfo.docInfo.metaData?.filter((el) => !el.ApprovedConfidence)
        .length > 0
    );
  };

  const itIncludes = (oneString) => {
    try {
      const lowerCaseString = oneString?.toLowerCase();

      const keywordsMap = {
        ine: ["ine", "ife"],
        passport: ["pasaporte"],
        curp: ["curp"],
        consentimiento: ["consentimiento", "ine", "ife"],
        contrato: ["contrato"],
        privacidad: ["privacidad"],
        nom: ["nom", "norma", "expediente"],
        comprobante: ["telmex", "cfe", "domicilio"],
        ingresos: ["ingresos"],
        ubicacion: ["ubicación"],
        evVideo: ["evidencia video"],
        frames: ["video frame"],
        evAudio: ["audio"],
      };

      for (let key in keywordsMap) {
        if (
          keywordsMap[key].some((keyword) => lowerCaseString?.includes(keyword))
        ) {
          return eval(key);
        }
      }
    } catch {
      return docGenerico;
    }

    return docGenerico;
  };

  const getDocumentInfo = async (docCurrentnfo) => {
    try {
      const response = await documentRequests.getDocumentInfo(docInfo.id);
      console.log("+++++++++++++++++++++++++++++++++1", response.Body);
      if (response.Body.Metadata?.length > 0) {
        console.log("+++++++++++++++++++++++++++++++++2", response.Body);
        console.log(response);
        console.log(
          "***Valida Muestra Modal****docCurrentnfo*****************: ",
          docCurrentnfo,
        );

        let metadata = response.Body.Metadata?.filter((el) => !el.GroupName);
        let metadataTables = docCurrentnfo.metaData?.filter(
          (el) => el.ValueType,
        );
        let metadataExtra = response.Body.Metadata?.filter(
          (el) => el.GroupName,
        );

        let openEM =
          response.Body?.Validation?.Status === null &&
          response.Body?.Metadata?.find(
            (item) => item.Name === "Confianza aprovada" && item.Value === "NO",
          )?.Value === "NO"
            ? true
            : false;
        console.log(
          "***Valida Muestra Modal****openEM*****************: ",
          openEM,
        );
        setopenEditableModal(openEM);
        setOpenViewer(!openEM);
        // setOpen(true)
        setIsApprovedDocument(
          response.Body?.Validation?.Approved === true ? true : false,
        );

        metadata = docCurrentnfo.metaData?.filter((el) => !el.ValueType);
        let docInfo = {
          metadata,
          metadataTables,
          metadataExtra,
        };
        setdocumentInfo(docInfo);
        console.log(docInfo);
        console.log("+++++++++++++++++++++++++++++++++: openEM: ", openEM);
      } else {
        setOpenViewer(true);
        setIsApprovedDocument(
          response.Body?.Validation?.Approved === true ? true : false,
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpen = () => {
    getDocumentInfo(docInfo);
    dispatch(documentActions.GetDocument(docInfo.id, docInfo.extension));
    console.log("print id doc", docInfo.id);
  };

  const handleClose = () => {
    if (!openEditableModal) {
      console.log("close 1: ", openEditableModal);
      setOpenViewer(false);
      setComment("");
    } else {
      console.log("close 2: ", openEditableModal);
      setopenEditableModal(false);
      setOpenViewer(false);
    }
  };

  useEffect(() => {
    const getGroups = docInfo.metaData
      .map((data) => {
        return data.GroupName;
      })
      .reduce((a, b) => (a.includes(b) ? a : [...a, b]), [])
      .map((grp) => {
        return {
          grupo: grp,
          info: docInfo.metaData?.filter((grpJ) => grp === grpJ.GroupName),
        };
      });
    setGroups(getGroups);

    if (postDocumentValidationState.error !== undefined && approved) {
      alert(postDocumentValidationState.error);
      Alert(
        {
          icon: 0,
          title: postDocumentValidationState.error,
        },
        null,
        null,
        t,
      );
    }
    if (postDocumentValidationState.items !== undefined && approved) {
      Alert(
        {
          icon: "success",
          title: t("document_validated"),
        },
        null,
        null,
        t,
      );
      refresh();
      handleClose();
    }
  }, [postDocumentValidationState]);

  useEffect(() => {
    if (extDocValidation.error !== undefined && extApproved) {
      alert(extDocValidation.error);
    }
    if (extDocValidation.items !== undefined && extApproved) {
      refresh();
      handleClose();
      console.log("se aprobo el doc");
    }
  }, [extDocValidation]);

  const getStatus = () => {
    let status = null;
    if (docInfo?.IsDocumentValidation == null) {
      status = "pending";
    } else if (docInfo?.IsDocumentValidation) {
      status = "approved";
    } else if (docInfo?.IsDocumentValidation) {
      status = "rejected";
    }
    return status;
  };

  const RENDER_ALERT_COMPONENT = {
    rejectedDocument: (
      <CustomAlert
        closeAction={() => {
          setOpenDocFileRejection(false);
          setComment("");
        }}
        open={openDocFileRejection}
        title={t("reject_document")}
        body={t("reject_document_confirm")}
        leftButtonText={t("general_cancel")}
        leftButtonAction={() => {
          setOpenDocFileRejection(false);
          setComment("");
        }}
        rightButtonText={t("general_accept")}
        comments={true}
        valueComment={comment}
        commentPlaceHolder={t("reject_document_motive")}
        setValueComment={setComment}
        // rightButtonAction={sendRejection} I am hidding this conditions just for the Demo made at the beginning of Oct 2021
        rightButtonAction={() => {
          let objRequestRechazo = {
            Document_Id: docInfo.id,
            Approved: false,
            Comments: comment,
            ValidateFileComplete: true,
            WorkFlowInstanceId: workFlowInstanceId,
          };
          console.log(
            "objRequestRechazo: sendRejection PostDocumentValidation: ",
            objRequestRechazo,
          );
          dispatch(validationActions.PostDocumentValidation(objRequestRechazo));
          dispatch(fileActions.AsignateCurrentUser({ File_Id: fileId }));
          setApproved(true);
        }}
      />
    ),
    rejectedDocumentExternal: (
      <CustomAlert
        closeAction={() => {
          setOpenExtRejection(false);
          setComment("");
        }}
        open={openExtRejection}
        title={t("reject_document")}
        body={t("reject_document_confirm")}
        leftButtonText={t("general_cancel")}
        leftButtonAction={() => {
          setOpenExtRejection(false);
          setComment("");
        }}
        rightButtonText={t("general_accept")}
        comments={true}
        valueComment={comment}
        commentPlaceHolder={t("reject_document_motive")}
        setValueComment={setComment}
        rightButtonAction={() => {
          setExtApproved(true);
          dispatch(
            validationActions.SendDocExternalVal({
              Document_Id: docInfo.id,
              Approved: false,
              Comments: comment,
              ValidateFileComplete: false,
            }),
          );
          handleClose();
        }}
      />
    ),
    default: null, // Componente vacío por defecto
  };

  const RENDER_VIEWER_COMPONENT = {
    modalViewer: (
      <Dialog
        open={openViewer}
        onClose={() => {
          handleClose();
        }}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
        PaperProps={{
          style: {
            // overflow: "hidden",
            minHeight: "80%",
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                variant="h5"
                style={{
                  padding: "5px 0.5em",
                  display: "block",
                }}
              >
                {docInfo.Description}
              </Typography>
              <Box sx={{ padding: "1px 0.5em;" }}>
                <StatusRenderer status={statusCurrent} />
              </Box>
            </Box>
            <IconButton
              aria-label="close"
              onClick={() => {
                setOpenViewer(false);
              }}
            >
              <CloseIcon style={{ color: "#898B8D" }} />
            </IconButton>
          </Box>

          <GridContainer container spacing={2}>
            <Grid item xs={12} sm={docInfo.metaData?.length > 0 ? 7 : 12}>
              {(statusVideo !== "VideoCompleto" &&
                statusVideo !== "Rechazado" &&
                document.extension === ".mp4") ||
              (document.extension === ".webm" &&
                (document.items === undefined || document.items === null)) ? (
                <div>
                  <div
                    style={{
                      float: "left",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <UseImage src={procesandoVideo} type="img" />
                  </div>
                  <div
                    style={{
                      float: "left",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    {statusVideo !== "VideoCompleto" &&
                    statusVideo !== "Rechazado" &&
                    (document.items === undefined ||
                      document.items === null) ? (
                      <Typography variant="p">
                        <b>Procesando video</b>
                      </Typography>
                    ) : document.extension === ".mp4" ||
                      document.extension === ".webm" ? (
                      <Typography variant="p">{statusVideo}</Typography>
                    ) : null}
                  </div>
                </div>
              ) : document.loading ? (
                <CustomLoadingOverlay
                  active={document.loading}
                  text={t("general_loading_message")}
                />
              ) : document.extension === ".mp4" ||
                document.extension === ".webm" ? (
                <video
                  className="videoClass"
                  width="100%"
                  height="90%"
                  controls
                  autoplay
                  loop
                  poster="fotograma.png"
                >
                  <source src={document.items} type={document.type} />
                  <p>Tu navegador no soporta HTML5</p>
                </video>
              ) : (
                // <object
                //   width="100%"
                //   height="100%"
                //   data={document.items}
                //   type="application/pdf"
                // >
                //   {" "}
                // </object>
                <VisorPDF
                  pdfUrl={document.items}
                  pdfName={"Document"}
                  height={"30vh"}
                />
              )}
            </Grid>
{console.log('check this', document.items)}
            {docInfo.metaData?.length > 0 && (
              <Grid item xs={12} sm={5}>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {docInfo.metaData?.length > 0 ? (
                    <Typography
                      variant="h6"
                      sx={{ width: "100%", textAlign: "center" }}
                    >
                      Metadatos
                    </Typography>
                  ) : null}
                  {docInfo.metaData?.length > 0 && (
                    <Box>
                      <Box
                        sx={{
                          overflowX: "hidden",
                          overflowY: "auto",
                          height: "65vh",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {groups.map((grp) => (
                          <Box
                            sx={{
                              width: "95%",
                              display: "flex",
                              flexWrap: "wrap",
                              marginBottom: theme.spacing(2),
                            }}
                          >
                            <Typography variant="p">{grp.grupo}</Typography>
                            <lineVisualDiv />
                            {grp.info.map((data) => (
                              <Box
                                className={
                                  data?.ValueType === "table"
                                    ? ""
                                    : {
                                        width: "46%",
                                        marginTop: theme.spacing(2),
                                        marginBottom: theme.spacing(2),
                                        marginLeft: theme.spacing(1),
                                        marginRight: theme.spacing(1),
                                      }
                                }
                              >
                                {data?.ValueType === "table" ? (
                                  <Box
                                    sx={{
                                      width: "100%",
                                      marginTop: theme.spacing(2),
                                      marginBottom: theme.spacing(2),
                                      marginLeft: theme.spacing(1),
                                      marginRight: theme.spacing(1),
                                    }}
                                  >
                                    <Typography>{data.Name}</Typography>
                                    <TableRowControl dataItems={data} />
                                  </Box>
                                ) : (
                                  <Box sx={{ height: "100%" }}>
                                    <Typography>{data.Name}</Typography>
                                    {grp.grupo == "Reconocimiento facial" &&
                                    data.Name.includes("Face") ? (
                                      <Typography>
                                        {addSpaceToString(data.Value)}
                                      </Typography>
                                    ) : (
                                      <Typography>
                                        {data.Value === "SI" ? (
                                          <CheckCircleIcon
                                            style={{ color: "#28A745" }}
                                          />
                                        ) : data.Value === "NO" ? (
                                          <CancelIcon
                                            style={{ color: "red" }}
                                          />
                                        ) : data.Value === "True" ? (
                                          <CheckCircleIcon
                                            style={{ color: "#28A745" }}
                                          />
                                        ) : data.Value === "False" ? (
                                          <CancelIcon
                                            style={{ color: "red" }}
                                          />
                                        ) : (
                                          data.Value
                                        )}
                                      </Typography>
                                    )}
                                  </Box>
                                )}
                              </Box>
                            ))}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Grid>
            )}
          </GridContainer>
          {/* {console.log("infoFile.itemsValidation?", infoFile.itemsValidation)}
          {console.log("docInfo.validacion,docInfo.docExtVal", docInfo.validacion, docInfo.docExtVal)} */}
          {infoFile.itemsValidation?.ShowSelectApproval === true &&
          docInfo.validacion === null ? (
            <DialogActions
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                id="btnApproveDoc"
                fullWidth
                variant="contained"
                sx={{ height: 45, px: 10, width: "40%", maxWidth: 380 }}
                onClick={() => {
                  setOpenDocFileApproval(true);
                }}
              >
                {t("approve_document")}
              </Button>
              <Button
                id="btnRejectDoc"
                fullWidth
                variant="outlined"
                sx={{ height: 45, px: 10, width: "40%", maxWidth: 380 }}
                onClick={() => {
                  setOpenDocFileRejection(true);
                }}
              >
                {t("reject_document")}
              </Button>
            </DialogActions>
          ) : (
            ""
          )}
          {docInfo.validacion === null && docInfo.docExtVal === true ? (
            <DialogActions
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                id="btnApproveDoc"
                fullWidth
                variant="contained"
                sx={{ height: 45, px: 10, width: "40%", maxWidth: 380 }}
                onClick={() => {
                  setOpenExtApproval(true);
                }}
              >
                {t("approve_document")}
              </Button>
              <Button
                id="btnRejectDoc"
                fullWidth
                variant="outlined"
                sx={{ height: 45, px: 10, width: "40%", maxWidth: 380 }}
                onClick={() => {
                  setOpenExtRejection(true);
                }}
              >
                {t("reject_document")}
              </Button>
            </DialogActions>
          ) : (
            ""
          )}
        </Box>
      </Dialog>
    ),
    modalEdited: (
      <Dialog
        open={openEditableModal}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          style: {
            minHeight: "80%",
          },
        }}
      >
        <Box
          sx={{
            display: "grid !important",
            gridTemplateColumns: "70% 30%",
            ["@media only screen and (max-width: 812px)"]: {
              gridTemplateColumns: "50% 50%",
            },
            overflow: "hidden !important",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column !important",
              alignItems: "flex-start !important",
              overflowY: "none",
              justifyContent: "center !important",
            }}
          >
            <Typography variant="h4" style={{ padding: "30px 20px 0px 20px" }}>
              {docInfo.Description}
              {isApprovedDocument ? (
                <CheckCircleIcon style={{ color: "#28A745" }} />
              ) : (
                <FiberManualRecord
                  style={{
                    color: "#28A745",
                    fontSize: 40,
                    marginLeft: 7,
                    marginBottom: 3,
                  }}
                />
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column !important",
              alignItems: "flex-end !important",
              justifyContent: "center !important",
              overflowY: "hidden !important",
            }}
          >
            <CloseIcon
              style={{ color: "#898B8D" }}
              sx={{
                alignSelf: "flex-end !important",
                marginTop: theme.spacing(1),
                marginRight: theme.spacing(1),
                marginLeft: "100%",
              }}
              onClick={handleClose}
            />
          </Box>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            paddingLeft: 10,
            paddingTop: 10,
            paddingBottom: 10,
            width: "100%",
            minHeight: "80vh",
          }}
        >
          <Grid
            item
            xs={12}
            sm={
              documentInfo?.metadata.length > 0 ||
              documentInfo?.metadataTables.length > 0
                ? 7
                : 12
            }
          >
            {document.loading ? (
              <CustomLoadingOverlay
                active={document.loading}
                text={t("general_loading_message")}
              />
            ) : (
              <object
                width="100%"
                height="100%"
                style={{ maxHeight: "100vh" }}
                data={document.items}
                type="application/pdf"
              >
                {" "}
              </object>
            )}
          </Grid>

          {(documentInfo?.metadata.length > 0 ||
            documentInfo?.metadataTables.length > 0) && (
            <Grid item xs={12} sm={5}>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ArrowDropDown />}>
                    <Typography variant="h6">
                      Edición de metadatos
                      {indicatorNotApprovedConfidence() && (
                        <FiberManualRecord
                          fontSize="small"
                          style={{ color: "red", marginLeft: 5 }}
                        />
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      {documentInfo.metadata.map((doc, i) => (
                        <Grid item xs={12} key={doc.Name + i}>
                          <Controller
                            control={control}
                            name={doc.Name}
                            defaultValue={doc.Value}
                            render={({ field: { ref, ...restFields } }) => (
                              <TextField
                                {...restFields}
                                inputRef={ref}
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: 15,
                                  /*   display: "block", */
                                  "& .MuiOutlinedInput-input": {
                                    height: 25,
                                    padding: "10px 15px",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    borderRadius: 12,
                                    borderColor: "red",
                                    fontSize: 14,
                                    "& fieldset legend span": {
                                      display: "none",
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: "#000",
                                    position: "relative",
                                    transform: "none",
                                    fontSize: 14,
                                    marginBottom: 8,
                                    marginTop: 5,
                                    display: "flex",
                                    alignItems: "center",
                                    pointerEvents: "auto",
                                    fontWeight: 500,
                                  },
                                  "& label.Mui-focused": {
                                    color: theme.palette.primary.main,
                                  },
                                }}
                                label={doc.Name}
                              />
                            )}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                {documentInfo.metadataTables.map((table, i) => (
                  <Accordion key={table.Name + i} defaultExpanded>
                    <AccordionSummary expandIcon={<ArrowDropDown />}>
                      <Typography
                        variant="h6"
                        component="p"
                        style={
                          !table.ApprovedConfidence
                            ? { color: "red" }
                            : undefined
                        }
                      >
                        {table.Name.toLowerCase()}
                        {!table.ApprovedConfidence && (
                          <FiberManualRecord
                            fontSize="small"
                            style={{ color: "red", marginLeft: 5 }}
                          />
                        )}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableEditable data={table} />
                    </AccordionDetails>
                  </Accordion>
                ))}

                <Grid container style={{ marginTop: "auto" }}>
                  <Grid item xs={12}>
                    <Button
                      id="btnRejectDoc"
                      fullWidth
                      variant="outlined"
                      style={{
                        maxWidth: 300,
                        margin: "10px auto",
                        display: "block",
                        borderRadius: 16,
                        borderColor: "rgb(225, 2, 17)",
                        color: "rgb(225, 2, 17)",
                      }}
                      disabled={loading}
                    >
                      {t("reject_document")}
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    id="btnApproveDoc"
                    fullWidth
                    variant="contained"
                    style={{
                      maxWidth: 300,
                      margin: "20px auto 0 auto",
                      display: "block",
                      borderRadius: 16,
                      backgroundColor: "rgb(225, 2, 17)",
                      color: "#FFF",
                      /* display: "flex",
                        alignItems: "center",
                        justifyContent: "center", */
                    }}
                    onClick={handleSubmit(handleAcceptDocument)}
                    disabled={loading}
                  >
                    {loading ? (
                      <CustomLoadingOverlay
                        active={loading}
                        text={t("general_loading_message")}
                      />
                    ) : (
                      t("approve_document")
                    )}
                  </Button>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
      </Dialog>
    ),
    default: null, // Componente vacío por defecto
  };

  const refresh = () => {
    dispatch(
      fileActions.GetFileInfo({
        FileId: fileId,
        WorkFlowInstanceId: workFlowInstanceId,
      }),
    );
  };
  return (
    <Box>
      <FormProvider {...formMethods}>
        <Card
          sx={{
            width: "13rem",
            borderRadius: "1rem",
            border: "1px solid #D2D2D2",
            boxShadow: 3,
          }}
          onClick={handleClickOpen}
        >
          <Grid container sx={{ maxWidth: "90%" }} spacing={"2rem"}>
            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
              <UseImage src={itIncludes(docInfo.nombre)} type="img" />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  textAlign: "center",
                }}
              >
                {docInfo.Description}
                {/* <StatusRenderer status={getStatus()} /> */}
              </Typography>
            </Grid>
          </Grid>
        </Card>

        {
          RENDER_VIEWER_COMPONENT[
            openViewer
              ? "modalViewer"
              : openEditableModal
                ? "modalEdited"
                : null
          ]
        }
        {
          RENDER_ALERT_COMPONENT[
            openDocFileRejection
              ? "rejectedDocument"
              : openExtRejection
                ? "rejectedDocumentExternal"
                : null
          ]
        }
      </FormProvider>
    </Box>
  );
};

const TableEditable = (props) => {
  const classes = useClasses();
  const [dataTable, setdataTable] = useState([]);
  const { control } = useFormContext();

  useEffect(() => {
    if (props.data) {
      setdataTable(JSON.parse(props.data.Value));
    }
  }, [props]);

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {dataTable.Columns?.map((col, i) => (
              <TableCell key={col + i}>{col}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {dataTable.Rows?.map((row, i) => (
            <TableRow key={i}>
              {row.map((cell, j) => (
                <TableCell key={cell.TextValue + i}>
                  <Controller
                    control={control}
                    name={`${props.data.Name}-${dataTable.Columns[j]}-${i}`}
                    defaultValue={cell.TextValue}
                    render={({ field: { ref, ...restFields } }) => (
                      <OutlinedInput
                        {...restFields}
                        inputRef={ref}
                        fullWidth
                        variant="outlined"
                        className={classes.outlinedText}
                      />
                    )}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const addSpaceToString = (string) => {
  const splited = string.split(",");
  const newString = splited.join(", ");
  return newString;
};
