import MainTable from 'components/common/Table';
import useSWR from 'swr';
import {
  deleteMetadata,
  getMetadata,
  updateMetadata,
} from 'api/configuration/configurationRequests';
import { dataAdapterMiddleware } from 'middlewares/swr/dataAdapterMiddleware';
import { metadataTableRowsAdapter } from 'adapters/metadataTableRowsAdapter';
import { useSnackbar } from 'notistack';
import { OBTableCell, OBTableRow } from '../../common/Table/Table.styled';
import { IconButton, MenuItem, Select, Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import { DataTypes } from 'components/configuration/ConfigDocumentMetadata/dataTypes';

export const MetadataTable = ({ onClickEdit }) => {
  const { t } = useTranslation();
  const configId = JSON.parse(sessionStorage.getItem('configurationData')).Id;
  const PAYLOAD = { ConfigurationID: configId };
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: tableData,
    isValidating,
    mutate,
  } = useSWR('GetMetadata', () => getMetadata(PAYLOAD), {
    use: [dataAdapterMiddleware(metadataTableRowsAdapter)],
    refreshInterval: 0,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const loading = isValidating;

  const rows = tableData?.map((row) => ({
    GroupId: row.AgrupadorId,
    MetadataId: row.Id,
    GrouperName: row.Agrupador,
    TagCapture: row.EtiquetaCaptura,
    DataType: row.TipoDato,
    Requeried: row.Obligatorio,
  }));

  const columns = [
    { id: 'GrouperName', label: t('configuration_metadata_header_workflow'), minWidth: 170 },
    { id: 'TagCapture', label: t('configuration_metadata_header_capture_label'), minWidth: 170 },
    { id: 'DataType', label: t('configuration_metadata_header_data_type'), minWidth: 170 },
    {
      id: 'Requeried',
      label: t('configuration_metadata_header_required'),
      maxWidth: 170,
      textAlign: 'center',
    },
    {
      id: 'Actions',
      label: t('configuration_metadata_header_actions'),
      maxWidth: 170,
      textAlign: 'center',
    },
  ];

  const handleDeleteClick = async (row) => {
    try {
      const DELETE_ROW_PAYLOAD = {
        MetadataId: row.MetadataId,
        ConfigurationID: configId,
      };
      await deleteMetadata(DELETE_ROW_PAYLOAD);
      await mutate();
      enqueueSnackbar(t('alert_successful_delete'), { variant: 'success' });
    } catch (error) {}
  };

  const handleCheckboxChange = async (checked, row) => {
    try {
      const { MetadataId, GroupId, TagCapture, DataType } = row;
      const UPDATE_ROW_PAYLOAD = {
        MetadataId,
        ConfigurationID: configId,
        GroupId,
        TagCapture,
        DataType,
        Requeried: checked,
      };
      await updateMetadata(UPDATE_ROW_PAYLOAD);
      await mutate();
      enqueueSnackbar(t('alert_successful_update'), { variant: 'success' });
    } catch (error) {}
  };

  const handleSelectChange = async (event, row) => {
    try {
      const { MetadataId, GroupId, TagCapture, Requeried } = row;
      const UPDATE_ROW_PAYLOAD = {
        MetadataId,
        ConfigurationID: configId,
        GroupId,
        TagCapture,
        DataType: event.target.value,
        Requeried,
      };
      await updateMetadata(UPDATE_ROW_PAYLOAD);
      await mutate();
      enqueueSnackbar(t('alert_successful_update'), { variant: 'success' });
    } catch (error) {}
  };

  const render = (row) => (
    <OBTableRow hover role="grid" loading={loading.toString()}>
      <OBTableCell>{row.GrouperName}</OBTableCell>
      <OBTableCell>{row.TagCapture}</OBTableCell>
      <OBTableCell>
        <Select fullWidth onChange={(event) => handleSelectChange(event, row)} value={row.DataType}>
          {DataTypes.map((option) => (
            <MenuItem key={option.Type} value={option.Type ?? ''}>
              {t(option.Label)}
            </MenuItem>
          ))}
        </Select>
      </OBTableCell>
      <OBTableCell>
        <Checkbox
          checked={row.Requeried}
          onChange={(event, checked) => handleCheckboxChange(checked, row)}
        />
      </OBTableCell>
      <OBTableCell>
        <Tooltip title={t('general_delete')} placement={'top'}>
          <IconButton onClick={() => handleDeleteClick(row)}>
            <Icon icon={'ph:trash'} />
          </IconButton>
        </Tooltip>
        {onClickEdit && (
          <Tooltip title={t('general_edit')} placement={'top'}>
            <IconButton onClick={() => onClickEdit(row)}>
              <Icon icon={'clarity:edit-line'} />
            </IconButton>
          </Tooltip>
        )}
      </OBTableCell>
    </OBTableRow>
  );

  return <MainTable rows={rows} columns={columns} render={render} loading={loading} rowPerPage={6}/>;
};
