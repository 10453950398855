import React, { useState, useEffect } from 'react';
import protect from '../../utils/protect';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fileActions } from '../../store/actions';
import { Typography, Tooltip, IconButton, useTheme } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import GavelIcon from '@mui/icons-material/Gavel';

import GroupIcon from '../../assets/GroupIcon';
import Apoderado from '../../assets/Apoderado';
import { FullWidthTabs } from '../../components';
import { useStyles } from './DetailStyles';
import { useTranslation } from 'react-i18next';

function DetailsComponent() {
  const theme = useTheme();
  // const mainPage = mainStyles.mainContainer()
  const localStyles = useStyles(theme);
  const { id } = useParams();

  const dispatch = useDispatch();

  const finbeColor = theme.palette.iconPrincButton.main;
  const white = '#ffffff';

  const infoFile = useSelector((state) => state.FileInfo);
  const infoActors = useSelector((state) => state.ActorsInfo);
  const [firstName, setFirstName] = useState('');
  const [initial, setInitial] = useState('');
  const [folio, setFolio] = useState('');
  const [status, setStatus] = useState('');
  const [validation, setValidation] = useState({});
  const [actors, setActors] = useState([]);
  const [selectedActor, setSelectedActor] = useState({});

  const [groupIconColor, setGroupIconColor] = useState(white);
  const [apoderadoColor, setApoderadoColor] = useState(finbeColor);
  const [hover, setHover] = useState(false);
  const workFlowInstanceId = sessionStorage.getItem('WorkFlowInstanceId');

  const { t } = useTranslation();

  useEffect(() => {
    console.log('ENTRO AQUI');
    console.log("---Peticion 13------- FileInfo")
    // dispatch(
    //   fileActions.GetFileInfo({
    //     FileId: id,
    //     WorkFlowInstanceId: workFlowInstanceId,
    //   }),
    // );
    dispatch(fileActions.GetActorsInfo(id));
  }, []);

  useEffect(() => {
    if (infoFile.items !== undefined) {
      setFirstName(infoFile.items.FullName);
      setInitial(infoFile.items.Name?.split('')[0]);
      setValidation(infoFile.itemsValidation);
      setFolio(infoFile.items.Folio);
      setStatus(infoFile.items.Stage.Description);
    }
  }, [infoFile]);

  useEffect(() => {
    if (infoActors.items !== undefined) {
      setActors(infoActors.items);
    }
  }, [infoActors]);

  const changeActor = (actor) => {
    setSelectedActor(actor);
    setGroupIconColor(finbeColor);
  };

  const renderComponent = (actor) => {
    if (actor.TypeName.includes('Solicitante')) {
      return (
        <IconButton classes={{ root: localStyles.materialIconContainer }}>
          <AccountCircleIcon
            className={
              actor.Id === selectedActor.Id
                ? localStyles.materialSelectedIcon
                : localStyles.materialIcon
            }
          />
        </IconButton>
      );
    } else if (actor.TypeName.includes('Aval')) {
      return (
        <IconButton classes={{ root: localStyles.materialIconContainer }}>
          <MonetizationOnIcon
            className={
              actor.Id === selectedActor.Id
                ? localStyles.materialSelectedIcon
                : localStyles.materialIcon
            }
          />
        </IconButton>
      );
    } else if (actor.TypeName.includes('Accionista')) {
      return (
        <IconButton classes={{ root: localStyles.materialIconContainer }}>
          <DataUsageIcon
            className={
              actor.Id === selectedActor.Id
                ? localStyles.materialSelectedIcon
                : localStyles.materialIcon
            }
          />
        </IconButton>
      );
    } else if (actor.TypeName.includes('Legal')) {
      return (
        <IconButton classes={{ root: localStyles.materialIconContainer }}>
          <GavelIcon
            className={
              actor.Id === selectedActor.Id
                ? localStyles.materialSelectedIcon
                : localStyles.materialIcon
            }
          />
        </IconButton>
      );
    } else if (actor.TypeName.includes('Apoderado')) {
      return <Apoderado fill={actor.Id === selectedActor.Id ? white : apoderadoColor} />;
    } else {
      return (
        <IconButton classes={{ root: localStyles.materialIconContainer }}>
          <AccountCircleIcon
            className={
              actor.Id === selectedActor.Id
                ? localStyles.materialSelectedIcon
                : localStyles.materialIcon
            }
          />
        </IconButton>
      );
    }
  };

  return (
    <FullWidthTabs
      currentActor={selectedActor}
      actorId={Object.keys(selectedActor).length > 0 ? selectedActor.Id : null}
    />
  );
}

export default protect(DetailsComponent);
