import { Button, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fileActions } from 'store/actions';
import genericStatusStage from 'assets/genericStatusStage.png';


const InformationStage = ({ title = '', description = '', showButton = false }) => {
  const workFlowInstanceId = sessionStorage.getItem('WorkFlowInstanceId');
  const navigate = useNavigate();
  const properties = useSelector((state) => state.FileInfo?.items?.Stage.Properties);
  const textTitle = properties.find((prop) => prop.Name === 'TitleData');
  const textData = properties.find((prop) => prop.Name === 'TextData');
  const buttonText = properties.find((prop) => prop.Name === 'ButtonData');
  const activeButton = properties.find((prop) => prop.Name === 'ActivateContinueButton');

  const isActiveButton = activeButton?.Value === '1';
  // const goToDashboard = properties.find((prop) => prop.Name === 'GoToDashboard');
  // const isGoToDashboard = goToDashboard?.Value === '1';
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleButton = () => {
    const request = {
      ObjectId: id,
      Metadata: [
        {
          GroupName: '',
          Name: '',
          Value: '',
        },
      ],
      ContinueWorkFlow: true,
      WorkFlowInstanceId: workFlowInstanceId,
    };
    if (isActiveButton) {
      dispatch(fileActions.FileUpdateMetadataAndRefresh(request));
      return;
    }

    navigate('/inbox/homeComponent');
  };

  return (
    <Stack
      sx={{ height: '100%', justifyContent: 'space-around' }}
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      {isActiveButton || showButton ? (
        <Button variant="contained" onClick={handleButton} sx={{ alignSelf: 'end', width: '20%'}}>
          {buttonText?.Value || 'Default Text'}
        </Button>
      ) : null}
      <Typography variant="h5" align="center" maxWidth="50%">
        {title ? title : textTitle?.Value}
      </Typography>

      <img
        alt=""
        src={genericStatusStage}
        style={{ width: '50%', height: '50%', objectFit: 'contain' }}
      />
      <Typography variant="p" align="center" maxWidth="50%">
        {description ? description : textData?.Value}
      </Typography>
    </Stack>
  );
};
export default InformationStage;
