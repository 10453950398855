import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  imageNotFound: {
    maxWidth: 300,
  },

  container: {
    width: "100%",
    height: "90%",
  },

  //*TABS*
  indicator: {
    backgroundColor: `${theme.palette.selectTab.indicator} !important`,
  },

  selectedTab: {
    fontSize: "17px",
    fontWeight: "500",
    backgroundColor: `${theme.palette.selectTab.info} !important`,
    color: `${theme.palette.selectTab.textColor} !important`,
    border: `solid 1px ${theme.palette.selectTab.border} !important`,
    outline: "none  !important",
    borderRadius: "0px !important",
  },

  selectedTabMobile: {
    fontSize: "17px",
    fontWeight: "500",
    border: `solid 1px 0px 0px 0px ${theme.palette.selectTab.border} !important`,
    outline: "none  !important",
    borderRadius: "0px !important",
  },

  title: {
    color: `${theme.palette.status.info} !important`,
    fontWeight: "bold !important",
  },

  titleSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  spinSection: {
    height: "60vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  scroll: {
    overflowX: "hidden",
    overflowY: "auto",
    height: "60vh",
    marginTop: theme.spacing(1),
  },
  scroll1: {
    overflowX: "hidden",
    overflowY: "auto",
    height: "54vh",
    marginTop: theme.spacing(2),
  },

  //*General Info*

  img: {
    height: "100px",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    borderRadius: "3px",
    // height: 147,
    // borderRadius: "50%",
    // overflow: "hidden",
    // justifySelf: "center"
  },

  table: {
    width: "90% !important",
    // borderRadius: 6,
    // border: "1px solid #D2D2D2",
  },

  tableFiles: {
    borderRadius: 10,
    border: "1px solid #000000",
    width: "50% !important",
    justifySelf: "center",
  },

  data: {
    width: "50%",
    height: "70vh",
    // overflow: 'auto',
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  dataTwo: {
    width: "50%",
    height: "70vh",
  },

  dataThree: {
    width: "60%",
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  tabWithData: {
    display: "flex",
  },

  tabWithoutDocs: {
    display: "flex",
    justifyContent: "center",
  },

  personalInfo: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // width: '100%',
    // display: 'grid',
    // gridRowGap: '20px',
    // padding: '30px 15px',
    // alignItems: 'center',
  },
  docs: {
    color: `${theme.palette.status.info} !important`,
  },

  docStatustable: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    overflowX: "hidden",
    overflowY: "auto",
    height: "60vh",
  },

  mensaje: {
    marginTop: theme.spacing(3),
    width: "60%",
    display: "flex",
    textAlign: "center",
  },

  miniContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },

  estatusContainer: {
    boxShadow: "0px 18px 53px 0px rgba(92, 92, 92, 0.09)",
    borderRadius: 12,
  },

  actorsContainer: {
    padding: "30px 10px",
    display: "flex",
    justifyContent: "space-between",
  },

  actor: {
    width: 106,
    position: "relative",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    height: 180,
    justifyContent: "space-between",
  },

  pictureWithButton: {
    position: "relative",
    display: "grid",
  },

  pictureButton: {
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    position: "absolute",
    backgroundColor: "#FFFFFF",
    bottom: 0,
    right: 0,
    borderRadius: "50%",
    width: 28,
    height: 28,
    padding: 2,
  },

  //*Actor general info*

  accepted: {
    color: "green",
  },
  // *Record File*

  accordion: {
    borderBottom: `solid ${theme.palette.accordion.line} 2px`,
  },

  // *Documentation*

  iconSection: {
    width: "18%",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  accordionDetail: {
    display: "flex !important",
    flexWrap: "wrap !important",
  },

  // *Biometrics*

  mainMatchInfo: {
    fontSize: 12,
    marginRight: theme.spacing(2),
  },

  biometricTitle: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },

  biometricScroll: {
    // overflowX: "hidden",
    // overflowY: "auto",
    height: "65vh",
  },

  mainResultSection: {
    display: "flex",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  bioImg: {
    width: "40%",
    height: "60%",
  },

  dactilarImg: {
    width: "30% !important",
    height: "50% !important",
  },

  dactilarSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    height: "100%",
    width: "100%",
    marginTop: theme.spacing(2),
  },

  approved: {
    color: theme.palette.success.main,
  },

  rejected: {
    color: theme.palette.error.main,
  },

  titleInfoMessage: {
    color: `${theme.palette.primary.main} !important`,
    fontWeight: "bold !important",
  },

  subTtitleInfoMessage: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  imgInfoMessage: {
    width: "30%",
    // marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
    borderRadius: "3px",
  },
  acceptFolio: {
    borderRadius: "25px !important",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
    width: "50% !important",
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "#ffffff !important",
    "&:focus": {
      outline: "none !important",
    },
    "&:hover": {
      backgroundColor: `${theme.palette.primary.dark} !important`,
    },
  },
  textGroup: {
    color: `${theme.palette.accordion.main} !important`,
  },
  tabRoot: {
    fontSize: "16px",
    fontWeight: "500",
    backgroundColor: `${theme.palette.selectTab.infoDisabled}`,
    color: `${theme.palette.selectTab.textColorDisabled}`,
    border: `solid 1px ${theme.palette.selectTab.infoDisabled}`,
    outline: "none  !important",
  },
  tabRootMobile: {
    backgroundColor: `red`,
    color: `${theme.palette.selectTab.textColorDisabled}`,
    outline: "none  !important",
    borderRadius: "0px !important",
    minWidth: "50px !important",
    padding: "0px !important",
  },
  subTabRoot: {
    fontSize: "20px",
    fontWeight: "300",
    backgroundColor: `${theme.palette.selectTab.textColor1}`,
    color: `${theme.palette.selectTab.textColor1} !important`,
    border: `solid 1px ${theme.palette.selectTab.infoDisabled}`,
    outline: "none  !important",
  },
  selectedSubTab: {
    fontSize: "17px",
    fontWeight: "500",
    color: `${theme.palette.selectTab.textColorDisabled} !important`,
    outline: "none  !important",
  },

  containerResult: {
    display: "grid !important",
    gridTemplateColumns: "70% 30%",
    width: "100%",
    ["@media only screen and (max-width: 812px)"]: {
      // gridTemplateColumns: '5% 90% 5%',
      gridTemplateColumns: "50% 50%",
    },
    overflow: "hidden !important",
  },
  leftColumnResult: {
    display: "flex",
    flexDirection: "column !important",
    alignItems: "flex-start !important",
    overflowY: "none",
    justifyContent: "center !important",
  },
  rightColumnResult: {
    display: "flex",
    flexDirection: "column !important",
    alignItems: "flex-end !important",
    justifyContent: "center !important",
    overflowY: "hidden !important",
  },
  btnViewDoc: {
    border: "none",
    outline: "none",
    "&:hover": {
      outline: "none",
      backgroundColor: "transparent",
    },
    "&:focus": {
      outline: "none",
      backgroundColor: "transparent",
    },
  },
  wid100: {
    width: "100% !important",
  },
  contentButtos: {
    width: "100% !important",
    marginTop: theme.spacing(1),
    textAlign: "center",
  },
  accordionDetailContent: {
    display: "flex !important",
    flexWrap: "wrap !important",
    flexDirection: "column !important",
    alignItems: "center !important",
  },
}));
