import React from 'react';
import {
  TextField,
  Typography,
  Box,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import useSWR from 'swr';
import { fetcher } from 'api/fetcher';
import { Config } from 'services/Config';
import { useTranslation } from 'react-i18next';
import { DataTypes } from 'components/configuration/ConfigDocumentMetadata/dataTypes';

export const MetadataForm = ({ formData, onChange }) => {
  const { t } = useTranslation();
  const configId = JSON.parse(sessionStorage.getItem('configurationData')).Id;
  const PAYLOAD = { ConfigurationID: configId };

  const { data: groupersData, isValidatingGroupers } = useSWR(
    '/configuration/GetMetadataGroup',
    (URL) => fetcher(URL, PAYLOAD, Config.ConfigAuth()),
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return (
    <Box display="flex" flexDirection="column" gap="1rem" width="100%">
      <Typography variant={'subtitle2'}>{t('configuration_metadata_add_workflow')}</Typography>
      <Select defaultValue={formData?.GroupId || 'default'} name="GroupId" onChange={onChange}>
        <MenuItem value="default" disabled>
          {t('configuration_metadata_add_select_workflow')}
        </MenuItem>
        {groupersData?.map((option) => (
          <MenuItem key={option.Id} value={option.Id}>
            {option.Description}
          </MenuItem>
        ))}
      </Select>
      <Typography variant={'subtitle2'}>{t('configuration_metadata_add_field_label')}</Typography>
      <TextField
        name="TagCapture"
        defaultValue={formData?.TagCapture}
        onChange={onChange}
        placeholder={t('configuration_metadata_add_enter_label')}
      />
      <Typography variant={'subtitle2'}>{t('configuration_metadata_add_data_type')}</Typography>
      <Select defaultValue={formData?.DataType || 'default'} name="DataType" onChange={onChange}>
        <MenuItem value="default" disabled>
          {t('configuration_metadata_add_select_data_type')}
        </MenuItem>
        {DataTypes?.map((option) => (
          <MenuItem key={option.Type} value={option.Type}>
            {t(option.Label)}
          </MenuItem>
        ))}
      </Select>
      <Typography variant={'subtitle2'}>{t('configuration_metadata_add_is_field_mandatory')}</Typography>
      <RadioGroup row name="Requeried" value={formData['Requeried']} onChange={onChange}>
        <FormControlLabel value={true} control={<Radio />} label={t('general_yes')} />
        <FormControlLabel value={false} control={<Radio />} label={t('general_no')} />
      </RadioGroup>
    </Box>
  );
};
