import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Typography } from "@mui/material";
import { formatDateByLocale } from "utils";
import { ExpirationStatusColorsMapping } from "mappings/ExpirationStatusColorsMapping";
import { useTranslation } from "react-i18next";

export const MainDocumentInfoTable = ({
  selectedDocumentInfo,
  grouperInfo,
}) => {
  const { t } = useTranslation();
  if (!selectedDocumentInfo) return null;

  const columns = [
    { id: "Grouper", label: t("main_doc_info_table_header") },
    { id: "Expiration", label: t("main_doc_info_table_expiration") },
    { id: "Version", label: t("main_doc_info_table_version") },
    { id: "UpdateDate", label: t("main_doc_info_table_update") },
    { id: "Name", label: t("main_doc_info_table_name") },
  ];

  const rows = {
    Grouper: grouperInfo.Name,
    Expiration:
      formatDateByLocale(selectedDocumentInfo?.Validity?.ValidityDateUTC) ||
      t("document_details_without_caducity"),
    Version: selectedDocumentInfo.Version,
    UpdateDate: formatDateByLocale(selectedDocumentInfo.CreationDate),
    Name: selectedDocumentInfo.Name,
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableBody>
          {columns.map((column) => (
            <TableRow>
              <TableCell
                sx={{ border: "1px solid lightgrey", borderRadius: "10px" }}
                width="50%"
              >
                <Typography variant="p">{column.label}</Typography>
              </TableCell>
              <TableCell sx={{ border: "1px solid lightgrey" }} width="50%">
                <Typography
                  variant="span"
                  color={
                    selectedDocumentInfo.IsCurrentVersion &&
                    column.id === "Expiration"
                      ? ExpirationStatusColorsMapping[
                          selectedDocumentInfo?.Validity?.GlobalStatus?.toUpperCase()
                        ]
                      : "inherit"
                  }
                >
                  {rows[column.id]}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
