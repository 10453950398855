import { authConstants } from '../constants/index.js'

export const Authentication = (state = {}, action) => {
console.log({action})
  switch (action.type) {
    case authConstants.AUTH_REQUEST:
      return {
        loading: true,
        items: undefined,
        error: undefined
      }//Mostrar gif loader mientras ejecuta request
      //break;
    case authConstants.AUTH_SUCCESS:      
      return {
        loading: false,
        items: action.authenticationData.Body,//Obtener respuesta correcta del API        
        usuario: action.authenticationData.Body.UserLoginData.Username,
        roles: action.authenticationData.roles || [],
        error: undefined
      }
      //break;
    case authConstants.AUTH_FAILURE:
      return {
        loading: false,
        items: undefined,
        error: action.error//Mostrar error del API
      }
    case authConstants.AUTH_CLEAN:
      return {
        loading: false,
        items: undefined,
        usuario: undefined,
      }
    case authConstants.AUTH_UPDATED:
      return {
        loading: false,
        items: action.authData,//Obtener respuesta correcta del API        
        usuario: action.authData.UserLoginData?.Username,
        error: undefined
      }
    default:
      return state//Retornar state vacío o actual
  }
}
//Siguiente paso => Volver a screens con respuesta
