import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { authActions, notificationActions } from "../../store/actions";
import { Alert } from "../../components/";
import { obtenerDireccionIP } from "../../utils";
import { useTranslation } from "react-i18next";
import { CustomLoadingOverlay } from "./../../components/CustomLoadingOverlay";
import { authRequests } from "../../services";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import {msalConfig} from "../../msalConfig";

export default function SSORedirectPage(props) {
  const [ipCurrent, setIpCurrent] = useState("");
  const [idRef, setIdRef] = useState("");
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { id } = useParams();

  //Redux hooks
  //const auth = useSelector((state) => state.Authentication);
  const dispatch = useDispatch();

  const isAuthenticated = useIsAuthenticated();
  const { accounts, instance } = useMsal();

  const account = useAccount(accounts[0] || {});

  console.log("account", account);

  /*  useEffect(() => {
    obtenerDireccionIP((ip) => {
      setIpCurrent(ip);
    });
  }, []); */

  //console.log({ auth });

  const getAccessToken = () => {
    if (account) {
      instance.acquireTokenSilent({
        ...msalConfig,
        account: account
      }).then(response => response.accessToken).catch(err => {
        console.error(err);
      });
    }
  }

  const handleValidateToken = async () => {
    //enviar token para validacion
    try {
      const accessToken = getAccessToken();
      console.log({accessToken})
      const request = {
        Username: "",
        PasswordHash: "",
        SelfieB64: "",
        Email: account.username,
        Token: account.idToken,
        RequestDataInfo: {
          File_Id: 0,
          Coordinates: { Latitude: "", Longitude: "" },
          SOVersion: "",
          PhoneBrand: "",
          PhoneModel: "",
          IP: "",
          MacAddress: "",
          BrowserRefer: "",
        },
      };
      console.log("request", { request });
      //dispatch(authActions.AuthenticationSSO(request))
      const auth = await authRequests.SSOAuthenticate(request);
      console.log("auth", auth);
      /* if (auth.Body !== undefined) {
        console.log("authauth 05", auth);
        var filterPermissions =
          auth.Body?.UserLoginData?.SecurityLoginData?.Applications?.filter(
            (item) => item.Name == "MesaControl"
          );
        console.log(
          "+++++----*****......filterPermissions---------*****+++++++++",
          filterPermissions,
          id
        );
        if (filterPermissions?.length > 0) {
          console.log("authauth 05.9", auth);
          dispatch(notificationActions.GetNotificationsUser());
          if (id === undefined) {
            console.log("authauth 06", auth);
            navigation("/inbox/homeComponent");
          } else if (id !== undefined) {
            console.log("authauth 07", auth);
            navigation("/inbox/homeComponent");
          }
        } else {
          setError(true);
          Alert(
            {
              icon: 0,
              title: t("user_not_permissions"),
              okbtntext: "Regresar",
            },
            () => navigation("/auth/login")
          );
        }
      } */
    } catch (error) {
      console.log(error);
      setError(true);
      Alert(
        {
          icon: "error",
          title: "Ocurrio un error",
          text: error.message,
          okbtntext: "Regresar",
        },
        () => navigation("/auth/login") //redireccionar a login
      );
    }
  };

  useEffect(() => {
    if (isAuthenticated && account) {
      handleValidateToken();
    }
  }, [isAuthenticated, account]);

  return !error ? (
    <CustomLoadingOverlay active={true} text={t("general_loading_message")} />
  ) : null;
}
