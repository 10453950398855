import Swal from 'sweetalert2';
import CheckGif from 'assets/DOC/gifts/Check.gif'
import ErrorGif from 'assets/DOC/gifts/ErrorIcon.gif'
import WarningGif from 'assets/DOC/gifts/Warning.gif'

const IconMapping = {
  'success':CheckGif,
  'error': ErrorGif,
  'warning': WarningGif,
}

export const AlertModal = (title, text, icon = 'error', t) => {
  return Swal.fire({
    title: title || '',
    text: text || '',
    imageUrl: IconMapping[icon],
    imageWidth: icon === 'error'? 140: 200,
    confirmButtonText: !!t ? t("general_close") : 'Cerrar'
  });
};
