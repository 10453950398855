import {
  TextField as TextF,
  InputAdornment,
  Button,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import { IsDevice } from "utils";
import { useState } from "react";

const TextField = styled(TextF)(({ theme }) => ({
  height: 40,
  paddingTop: 3,
  padding: 0,
  border: "1px solid #DADADA",
  width: "100% !important",
  borderRadius: "5px !important",
}));

const mainGridStyle = (IsMobile) =>
  IsMobile
    ? { margin: "0 .8em .5em 0em", justifyContent: "center" }
    : { margin: "0 .8em .5em 0em", padding: "15px" };
const buttonGridStyle = (IsMobile) =>
  IsMobile
    ? { paddingTop: "10px !important", paddingLeft: "6px !important" }
    : { paddingTop: "0px !important", paddingLeft: "6px !important" };

export const GenericHeaderActions = (props) => {
  const { t } = useTranslation();
  const { IsMobile } = IsDevice();
  const [textSearch, setTextSearch] = useState("");

  const handleClear = () => {
    setTextSearch("");
    props.onClickCancel();
  };
  const handleChangeText = (e) => {
    setTextSearch(e.target.value);
    props.onChangeTextField(e);
  };

  return (
    <Grid container spacing={2} style={mainGridStyle(IsMobile)}>
      <Grid
        item
        lg={lengthGrid(props)}
        xs={12}
        sx={{ paddingTop: "0px !important", paddingLeft: "0px !important" }}
      >
        {props.onChangeTextField != undefined ? (
          <TextField
            variant="standard"
            margin="none"
            size="normal"
            value={textSearch}
            placeholder={props.placeholderTextField}
            onChange={handleChangeText}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={(theme) => ({ marginLeft: theme.spacing(1) })}
                >
                  <Icon
                    fontSize={18}
                    icon="akar-icons:search"
                    color={"#9E9E9E"}
                  />
                  {/* <FolderIcon className={localStyles.iconMaterial} /> */}
                </InputAdornment>
              ),
            }}
          />
        ) : null}
        {props.LabelTitle != undefined ? (
          <Typography variant="h6">
            {props.LabelTitle} {props.toolTip}
          </Typography>
        ) : null}
      </Grid>
      {props.onClickEditMetadata != undefined
        ? showMetadataEditButton(props, IsMobile, t)
        : null}
      {props.onClickConfirm != undefined
        ? showConfirmButton(props, IsMobile, t)
        : null}
      {props.onClickCancel != undefined
        ? showCancelButton(props, IsMobile, t, handleClear)
        : null}
    </Grid>
  );
};

const lengthGrid = (props) => {
  if (
    props.onClickConfirm != undefined &&
    props.onClickCancel != undefined &&
    props.onClickEditMetadata == undefined
  ) {
    return 6.7;
  } else if (
    props.onClickConfirm != undefined &&
    props.onClickCancel != undefined &&
    props.onClickEditMetadata != undefined
  ) {
    return 4.2;
  } else if (
    props.onClickConfirm == undefined &&
    props.onClickCancel == undefined &&
    props.onClickEditMetadata == undefined
  ) {
    return 12;
  } else if (props.onClickCancel != undefined) {
    return 9;
  } else if (props.onClickConfirm != undefined) {
    return 9;
  } else {
    return 6.7;
  }
};

const showMetadataEditButton = (props, IsMobile, t) => {
  return !IsMobile || props.PersistButtons ? (
    <Grid
      item
      lg={2.5}
      xs={12}
      sx={{ paddingTop: "0px !important", paddingLeft: "6px !important" }}
    >
      <Button
        id="btnHeaderEdit"
        variant="contained"
        fullWidth
        sx={{
          height: 45,
          ...(IsMobile && {
            "&:hover": {
              backgroundColor: "red",
            },
          }),
        }}
        onClick={props.onClickEditMetadata}
        startIcon={props.EditMetadataStartIcon}
        disabled={props.EditMetadataDisabled}
        type="submit"
      >
        {props.EditMetadataText == undefined
          ? t("general_edit")
          : props.EditMetadataText}
      </Button>
    </Grid>
  ) : null;
};

const showConfirmButton = (props, IsMobile, t) => {
  return !IsMobile || props.PersistButtons ? (
    <Grid item lg={2.5} xs={IsMobile ? 11 : 12} sx={buttonGridStyle(IsMobile)}>
      <Button
        variant="contained"
        fullWidth
        sx={{
          height: 45,
          ...(IsMobile && {
            "&:hover": {
              backgroundColor: "red",
            },
          }),
        }}
        onClick={props.onClickConfirm}
        startIcon={props.ConfirmButtonStartIcon}
        disabled={props.ConfirmButtonDisabled}
        type="submit"
      >
        {props.ConfirmButtonText == undefined
          ? t("general_search")
          : props.ConfirmButtonText}
      </Button>
    </Grid>
  ) : null;
};

const showCancelButton = (props, IsMobile, t, cancelFunction) => {
  return !IsMobile || props.PersistButtons ? (
    <Grid item lg={2.5} xs={IsMobile ? 11 : 12} sx={buttonGridStyle(IsMobile)}>
      <Button
        variant="outlined"
        fullWidth
        sx={{
          height: 45,
          ...(IsMobile && {
            "&:hover": {
              backgroundColor: "white",
            },
          }),
        }}
        onClick={cancelFunction}
      >
        {props.CancelButtonText == undefined
          ? t("search_clean")
          : props.CancelButtonText}
      </Button>
    </Grid>
  ) : null;
};
