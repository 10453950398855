import MainTable from "components/common/Table";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { OBTableCell, OBTableRow } from '../../common/Table/Table.styled';
import { IconButton, Tooltip } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Icon } from "@iconify/react";
import {
  deleteDocumentMetadata,
  getDocumentMetadata,
  updateDocumentMetadata
} from "api/document/documentRequests";
import { useTranslation } from "react-i18next";
import { DataTypes } from 'components/configuration/ConfigDocumentMetadata/dataTypes';

export const DocumentMetadataTable = ({ selectedDocument, handleMode, setSelectedDocumentMetadata }) => {
  const { t } = useTranslation()

  const configId = JSON.parse(sessionStorage.getItem("configurationData")).Id;
  const { enqueueSnackbar } = useSnackbar();

  const PAYLOAD = {
    ClasificationId: configId,
    DocumentTypeId: selectedDocument?.Id
  };

  const {
    data: tableData,
    isValidating,
    mutate,
  } = useSWR("GetDocumentMetadata", () => getDocumentMetadata(PAYLOAD), {
    refreshInterval: 0,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const loading = isValidating;

  const rows = tableData?.Metadatas?.map((row) => ({
    DocumentTypeId: selectedDocument?.Id,
    MetadataId: row.Id,
    Section: row.Name,
    TagCapture: row.Name,
    TagType: row.TagType,
    Required: row.Required,
  }));

  const columns = [
    { id: "Section", label: t("configuration_docStructure_edit_metadata_section"), minWidth: 170 },
    { id: "TagCapture", label: t("configuration_docStructure_edit_metadata_capture_label"), minWidth: 170 },
    { id: "DataType", label: t("configuration_docStructure_edit_metadata_data_type"), minWidth: 170 },
    { id: "Required", label: t("configuration_docStructure_edit_metadata_required"), maxWidth: 170, textAlign: "center", },
    { id: "Actions", label: t("configuration_docStructure_edit_metadata_actions"), maxWidth: 170, textAlign: "center" },
  ];

  const handleDeleteClick = async (row) => {
    try {
      const DELETE_ROW_PAYLOAD = {
        MetadataId: row.MetadataId,
      };
      await deleteDocumentMetadata(DELETE_ROW_PAYLOAD);
      await mutate();
      enqueueSnackbar(t("alert_successful_delete"), { variant: "success" });
    } catch (error) { }
  };

  const handleCheckboxChange = async (checked, row) => {
    try {
      const { MetadataId, DocumentTypeId, TagCapture, TagType } = row;
      const UPDATE_ROW_PAYLOAD = {
        MetadataId,
        DocumentTypeId,
        TagCapture,
        TagType,
        Required: checked,
      };
      await updateDocumentMetadata(UPDATE_ROW_PAYLOAD);
      await mutate();
      enqueueSnackbar(t("alert_successful_update"), { variant: "success" });
    } catch (error) { }
  };

  const handleEditClick = (row) => {
    setSelectedDocumentMetadata(row);
    handleMode("ADD_DOCUMENT_METADATA_FORM");
  }

  const render = (row) => {
    const dataType = DataTypes.find(option => option.Type === row.TagType);

    return (
      <OBTableRow hover role="grid" loading={loading.toString()}>
        <OBTableCell>{row.TagCapture}</OBTableCell>
        <OBTableCell>{row.TagCapture}</OBTableCell>
        <OBTableCell>{t(dataType?.Label)}</OBTableCell>
        <OBTableCell>
          <Checkbox
            checked={row.Required}
            onChange={(event, checked) => handleCheckboxChange(checked, row)}
          />
        </OBTableCell>
        <OBTableCell>
          <Tooltip title={t("general_delete")} placement="top">
            <IconButton onClick={() => handleDeleteClick(row)}>
              <Icon icon="ph:trash" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("general_edit")} placement="top">
            <IconButton onClick={() => handleEditClick(row)}>
              <Icon icon="clarity:edit-line" />
            </IconButton>
          </Tooltip>
        </OBTableCell>
      </OBTableRow>
    );
  };

  return (
    <MainTable
      rows={rows}
      columns={columns}
      render={render}
      loading={loading}
    />
  );
};
