import {
  Box,
  Button as Btn,
  ButtonGroup,
  styled,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { BasicSearchFilter } from "./BasicSearchFilter";
import { AdvancedSearchFilter } from "./AdvancedSearchFilter";
import { getElasticProperties } from "api/files/filesRequests";
import { Icon } from "@iconify/react";
import { SearchResultsTable } from "./Tables/SearchResultsTable";
import { SearchResultsList } from "./SearchResultsList";
import { CustomLoadingOverlay } from "../CustomLoadingOverlay";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ElasticSearchIndex } from "./ElasticSearch";
import { IsDevice } from "utils";
import { SearchResultsListMobile } from "./SearchResultListMobile";
import { getSearch } from "api/procedures/proceduresRequests";
import useSWRMutation from "swr/mutation";

const BtnCustom = styled(Btn)(({ theme }) => ({
  borderRadius: "0px !important",
  "&:hover": {
    background: "rgba(191, 191, 191, 0.21)",
  },
}));

const elasticEnabled = (elastic) => {
  if (elastic?.SearchType === "Especific") {
    return true;
  } else {
    return false;
  }
};

export const SearchView = () => {
  const stateAuth = useSelector((state) => state.Authentication);
  // const userId = stateAuth.items?.UserLoginData?.Id;
  const SEARCH_PAYLOAD = {
    SearchType: "Basic",
    PageNumber: 1,
    Search: null,
    Folio: null,
    Nombre: null,
    Puesto: null,
    Geid: null,
    Compañia: null,
  };
  const { t } = useTranslation();
  const { IsMobile } = IsDevice();
  const [searchMode, setSearchMode] = useState("BASIC_SEARCH_FILTER");
  const [filterSettings, setFilterSettings] = useState(SEARCH_PAYLOAD);
  const [viewResultsMode, setViewResultsMode] = useState(
    localStorage.getItem("lastViewResultsMode") ||
      (IsMobile ? "LIST" : "TABLE"),
  );
  const [loadingResults, setLoadingResults] = useState(false);
  const [formData, setFormData] = useState({
    Search: "",
    Nombre: "",
    Folio: "",
    Geid: "",
    Puesto: "",
    Compañia: "",
  });
  const navigate = useNavigate();
  const workFlowId = stateAuth.items?.WorkFlowData?.WorkFlowId;

  /*   const { data: searchResults, isValidating: isValidatingSearch } = useSWR('GetSearchFile', (URL) => getSearchFile(SEARCH_PAYLOAD), {
    refreshInterval: 0,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  }); */
  // const { data: searchResults, isValidating } = useSWR('GetFile', (URL) => getSearchFileDynamic(SEARCH_PAYLOAD), {
  //   refreshInterval: 0,
  //   revalidateOnFocus: false,
  //   revalidateOnReconnect: false,
  // });

  const {
    data: searchResults,
    isMutating,
    trigger: triggerSearch,
  } = useSWRMutation("GetSearch", (url, payload) => getSearch(payload));

  const { data: elastic } = useSWRMutation(
    "GetElasticProperties",
    (URL) => getElasticProperties(workFlowId),
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const handleViewResultsMode = (mode) => {
    localStorage.setItem("lastViewResultsMode", mode);
    setViewResultsMode(mode);
  };

  const handleSearchModeChange = () => {
    setSearchMode((prevMode) =>
      prevMode === "BASIC_SEARCH_FILTER"
        ? "ADVANCED_SEARCH_FILTER"
        : "BASIC_SEARCH_FILTER",
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputClear = (name) => {
    setFormData({
      ...formData,
      [name]: "",
    });
  };

  const handleClearAllFields = () => {
    setFormData(
      Object.fromEntries(Object.keys(formData).map((key) => [key, ""])),
    );
  };

  // const handleSearch = async (filterSettings) => {
  //   setFilterSettings({ ...filterSettings });
  //   try {
  //     setLoadingResults(true);
  //     SEARCH_PAYLOAD.FilterSetting = filterSettings;
  //     const PAYLOAD = Object.entries(filterSettings).map(([key, value]) => ({
  //       Parameter: key,
  //       Value: value,
  //     }));
  //     console.log("PAYLOAD", PAYLOAD);
  //     await mutateSearch(getSearch(SEARCH_PAYLOAD), false);
  //   } catch (error) {
  //   } finally {
  //     setLoadingResults(false);
  //   }
  // };

  // const handleSearch = async (filterSettings) => {
  //   const PARSED_FILTER_SETTINGS = Object.entries(filterSettings).map(
  //     ([key, value]) => ({
  //       Parameter: key,
  //       Value: value,
  //     }),
  //   );

  //   setFilterSettings(PARSED_FILTER_SETTINGS);
  // };

  const handleClearResults = async () => {
    try {
      setLoadingResults(true);
      handleClearAllFields();
      // await mutate('GetSearchFile');
      await triggerSearch();
    } catch (error) {
    } finally {
      setLoadingResults(false);
    }
  };

  const handleResultClick = (result) => {
    sessionStorage.setItem("WorkFlowInstanceId", result.WorkflowInstanceId);
    navigate(`/inbox/asignacion/${result.Id}`);
  };

  const getParsedFiltersSettingsForRequest = () =>
    Object.entries(filterSettings).map(([key, value]) => ({
      Parameter: key,
      Value: value,
    }));

  const handleSetCurrentPage = (page) => {
    setFilterSettings({
      ...filterSettings,
      PageNumber: page,
    });
  };

  const handleSetFilterSettings = (selectedFilterSettings) => {
    setFilterSettings({
      ...filterSettings,
      ...selectedFilterSettings,
      PageNumber: 1,
    });
  };

  useEffect(() => {
    const parsedFilterSettingsForRequest =
      getParsedFiltersSettingsForRequest(filterSettings);
    triggerSearch(parsedFilterSettingsForRequest);
  }, [filterSettings, triggerSearch]);

  const RENDER_SEARCH_FILTERS_BY_MODE = {
    BASIC_SEARCH_FILTER: (
      <BasicSearchFilter
        formData={formData}
        onInputChange={handleInputChange}
        onInputClear={handleInputClear}
        onSearch={handleSetFilterSettings}
        onClearResults={handleClearResults}
      />
    ),
    ADVANCED_SEARCH_FILTER: (
      <AdvancedSearchFilter
        formData={formData}
        onInputChange={handleInputChange}
        onInputClear={handleInputClear}
        onSearch={handleSetFilterSettings}
        onClearResults={handleClearResults}
      />
    ),
  };
  // console.log("tyabledataa", searchResults.Data);

  const RENDER_RESULTS_BY_MODE = {
    TABLE: (
      <SearchResultsTable
        CurrentPage={filterSettings.PageNumber}
        tableData={searchResults}
        loading={loadingResults}
        onResultClick={handleResultClick}
        handleChangePage={handleSetCurrentPage}
      />
    ),
    LIST: IsMobile ? (
      <SearchResultsListMobile
        data={searchResults}
        onResultClick={handleResultClick}
      />
    ) : (
      <SearchResultsList
        searchResults={searchResults}
        onResultClick={handleResultClick}
      />
    ),
  };

  return elasticEnabled(elastic) ? (
    <ElasticSearchIndex />
  ) : (
    <Box padding={IsMobile ? "0.5em" : "2rem"}>
      <CustomLoadingOverlay active={isMutating} />
      {RENDER_SEARCH_FILTERS_BY_MODE[searchMode]}
      <Box display="flex" justifyContent="space-between" margin={"1rem 0"}>
        <Tooltip
          title={
            searchMode === "BASIC_SEARCH_FILTER"
              ? t("basic_search_filter_basic")
              : t("basic_search_filter_specific")
          }
          placement={"top-start"}
          arrow
        >
          <div>
            <Switch onChange={handleSearchModeChange} />
          </div>
        </Tooltip>
        {searchResults && !IsMobile && (
          <ButtonGroup
            variant="outlined"
            color={"borderBlue" /* 'borderRed' */}
          >
            <Tooltip title={t("general_table")} placement="top">
              <BtnCustom
                variant="outlined"
                onClick={() => handleViewResultsMode("TABLE")}
              >
                <Icon icon={"fluent:table-28-regular"} fontSize="1.5rem" />
              </BtnCustom>
            </Tooltip>
            <Tooltip title={t("general_list")} placement="top">
              <BtnCustom
                variant="outlined"
                onClick={() => handleViewResultsMode("LIST")}
              >
                <Icon icon={"material-symbols:menu"} fontSize="1.5rem" />
              </BtnCustom>
            </Tooltip>
          </ButtonGroup>
        )}
      </Box>
      {searchResults && searchResults?.Data?.length > 0 ? (
        RENDER_RESULTS_BY_MODE[IsMobile ? "LIST" : viewResultsMode]
      ) : (
        <div style={{ display: "grid", placeItems: "center", gap: "1.5rem" }}>
          <Typography variant="h5" align="center">
            Lo sentimos, no encontramos el folio ingresado.
          </Typography>
        </div>
      )}
    </Box>
  );
};
