
import { useTheme } from '@mui/styles';
import { InformationTab } from 'components/configuration/TabViewsConfiguration/ProfilesView/InformationTab';
import { ProfilesTable } from 'components/Profiles/Tables/ProfilesTable';
import { Typography } from '@mui/material';
import TabPanel from 'components/common/TabPanel/TabPanel';
import TabsComponent from 'components/common/Tab/Tab';
import { useTranslation } from 'react-i18next';



export const ProfilesManagement = () => {
  const { t } = useTranslation()
  const theme = useTheme();

  const TABS = [//For translations
    {
      label: t('profile_management_tab_inforamtion'),
    },
    {
      label: t('profile_management_tab_profiles'),
    },
  ];

  const render = (value) => {
    return (
      <>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <InformationTab />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Typography variant="span">
            {t("profile_management_permissions_intructions")}
          </Typography>
          <ProfilesTable />
        </TabPanel>
      </>
    );
  };
  return <TabsComponent tabs={TABS} render={render} />;
};
