import { Box } from "@mui/material";
import { RoundedButton } from "../Buttons";
import { Icon } from "@iconify/react";
import { ClearableTextField } from "../common/ClearableTextField";
import { useState } from "react";
import { AdvancedSearchFiltersModal } from "../common/Modals/Views/AdvancedSearchFiltersModal";
import { useTranslation } from "react-i18next";
import { IsDevice } from "utils";

export const AdvancedSearchFilter = ({
  formData,
  onInputChange,
  onInputClear,
  onSearch,
  onClearResults,
}) => {
  const { t } = useTranslation();
  const { IsMobile } = IsDevice();
  const [showAllFiltersModal, setShowAllFiltersModal] = useState(false);

  const specificFilterSettings = {
    SearchType: "Especific",
    Nombre: formData.Nombre,
    Folio: formData.Folio,
  };

  const [modalFormData, setModalFormData] = useState({
    Nombre: "",
    Folio: "",
    Geid: "",
    Puesto: "",
    Compañia: "",
  });

  const handleModalFormData = (data) => {
    setModalFormData(data);
  };

  const handleOpenFiltersModal = () => {
    setShowAllFiltersModal(true);
  };

  const handleCloseFiltersModal = () => {
    setShowAllFiltersModal(false);
  };

  const handleClearAllFieldsModal = () => {
    setModalFormData(
      Object.fromEntries(Object.keys(modalFormData).map((key) => [key, ""])),
    );
  };

  return (
    <Box
      display="flex"
      gap={IsMobile ? "0.5rem" : "2rem"}
      sx={{ ...(IsMobile && { flexDirection: "column" }) }}
    >
      <Box
        display="flex"
        gap={"0.5rem"}
        sx={IsMobile ? { flexDirection: "column" } : { width: "45%" }}
        // sx={{ width: "45%" }}
      >
        <ClearableTextField
          name="Folio"
          placeholder={t("search_advanced_placeholder_folio")}
          value={formData.Folio}
          onChange={onInputChange}
          onClear={onInputClear}
          size={"small"}
        />
        <ClearableTextField
          name="Nombre"
          placeholder={t("search_advanced_placeholder_name")}
          value={formData.Nombre}
          onChange={onInputChange}
          onClear={onInputClear}
          size={"small"}
        />
      </Box>
      <RoundedButton
        variant="contained"
        sx={{
          minWidth: "15%",
          /* "&:hover": {
            backgroundColor: "red",
          }, */
        }}
        onClick={() => onSearch(specificFilterSettings)}
      >
        {t("general_search")}
      </RoundedButton>
      <RoundedButton
        variant="outlined"
        sx={{
          minWidth: "15%",
          "&:hover": {
            backgroundColor: "white",
          },
        }}
        onClick={() => {
          onClearResults();
          handleClearAllFieldsModal();
        }}
      >
        {t("search_clean")}
      </RoundedButton>
      {/*       <RoundedButton
        variant="outlined"
        sx={{ minWidth: "15%" }}
        onClick={onClearResults}
      >
        {t("search_clean")}
      </RoundedButton> */}
      <RoundedButton
        variant="outlined"
        sx={{
          minWidth: "15%",
          "&:hover": {
            backgroundColor: "white",
          },
        }}
        startIcon={<Icon icon={"iconoir:filter"} />}
        onClick={handleOpenFiltersModal}
      >
        {t("search_filter")}
      </RoundedButton>
      {showAllFiltersModal && (
        <AdvancedSearchFiltersModal
          open={showAllFiltersModal}
          handleClose={handleCloseFiltersModal}
          onSearch={onSearch}
          formData={modalFormData}
          handleModalFormData={handleModalFormData}
        />
      )}
    </Box>
  );
};
