import Stack from "@mui/material/Stack";
import { Box, Button, Typography } from "@mui/material";
import { DocumentTable } from "../Tables/DocumentTable";
import React from "react";
import { useTranslation } from "react-i18next";

export const DocumentView = ({ handleMode, setSelectedDocument }) => {
  const { t } = useTranslation()

  return (
    <Stack spacing={"2rem"}>
      <Box display='flex' justifyContent='space-between'>
        <Typography variant="span">
          {t("configuration_docStructure_instructions")}
        </Typography>
        <Button sx={{ borderRadius: "2rem", minWidth: "20%" }} variant="contained" onClick={() => handleMode("ADD_DOCUMENT_FORM")}>
          {t("configuration_docStructure_add_document")}
        </Button>
      </Box>
      <DocumentTable handleMode={handleMode} setSelectedDocument={setSelectedDocument} />
    </Stack>
  );
}