import axios from 'axios';
import { AppUrls, } from './Globals';
import { Config } from './Config';

export const documentRequests = {}
function getType(type) {
  switch (type) {
    case '.pdf':
      return 'application/pdf'
    case '.mp4':
      return 'video/mp4'
    case '.jpg':
      return 'image/jpeg'
    case '.mp3':
      return 'audio/mp3'
    case '.webm':
      return 'video/webm'
    default:
      return '.pdf'
  }
}

documentRequests.DownloadDocument = (data) => {
  return axios
    .post(AppUrls.DownloadDocument, Config.SetParameters(data, '', '', ''), Config.ConfigAuth())
    .then(handleResponsePlainObject);
};

documentRequests.GetVersionedDocument = (data) => {
  return axios
    .post(AppUrls.GetVersionedDocument, Config.SetParameters(data, '', '', ''), Config.ConfigAuth())
    .then(handleResponsePlainObject);
};

documentRequests.DownloadVersionedDocument = (data) => {
  return axios
    .post(AppUrls.DownloadVersionedDocument, Config.SetParameters(data, '', '', ''), Config.ConfigAuth())
    .then(handleResponsePlainObject);
};

documentRequests.DeleteDocument = (data) => {
  return axios
    .post(AppUrls.DeleteDocument, Config.SetParameters(data, '', '', ''), Config.ConfigAuth())
    .then(handleResponsePlainObject);
};

documentRequests.GetStagePendingDocuments = (data) => {
  return axios
    .post(AppUrls.GetStagePendingDocuments, Config.SetParameters(data, '', '', ''), Config.ConfigAuth())
    .then(handleResponsePlainObject);
};

documentRequests.UpdateDocumentMetadata = (data) => {
  return axios
    .post(AppUrls.UpdateDocumentMetadata, Config.SetParameters(data, '', '', ''), Config.ConfigAuth())
    .then(handleResponsePlainObject);
};

documentRequests.GetDocumentQuestions = (data) => {
  return axios
    .post(AppUrls.GetDocumentQuestions, Config.SetParameters(data, '', '', ''), Config.ConfigAuth())
    .then(handleResponsePlainObject);
};

documentRequests.GetDocumentalStructure = (data) => {
  return axios
    .post(AppUrls.GetDocumentalStructure, Config.SetParameters(data, '', '', ''), Config.ConfigAuth())
    .then(handleResponsePlainObject);
};

documentRequests.GetDocument = (data, extension = ".pdf") => {
  return axios({
    url: AppUrls.GetDocument + "?document_Id=" + data + "&&Authorization=" + Config.getTokenCurrentUser(),
    method: 'GET',
    responseType: 'blob', // Important to handle binary data
    headers: {
      'Cache-Control': 'no-cache',
    },
  }).then(response => handleDownloadMultimediaResponse(response, extension));
  // return axios.get(
  //   AppUrls.GetDocument + "?document_Id=" + data + "&&Authorization=" + Config.getTokenCurrentUser(),
  //   Config.ConfigNoAuthDoc()
  // ).then(handleResponse)
}

documentRequests.GetDocumentZip = (data) => {
  return axios.get(
    AppUrls.GetDocument + "?document_Id=" + data + "&&Authorization=" + Config.getTokenCurrentUser(),
    Config.ConfigNoAuthDoc()
  ).then(handleResponseZip)
}

documentRequests.GetBioDocument = (data) => {
  return axios.get(
    AppUrls.GetBioDocument + "?Id=" + data + "&&Authorization=" + Config.getTokenCurrentUser(),
    Config.ConfigNoAuthDoc()
  ).then(handleResponse)
}

let handleResponse = (response) => {
  console.log('handleResponse', response)
  //Se obtiene el tipo de archivo
  // console.log("*******response document: ******: ", response)
  const headerval = response.headers['content-disposition'];
  console.log("headerval: ", headerval)
  let extFile = '';
  if (headerval === undefined || headerval === null) {
    extFile = '.pdf'
  } else {
    if (headerval.includes("?utf")) {
      extFile = '.pdf'
    } else {
      extFile = '.' + headerval.split('.')[1].substring(0, 3);
    }
  }
  //Create a Blob from the PDF Stream
  const file = new Blob(
    [response.data],
    //{ type: response.data.type }
    { type: getType(extFile) }
  );//Build a URL from the file
  const fileURL = URL.createObjectURL(file);//Open the URL on new Window

  let respObject = {
    data: fileURL,
    url: response.config.url,
    type: getType(extFile),
    extension: extFile
  };

  if (response.status !== 200 &&
    response.statusText !== "OK") {
    let responseObject = { message: response.Messages + ' ' + (response.TansactionId ? response.TansactionId : "") + ' ' + (response.statusText ? response.statusText : "") };
    const error = (respObject.Body || responseObject);
    return Promise.reject(error);
  } else {
    return respObject;
  }
}


let handleResponseZip = (response) => {
  console.log('handleResponse', response)
  const headerval = response.headers['content-disposition'];
  console.log("headerval: ", headerval)
  let extFile = '.zip';
  //Create a Blob from the PDF Stream
  const file = new Blob(
    [response.data],
    //{ type: response.data.type }
    { type: getType(extFile) }
  );//Build a URL from the file
  const fileURL = URL.createObjectURL(file);//Open the URL on new Window

  let respObject = {
    data: fileURL,
    url: response.config.url,
    type: extFile,
    extension: extFile
  };

  if (response.status !== 200 &&
    response.statusText !== "OK") {
    let responseObject = { message: response.Messages + ' ' + (response.TansactionId ? response.TansactionId : "") + ' ' + (response.statusText ? response.statusText : "") };
    const error = (respObject.Body || responseObject);
    return Promise.reject(error);
  } else {
    return respObject;
  }
}

documentRequests.getDocumentInfo = (data) => {
  return axios.post(
    AppUrls.GetDocumentInfo,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

documentRequests.UpdateMetaData = (data) => {
  return axios.post(
    AppUrls.UpdateMetadata,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

documentRequests.validateDocument = (data) => {
  return axios.post(
    AppUrls.ValidateDocument,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

documentRequests.CargaDocumentoWithSection = (data) => {
  return axios.post(
    AppUrls.CargaDocumentoWithSection,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

documentRequests.CargarSeccion = (data) => {
  return axios.post(
    AppUrls.UploadSectionApi,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

documentRequests.RegisterMetadata = (data) => {
  return axios.post(
    AppUrls.RegisterMetadata,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

documentRequests.GetOcrData = (data) => {
  return axios.post(
    AppUrls.GetOcrData,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

documentRequests.GetDocumentsGroupByRol = (data) => {
  return axios.post(
    AppUrls.GetDocumentsGroupByRol,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

// Te va aregresar los documentos preseleccionados 
documentRequests.GetDocumentGeneratorSelection = (data) => {
  console.log('datadatadatadata', data)
  return axios.post(
    AppUrls.GetDocumentGeneratorSelection,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

//Para actualizar la seleccion y el orden
documentRequests.RegisterDocumentGeneratorSelection = (data) => {
  console.log('newArra', data)
  return axios.post(
    AppUrls.RegisterDocumentGeneratorSelection,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

//Para visualizar el documento, si este ya esta cargado te regresa el documento cargado, si es un documento generado te regresa la plantilla en pdf
documentRequests.DownloadDocumentGeneratorSelectionContent = (data) => {
  return axios.post(
    AppUrls.DownloadDocumentGeneratorSelectionContent,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}
documentRequests.GetDownloadDocumentGeneratorSelectionContent = (data) => {
  return axios.get(
    AppUrls.DownloadDocumentGeneratorSelectionContent + "?documentGeneratorSelection_Id=" + data,
    {
      responseType: "blob",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `${sessionStorage.getItem('tokenMc')}`,
      },
    }
  ).then(handleResponse)
}
documentRequests.DownloadDocumentViewerContent = (data) => {
  return axios.get(
    AppUrls.DownloadDocumentViewerContent + "?documentGeneratorSelection_Id=" + data,
    {
      responseType: "blob",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `${sessionStorage.getItem('tokenMc')}`,
      },
    }
  ).then(handleResponse)
}
// Para Generar el kit de contratacion y avanzar el stage
documentRequests.ProcessDocumentGeneratorSelection = (data) => {
  return axios.post(
    AppUrls.ProcessDocumentGeneratorSelection,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

documentRequests.DownloadTemplateGEIDContent = (data) => {
  console.log(data, 'data')
  return axios.get(
    AppUrls.DownloadTemplateGEIDContent + "?workflow_Id=" + data,
    {
      responseType: "blob",
      headers: {
        //Configuración de headers a enviar al consumir cualquier API ***Incluye token de autorización
        // "Access-Control-Allow-Origin": "*",
        Accept: "application/json", //JSON
        "Content-Type": "application/json;charset=UTF-8", //Esperamos un json con configuración UTF-8
        Authorization: `${sessionStorage.getItem('tokenMc')}`, //Se añade Token   Content-Type: multipart/form-data; boundary=something
      },
    }
  ).then((response) => {
    console.log('responseresponse', response)
    return response.data;
  })
}

documentRequests.GetFacialComparison = (data) => {
  return axios.post(
    AppUrls.GetFacialComparison,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

documentRequests.ValidateIdentityData = (data) => {
  return axios.post(
    AppUrls.ValidateIdentityData,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

documentRequests.GetValidateIdentityData = (data) => {
  return axios.post(
    AppUrls.GetValidateIdentityData,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

documentRequests.RegisterDataTwo = (data) => {
  return axios.post(
    AppUrls.RegisterDataTwo,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}
documentRequests.AutographSignDocument = (data) => {
  return axios.post(
    AppUrls.AutographSignDocument,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

let handleResponsePlainObject = (response) => {
  console.log('responseDocs', response)
  let respObject = response.data;//BodySecurityResponse(response.data);
  if (!respObject.IsOK) {
    let responseObject = { message: respObject.Messages + ' ' + (respObject.TansactionId ? respObject.TansactionId : '') + ' ' + (respObject.statusText ? respObject.statusText : '') };
    let error = (respObject.Body || responseObject);
    return Promise.reject(error);
  }
  return respObject;
}

let handleDownloadMultimediaResponse = (response, data) => {
  console.log('handleDownloadMultimediaResponse----data--paramm', data)
  console.log('handleDownloadMultimediaResponse----evidence', response)
  //Se obtiene el tipo de asrchivo
  // console.log("*******response document: ******: ", response)
  const headerval = data ?? response.headers['content-disposition'];
  console.log("headerval: ", headerval)
  let extFile = data;
  if (headerval === undefined || headerval === null) {
    extFile = '.pdf'
  } else if (headerval !== undefined && headerval !== null && headerval !== "") {
    if (headerval.includes("webm")) {
      extFile = ".webm"
    }
    else {
      extFile = data
    }
  }
  else {
    if (headerval.includes("?utf")) {
      extFile = '.pdf'
    }
    else {
      extFile = '.' + headerval.split('.')[1].substring(0, 3);
    }
  }

  console.log("extFile-----: ", extFile)

  //Create a Blob from the PDF Stream
  const file = new Blob(
    [response.data],
    //{ type: response.data.type }
    { type: getType(extFile) }
  );//Build a URL from the file
  const fileURL = URL.createObjectURL(file);//Open the URL on new Window

  let respObject = {
    data: fileURL,
    url: response.config.url,
    type: getType(extFile),
    extension: extFile
  };
  console.log("respObject ----handleDownloadMultimediaResponse: ", respObject)

  if (response.status !== 200 &&
    response.statusText !== "OK") {
    let responseObject = { message: response.Messages + ' ' + (response.TansactionId ? response.TansactionId : "") + ' ' + (response.statusText ? response.statusText : "") };
    const error = (respObject.Body || responseObject);
    return Promise.reject(error);
  } else {
    return respObject;
  }
}
