export const documentConstants = {
  DOCUMENTS_REQUEST: 'DOCUMENTS_REQUEST',
  DOCUMENTS_SUCCESS: 'DOCUMENTS_SUCCESS',
  DOCUMENTS_FAILURE: 'DOCUMENTS_FAILURE',

  VERSIONED_DOCUMENT_REQUEST: 'VERSIONED_DOCUMENT_REQUEST',
  VERSIONED_DOCUMENT_SUCCESS: 'VERSIONED_DOCUMENT_SUCCESS',
  VERSIONED_DOCUMENT_FAILURE: 'VERSIONED_DOCUMENT_FAILURE',
  CLEAN_VERSIONED_DOCUMENT: 'CLEAN_VERSIONED_DOCUMENT',

  DOWNLOAD_VERSIONED_DOCUMENT_REQUEST: 'DOWNLOAD_VERSIONED_DOCUMENT_REQUEST',
  DOWNLOAD_VERSIONED_DOCUMENT_SUCCESS: 'DOWNLOAD_VERSIONED_DOCUMENT_SUCCESS',
  DOWNLOAD_VERSIONED_DOCUMENT_FAILURE: 'DOWNLOAD_VERSIONED_DOCUMENT_FAILURE',

  DELETE_DOCUMENT_REQUEST: 'DELETE_DOCUMENT_REQUEST',
  DELETE_DOCUMENT_SUCCESS: 'DELETE_DOCUMENT_SUCCESS',
  DELETE_DOCUMENT_FAILURE: 'DELETE_DOCUMENT_FAILURE',

  GET_STAGE_PENDING_DOCUMENTS_REQUEST: 'GET_STAGE_PENDING_DOCUMENTS_REQUEST',
  GET_STAGE_PENDING_DOCUMENTS_SUCCESS: 'GET_STAGE_PENDING_DOCUMENTS_SUCCESS',
  GET_STAGE_PENDING_DOCUMENTS_FAILURE: 'GET_STAGE_PENDING_DOCUMENTS_FAILURE',

  UPDATE_DOCUMENT_METADATA_REQUEST: 'UPDATE_DOCUMENT_METADATA_REQUEST',
  UPDATE_DOCUMENT_METADATA_SUCCESS: 'UPDATE_DOCUMENT_METADATA_SUCCESS',
  UPDATE_DOCUMENT_METADATA_FAILURE: 'UPDATE_DOCUMENT_METADATA_FAILURE',

  GET_DOCUMENT_QUESTIONS_REQUEST: 'GET_DOCUMENT_QUESTIONS_REQUEST',
  GET_DOCUMENT_QUESTIONS_SUCCESS: 'GET_DOCUMENT_QUESTIONS_SUCCESS',
  GET_DOCUMENT_QUESTIONS_FAILURE: 'GET_DOCUMENT_QUESTIONS_FAILURE',

  GET_DOCUMENTAL_STRUCTURE_REQUEST: 'GET_DOCUMENTAL_STRUCTURE_REQUEST',
  GET_DOCUMENTAL_STRUCTURE_SUCCESS: 'GET_DOCUMENTAL_STRUCTURE_SUCCESS',
  GET_DOCUMENTAL_STRUCTURE_FAILURE: 'GET_DOCUMENTAL_STRUCTURE_FAILURE',

  DOCUMENT_FILE_REQUEST: 'DOCUMENT_FILE_REQUEST',
  DOCUMENT_FILE_SUCCESS: 'DOCUMENT_FILE_SUCCESS',
  DOCUMENT_FILE_FAILURE: 'DOCUMENT_FILE_FAILURE',

  DOCUMENT_BIO_REQUEST: 'DOCUMENT_BIO_REQUEST',
  DOCUMENT_BIO_SUCCESS: 'DOCUMENT_BIO_SUCCESS',
  DOCUMENT_BIO_FAILURE: 'DOCUMENT_BIO_FAILURE',

  DOCUMENT_UPDATEMETADATA_REQUEST: 'DOCUMENT_UPDATEMETADATA_REQUEST',
  DOCUMENT_UPDATEMETADATA_SUCCESS: 'DOCUMENT_UPDATEMETADATA_SUCCESS',
  DOCUMENT_UPDATEMETADATA_FAILURE: 'DOCUMENT_UPDATEMETADATA_FAILURE',

  CARGADOC_WITH_SECTION_REQUEST: 'CARGADOC_WITH_SECTION_REQUEST',
  CARGADOC_WITH_SECTION_SUCCESS: 'CARGADOC_WITH_SECTION_SUCCESS',
  CARGADOC_WITH_SECTION_FAILURE: 'CARGADOC_WITH_SECTION_FAILURE',

  UPLOAD_SECTION_REQUEST: 'UPLOAD_SECTION_REQUEST',
  UPLOAD_SECTION_SUCCESS: 'UPLOAD_SECTION_SUCCESS',
  UPLOAD_SECTION_FAILURE: 'UPLOAD_SECTION_FAILURE',

  REGISTER_DATA_REQUEST: 'REGISTER_DATA_REQUEST',
  REGISTER_DATA_SUCCESS: 'REGISTER_DATA_SUCCESS',
  REGISTER_DATA_FAILURE: 'REGISTER_DATA_FAILURE',

  GET_OCRDATA_REQUEST: 'GET_OCRDATA_REQUEST',
  GET_OCRDATA_SUCCESS: 'GET_OCRDATA_SUCCESS',
  GET_OCRDATA_FAILURE: 'GET_OCRDATA_FAILURE',

  GET_DOCUMENTS_BY_ROL_REQUEST: 'GET_DOCUMENTS_BY_ROL_REQUEST',
  GET_DOCUMENTS_BY_ROL_SUCCESS: 'GET_DOCUMENTS_BY_ROL_SUCCESS',
  GET_DOCUMENTS_BY_ROL_FAILURE: 'GET_DOCUMENTS_BY_ROL_FAILURE',

  GET_DOCUMENT_GENERATOR_SELECTION_REQUEST: 'GET_DOCUMENT_GENERATOR_SELECTION_REQUEST',
  GET_DOCUMENT_GENERATOR_SELECTION_SUCCESS: 'GET_DOCUMENT_GENERATOR_SELECTION_SUCCESS',
  GET_DOCUMENT_GENERATOR_SELECTION_FAILURE: 'GET_DOCUMENT_GENERATOR_SELECTION_FAILURE',

  REGISTER_DOCUMENT_GENERATOR_REQUEST: 'REGISTER_DOCUMENT_GENERATOR_REQUEST',
  REGISTER_DOCUMENT_GENERATOR_SUCCESS: 'REGISTER_DOCUMENT_GENERATOR_SUCCESS',
  REGISTER_DOCUMENT_GENERATOR_FAILURE: 'REGISTER_DOCUMENT_GENERATOR_FAILURE',

  DONWLOAD_DOCUMENT_GENERATOR_REQUEST: 'DONWLOAD_DOCUMENT_GENERATOR_REQUEST',
  DONWLOAD_DOCUMENT_GENERATOR_SUCCESS: 'DONWLOAD_DOCUMENT_GENERATOR_SUCCESS',
  DONWLOAD_DOCUMENT_GENERATOR_FAILURE: 'DONWLOAD_DOCUMENT_GENERATOR_FAILURE',

  RPOCESS_DOCUMENT_GENERATOR_REQUEST: 'RPOCESS_DOCUMENT_GENERATOR_REQUEST',
  RPOCESS_DOCUMENT_GENERATOR_SUCCESS: 'RPOCESS_DOCUMENT_GENERATOR_SUCCESS',
  RPOCESS_DOCUMENT_GENERATOR_FAILURE: 'RPOCESS_DOCUMENT_GENERATOR_FAILURE',

  FACIAL_COMPARISON_REQUEST: 'FACIAL_COMPARISON_REQUEST',
  FACIAL_COMPARISON_SUCCESS: 'FACIAL_COMPARISON_SUCCESS',
  FACIAL_COMPARISON_FAILURE: 'FACIAL_COMPARISON_FAILURE',

  VALIDATEDENTITY_DATA_REQUEST: 'VALIDATEDENTITY_DATA_REQUEST',
  VALIDATEDENTITY_DATA_SUCCESS: 'VALIDATEDENTITY_DATA_SUCCESS',
  VALIDATEDENTITY_DATA_FAILURE: 'VALIDATEDENTITY_DATA_FAILURE',

  GETVALIDATEDENTITY_DATA_REQUEST: 'GETVALIDATEDENTITY_DATA_REQUEST',
  GETVALIDATEDENTITY_DATA_SUCCESS: 'GETVALIDATEDENTITY_DATA_SUCCESS',
  GETVALIDATEDENTITY_DATA_FAILURE: 'GETVALIDATEDENTITY_DATA_FAILURE',

  REGISTERTWO_DATA_REQUEST: 'REGISTERTWO_DATA_REQUEST',
  REGISTERTWO_DATA_SUCCESS: 'REGISTERTWO_DATA_SUCCESS',
  REGISTERTWO_DATA_FAILURE: 'REGISTERTWO_DATA_FAILURE',

  AUTOGRAPH_SIGN_REQUEST: 'AUTOGRAPH_SIGN_REQUEST',
  AUTOGRAPH_SIGN_SUCCESS: 'AUTOGRAPH_SIGN_SUCCESS',
  AUTOGRAPH_SIGN_FAILURE: 'AUTOGRAPH_SIGN_FAILURE',
  AUTOGRAPH_SIGN_RESET: 'AUTOGRAPH_SIGN_RESET',
};
