export const signStyles = {
  container: {
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'stretch',
    gap: '2rem',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    textAlign: 'center',
  },
  documentViewer: {
    width: '50%',
  },
  componentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '50%',
  },
};
