import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Button,
  List,
  SwipeableDrawer,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Dialog,
  Box,
  styled,
  IconButton,
  Paper,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useForm, FormProvider } from "react-hook-form";

// import DeleteIcon from "@mui/icons-material/Delete";
// import ReceiptIcon from "@mui/icons-material/Receipt";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import AttachFileIcon from "@mui/icons-material/AttachFile";
// import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
// import AccountBoxIcon from "@mui/icons-material/AccountBox";
// import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
// import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
// import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
// import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
// import PersonIcon from "@mui/icons-material/Person";
// import AddIcon from "@mui/icons-material/Add";
// import PersonAdd from "@mui/icons-material/PersonAdd";
// import PhotoCameraOutlined from "@mui/icons-material/PhotoCameraOutlined";
// import Group from "@mui/icons-material/Group";
// import Description from "@mui/icons-material/Description";

import { B64toBlob } from "../utils";
import VisorPDF from "./Visor/VisorPDF";
// import {
//   mainStyles, //Estílos genéricos
//   theme, //Téma con colores de DS
// } from "../styles/Main.js"; //Se importan los estilos genéricos
import { UploadDcumentsControl } from "./";
import { useTranslation } from "react-i18next";

const AccordionCustom = styled(Accordion)(({ theme }) => ({
  width: "100%",
  "& .MuiAccordionSummary-content": {
    margin: "1px 0 !important",
  },
  "& .MuiIconButton-root": {
    padding: "0 0.5em !important",
  },
}));

const AccordionDetailsCustom = styled(AccordionDetails)(({ theme }) => ({
  width: "100%",
  padding: "16px",
  display: "flex",
  flexDirection: "column",
}));

const ContainerDropZone = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  margin: "0px",
  marginBottom: "0px",
  gridTemplateColumns: "20% 60% 20%",
  display: "grid !important",
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "100%",
    minHeight: "90vh",
  },
  overflowY: "hidden !important",
  height: "100vh",
}));

export const AccordeonElemnts = (props) => {
  const theme = useTheme();
  const formMethods = useForm({ mode: "onChange" });
  const [fileB64, setFileB64] = useState(null);
  const [fileUrl, setfileUrl] = useState(null);
  const [extensionCurrent, setExtensionCurrent] = useState("");
  const [expanded, setExpanded] = React.useState(false);
  const fileInfoResponse = useSelector((state) => state.FileInfo);

  const { t } = useTranslation();

  let handleFileChange = (files, fileB64Response, extension) => {
    console.log(
      "handleFileChange***********************extension: ",
      extension,
      " fileB64Response: ",
      fileB64Response
    );
    if (fileB64Response == undefined || fileB64Response === null) {
      return;
    } else if (
      navigator.userAgent.match(/iPhone/i) &&
      (props.DocName === "TELMEX" || props.DocName === "IZZI")
    ) {
      props.onSendData(fileB64Response, extensionCurrent);
      props.onClose();
    } else {
      setFileB64(fileB64Response);
    }
    setExtensionCurrent(`.${extension}`);
  };

  useEffect(() => {
    if (fileB64 !== undefined && fileB64 !== null && fileB64 !== "") {
      console.log("extensionCurrent**********: ", extensionCurrent);
      let mimeType =
        extensionCurrent === ".pdf"
          ? "application/pdf"
          : `image/${extensionCurrent}`;
      const blob = B64toBlob(fileB64, mimeType);
      console.log("Blob generado: ", blob);
      const blobUrl = URL.createObjectURL(blob);
      // console.log("URL Blob: ", blobUrl)
      setfileUrl(blobUrl);
    }
  }, [fileB64]);

  useEffect(() => {
    console.log("items data accorion: ", props.Data);
  }, [props.Data]);

  let handlePhoto = () => {
    console.log("handlePhoto*********************** ");
    props.handleCamera();
  };

  const handleChange = (panel) => (event, isExpanded) => {
    console.log("panel: ", panel);
    setExpanded(isExpanded ? panel : false);
  };

  console.log(fileInfoResponse, "fileInfoResponse");

  useEffect(() => {
    console.log("AJUSTEBORRADO", fileB64, props.ExtensionsDoc?.includes(extensionCurrent))
    if (fileB64 && props.ExtensionsDoc?.includes(extensionCurrent) == false) {
      setfileUrl(null);
      setFileB64(null);
      console.log("AJUSTEBORRADO")
    }
  }, [fileB64]);

  const required_serrasa_text = (docItem) => {
    if (!docItem) return null;
    if (fileInfoResponse?.items?.WorkFlow?.Id !== 12) return null;
    if (fileInfoResponse.items.Stage.FlowState !== "Carga documental BGC") return null;
    if (docItem.Name !== "SERASA") return null
    return (
      <Typography sx={{ lineHeight: 1, pl: 1 }}>
        <b>{t("requeriment_serasa_text")}</b>
      </Typography>
    )
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {" "}
      {props.Data?.map((docItem, index) => {
        return (
          <AccordionCustom
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            key={`${docItem._id}_${index}`}
          >
            <AccordionSummary
              onClick={() => {
                props.handleButton(docItem);
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`id_${docItem.Id}`}
              key={`id_${docItem.Id}`}
              sx={{ m: 0, p: 0 }}
            >
              <div>
                <Box sx={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 0,
                  padding: "0.5em",
                }}>
                  <Typography variant="h6">
                    {docItem.Required ? (
                      <>
                        {" "}
                        {docItem.Name}
                        <spam style={{ color: "#F43E3E" }}> * </spam>
                      </>
                    ) : (
                      docItem.Name
                    )}
                  </Typography>{" "}
                  &nbsp; &nbsp;
                  <Typography
                    variant="h6"
                    style={{
                      color: getReadableColorStatus(docItem.IsRejected),
                    }}
                  >
                    {getReadableStatus(docItem.IsRejected, t)}
                  </Typography>
                </Box>
                {required_serrasa_text(docItem)}
              </div>
            </AccordionSummary>
            <AccordionDetailsCustom>
              {docItem.DocumentTypes?.length > 1 ? (
                <Typography variant="h6">{docItem.Name}</Typography>
              ) : (
                <FormProvider {...formMethods}>
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ display: "flex", width: "100%" }}>
                      <Box sx={{ width: "100%", flexGrow: 1 }}></Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {fileB64 !== null ? (
                          <Button
                            fullWidth
                            variant="contained"
                            sx={{ height: 45, mr: 3, px: 5, maxWidth: 300 }}
                            onClick={() => {
                              props.onSendData(fileB64, extensionCurrent);
                              setFileB64(null);
                              setExtensionCurrent(null);
                            }}
                          >
                            {/* {t("document_upload")} */}
                            {t("general_continue")}
                          </Button>
                        ) : null}
                        {fileB64 !== null ? (
                          <Button
                            fullWidth
                            variant="outlined"
                            sx={{
                              height: 45,
                              px: 5,
                              maxWidth: 300,
                            }}
                            onClick={() => {
                              setFileB64(null);
                              setExtensionCurrent(null);
                            }}
                          >
                            {t("choose_another_file")}
                          </Button>
                        ) : null}
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      minHeight: "34vh",
                    }}
                  >
                    {console.log("TIPOS", props.ExtensionsDoc?.includes(extensionCurrent) && extensionCurrent === ".pdf")}
                    {fileB64 === null ? (
                      <>
                        {fileInfoResponse.items?.StageName ===
                          "Carga documental" &&
                          fileInfoResponse?.items?.Status !==
                          "Carga documental por Background Check Vendor" ? (
                          <>
                            {/* <Typography variant="subtitle1">
                              Carga los documentos que formarán parte del
                              expediente, estos documentos podrán ser editables o
                              solo de lectura.{" "}
                            </Typography>{" "} 
                            <Typography variant="subtitle1">
                              Si el documento no es el correcto, presiona el botón
                              de “Cargar documento” y sube el archivo correcto.{" "}
                            </Typography>{" "}*/}
                            &nbsp; &nbsp;
                          </>
                        ) : null}
                        {console.log("AJUSTEACORDEON", props.ExtensionsDoc)}
                        <UploadDcumentsControl
                          ExtensionsDoc={props.ExtensionsDoc}
                          initLoadChange={true}
                          IsPhoto={false}
                          handleCamera={handlePhoto}
                          onChange={handleFileChange}
                          onClear={fileB64 == null ? true : false}
                        />
                      </>
                    ) : fileUrl == null ? (
                      <span>{t("general_wait")}...</span>
                    ) : props.ExtensionsDoc?.includes(extensionCurrent) && extensionCurrent === ".pdf" ? (
                      <Box sx={{ width: "100%" }}>
                        <VisorPDF
                          pdfUrl={fileUrl}
                          pdfName={"Document"}
                          height={"50vh"}
                        />
                      </Box>
                    ) : props.ExtensionsDoc?.includes(extensionCurrent) && (extensionCurrent == '.png' || extensionCurrent == '.jpg' || extensionCurrent == '.jpeg') ? (
                      <img
                        style={{ width: "100%" }}
                        src={fileUrl}
                        alt="experiment"
                      />
                    ) : null}
                  </Box>
                </FormProvider>
              )}
            </AccordionDetailsCustom>
          </AccordionCustom>
        );
      })}
    </Box>
  );
};

const getReadableColorStatus = (status) => {
  if (status == false) {
    return "#F20000";
  } else if (status == true || status == 1) {
    return "#28a745";
  } else {
    return "#FFD233";
  }
};

const getReadableStatus = (status, t) => {
  if (status == false) {
    return t ? t("state_rejected") : "Rechazado";
  } else if (status == true) {
    return t ? t("state_uploaded_verified") : "Cargado y verificado";
  } else if (status == 1) {
    return t ? t("state_captured") : "Capturado";
  } else {
    return t ? t("state_pending_upload") : "Pendiente de carga";
  }
};
