import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { AddMetadata } from "./AddMetadata";
import { EditMetadataModal } from "../../common/Modals/Views/EditMetadataModal";
import { MetadataTable } from "../Tables/MetadataTable";
import { useTranslation } from "react-i18next";

export const ConfigDocumentMetadata = () => {
  const { t } = useTranslation()
  const [view, setView] = useState("table");
  const [showEditModal, setShowEditModal] = useState();
  const [selectedEditRow, setSelectedEditRow] = useState();

  const handleView = (view) => {
    setView(view);
  };

  const handleOpenEditModal = (row) => {
    setSelectedEditRow(row);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  return (
    <>
      {view === "table" ? (
        <Stack spacing={"2rem"}>
          <Grid container>
            <Grid item md={10}>
              <Typography variant="p">
                {t("configuration_metadata_instruction")}
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Button
                sx={{ borderRadius: "2rem", minWidth: "20%" }}
                variant="contained"
                fullWidth
                onClick={() => {
                  handleView("form");
                }}
              >
                {t("configuration_metadata_add_metadata")}
              </Button>
            </Grid>
          </Grid>
          <MetadataTable onClickEdit={handleOpenEditModal} />
          <EditMetadataModal
            open={showEditModal}
            handleClose={handleCloseEditModal}
            initialData={selectedEditRow}
          />
        </Stack>
      ) : (
        <AddMetadata handleView={handleView} />
      )}
    </>
  );
};
