import React from 'react';
import { OBTableCell, OBTableRow } from 'components/common/Table/Table.styled';
import { Box, Typography } from '@mui/material';
import MainTable from 'components/common/Table';
import { useTranslation } from 'react-i18next';
// import { ReactComponent as Engranajes } from 'assets/DOC/Icons/engranajes.svg';
import Engranajes from 'assets/Metlife/Icons/engranajes.png';
import { UseImage } from 'components';

export const InformationTab = () => {
  const { t } = useTranslation();
  const selectedConfig = JSON.parse(sessionStorage.getItem('configurationData'));

  const columns = [
    { id: 'RoleName', label: t('profile_management_header_workflow'), minWidth: 170 },
    {
      id: 'PermissionGroupDescription',
      label: t('profile_management_header_description'),
      minWidth: 170,
    },
  ];
  console.log(selectedConfig)
  const rows = [selectedConfig];

  const render = (row) => (
    <OBTableRow hover role="checkbox">
      <OBTableCell>{row.Name}</OBTableCell>
      <OBTableCell>{row.Description}</OBTableCell>
    </OBTableRow>
  );

  return (
    <>
      <Typography variant={'h6'}>{t('profile_management_header_title')}</Typography>
      <Box sx={{ display: 'grid', placeItems: 'center', height: '100%' }}>
        <UseImage src={Engranajes} />
        <MainTable columns={columns} rows={rows} render={render} />
      </Box>
    </>
  );
};
