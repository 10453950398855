import { Box, Button, IconButton, MenuItem, Select, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import useSWRMutation from "swr/mutation";

import { useParams } from "react-router-dom";
import { createDocumentActionId } from "api/document/documentRequests";
import { useTranslation } from "react-i18next";

export const DocumentUploadSelector = ({ handleMode, handleSetDocumentSpec }) => {
  const { t } = useTranslation()
  const [pendingDocumentGroups, setPendingDocumentGroups] = useState([]);
  const [selectedDocumentGroup, setSelectedDocumentGroup] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(' ');
  const { id } = useParams();

  useEffect(() => {
    setPendingDocumentGroups(JSON.parse(localStorage.getItem('StagePendingDocuments')));
  }, []);

  const { trigger } = useSWRMutation(
    'CreateActionId',
    (url, payload) => createDocumentActionId(payload),
    {
      populateCache: true,
      revalidate: false,
      onSuccess: () => handleMode("DOCUMENT_UPLOAD"),
    },
  );

  const handleGroupSelectionChange = (event) => {
    setSelectedDocumentGroup(event.target.value);
    setSelectedDocument(' ');
  };

  const handleDocumentSelectionChange = (event) => {
    setSelectedDocument(event.target.value);
  };

  const handleContinueButton = async () => {
    const documentSpec = {
      DocumentalGroupName: selectedDocumentGroup.Name,
      DocumentType_Id: selectedDocument?.DocumentType_Id,
      SectionId: selectedDocument?.Sections[0]?.Section_Id,
    };
    handleSetDocumentSpec(documentSpec)
    const PAYLOAD = {
      FileId: id, //3123,
      DocumentTypeId: selectedDocument?.DocumentType_Id,
      UserId: 32123,
      AuthToken: sessionStorage.getItem('tokenMc'),
    };
    await trigger(PAYLOAD);
    handleMode('DOCUMENT_UPLOAD');
  };

  if (pendingDocumentGroups.length === 0) return null;

  return (
    <Stack spacing={3}>
      <Box>
        <IconButton onClick={() => { handleMode('ADVANCED_VISUALIZATION') }}> <Icon icon={'mdi:arrow-back'} /> </IconButton>
        <Typography variant={'span'}>{t("documentation_table_view_upload_instructions")}</Typography>
      </Box>
      <Stack spacing={2}>
        <Typography>{t("documentation_table_view_upload_doc_grouper")}</Typography>
        <Select
          fullWidth
          defaultValue={' '}
          onChange={handleGroupSelectionChange}
        >
          <MenuItem value={' '} disabled>{t("documentation_table_view_upload_select_doc_grouper")}</MenuItem>
          {pendingDocumentGroups.map(group => (
            <MenuItem key={group.Name} value={group}>{group.Name}</MenuItem>
          ))}
        </Select>
      </Stack>
      <Stack spacing={2}>
        <Typography>{t("documentation_table_view_upload_doc")}</Typography>
        <Select
          fullWidth
          value={selectedDocument}
          onChange={handleDocumentSelectionChange}
        >
          <MenuItem value={' '} disabled>{t("documentation_table_view_select_upload_doc")}</MenuItem>
          {selectedDocumentGroup?.Documents?.map(document => (
            <MenuItem key={document.Name} value={document}>{document.Name}</MenuItem>
          ))}
        </Select>
      </Stack>
      <Box display='flex' justifyContent='center' gap='2rem' height='3rem'>
        <Button sx={{ borderRadius: '2rem', minWidth: '20%' }} variant='outlined' onClick={() => { handleMode('ADVANCED_VISUALIZATION') }}>{t("general_back")}</Button>
        <Button sx={{ borderRadius: '2rem', minWidth: '20%' }} variant='contained' onClick={handleContinueButton} disabled={selectedDocument === ' '}>{t("general_next")}</Button>
      </Box>
    </Stack>
  );
}
