import { Button as Btn, styled } from '@mui/material';
import { UploadSlotControlsContainer } from './UploadSlotControls.styled';
import { useTranslation } from 'react-i18next';

const Button = styled(Btn)(({ theme }) => ({
  minHeight: '50px'
}));

const UploadSlotControls = ({ handleUpload, resetFileAndExtension, isOnlyUploadSlot }) => {
  const { t } = useTranslation();
  const confirmTextButton = isOnlyUploadSlot ? 'document_upload_without_metadata' : 'general_continue'
  return (
    <UploadSlotControlsContainer>
      <Button fullWidth variant="outlined" onClick={resetFileAndExtension}>
        {t("choose_another_file")}
      </Button>
      <Button fullWidth variant="contained" onClick={handleUpload}>
        {t(confirmTextButton)}
      </Button>
    </UploadSlotControlsContainer>
  );
};

export default UploadSlotControls;
