import { Box, Button, Typography } from "@mui/material";
import noActionsPendingImage from 'assets/noDataToShow.svg'
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";

export const NoActionsPending = () => {
  const { t } = useTranslation()
  return (
    <Stack
      sx={{ paddingTop: '15vh' }}
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <Box component="img" src={noActionsPendingImage} />
      <Typography variant={'h5'} align={'center'} maxWidth={'50%'}>
        {t("no_more_actions")}
      </Typography>
    </Stack>
  );
}