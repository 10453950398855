import { Box, styled } from '@mui/material';

export const DropZoneContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  border: `dashed 3px #979797`,
  background: '#F9F9F9',
  borderRadius: 5,
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '&:hover': {
    opacity: 0.5,
    cursor: 'pointer',
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: 200,
  },
}));
