import { Button, Box, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Icon } from '@iconify/react';
import { DropZoneContainer } from './DragAndDrop.styled';
import { convertPdfToB64 } from 'utils/tools';
import { useTranslation } from 'react-i18next';
import { AlertModal } from '../Modals/Views/AlertModal';
import { useTheme } from '@mui/styles';

const DragAndDrop = ({ title, allowedExtensions, isExcelDocument, onChange }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const onDrop = (files) => {
    if (files.length === 0) {
      AlertModal('Error', t('alert_drag_n_drop_invalid_doc'), null, t);
      return;
    }
    const extension = files[0]?.name?.split('.').pop()?.toLowerCase();
    if (isExcelDocument && extension !== 'xlsx') {
      AlertModal('Error', t('alert_drag_n_drop_select_excel'), null, t);
      return;
    }
    if (allowedExtensions?.includes(extension) || (isExcelDocument && extension === 'xlsx')) {
      convertFileToB64(files[0]);
      return;
    }
    AlertModal('Error', t('alert_drag_n_drop_select_correct_type'), null, t);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: isExcelDocument
      ? '.xlsx'
      : {
          'image/png': ['.png'],
          'image/jpg': ['.jpg'],
          'application/pdf': ['.pdf'],
          'application/zip': ['.zip'],
        },
  });

  const convertFileToB64 = async (file) => {
    try {
      const splitted = file.name.split('.');
      const extention = splitted[splitted.length - 1];
      const { base64result } = await convertPdfToB64(file);
      onChange(base64result, extention);
    } catch (error) {
      onChange(null, null);
    }
  };

  return (
    <DropZoneContainer {...getRootProps()}>
      <input {...getInputProps()} style={{ display: 'none' }} />
      <Box margin={1} display={'flex'} justifyContent="center">
        <Icon fontSize={50} color={theme.palette.primary.main} icon="clarity:upload-cloud-line" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
        }}
      >
        <Typography align="center" variant="body2">
          {title ? title : t('general_drag_your_file')}
        </Typography>
        &nbsp;
        <a
          href="#"
          style={{
            color: theme.palette.primary.main,
            textDecoration: 'underline',
          }}
        >
          {t('general_upload_your_file_xlsx_button')}
        </a>
      </Box>
    </DropZoneContainer>
  );
};

export default DragAndDrop;
