import { Typography } from '@mui/material';
import SelectOptions from 'components/common/SelectOptions';
import SelectOptionsItem from 'components/common/SelectOptions/SelectOptionsItem';
import { useTranslation } from 'react-i18next';

const MobileUploaderSelector = ({ requiredDocument, handleCaptureMethod }) => {
  const { t } = useTranslation();

  const OPTIONS = [
    {
      id: 'CAMERA',
      label: t('general_capture_doc'),
      icon: 'solar:camera-broken',
    },
    {
      id: 'DRAG',
      label: t('general_upload_doc'),
      icon: 'solar:upload-broken',
    },
  ];
  return (
    <>
      <Typography sx={{ width: '100%' }} textAlign={'start'}>
        {t('upload_doc_instructions_1')} {requiredDocument}
      </Typography>
      <SelectOptions>
        {OPTIONS.map((option) => {
          return (
            <SelectOptionsItem
              key={option.id}
              label={option.label}
              icon={option.icon}
              handleOption={() => handleCaptureMethod(option.id)}
            />
          );
        })}
      </SelectOptions>
    </>
  );
};

export default MobileUploaderSelector;
