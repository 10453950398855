import React from 'react';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export const CustomAlertDialog = (props) => {
  const classes = {};

  return (
    <Dialog
      onClose={props.closeAction}
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <b>{props.title}</b>
      </DialogTitle>
      <DialogContent className={classes.DialogContent}>
        {props.textDescription === undefined || props.textDescription === null ? (
          props.Body
        ) : (
          <DialogContentText id="alert-dialog-description" className={classes.DialogContentText}>
            {props.textDescription}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions className={classes.DialogActions}>
        {props.textCancel != '' && props.textCancel != undefined && props.textCancel != null ? (
          <Button onClick={props.cancelAction} color="primary">
            {props.textCancel}
          </Button>
        ) : null}
        {!!props.textAcepted ? (
          <Button
            variant="contained"
            sx={{ height: 38 }}
            onClick={props.aceptedAction}
            color="primary"
            autoFocus
          >
            {props.textAcepted}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};
