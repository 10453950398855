import { notificationConstants } from '../constants';
import { notificationRequests } from '../../services';
import { fileActions } from './FileActions';

export const notificationActions = {};

notificationActions.GetNewPin = (data) => {
  return (dispatch) => {
    dispatch(request());
    notificationRequests.GetNewPin(data).then(
      (newPin) => dispatch(success(newPin)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: notificationConstants.NEW_PIN_REQUEST };
  }

  function success(newPin) {
    return { type: notificationConstants.NEW_PIN_SUCCESS, newPin };
  }

  function failure(error) {
    return { type: notificationConstants.NEW_PIN_FAILURE, error };
  }
};

notificationActions.GetNotificationsUser = (data) => {
  return (dispatch) => {
    dispatch(request());
    notificationRequests.GetNotificationsUser(data).then(
      (notifications) => dispatch(success(notifications)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: notificationConstants.GET_NOTIFICATIONS_USER_REQUEST };
  }

  function success(notifications) {
    return { type: notificationConstants.GET_NOTIFICATIONS_USER_SUCCESS, notifications };
  }

  function failure(error) {
    return { type: notificationConstants.GET_NOTIFICATIONS_USER_FAILURE, error };
  }
};

notificationActions.ReSendNip = (nipData) => {
  return (dispatch) => {
    dispatch(request());
    notificationRequests.ReSendNip(nipData).then(
      (responseNipData) => dispatch(success(responseNipData)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: notificationConstants.NIP_REQUEST };
  }
  function success(responseNipData) {
    return { type: notificationConstants.NIP_SUCCESS, responseNipData };
  }
  function failure(error) {
    return { type: notificationConstants.NIP_FAILURE, error };
  }
};

notificationActions.SendNip = (nipData) => {
  return async (dispatch) => {
    try {
      dispatch(request());
      const sendNipData = await notificationRequests.SendNip(nipData);
      dispatch(success(sendNipData));
    } catch (error) {
      dispatch(failure(error.message));
      throw error;
    }
  };

  function request() {
    return { type: notificationConstants.SENDNIP_REQUEST };
  }
  function success(sendNipData) {
    return { type: notificationConstants.SENDNIP_SUCCESS, sendNipData };
  }
  function failure(error) {
    return { type: notificationConstants.SENDNIP_FAILURE, error };
  }
};
