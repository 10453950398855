const { styled, Box } = require('@mui/material');

export const UploadSlotControlsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  gap: '1rem',
  '@media only screen and (min-width: 450px)': {
    flexDirection: 'row',
  },
}));
