import { DialogTitle, IconButton, Typography } from "@mui/material";
import { ModalContainer } from "../Modal.styles";
import React from "react";
import { ClearableTextField } from "../../ClearableTextField";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { es, ja, enUS as en, ptBR as pt } from "date-fns/locale";
// import { esES, ptBR, enUS, jaJP  } from '@mui/x-date-pickers/locales';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RoundedButton } from "../../../Buttons";
// import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/styles";
import { IsDevice } from "utils";

export const AdvancedSearchFiltersModal = ({
  open,
  handleClose,
  onSearch,
  formData,
  handleModalFormData,
}) => {
  const { t } = useTranslation();
  const { IsMobile } = IsDevice();
  const theme = useTheme();
  // const lang = i18n.language || 'es';

  // const currentLang = () => {
  //   const languages = {
  //     'es': es,
  //     'en': en,
  //     'pt': pt,
  //     'ja': ja
  //   }
  //   return languages[lang]
  // }

  // const localeTextLang = {
  //   'es': esES.components.MuiLocalizationProvider.defaultProps.localeText,
  //   'en': enUS.components.MuiLocalizationProvider.defaultProps.localeText,
  //   'pt': ptBR.components.MuiLocalizationProvider.defaultProps.localeText,
  //   'ja': jaJP.components.MuiLocalizationProvider.defaultProps.localeText, 
  // }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    handleModalFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleDateChange = (date) => {
  //   handleModalFormData({
  //     ...formData,
  //     Date: dayjs(date).isValid() && date !== null ? date.toISOString() : "",
  //   });
  // };

  const handleInputClear = (name) => {
    handleModalFormData({
      ...formData,
      [name]: "",
    });
  };

  const handleSearchButton = () => {
    const specificFilterSettings = {
      SearchType: "Especific",
      Nombre: formData.Nombre,
      Folio: formData.Folio,
      Geid: formData.Geid,
      // Area: formData.Area,
      Puesto: formData.Puesto,
      Compañia: formData.Compañia
      // Date: formData.Date,
      // Status: formData.Status,
    };
    onSearch(specificFilterSettings);
    handleClose();
  };

  return (
    <ModalContainer
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiDialog-paper": {
            width: "100%",
            borderRadius: "1rem",
            ...(!IsMobile && {
              maxWidth: "59%",
              padding: "1rem",
            }),
          },
        },
      }}
    >
      <DialogTitle sx={{ width: "100%", padding: "0rem" }}>
        <Typography variant={"titulo"}>
          {t("search_advanced_filters_title")}
        </Typography>
      </DialogTitle>
      <IconButton
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: "2rem",
          top: "1rem",
          color: theme.palette.primary.main,
        }}
      >
        <CloseIcon fontSize={"large"} />
      </IconButton>
      <ClearableTextField
        name="Folio"
        placeholder={t("search_advanced_filters_folio")}
        value={formData.Folio}
        onChange={handleInputChange}
        onClear={handleInputClear}
      />
      <ClearableTextField
        name="Nombre"
        placeholder={t("search_advanced_filters_name")}
        value={formData.FullName}
        onChange={handleInputChange}
        onClear={handleInputClear}
      />
      {/* <ClearableTextField
        name="Area"
        placeholder={t("search_advanced_filters_area")}
        value={formData.Area}
        onChange={handleInputChange}
        onClear={handleInputClear}
      /> */}
      <ClearableTextField
        name="Puesto"
        type="search"
        placeholder={t("search_advanced_filters_job")}
        value={formData.JobTitle}
        onChange={handleInputChange}
        onClear={handleInputClear}
      />
      <ClearableTextField
        name="Geid"
        type="search"
        placeholder={t("search_advanced_filters_geid")}
        value={formData.GEID}
        onChange={handleInputChange}
        onClear={handleInputClear}
      />
      <ClearableTextField
        name="Compañia"
        type="search"
        placeholder="Ingresa la compañía"
        value={formData.Company}
        onChange={handleInputChange}
        onClear={handleInputClear}
      />
      {/* <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={currentLang()}
        localeText={localeTextLang[lang]}
      >
        <DatePicker
          localeText={i18n.language}
          sx={{ minWidth: "100%" }}
          type="search"
          placeholder={t("search_advanced_filters_date")}
          onChange={handleDateChange}
          slotProps={{ field: { clearable: true } }}
        />
      </LocalizationProvider> */}
      {/* <ClearableTextField
        name="Status"
        type="search"
        placeholder={t("search_advanced_filters_status")}
        value={formData.Status}
        onChange={handleInputChange}
        onClear={handleInputClear}
      /> */}
      <RoundedButton
        variant="contained"
        sx={{ minWidth: "15rem", height: "4rem" }}
        onClick={handleSearchButton}
        size={"large"}
      >
        {t("general_search")}
      </RoundedButton>
    </ModalContainer>
  );
};
