import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const ResultCounterText = ({ result }) => {
  const { t } = useTranslation();

  if (!result || !result.length) return null;

  const { results, exp_results, doc_results } = result.reduce(
    (acc, item) => {
      acc.results++;
      if (item.ItemResult === "EXP") {
        acc.exp_results++;
      } else if (item.ItemResult === "DOC" || item.ItemResult === "CONTENT_DOC") {
        acc.doc_results++;
      }
      return acc;
    },
    { results: 0, exp_results: 0, doc_results: 0 }
  );

  return (
    <Box display="flex" justifyContent="space-between" margin=".5rem 0">
      <Typography style={{ fontSize: 13 }}>
        {t("advanced_search_result_message", {
          results,
          exp_results,
          doc_results,
        })}
      </Typography>
    </Box>
  );
};
