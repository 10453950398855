import { styled, TableCell, TableContainer, TableRow } from '@mui/material';

export const OBTableContainer = styled(TableContainer)(({ loading, theme }) => ({
  width: '100%',
  borderRadius: '0.5rem',
  border: '1px solid #ECECEC',
  backgroundColor: loading ? '#F6F6F6' : '',
  // minHeight: 'calc(100vh - 400px)'
}));

export const OBTableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  backgroundColor: `${theme.palette.primary.tableHeadLight} !important`,
  color: `${theme.palette.primary.tableHeadLightText} !important`,
  justifyContent: 'center',
}));

export const OBTableRow = styled(TableRow)(({ loading, theme }) => ({
  '&:hover': {
    backgroundColor: !loading ? `${theme.palette.primary.tableHover} !important` : '',
  },
}));

export const OBTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
}));