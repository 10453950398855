import { useEffect, useState } from 'react';
import { Button, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CustomAlertMaterial } from 'components/CustomAlertMaterial';
import { useSelector } from 'react-redux';
import { ReactComponent as SignIcon } from 'assets/DOC/Icons/Sign.svg';
import { useStyles } from 'components/NoShares';

export const SignHelp = ({ handleSetMode }) => {
  const localStyles = useStyles();
  const [docType, setDocType] = useState([]);
  const [cancelAlert, setCancelAlert] = useState(false);
  const file = useSelector((state) => state.FileInfo);

  useEffect(() => {
    if (file.items) {
      const storedProperties = sessionStorage.getItem('properties');
      setDocType(storedProperties ? JSON.parse(storedProperties) : []);
    }
  }, [file]);

  const handleSignButton = () => handleSetMode('CANVAS_SIGN');

  const handleCancelAlertClose = () => setCancelAlert(false);

  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        Al firmar estarás aceptando el uso de tu firma autógrafa en mensaje de datos en los
        siguientes documentos:
      </Typography>

      <SignIcon className={localStyles.imgSign} />

      <List dense disablePadding>
        {docType.map((doc, index) => (
          <ListItem key={index} dense disableGutters>
            <ListItemIcon>
              <CheckCircleIcon style={{ fill: '#89C34A' }} />
            </ListItemIcon>
            <ListItemText className={localStyles.listText} primary={doc.Name} />
          </ListItem>
        ))}
      </List>

      <Button variant="contained" onClick={handleSignButton} sx={{ width: '80%' }}>
        Firmar
      </Button>

      {cancelAlert && (
        <CustomAlertMaterial
          title="Firma autógrafa"
          body="Al cancelar, deberás comenzar el proceso para una nueva Solicitud. ¿Estas seguro de rechazar?"
          closeAction={handleCancelAlertClose}
          open={cancelAlert}
          leftButtonText="Cancelar"
          leftButtonAction={handleCancelAlertClose}
          rightButtonText="Aceptar"
          rightButtonAction={() => {}}
        />
      )}
    </>
  );
};
