import {
  Box,
  Button,
  Button as Btn,
  ButtonGroup,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
  styled,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MainDocumentInfoTable } from "./tables/MainDocumentInfoTable";
import { MainDocumentMetadataTable } from "./tables/MainDocumentMetadataTable";
import { MainDocumentVersionsTable } from "./tables/MainDocumentVersionsTable";
import { b64toUrl, getBase64FileExtension, GetMimeType, IsDevice } from "utils";
import VisorPDF from "components/Visor/VisorPDF";
import { documentActions } from "store/actions";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import { Icon } from "@iconify/react";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useStyles } from "components/tabs/TabsStyle";
import useSWRMutation from "swr/mutation";
import { CustomLoadingOverlay } from "components/CustomLoadingOverlay";
import { createLoadingSelector } from "store/selectors/LoadingSelectors";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { createDocumentActionId } from "api/document/documentRequests";
import TabPanel from "components/common/TabPanel";
import { ExpirationStatusColorsMapping } from "mappings/ExpirationStatusColorsMapping";
import { documentConstants } from "store/constants";
import XMLViewer from "react-xml-viewer";

const BtnCustom = styled(Btn)(({ theme }) => ({
  borderRadius: "0px !important",
}));

const StatusPoint = styled(Box)(({ theme }) => ({
  width: "1rem",
  height: "1rem",
  borderRadius: "50%",
}));

export const DocumentDetails = ({ handleMode, handleSetDocumentSpec }) => {
  const { id } = useParams();
  const [documentId, setDocumentId] = useState();
  const [tabValue, setTabValue] = useState(0);
  const [versionedDocumentData, setVersionedDocumentData] = useState();
  const [selectedDocumentData, setSelectedDocumentData] = useState();
  const [selectedDocMetadata, setSelectedDocMetadata] = useState([]);
  const [isOldVersionSelected, setIsOldVersionSelected] = useState(false);
  const [documentUrl, setDocumentUrl] = useState();
  const [fileExtension, setFileExtension] = useState();
  const [base64Doc, setBase64Doc] = useState(null);
  const stateAuth = useSelector((state) => state.Authentication);
  const userId = stateAuth.items?.UserLoginData?.Id;

  const documentPermissions = useMemo(
    () =>
      localStorage.getItem("selectedDocumentPermissions") == "undefined" ||
      localStorage.getItem("selectedDocumentPermissions") == "null"
        ? []
        : JSON.parse(localStorage.getItem("selectedDocumentPermissions")),
    [],
  );

  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const { IsMobile } = IsDevice();
  const localStyles = useStyles(theme);

  const { isMutating, trigger } = useSWRMutation(
    "CreateActionId",
    (url, payload) => createDocumentActionId(payload),
    {
      populateCache: true,
      revalidate: false,
      onSuccess: () => handleMode("DOCUMENT_UPLOAD"),
    },
  );

  const LOADING_STATES = [
    "VERSIONED_DOCUMENT",
    "DOCUMENTS",
    "DOWNLOAD_VERSIONED_DOCUMENT",
  ];
  const isLoading = useSelector(createLoadingSelector(LOADING_STATES));

  useEffect(() => {
    const documentIdStorage = localStorage.getItem("selectedViewDocument");
    setDocumentId(documentIdStorage);
    getDocumentDispatch(documentIdStorage);

    return () => dispatch({ type: documentConstants.CLEAN_VERSIONED_DOCUMENT });
  }, []);

  const sortedDocumentVersions = versionedDocumentData?.Versions.sort(
    (a, b) => b.Version - a.Version,
  );

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getDocumentDispatch = async (documentId) => {
    setIsOldVersionSelected(false);
    const documentVersions = await dispatch(
      documentActions.GetVersionedDocument(documentId),
    );
    setVersionedDocumentData(documentVersions);
    setSelectedDocumentData(documentVersions?.CurrentVersion);
    setSelectedDocMetadata(documentVersions?.CurrentVersion.Metadata);
    await getDocumentFile(documentVersions?.CurrentVersion.Id, true);
  };

  const getDocumentFile = async (documentFileId, isLastVersion) => {
    const documentB64 = isLastVersion
      ? await dispatch(documentActions.DownloadDocument(documentFileId))
      : await dispatch(
          documentActions.DownloadVersionedDocument({
            DocumentVersion_Id: documentFileId,
          }),
        );
    console.log('documentB64 data:',documentB64)
    if (documentB64) {
      setBase64Doc(documentB64.B64Content);
      const fileName = documentB64.FileName?.split(".")
      const extension = getBase64FileExtension(documentB64.B64Content);
      const newExt =  extension === "unknown" ? fileName.pop() : extension
      
      setFileExtension(newExt);
      const documentBlob = await b64toUrl(
        `${GetMimeType(`.${newExt}`)},${documentB64.B64Content}`,
      );
      setDocumentUrl(documentBlob);
    }
    return null;
  };

  const handleUploadButton = async () => {
    const documentSpec = {
      DocumentalGroupName: versionedDocumentData?.DocumentalGroup.Name,
      Document_Id: versionedDocumentData?.CurrentVersion?.Id,
      DocumentType_Id: versionedDocumentData?.CurrentVersion?.DocumentType_Id,
      SectionId: versionedDocumentData?.CurrentVersion?.Sections[0].Section_Id,
    };
    handleSetDocumentSpec(documentSpec);
    const PAYLOAD = {
      FileId: id,
      DocumentTypeId: versionedDocumentData?.CurrentVersion?.DocumentType_Id,
      UserId: userId,
      AuthToken: sessionStorage.getItem("tokenMc"),
    };
    await trigger(PAYLOAD);

    handleMode("DOCUMENT_UPLOAD");
  };

  const handleMetadataUpdate = async () => {
    const documentSpec = {
      DocumentalGroupName: versionedDocumentData?.DocumentalGroup.Name,
      Document_Id: versionedDocumentData?.CurrentVersion?.Id,
      DocumentType_Id: versionedDocumentData?.CurrentVersion?.DocumentType_Id,
      CurrentMetadata: versionedDocumentData?.CurrentVersion?.Metadata,
    };
    handleSetDocumentSpec(documentSpec);
    handleMode("DOCUMENT_METADATA_UPDATE");
  };

  const handleDownloadButton = () => {
    console.log('selectedDocumentData', selectedDocumentData,documentUrl)
    // return null
    const link = document.createElement("a");
    link.href = documentUrl;
    link.download = selectedDocumentData?.Name || "PDF_Example.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSelectLastVersion = async () => {
    setSelectedDocumentData(versionedDocumentData?.CurrentVersion);
    setSelectedDocMetadata(versionedDocumentData?.CurrentVersion?.Metadata);
    setIsOldVersionSelected(false);
    await getDocumentFile(versionedDocumentData?.CurrentVersion?.Id, true);
  };

  const handleSelectTableVersion = async (row) => {
    if (row?.IsCurrentVersion) return await handleSelectLastVersion();
    setSelectedDocumentData(row);
    setIsOldVersionSelected(true);
    const lastMetadata = versionedDocumentData.CurrentVersion.Metadata;
    const selectedMetadata = row.Metadata;
    const mergedMetadata = lastMetadata.map((item1) => {
      const matchingItem = selectedMetadata.find(
        (item2) => item2.Name === item1.Name,
      );
      return matchingItem ? matchingItem : item1;
    });
    setSelectedDocMetadata(mergedMetadata);
    await getDocumentFile(row?.DocumentVersionId, false);
  };

  const showPreviewDoc = () => {
    switch (fileExtension) {
      case "pdf":
        return (
          <Box sx={{ height: IsMobile ? "50vh" : "100vh" }}>
            <VisorPDF
              pdfName={selectedDocumentData?.Name}
              pdfUrl={documentUrl}
            />
          </Box>
        );
      case "xml": {
        const buff = Buffer.from(base64Doc, "base64");
        const xmlData = buff.toString();
        return (
          <Box
            overflow={"auto"}
            sx={{ wordWrap: "break-word", width: "100%", height: "50vh" }}
          >
            <XMLViewer height={500} xml={xmlData} />
          </Box>
        );
      }
      case "mp4":
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <video controls height={250} width="80%">
              <source
                type="video/webm"
                src={`data:video/webm;base64,${base64Doc}`}
              />
              <source
                type="video/mp4"
                src={`data:video/mp4;base64,${base64Doc}`}
              />
            </video>
          </Box>
        );

      default:
        return <Box component="img" src={documentUrl} width="100%" />;
    }
  };

  const TAB_LABELS = [
    t("document_details_table_header_information"),
    t("document_details_table_header_metadata"),
    t("document_details_table_header_versioned"),
  ];

  const updatePermission = documentPermissions[0]?.Status === "1";
  const uploadPermission = documentPermissions[1]?.Status === "1";
  const downloadPermission = documentPermissions[2]?.Status === "1";
  const visualizePermission = documentPermissions[3]?.Status === "1";

  return (
    <Grid container sx={{ height: "100%", position: "relative" }}>
      <CustomLoadingOverlay
        active={isMutating || isLoading}
        text={t("general_loading_message")}
      />
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Box display="flex" alignItems="center" gap={"1rem"}>
            <IconButton onClick={() => handleMode(localStorage.getItem('lastDocumentViewMode'))}>
              <Icon icon={"mdi:arrow-back"} />
            </IconButton>
            {selectedDocumentData && (
              <div>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <Typography variant={"h4"}>
                    {selectedDocumentData?.Name || ""}
                  </Typography>
                  {!isOldVersionSelected && (
                    <Tooltip
                      title={
                        selectedDocumentData?.Validity?.Status ||
                        t("document_details_without_caducity")
                      }
                      placement={"top"}
                      arrow
                    >
                      <StatusPoint
                        sx={{
                          backgroundColor:
                            ExpirationStatusColorsMapping[
                              selectedDocumentData?.Validity?.GlobalStatus?.toUpperCase()
                            ] || "#bbbbbb",
                        }}
                      />
                    </Tooltip>
                  )}
                </Box>
                <Typography
                  variant="subtitle1"
                  sx={{ whiteSpace: "pre" }}
                  color="textSecondary"
                >
                  {`${versionedDocumentData?.DocumentalGroup?.Description}      ${t("general_version")}: ${selectedDocumentData?.Version}.0`}
                </Typography>
              </div>
            )}
            {!IsMobile && (
              <ButtonGroup
                variant="outlined"
                color={"borderGray"}
                sx={{ ml: "auto" }}
              >
                {downloadPermission && (
                  <Tooltip title={t("general_download")} placement="top">
                    <BtnCustom
                      onClick={handleDownloadButton}
                      variant="outlined"
                    >
                      <FileDownloadOutlinedIcon />
                    </BtnCustom>
                  </Tooltip>
                )}
                {uploadPermission && (
                  <Tooltip title={t("general_upload")} placement="top">
                    <BtnCustom onClick={handleUploadButton} variant="outlined">
                      <FileUploadOutlinedIcon />
                    </BtnCustom>
                  </Tooltip>
                )}
                <Tooltip title={t("request_refresh")} placement="top">
                  <BtnCustom
                    onClick={() => getDocumentDispatch(documentId)}
                    variant="outlined"
                  >
                    <CachedOutlinedIcon />
                  </BtnCustom>
                </Tooltip>
              </ButtonGroup>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          {isOldVersionSelected && !IsMobile && (
            <Button onClick={handleSelectLastVersion}>
              {t("document_details_go_back_version")}
            </Button>
          )}
          {visualizePermission && showPreviewDoc()}
        </Grid>
        <Grid item md={6} sx={{ overflowY: "auto" }}>
          <Tabs
            variant="fullWidth"
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="secondary"
            textColor="black"
            sx={{ borderBottom: "1px solid #D6D6D6" }}
          >
            {TAB_LABELS.map((tabLabel) => (
              <Tab
                label={tabLabel}
                classes={{
                  root: localStyles.subTabRoot,
                  selected: localStyles.selectedSubTab,
                }}
              />
            ))}
          </Tabs>
          <TabPanel value={tabValue} index={0} /* padding={"2rem 0"} */>
            <MainDocumentInfoTable
              selectedDocumentInfo={selectedDocumentData}
              grouperInfo={versionedDocumentData?.DocumentalGroup}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1} /*  padding={"2rem 0"} */>
            {!isOldVersionSelected &&
              updatePermission &&
              selectedDocMetadata.length > 0 &&
              !IsMobile && (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  marginBottom={"2rem"}
                >
                  <Button
                    onClick={handleMetadataUpdate}
                    variant="contained"
                    sx={{ borderRadius: "2rem" }}
                  >
                    {t("document_details_edit_metadata")}
                  </Button>
                </Box>
              )}
            <MainDocumentMetadataTable docMetadata={selectedDocMetadata} />
          </TabPanel>
          <TabPanel value={tabValue} index={2} /*  padding={"2rem 0"} */>
            <MainDocumentVersionsTable
              documentVersions={sortedDocumentVersions}
              onRowClick={handleSelectTableVersion}
            />
          </TabPanel>
        </Grid>
      </Grid>
    </Grid>
  );
};
