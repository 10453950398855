import axios from "axios";
import { AppUrls } from "./Globals";
import { Config } from "./Config";

export const workFlowRequests = {};

workFlowRequests.GetBinnacleInfo = (data) => {
  return axios
    .post(
      AppUrls.GetBinnacleInfo,
      Config.SetParameters(data, "", "", ""),
      Config.ConfigAuth()
    )
    .then(handleResponse);
};

workFlowRequests.ContinueWorkFlow = (data) => {
  return axios
    .post(
      AppUrls.ContinueWorkFlow,
      Config.SetParameters(data, "", "", ""),
      Config.ConfigAuth()
    )
    .then(handleResponse);
};

workFlowRequests.ReturnStage = (data) => {
  return axios
    .post(
      AppUrls.ReturnStage,
      Config.SetParameters(data, "", "", ""),
      Config.ConfigAuth()
    )
    .then(handleResponse);
};

workFlowRequests.RegisterStatusStage = (data) => {
  return axios.post(
    AppUrls.RegisterStatusStage,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
    ).then(handleResponse)
}

let handleResponse = (response) => {
  let respObject = response.data; //BodySecurityResponse(response.data);
  if (!respObject.IsOK) {
    let responseObject = {
      message:
        respObject.Messages +
        " " +
        (respObject.TansactionId ? respObject.TansactionId : "") +
        " " +
        (respObject.statusText ? respObject.statusText : ""),
    };
    let error = respObject.Body || responseObject;
    return Promise.reject(error);
  }
  return respObject;
};
