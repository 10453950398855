import { RoundedButton } from '../../../Buttons';
import React from 'react';
import { ModalContainer } from '../Modal.styles';
import { useTranslation } from 'react-i18next';
import WarningGif from 'assets/icons/WarningIcon.gif'
import { DialogTitle, Typography } from '@mui/material';
import { Box } from '@mui/system';

export const AddMetadataToDocModal = ({ open, onLeftClick, onRightClick }) => {
  const { t } = useTranslation();

  return (
    <ModalContainer open={open}>
      <DialogTitle sx={{ width: '10rem', padding: '0rem' }}>
        <img src={WarningGif} alt="WarningGif" />
      </DialogTitle>
      <Typography variant={'h6'}>{t('add_metadata_modal_instructions')}</Typography>
      <Box sx={{display:'flex', gap: '2rem', width:'100%'}}>
        <RoundedButton variant="outlined" onClick={onLeftClick} sx={{ width: '50%' }}>
          {t('add_metadata_modal_continue')}
        </RoundedButton>
        <RoundedButton variant="contained" onClick={onRightClick} sx={{ width: '50%' }}>
          {t('add_metadata_modal_yes')}
        </RoundedButton>
      </Box>
    </ModalContainer>
  );
};
