import { documentConstants } from '../constants';
import { documentRequests } from '../../services';

export const documentActions = {};

documentActions.DownloadDocument = (data) => {
  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await documentRequests.DownloadDocument(data);
      console.log(response);
      dispatch(success(response.Body));
      return response.Body;
    } catch (error) {
      dispatch(failure(error.message));
    }
  };

  function request() {
    return { type: documentConstants.DOCUMENTS_REQUEST };
  }

  function success(document) {
    return { type: documentConstants.DOCUMENTS_SUCCESS, payload: document };
  }

  function failure(error) {
    return { type: documentConstants.DOCUMENTS_FAILURE, error };
  }
};

documentActions.GetVersionedDocument = (data) => {
  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await documentRequests.GetVersionedDocument(data);
      dispatch(success(response.Body));
      return response.Body;
    } catch (error) {
      dispatch(failure(error.message));
    }
  };

  function request() {
    return { type: documentConstants.VERSIONED_DOCUMENT_REQUEST };
  }

  function success(document) {
    return { type: documentConstants.VERSIONED_DOCUMENT_SUCCESS, payload:document };
  }

  function failure(error) {
    return { type: documentConstants.VERSIONED_DOCUMENT_FAILURE, error };
  }
};

documentActions.DownloadVersionedDocument = (data) => {
  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await documentRequests.DownloadVersionedDocument(data);
      dispatch(success(response.Body));
      return response.Body;
    } catch (error) {
      dispatch(failure(error.message));
    }
  };

  function request() {
    return { type: documentConstants.DOWNLOAD_VERSIONED_DOCUMENT_REQUEST };
  }

  function success(document) {
    return { type: documentConstants.DOWNLOAD_VERSIONED_DOCUMENT_SUCCESS, document };
  }

  function failure(error) {
    return { type: documentConstants.DOWNLOAD_VERSIONED_DOCUMENT_FAILURE, error };
  }
};

documentActions.DeleteDocument = (data) => {
  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await documentRequests.DeleteDocument(data);
      dispatch(success(response.Body));
      return response.Body;
    } catch (error) {
      dispatch(failure(error.message));
    }
  };

  function request() {
    return { type: documentConstants.DELETE_DOCUMENT_REQUEST };
  }

  function success(document) {
    return { type: documentConstants.DELETE_DOCUMENT_SUCCESS, document };
  }

  function failure(error) {
    return { type: documentConstants.DELETE_DOCUMENT_FAILURE, error };
  }
};

documentActions.GetStagePendingDocuments = (data) => {
  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await documentRequests.GetStagePendingDocuments(data);
      dispatch(success(response.Body));
      return response.Body;
    } catch (error) {
      dispatch(failure(error.message));
    }
  };

  function request() {
    return { type: documentConstants.GET_STAGE_PENDING_DOCUMENTS_REQUEST };
  }

  function success(document) {
    return { type: documentConstants.GET_STAGE_PENDING_DOCUMENTS_SUCCESS, document };
  }

  function failure(error) {
    return { type: documentConstants.GET_STAGE_PENDING_DOCUMENTS_FAILURE, error };
  }
};

documentActions.UpdateDocumentMetadata = (data) => {
  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await documentRequests.UpdateDocumentMetadata(data);
      dispatch(success(response.Body));
      return response.Body;
    } catch (error) {
      dispatch(failure(error.message));
    }
  };

  function request() {
    return { type: documentConstants.UPDATE_DOCUMENT_METADATA_REQUEST };
  }

  function success(document) {
    return { type: documentConstants.UPDATE_DOCUMENT_METADATA_SUCCESS, document };
  }

  function failure(error) {
    return { type: documentConstants.UPDATE_DOCUMENT_METADATA_FAILURE, error };
  }
};

documentActions.GetDocumentQuestions = (data) => {
  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await documentRequests.GetDocumentQuestions(data);
      dispatch(success(response.Body));
      return response.Body;
    } catch (error) {
      dispatch(failure(error.message));
    }
  };

  function request() {
    return { type: documentConstants.GET_DOCUMENT_QUESTIONS_REQUEST };
  }

  function success(document) {
    return { type: documentConstants.GET_DOCUMENT_QUESTIONS_SUCCESS, document };
  }

  function failure(error) {
    return { type: documentConstants.GET_DOCUMENT_QUESTIONS_FAILURE, error };
  }
};

documentActions.GetDocumentalStructure = (data) => {
  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await documentRequests.GetDocumentalStructure(data);
      dispatch(success(response.Body));
      return response.Body;
    } catch (error) {
      dispatch(failure(error.message));
    }
  };

  function request() {
    return { type: documentConstants.GET_DOCUMENTAL_STRUCTURE_REQUEST };
  }

  function success(document) {
    return { type: documentConstants.GET_DOCUMENTAL_STRUCTURE_SUCCESS, document };
  }

  function failure(error) {
    return { type: documentConstants.GET_DOCUMENTAL_STRUCTURE_FAILURE, error };
  }
};

documentActions.GetDocument = (data, extension = '.pdf') => {
  return (dispatch) => {
    dispatch(request());
    documentRequests.GetDocument(data, extension).then(
      (document) => dispatch(success(document)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: documentConstants.DOCUMENT_FILE_REQUEST };
  }

  function success(document) {
    return { type: documentConstants.DOCUMENT_FILE_SUCCESS, document };
  }

  function failure(error) {
    return { type: documentConstants.DOCUMENT_FILE_FAILURE, error };
  }
};

documentActions.GetBioDocument = (data) => {
  return (dispatch) => {
    dispatch(request());
    documentRequests.GetBioDocument(data).then(
      (bioDocument) => dispatch(success(bioDocument)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: documentConstants.DOCUMENT_BIO_REQUEST };
  }

  function success(bioDocument) {
    return { type: documentConstants.DOCUMENT_BIO_SUCCESS, bioDocument };
  }

  function failure(error) {
    return { type: documentConstants.DOCUMENT_BIO_FAILURE, error };
  }
};

documentActions.UpdateMetaData = (data) => {
  return async (dispatch) => {
    dispatch(request());

    try {
      const docUpdateData = await documentRequests.UpdateMetaData(data);
      dispatch(success(docUpdateData));
    } catch (error) {
      dispatch(failure(error.message));
    }
  };

  function request() {
    return { type: documentConstants.DOCUMENT_UPDATEMETADATA_REQUEST };
  }

  function success(docUpdateData) {
    return { type: documentConstants.DOCUMENT_UPDATEMETADATA_SUCCESS, docUpdateData };
  }

  function failure(error) {
    return { type: documentConstants.DOCUMENT_UPDATEMETADATA_FAILURE, error };
  }
};

documentActions.CargaDocumentoWithSection = (data) => {
  return (dispatch) => {
    dispatch(request());
    documentRequests.CargaDocumentoWithSection(data).then(
      (cargaDocData) => dispatch(success(cargaDocData)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: documentConstants.CARGADOC_WITH_SECTION_REQUEST };
  }

  function success(cargaDocData) {
    return { type: documentConstants.CARGADOC_WITH_SECTION_SUCCESS, cargaDocData };
  }

  function failure(error) {
    return { type: documentConstants.CARGADOC_WITH_SECTION_FAILURE, error };
  }
};

documentActions.CargaSeccion = (sectionData) => {
  return async (dispatch) => {
    try {
      dispatch(request());
      const uploadSecData = await documentRequests.CargarSeccion(sectionData);
      dispatch(success(uploadSecData));
      return uploadSecData.Body;
    } catch (err) {
      dispatch(failure(err.message));
      throw err;
    }
  };

  function request() {
    return { type: documentConstants.UPLOAD_SECTION_REQUEST };
  }

  function success(uploadSecData) {
    return { type: documentConstants.UPLOAD_SECTION_SUCCESS, uploadSecData };
  }

  function failure(error) {
    return { type: documentConstants.UPLOAD_SECTION_FAILURE, error };
  }
};

documentActions.RegisterMetadata = (data) => {
  return (dispatch) => {
    dispatch(request());
    documentRequests.RegisterMetadata(data).then(
      (metaData) => dispatch(success(metaData)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: documentConstants.REGISTER_DATA_REQUEST };
  }

  function success(metaData) {
    return {
      type: documentConstants.REGISTER_DATA_SUCCESS,
      metaData,
    };
  }

  function failure(error) {
    return { type: documentConstants.REGISTER_DATA_FAILURE, error };
  }
};

documentActions.GetOcrData = (data) => {
  return (dispatch) => {
    dispatch(request());
    documentRequests.GetOcrData(data).then(
      (ocrData) => dispatch(success(ocrData)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: documentConstants.GET_OCRDATA_REQUEST };
  }

  function success(ocrData) {
    return {
      type: documentConstants.GET_OCRDATA_SUCCESS,
      ocrData,
    };
  }

  function failure(error) {
    return { type: documentConstants.GET_OCRDATA_FAILURE, error };
  }
};

documentActions.GetDocumentsGroupByRol = (data) => {
  console.log('GetDocumentsGroupByRol', data);
  return (dispatch) => {
    dispatch(request());
    documentRequests.GetDocumentsGroupByRol(data).then(
      (documents) => dispatch(success(documents)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: documentConstants.GET_DOCUMENTS_BY_ROL_REQUEST };
  }

  function success(documents) {
    return { type: documentConstants.GET_DOCUMENTS_BY_ROL_SUCCESS, documents };
  }

  function failure(error) {
    return { type: documentConstants.GET_DOCUMENTS_BY_ROL_FAILURE, error };
  }
};

// --------------------------------------------------------------

documentActions.GetDocumentGeneratorSelection = (data) => {
  return (dispatch) => {
    dispatch(request());
    documentRequests.GetDocumentGeneratorSelection(data).then(
      (cargaDocData) => dispatch(success(cargaDocData)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: documentConstants.GET_DOCUMENT_GENERATOR_SELECTION_REQUEST };
  }

  function success(cargaDocData) {
    return { type: documentConstants.GET_DOCUMENT_GENERATOR_SELECTION_SUCCESS, cargaDocData };
  }

  function failure(error) {
    return { type: documentConstants.GET_DOCUMENT_GENERATOR_SELECTION_FAILURE, error };
  }
};

documentActions.RegisterDocumentGeneratorSelection = (data) => {
  return (dispatch) => {
    dispatch(request());
    documentRequests.RegisterDocumentGeneratorSelection(data).then(
      (cargaDocData) => dispatch(success(cargaDocData)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: documentConstants.REGISTER_DOCUMENT_GENERATOR_REQUEST };
  }

  function success(cargaDocData) {
    return { type: documentConstants.REGISTER_DOCUMENT_GENERATOR_SUCCESS, cargaDocData };
  }

  function failure(error) {
    return { type: documentConstants.REGISTER_DOCUMENT_GENERATOR_FAILURE, error };
  }
};

documentActions.DownloadDocumentGeneratorSelectionContent = (data) => {
  return (dispatch) => {
    dispatch(request());
    documentRequests.DownloadDocumentGeneratorSelectionContent(data).then(
      (cargaDocData) => dispatch(success(cargaDocData)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: documentConstants.DONWLOAD_DOCUMENT_GENERATOR_REQUEST };
  }

  function success(cargaDocData) {
    return { type: documentConstants.DONWLOAD_DOCUMENT_GENERATOR_SUCCESS, cargaDocData };
  }

  function failure(error) {
    return { type: documentConstants.DONWLOAD_DOCUMENT_GENERATOR_FAILURE, error };
  }
};

documentActions.ProcessDocumentGeneratorSelection = (data) => {
  return (dispatch) => {
    dispatch(request());
    documentRequests.ProcessDocumentGeneratorSelection(data).then(
      (cargaDocData) => dispatch(success(cargaDocData)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: documentConstants.RPOCESS_DOCUMENT_GENERATOR_REQUEST };
  }

  function success(cargaDocData) {
    return { type: documentConstants.RPOCESS_DOCUMENT_GENERATOR_SUCCESS, cargaDocData };
  }

  function failure(error) {
    return { type: documentConstants.RPOCESS_DOCUMENT_GENERATOR_FAILURE, error };
  }
};

documentActions.GetFacialComparison = (data) => {
  return async (dispatch) => {
    dispatch(request());

    try {
      const facialResult = await documentRequests.GetFacialComparison(data);
      dispatch(success(facialResult));
    } catch (err) {
      dispatch(failure(err.message));
      throw err;
    }
  };

  function request() {
    return { type: documentConstants.FACIAL_COMPARISON_REQUEST };
  }

  function success(facialResult) {
    return { type: documentConstants.FACIAL_COMPARISON_SUCCESS, facialResult };
  }

  function failure(error) {
    return { type: documentConstants.FACIAL_COMPARISON_FAILURE, error };
  }
};

documentActions.ValidateIdentityData = (data) => {
  return (dispatch) => {
    dispatch(request());
    documentRequests.ValidateIdentityData(data).then(
      (valIndetityData) => dispatch(success(valIndetityData)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: documentConstants.VALIDATEDENTITY_DATA_REQUEST };
  }

  function success(valIndetityData) {
    return { type: documentConstants.VALIDATEDENTITY_DATA_SUCCESS, valIndetityData };
  }

  function failure(error) {
    return { type: documentConstants.VALIDATEDENTITY_DATA_FAILURE, error };
  }
};

documentActions.GetValidateIdentityData = (data) => {
  return (dispatch) => {
    dispatch(request());
    documentRequests.GetValidateIdentityData(data).then(
      (getValIndetityData) => dispatch(success(getValIndetityData)),
      (error) => dispatch(failure(error.message)),
    );
  };

  function request() {
    return { type: documentConstants.GETVALIDATEDENTITY_DATA_REQUEST };
  }

  function success(getValIndetityData) {
    return { type: documentConstants.GETVALIDATEDENTITY_DATA_SUCCESS, getValIndetityData };
  }

  function failure(error) {
    return { type: documentConstants.GETVALIDATEDENTITY_DATA_FAILURE, error };
  }
};
documentActions.RegisterDataTwo = (data) => {
  return async (dispatch) => {
    try {
      dispatch(request());

      const registeredData = await documentRequests.RegisterDataTwo(data);
      dispatch(success(registeredData));
      return registeredData;
    } catch (err) {
      dispatch(failure(err.message));
    }
  };

  function request() {
    return { type: documentConstants.REGISTERTWO_DATA_REQUEST };
  }

  function success(registeredData) {
    return { type: documentConstants.REGISTERTWO_DATA_SUCCESS, registeredData };
  }

  function failure(error) {
    return { type: documentConstants.REGISTERTWO_DATA_FAILURE, error };
  }
};

documentActions.AutographSign = (data, isReset = false) => {
  return async (dispatch) => {
    if (isReset) {
      dispatch(reset());
    } else {
      dispatch(request());
      try {
        const response = await documentRequests.AutographSignDocument(data);
        dispatch(success(response));
      } catch (error) {
        dispatch(failure(error.message));
        throw error;
      }
    }
  };

  function request() {
    return { type: documentConstants.AUTOGRAPH_SIGN_REQUEST };
  }

  function success(response) {
    return { type: documentConstants.AUTOGRAPH_SIGN_SUCCESS, response };
  }

  function failure(error) {
    return { type: documentConstants.AUTOGRAPH_SIGN_FAILURE, error };
  }

  function reset() {
    return { type: documentConstants.AUTOGRAPH_SIGN_RESET };
  }
};
