import { notificationConstants } from '../constants/index.js'

export const NewPin = (state = {}, action) => {
    switch (action.type) {
        case notificationConstants.NEW_PIN_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
            //break;
        case notificationConstants.NEW_PIN_SUCCESS:
            return {
                loading: false,
                items: action.newPin.Body,
                error: undefined
            }
            //break;
        case notificationConstants.NEW_PIN_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
            //break;
        default:
            return state
    }
}


export const GetNotificationsUser = (state = {}, action) => {
    switch (action.type) {
        case notificationConstants.GET_NOTIFICATIONS_USER_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case notificationConstants.GET_NOTIFICATIONS_USER_SUCCESS:
            return {
                loading: false,
                items: action.notifications.Body.NotificacionesPortal,
                error: undefined
            }
        case notificationConstants.GET_NOTIFICATIONS_USER_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        default:
            return state
    }
}
export const ReSendNip = (state = {}, action) => {
    switch (action.type) {
      case notificationConstants.NIP_REQUEST:
        return { loading: true, items: undefined, error: undefined }
      case notificationConstants.NIP_SUCCESS:
        return {
          loading: false,
          items: action.responseNipData.Body,
        }
      case notificationConstants.NIP_FAILURE:
        return {
          loading: false,
          items: undefined,
          error: action.error
        }
      default:
        return state
    }
  }

  
  export const SendNip = (state = {}, action) => {
    switch (action.type) {
      case notificationConstants.SENDNIP_REQUEST:
        return { loading: true, items: undefined, error: undefined }
      case notificationConstants.SENDNIP_SUCCESS:
        return {
          loading: false,
          items: action.sendNipData.Body,
        }
      case notificationConstants.SENDNIP_FAILURE:
        return {
          loading: false,
          items: undefined,
          error: action.error
        }
      default:
        return state
    }
  }