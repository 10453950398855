import { alpha } from "@mui/material";

const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#28A745",
  darker: "#08660D",
  contrastText: "#212B36",
};

/* const ERROR = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff",
}; */

const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff",
};

const INFO = {
  main: "#FFC222",
};

export const palette = {
  primary: {
    dark: "#0061A0",
    main: "#42a5f5",
    main100: alpha("#42a5f5"),
    main80: alpha("#42a5f5", 0.8),
    main60: alpha("#42a5f5", 0.6),
    main40: alpha("#42a5f5", 0.4),
    main20: alpha("#42a5f5", 0.2),
    main10: alpha("#42a5f5", 0.1),
    mainHoverBackground:"rgba(66, 164, 245, 0.06)",
    lightContrast: "#FCFCFC",
    darkContrast: "#000000",
    contrastText: "#F2F2F2",
    TableHeader: "#42a5f5",
    TableHeader2: "#42a5f5",
    text: "#FFFFFF",
    selectMenu: "#A4CE4E",
    borderASummary: "#EEEEEE",
    mainLyrics: '#FFFFFF',
    tableMain: "#42a5f5",
    tableHeadLight: '#F6F6F6',
    tableHeadLightText:'#5E5C5C',
    tableSecondary: "#FFB8B8",
    tableText: "#FFFFFF",
    tableHover: '#F2F2F2',
  },
  secondary: {
    dark: "#000000",
    main: "#A4CE4E",
    main100: alpha("#A4CE4E"),
    main80: alpha("#A4CE4E", 0.8),
    main60: alpha("#A4CE4E", 0.6),
    main40: alpha("#42a5f5", 0.4),
    main20: alpha("#A4CE4E", 0.2),
    main10: alpha("#A4CE4E", 0.1),
    lightContrast: "#F7F7F3",
    darkContrast: "#000000",
    contrastText: "#FFFFFF",
    light: '#F4F4F4',
  },
  nabvar: {
    main: "#42a5f5",
  },
  check: {
    main: '#42a5f5',
    text: '#1E1E1E',
  },
  tab: {
    text: '#42a5f5',
  },
  footerBar: {
    main: '#424242',
    text: '#ffffff',
  },
  menuColor: {
    main: '#A4CE4E',// Color de backgroung
    text: '#42a5f5',
  },
  userStyle: {
    icNotificaciones: '#292B30',
    icUser: '#42a5f5',
    textDetails: '#292B30',
    textInstruccions: '#292B30',
  },
  iconPrincButton: {
    main: '#42a5f5',
    bgIcon: '#FFFFFF',
    text: '#FFFFFF',
  },
  iconNamePrincipal: {
    main: '#42a5f5',
    text: '#ffffff'
  },
  linkButton: {
    main: '#42a5f5', // color de fondo button
  },
  actorsButton: {
    main: '#42a5f5',
    bgIcon: '#FFFFFF'
  },
  status: {
    info: '#000000',// Color de textos detalle
  },
  selectTab: {
    info: '#42a5f5', // Tabs selected color
    icon: '#484D58',
    textColor: '#FFFFFF',
    textColor1: '#9C9C9C',
    indicator: '#42a5f5',
    border: '#42a5f5',
    infoDisabled: '#F6F6F6',
    textColorDisabled: '#000000',
  },
  accordion: {
    main: '#243746 ',
    line: '#FFF0E8'
  },
  borderGray: {
    main: '#484D58',
    line: '#FFF0E8'
  },
  borderGreen: {
    main: '#A3D043',
    line: '#FFF0E8'
  },
  borderRed: {
    main: '#C20E30',
    line: '#FFF0E8'
  },
  borderBlue: {
    main: '#42a5f5',
    line: '#FFF0E8'
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  success: { ...SUCCESS },
  error: { ...ERROR },
  info: { ...INFO },
};
