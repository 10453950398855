import { Dialog, styled } from '@mui/material';

export const ModalContainer = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    '& .MuiDialog-paper': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      gap: '2rem',
      alignItems: 'center',
      width: '100%',
      maxWidth: '700px',
      padding: '2rem',
    },
    '& .MuiDialogContent-root': {
      flex: 'none',
    },
  },
}));
