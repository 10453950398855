import { Typography, Grid } from '@mui/material';
import { DocImageMappings } from './DocImageMappings';
import { Card } from './requestDocumentationViewer.styled';
import { UseImage } from 'components/UseImage';

export const DocCard = ({ docInfo, onCardClick }) => {
  const imageDoc = DocImageMappings[docInfo.tipoDocumentoDesc] || DocImageMappings['default'];

  return (
    <Card sx={{ width: '13rem', borderRadius:'1rem', border:'1px solid #D2D2D2', boxShadow:3 }} onClick={onCardClick}>
      <Grid container sx={{ maxWidth: '90%' }} spacing={'2rem'}>
        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
          <UseImage src={imageDoc} type="img" />
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              textAlign: 'center',
            }}
          >
            {docInfo.Name}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};
