import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  styled,
  IconButton,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { IsDevice } from "../../utils/index.js";

import { paths, routes } from "../../utils/index.js";
import { useTranslation } from "react-i18next";

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  backgroundColor: `${theme.palette.primary.tableMain} !important`,
  color: `${theme.palette.primary.tableText} !important`,
}));

const TableRowCustom = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: `${theme.palette.primary.tableHover} !important`,
  },
}));

export function MainTablePagination({
  data,
  PageSize,
  CurrentPage,
  TotalRecords,
  SetCurrentPage,
  SetCurrentSort,
  CurrentSort,
  SetOrder,
  Order
}) {
  const { t } = useTranslation();
  const history = useNavigate();
  const { IsMobile } = IsDevice();
  let path = window.location.pathname;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PageSize);
  const [order, setOrder] = React.useState(Order ?? "asc");
  const [orderBy, setOrderBy] = React.useState(CurrentSort ?? "folio");

  const newData = data?.map((col) => {
    return {
      Folio: col.Folio,
      ClaveGrupo: col.ClaveGrupo,
      Puesto: col.Metadata?.find((item) => item.Name === "Puesto")?.Value,
      Solicitante: col.FullName,
      Correo: col.Email,
      CreationDate: col.CreationDateFormatted,
      Estatus: t(col?.StepperStatus?.Label),
      IdFolio: col.Id,
      TipoProducto: col.WorkFlowName,
      WorkFlowInstanceId: col.WorkFlow?.WorkflowInstance?.Id,
    };
  });

  const columns = [
    { id: "folio", label: t("table_geid_folio"), minWidth: 170 },
    { id: "solicitante", label: t("table_name"), minWidth: 170 },
    { id: "creationDate", label: t("table_date"), minWidth: 170 },
    { id: "status", label: t("table_status"), minWidth: 170 },
  ];

  const createData = (
    folio,
    idGrupo,
    puesto,
    solicitante,
    tipoProducto,
    creationDate,
    status,
    idOfFolio,
    WorkFlowInstanceId,
  ) => {
    return {
      folio,
      idGrupo,
      puesto,
      solicitante,
      tipoProducto,
      creationDate,
      status,
      idOfFolio,
      WorkFlowInstanceId,
    };
  };

  const rows = newData?.map((r) => {
    return createData(
      r.Folio,
      r.ClaveGrupo,
      r.Puesto,
      r.Solicitante,
      r.TipoProducto,
      r.CreationDate,
      r.Estatus,
      r.IdFolio,
      r.WorkFlowInstanceId,
    );
  });

/*   useEffect(() => {
    console.log('Cambio rows', order)
    if(CurrentSort){
      handleRequestSort(null, CurrentSort)
    }
  }, [data]) */
  
  const handleChangePage = (newPage) => {
    SetCurrentPage(newPage);
    setPage(newPage - 1);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

    const visibleRows = React.useMemo(() => {
    return [...rows]
      .sort(getComparator(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [order, orderBy, page, rowsPerPage]);

/*   useEffect(() => {
    
    const asd = [...rows]
      .sort(getComparator(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
console.log('VALUES:',rowsPerPage, page)
      setValuesRow(asd)
  }, [data, order, orderBy, page, rowsPerPage]); */

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy !== property || order === "asc";
    const ord = isAsc ? "desc" : "asc"
    setOrder(ord);
    SetOrder(ord);
    setOrderBy(property);
  };

  return (
    <div>
      <TableContainer sx={{ width: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          {!IsMobile && (
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCellHead
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                    onClick={(e) => {
                      handleRequestSort(e, column.id);
                      SetCurrentSort(column.id)
                    }}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={(e) => {handleRequestSort(e, column.id); SetCurrentSort(column.id) }}
                      sx={{ color: "#fff !important" }}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCellHead>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {/* valuesRow */visibleRows.map((row) => {
              return (
                <TableRowCustom
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                  onClick={() => {
                    sessionStorage.removeItem("WorkFlowInstanceId");
                    if (path === paths.search) {
                      history(`${routes.busqueda}/${row.idOfFolio}`);
                      sessionStorage.setItem(
                        "WorkFlowInstanceId",
                        row.WorkFlowInstanceId,
                      );
                    } else if (
                      path === paths.homeComponentSingle ||
                      path === paths.homeComponent
                    ) {
                      history(`${routes.asignacion}/${row.idOfFolio}`);
                      sessionStorage.setItem(
                        "WorkFlowInstanceId",
                        row.WorkFlowInstanceId,
                      );
                      console.log("rowidOfFolio: 0", row);
                    }
                  }}
                >
                  {IsMobile ? (
                    <TableCell sx={{ borderBottom: "none", p: 1, py: 2 }}>
                      <CustomAssigmentCardMobile data={row} columns={columns} />
                    </TableCell>
                  ) : (
                    columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })
                  )}
                </TableRowCustom>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationCustom
        CurrentPage={CurrentPage}
        TotalRecords={TotalRecords}
        PageSize={PageSize}
        OnChangePage={(pg) => {
          handleChangePage(pg);
        }}
      />
    </div>
  );
}

const MainStack = styled(Stack)(({ theme }) => ({
  border: "1px solid lightgray",
  borderRadius: "10px",
  minHeight: "12em",
  padding: "18px",
  paddingLeft: "24px",
  paddingRight: "24px",
  userSelect: "none",
}));
const BoxRow = styled(Box)(({ theme }) => ({
  borderBottom: "1px solid lightgray",
  paddingTop: "4px",
  paddingBottom: "4px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));
const TypographyColumn = styled(Typography)(({ theme }) => ({
  maxWidth: "50%",
  textAlign: "left",
  fontWeight: 550,
}));
const TypographyData = styled(Typography)(({ theme }) => ({
  maxWidth: "50%",
  textAlign: "right",
}));

const CustomAssigmentCardMobile = ({ data, columns }) => {
  return (
    <MainStack>
      {columns?.map((col) => {
        return col.id == "folio" ? (
          <Box sx={{ borderBottom: "1px solid lightgray", pb: 1 }}>
            <Typography variant="h5">{data.folio}</Typography>
          </Box>
        ) : (
          <BoxRow>
            <TypographyColumn>{col.label ?? ""}</TypographyColumn>
            <TypographyData variant="body1">{data[col.id]}</TypographyData>
          </BoxRow>
        );
      })}
    </MainStack>
  );
};

export const PaginationCustom = ({
  CurrentPage,
  TotalRecords,
  PageSize,
  OnChangePage,
}) => {
  const reachCurrentRecords =
    CurrentPage * PageSize > TotalRecords
      ? TotalRecords
      : CurrentPage * PageSize;
  const startCurrentRecords = CurrentPage * PageSize - PageSize + 1;
  return (
    <Stack
      sx={{
        py: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <Box sx={{ px: 1 }}>
        <Typography variant="body2">
          {startCurrentRecords} - {reachCurrentRecords} de {TotalRecords}
        </Typography>
      </Box>
      <Box>
        <IconButton
          disabled={startCurrentRecords == 1}
          onClick={() => {
            OnChangePage(CurrentPage - 1);
          }}
        >
          <ArrowBackIosNewIcon sx={{ fontSize: 15 }} />
        </IconButton>
        <IconButton
          disabled={reachCurrentRecords == TotalRecords}
          onClick={() => {
            OnChangePage(CurrentPage + 1);
          }}
        >
          <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
        </IconButton>
      </Box>
    </Stack>
  );
};
