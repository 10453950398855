import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./TableStyle";

import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  styled,
  useTheme,
} from "@mui/material";

import { IsDevice } from "../../utils";

import { constPathRoot, paths, routes } from "../../utils";
import { ThemeConfigMake } from "../../theme/ThemeConfig.jsx";
import { useTranslation } from "react-i18next";

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  backgroundColor: `${theme.palette.primary.tableMain} !important`,
  color: `${theme.palette.primary.tableText} !important`,
}));

const TableRowCustom = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: `${theme.palette.primary.tableHover} !important`,
  },
}));

export function MainTable({ data }) {
  const theme = useTheme();
  const history = useNavigate();
  const localStyle = useStyles(theme);
  const { IsMobile } = IsDevice();
  let path = window.location.pathname;

  // console.log("data: INFO", data)
  const { t } = useTranslation();

  const newData = data?.map((col) => {
    return {
      Folio: col.Folio,
      ClaveGrupo: col.ClaveGrupo,
      Puesto: col.Metadata?.find((item) => item.Name === "Puesto")?.Value,
      Solicitante: col.FullName,
      Correo: col.Email,
      CreationDate: col.CreationDateFormatted,
      Estatus: t(col?.StepperStatus?.Label),
      IdFolio: col.Id,
      TipoProducto: col.WorkFlowName,
      WorkFlowInstanceId: col.WorkFlow?.WorkflowInstance?.Id,
    };
  });

  const columns = [
    { id: "folio", label: t("table_geid_folio"), minWidth: 170 },
    // { id: 'idGrupo', label: 'Clave Grupo', minWidth: 170 },
    // { id: 'puesto', label: 'Puesto', minWidth: 170 },
    { id: "solicitante", label: t("table_name"), minWidth: 170 },
    // { id: 'tipoProducto', label: 'Tipo de producto', minWidth: 170 },
    { id: "creationDate", label: t("table_date"), minWidth: 170 },
    { id: "status", label: t("table_status"), minWidth: 170 },
  ];

  const createData = (
    folio,
    idGrupo,
    puesto,
    solicitante,
    tipoProducto,
    creationDate,
    status,
    idOfFolio,
    WorkFlowInstanceId
  ) => {
    return {
      folio,
      idGrupo,
      puesto,
      solicitante,
      tipoProducto,
      creationDate,
      status,
      idOfFolio,
      WorkFlowInstanceId,
    };
  };

  const rows = newData?.map((r) => {
    return createData(
      r.Folio,
      r.ClaveGrupo,
      r.Puesto,
      r.Solicitante,
      r.TipoProducto,
      r.CreationDate,
      r.Estatus,
      r.IdFolio,
      r.WorkFlowInstanceId
    );
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const [orderDirection, setOrderDirection] = useState("");
  const [valueToOrderBy, setValueToOrderBy] = useState("");

  const handlerSort = (event, property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };

  const createSortHandler = (property) => (event) => {
    handlerSort(event, property);
  };

  // console.log("rows", rows);

  return (
    <div>
      <TableContainer sx={{ width: '100%' }}>
        <Table stickyHeader aria-label="sticky table">
          {!IsMobile && (
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCellHead
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                    onClick={createSortHandler(column.label)}
                  >
                    {column.label}
                  </TableCellHead>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {rows
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRowCustom
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    onClick={() => {
                      sessionStorage.removeItem("WorkFlowInstanceId");
                      if (path === paths.search) {
                        history(`${routes.busqueda}/${row.idOfFolio}`);
                        sessionStorage.setItem(
                          "WorkFlowInstanceId",
                          row.WorkFlowInstanceId
                        );
                      } else if (
                        path === paths.homeComponentSingle ||
                        path === paths.homeComponent
                      ) {
                        history(`${routes.asignacion}/${row.idOfFolio}`);
                        sessionStorage.setItem(
                          "WorkFlowInstanceId",
                          row.WorkFlowInstanceId
                        );
                        console.log("rowidOfFolio: 0", row);
                      }
                    }}
                  >
                    {IsMobile ? (
                      <TableCell sx={{ borderBottom: "none", p: 1, py: 2 }}>
                        <CustomAssigmentCardMobile
                          data={row}
                          columns={columns}
                        />
                      </TableCell>
                    ) : (
                      columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })
                    )}
                  </TableRowCustom>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={localStyle.tablePaginacion}
        rowsPerPageOptions={[10]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
      />
    </div>
  );
}

const MainStack = styled(Stack)(({ theme }) => ({
  border: "1px solid lightgray",
  borderRadius: "10px",
  minHeight: "12em",
  padding: "18px",
  paddingLeft: "24px",
  paddingRight: "24px",
  userSelect: "none",
}));
const BoxRow = styled(Box)(({ theme }) => ({
  borderBottom: "1px solid lightgray",
  paddingTop: "4px",
  paddingBottom: "4px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));
const TypographyColumn = styled(Typography)(({ theme }) => ({
  maxWidth: "50%",
  textAlign: "left",
  fontWeight: 550,
}));
const TypographyData = styled(Typography)(({ theme }) => ({
  maxWidth: "50%",
  textAlign: "right",
}));

const CustomAssigmentCardMobile = ({ data, columns }) => {
  return (
    <MainStack>
      {columns?.map((col) => {
        return col.id == "folio" ? (
          <Box sx={{ borderBottom: "1px solid lightgray", pb: 1 }}>
            <Typography variant="h5">{data.folio}</Typography>
          </Box>
        ) : (
          <BoxRow>
            <TypographyColumn>{col.label ?? ""}</TypographyColumn>
            <TypographyData variant="body1">{data[col.id]}</TypographyData>
          </BoxRow>
        );
      })}
    </MainStack>
  );
};
