export const dynamicFormAdapter = (stageData, nativeId = false) => {
  return stageData.QuestionGroups.map((questionGroup) => ({
    ...questionGroup,
    groupTitle: questionGroup.TranslatedText || questionGroup.Description || questionGroup.Name,
    llave: questionGroup.Id,
    Questions: questionGroup.Questions.map((question) => ({
      ...question,
      GroupName: questionGroup.Name,
      Name: question.Name,
      Value: question.Metadata !== null ? question.Metadata?.Value : '',
      QuestionType: question.QuestionType,
      Options: question.Options,
      Required: question.Required,
      Id: nativeId ? question.Id : question.Name,
      DefaultValue: null,
      Visible:
        question.Visible === undefined || question.Visible === null ? true : question.Visible,
      Captured: null,
      Disabled: stageData?.Properties ? !!stageData.Properties.some(
        (propertie) => propertie.Name === 'Readonly' && propertie.Value === "1",
      ) : false,
    })),
  }));
};
