import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { CustomLoadingOverlay, UseImage } from "components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reportsActions } from "store/actions";
import imageReport from "../../assets/imageReport.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from '@mui/styles';

export default function InboxReportsFilter() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fileInfoResponse = useSelector((state) => state.GetReports);
  const userId = useSelector(
    (state) => state.Authentication.items.UserLoginData.Id
  );
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    dispatch(
      reportsActions.GetReports({
        TenantId: 0,
        UserId: userId,
      })
    );
  }, []);

  useEffect(() => {
    setShowLoader(fileInfoResponse.loading);
  }, [fileInfoResponse]);

  const redirectReportData = (reportData) => {
    dispatch(reportsActions.GetReportData("", true));
    navigate("/inbox/reportsData", {
      state: { DataReport: reportData },
    });
  };

  return (
    <Grid container>
      <Grid item lg={12}>
        <Box sx={{ mt: 4, ml: 2, mb: 2 }}>
          <Typography>
            {t("reports_instructions")}
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={12}>
        <Box>
          {fileInfoResponse.items?.Reports?.map((item, index) => (
            <Box sx={{ p: 2 }}>
              <Accordion
                defaultExpanded
                key={`${item.NameGrouop}_${index}`}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id={`id_${item.NameGrouop}`}
                  key={`id_${item.NameGrouop}`}
                  sx={{ m: 0, p: 0, borderBottom: `1px solid ${theme.palette.primary.main}` }}
                >
                  <Box sx={{ ml: 2 }}>
                    <Typography variant="subtitle2">
                      {t(item.NameGrouop)}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {item.Reports?.map((report) => (
                    <Box
                      onClick={() => redirectReportData(report)}
                      sx={{
                        mt: 0.8,
                        display: "flex",
                        alignItems: "center",
                        p: 2,
                        border: ".8px solid #C2C2C2",
                        borderRadius: "16px",
                        padding: "16px",
                        cursor: "pointer",
                        transition: "transform 0.2s, box-shadow 0.2s",
                        "&:hover": {
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                        },
                        "&:active": {
                          transform: "scale(0.98)",
                        },
                      }}
                    >
                      <Box>
                        <UseImage src={imageReport} type="img" />
                      </Box>
                      <Box sx={{ ml: 3 }}>
                        <Typography variant="subtitle2">
                          {t(report.Name)}
                        </Typography>
                        <Typography variant="body2">
                          {t(report.Description)}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Box>
          ))}
        </Box>
      </Grid>
      {showLoader && (
        <CustomLoadingOverlay
          active={showLoader}
          text={t("general_loading_message")}
        />
      )}
    </Grid>
  );
}
