import { Box, IconButton, MenuItem, Select, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { getAllDocuments } from 'api/massiveFlow/massiveFlowRequests';
import { ReactComponent as ExcelDownloadIcon } from 'assets/excelDownloadIcon.svg';
import UploadSlot from 'components/common/UploadSlot';
import {
  downloadTemplate,
  registerDownloadBatch,
} from 'api/massiveDownload/massiveDownloadRequests';
import { b64toUrl } from 'utils';
import { AlertModal } from 'components/common/Modals/Views/AlertModal';

export const DetailsFormatUpload = ({ onSendTemplateFile }) => {
  const [selectedDocumentGroup, setSelectedDocumentGroup] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(' ');
  const { t } = useTranslation();

  const { data: allDocuments } = useSWR('GetAllDocuments', () => getAllDocuments(), {
    refreshInterval: 0,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const handleGroupSelectionChange = (event) => {
    setSelectedDocumentGroup(event.target.value);
    setSelectedDocument(' ');
  };

  const handleDocumentSelectionChange = (event) => {
    setSelectedDocument(event.target.value);
  };

  const handleSendData = async ({ fileB64, extension }) => {
    try {
      const REGISTER_DOWNLOAD_BATCH_PAYLOAD = {
        DocumentalGroup_Id: selectedDocumentGroup.Id,
        DocumentType_Id: selectedDocument.Id,
        FileName: 'DescargaMasiva - Template',
        FileExtension: '.xlsx',
        TemplateBase64: fileB64,
      };
      const registerResponse = await registerDownloadBatch(REGISTER_DOWNLOAD_BATCH_PAYLOAD);
      onSendTemplateFile(registerResponse);
    } catch (error) {
      AlertModal('Error', error.message, 'error', t);
    }
  };

  const handleDownloadTemplate = async () => {
    const DOWNLOAD_TEMPLATE_PAYLOAD = {
      DocumentalGroup_Id: selectedDocumentGroup.Id,
      DocumentType_Id: selectedDocument.Id,
    };
    const templateBase64 = await downloadTemplate(DOWNLOAD_TEMPLATE_PAYLOAD);
    const templateUrl = await b64toUrl(
      `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${templateBase64}`,
    );
    const link = document.createElement('a');
    link.href = templateUrl;
    link.download = `TemplateFile.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Box display="flex" justifyContent={'space-between'}>
        <Typography>{t('massive_download_new_description_1')}</Typography>
        <IconButton onClick={handleDownloadTemplate} disabled={selectedDocument === ' '}>
          <ExcelDownloadIcon
            style={{ filter: selectedDocument === ' ' ? 'grayscale(1)' : 'none' }}
          />
        </IconButton>
      </Box>
      <Stack spacing={'2rem'}>
        <Typography variant={'subtitle2'}>
          {t('documentation_table_view_upload_doc_grouper')}
        </Typography>
        <Select fullWidth defaultValue={' '} onChange={handleGroupSelectionChange}>
          <MenuItem value={' '} disabled>
            {t('documentation_table_view_upload_select_doc_grouper')}
          </MenuItem>
          {allDocuments?.DocumentalGroups?.map((group) => (
            <MenuItem key={group.Name} value={group}>
              {group.Name}
            </MenuItem>
          ))}
        </Select>
        <Typography variant={'subtitle2'}>{t('documentation_table_view_upload_doc')}</Typography>
        <Select fullWidth value={selectedDocument} onChange={handleDocumentSelectionChange}>
          <MenuItem value={' '} disabled>
            {t('documentation_table_view_select_upload_doc')}
          </MenuItem>
          {selectedDocumentGroup?.DocumentTypes?.map((document) => (
            <MenuItem key={document.Name} value={document}>
              {document.Name}
            </MenuItem>
          ))}
        </Select>
        <Typography>{t('massive_download_new_description_2')}</Typography>
        <Box height={'25vh'}>
          <UploadSlot
            allowedExtensions={['xlsx']}
            isExcelDocument
            onSendData={(fileB64, extension) => handleSendData({ fileB64, extension })}
            isOnlyUploadSlot
          />
        </Box>
      </Stack>
    </>
  );
};
