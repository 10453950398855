import { Box, Typography, useTheme } from '@mui/material';
import { GenericStatusElement } from 'components/common/GenericStatusElement/GenericStatusElement';
import Geolocation from 'components/common/Geolocation';
import { CustomLoadingOverlay } from 'components/CustomLoadingOverlay';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { documentActions } from 'store/actions';

import { HelpDialog } from 'components/common/HelpDialog/HelpDialog';
import { CustomCameraSelfie } from 'components/Camera/CustomCameraSelfie';

export const DocumentValidation = ({ handleChangeCurrentStep }) => {
  const { id: folioId } = useParams();
  const theme = useTheme();

  const [loadView, setLoadView] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  const [sectionsCurrent, setSectionsCurrent] = useState([]);
  const [sectionId, setSectionId] = useState(0);
  const [docNameCurrent, setDocNameCurrent] = useState(''); //for the metadata
  const [sectionName, setSectionName] = useState(''); //for the metadata
  const [captureType, setCaptureType] = useState('');
  const [latitude, setLatitude] = useState('1111111111');
  const [longitude, setLongitude] = useState('222222222');
  const [activeGeolocation, setActiveGeolocation] = useState(false);
  const [typeOcrCaptureCurrent, setTypeOcrCaptureCurrent] = useState('Online');
  const [docType_Id, setDocType_Id] = useState(null);
  const [context, setContexData] = useState({ sequences: 0, selectedCapture: 'photo' });
  const [uploadingFile, setUploadingFile] = useState(false);
  const [activeCaptureDoc, setActiveCaptureDoc] = useState(false);
  const [mode, setMode] = useState('LIST');

  const file = useSelector((state) => state.FileInfo);

  const cargaDocWithSecResponse = useSelector((state) => state.CargaDocWithSecData);
  const dispatch = useDispatch();
  const allowedDocumentsForIdentityValidation = useMemo(() => {
    if (!file?.items) return [];

    return (
      file?.items?.Stage?.DocumentalCategory?.DocumentalGroups?.flatMap((itemPrin) =>
        itemPrin.DocumentTypes?.map((documentType) => ({
          ...documentType,
          IdDocCurrent: documentType?.Document ? documentType.Document?.Id : null,
        })),
      ) || []
    );
  }, [file]);

  // useEffect(() => {
  //   dispatch(
  //     fileActions.GetFileInfo({
  //       FileId: folioId,
  //       WorkFlowInstanceId: workFlowInstanceId,
  //     }),
  //   );
  // }, [dispatch, folioId]);

  useEffect(() => {
    if (sectionsCurrent && sectionsCurrent?.length <= 2 && uploadingFile && context.sequences > 0) {
      let requestOcr = {
        File_id: folioId,
        DocumentType_Id: docType_Id,
        TransactionId: localStorage.getItem('TransactionId'),
      };
      dispatch(documentActions.GetOcrData(requestOcr));
      handleChangeCurrentStep('FACE_VALIDATION_INSTRUCTIONS');
      // dispatch(
      //   fileActions.GetFileInfo({
      //     FileId: folioId,
      //     WorkFlowInstanceId: workFlowInstanceId,
      //   }),
      // );
    } else if (context.sequences > 0 && context.sequences < 2 && uploadingFile) {
      followCaptureProcess();
    } else if (context.sequences > 0 && context.sequences >= 2 && uploadingFile) {
      let requestOcr = {
        File_id: folioId,
        DocumentType_Id: docType_Id,
        TransactionId: localStorage.getItem('TransactionId'),
      };
      dispatch(documentActions.GetOcrData(requestOcr));
      handleChangeCurrentStep('FACE_VALIDATION_INSTRUCTIONS');

      // dispatch(
      //   fileActions.GetFileInfo({
      //     FileId: folioId,
      //     WorkFlowInstanceId: workFlowInstanceId,
      //   }),
      // );
    }
  }, [context.sequences, uploadingFile]);

  useEffect(() => {
    if (
      sectionName != '' &&
      docNameCurrent != '' &&
      sectionName?.length > 0 &&
      docNameCurrent?.length > 0 &&
      activeCaptureDoc
    ) {
      setShowHelp(false);
      handleContinueCapture();
    }
  }, [sectionName, docNameCurrent, captureType, activeCaptureDoc]);

  useEffect(() => {
    if (!uploadingFile) return;

    const handleSuccessfulUpload = () => {
      setLoadView(false);
      localStorage.setItem('TransactionId', cargaDocWithSecResponse?.items?.TransactionId);

      if (shouldStoreDocumentLivenessData()) {
        localStorage.setItem(
          'validateINEFront',
          JSON.stringify(cargaDocWithSecResponse.items.DocumentLivenessResponseData),
        );
      }

      setContexData({
        ...context,
        sequences: context.sequences + 1,
      });
    };

    const handleFailedUpload = () => {
      setLoadView(false);
      setUploadingFile(false);
      alert(cargaDocWithSecResponse.error);
      clearCancelAction();
    };

    const shouldStoreDocumentLivenessData = () => {
      return (
        context.sequences === 0 &&
        sectionsCurrent &&
        sectionsCurrent.length > 2 &&
        cargaDocWithSecResponse.items.DocumentLivenessResponseData
      );
    };

    if (cargaDocWithSecResponse.items?.TransactionId) {
      handleSuccessfulUpload();
    } else if (cargaDocWithSecResponse.error) {
      handleFailedUpload();
    }
  }, [cargaDocWithSecResponse]);

  let clearCancelAction = () => {
    setContexData({ sequences: 0, selectedCapture: 'photo' });
    setActiveCaptureDoc(false);
    setSectionId(null);
    setSectionName(null);
    setCaptureType(null);
  };

  const handleUploadIdentifications = (document) => {
    if (!document && context.selectedCapture === 'photo') return;
    setLoadView(true);
    let requestUpload = {
      UploadSectionRequestData: {
        B64Content: null,
        FileId: folioId,
        Actor_Id: null,
        SkipActor: 0,
        SectionId: sectionId,
        Extension: null,
        Skip: false,
        SkipReason: null,
        Metadata: null,
        ContinueWorkFlow: false,
        Geolocation: { Latitude: latitude, Longitude: longitude },
        DeferLoad: false,
        OmitirMergeVideo: null,
        OmitirRemovebg: null,
        AsignarEstatusCarga: null,
      },
      File_id: 3002,
      Carga: {
        Referencia: folioId.toString(),
        TransactionId:
          localStorage.getItem('TransactionId') == '' ||
          localStorage.getItem('TransactionId') == 'null'
            ? null
            : localStorage.getItem('TransactionId'),
        EsBolqueFin:
          sectionsCurrent != undefined && sectionsCurrent != null && sectionsCurrent?.length <= 2
            ? true
            : (context.sequences == 0 && context.sequences < 2) ||
              (context.sequences > 0 && context.sequences >= 2)
            ? false
            : true,
        TipoDocumento: docNameCurrent,
        Seccion: sectionName,
        Imagenes: [
          {
            B64: document,
            Extension: '.jpg',
          },
        ],
        Metadatos: null,
      },
    };
    setUploadingFile(true);
    dispatch(documentActions.CargaDocumentoWithSection(requestUpload));
  };

  const captureProcess = (Sections, DocName, Properties, docIdCurrent, documentType_Id) => {
    const currentIndex = context.sequences;

    localStorage.setItem('sections', JSON.stringify(Sections));
    setSectionsCurrent(Sections);
    setSectionId(Sections[currentIndex]?.Id);
    setSectionName(Sections[currentIndex]?.Name);
    setDocNameCurrent(DocName);
    setCaptureType(Sections[currentIndex]?.CaptureType);
    setDocType_Id(documentType_Id);

    const setIfDefined = (propertyName, setter) => {
      const property = Properties?.find((element) => element.Name === propertyName);
      setter(property?.Value ?? false);
    };

    const setIfValueDefined = (propertyName, setter) => {
      const property = Properties?.find((element) => element.Name === propertyName);
      if (property?.Value) {
        setter(property.Value);
      }
    };

    setIfValueDefined('TypeOcrCapture', setTypeOcrCaptureCurrent);
    setIfDefined('ActiveGeolocation', setActiveGeolocation);
  };

  const followCaptureProcess = () => {
    setSectionId(sectionsCurrent[context.sequences].Id);
    setSectionName(sectionsCurrent[context.sequences].Name);
    setCaptureType(sectionsCurrent[context.sequences].CaptureType);
  };

  const handleContinueCapture = (event) => {
    if (typeOcrCaptureCurrent === 'Online') {
      setMode('INSTRUCTIONS');
    }
  };

  if (!allowedDocumentsForIdentityValidation) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {loadView && <CustomLoadingOverlay active={loadView} text="Espere un momento por favor..." />}

      {activeGeolocation && (
        <Geolocation
          SuccessFN={(latitude, longitude) => {
            setLatitude(latitude);
            setLongitude(longitude);
          }}
          ErrorFN={(error) => console.log('error', error)}
        />
      )}

      {mode === 'LIST' ? (
        <>
          {allowedDocumentsForIdentityValidation?.map((i, index) => {
            return (
              <Fragment key={index}>
                <Typography variant="subtitle1">Selecciona el tipo de identificación.</Typography>
                <Box
                  sx={{
                    width: '100% !important',
                    padding: '16px',
                    gap: '16px',
                    minHeight: '56px',
                    background: theme.palette.primary.lightContrast,
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                    borderRadius: '0px',
                    marginTop: '16px',
                    '&:focus': {
                      outline: 'none !important',
                      background: theme.palette.primary.main80,
                      border: `1px solid ${theme.palette.primary.main80}`,
                    },
                    '&:hover': {
                      outline: 'none !important',
                      background: theme.palette.primary.main60,
                      border: `1px solid ${theme.palette.primary.main60}`,
                    },
                  }}
                  key={`btn_key_${i.Id}`}
                  onClick={() => {
                    setActiveCaptureDoc(true);
                    captureProcess(i.Sections, i.Name, i.Properties, i.IdDocCurrent, i.Id);
                  }}
                >
                  <GenericStatusElement
                    key={`key_${i.Id}`}
                    keyProps={i.Id}
                    text={i.Required ? `${i.Name}*` : i.Name}
                    description={i.Description}
                  />
                </Box>
              </Fragment>
            );
          })}
        </>
      ) : mode === 'INSTRUCTIONS' ? (
        <HelpDialog
          open={showHelp}
          closeAction={() => {
            setShowHelp(false);
          }}
          sectionName={sectionName}
          DocName={docNameCurrent}
          onCapture={() => {
            setMode('CAMERA');
          }}
        />
      ) : mode === 'CAMERA' ? (
        <CustomCameraSelfie 
          // isCredential
          isDisplay={true}
          onCapture={(data) => handleUploadIdentifications(data)}
          Redimensionar={true}
          // CaptureInit={captureInit}
        />
      ) : null}
      {/* {initCamera && (
          // <FullCameraCapture
          //   open={initCamera}
          //   onClose={() => {
          //     setInitCamera(false);
          //     clearCancelAction();
          //   }}
          //   onSendData={(data) => {
          //     handleUploadIdentifications(data);
          //   }}
          //   sectionName={sectionName}
          //   DocName={docNameCurrent}
          //   capture={captureType}
          //   showHelp
          // />

          <CustomCameraSelfie
            isDisplay={true}
            onCapture={(data) => {
              handleUploadIdentifications(data);
            }}
            Redimensionar={true}
            // CaptureInit={captureInit}
          />
        )} */}
    </Box>
  );
};
