import { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useSign = () => {
  const ctrlCheckRef = useRef();
  const [currentDocumentId, setCurrentDocumentId] = useState(0);
  const [checkState, setCheckState] = useState(false);
  const [mode, setMode] = useState('DOCUMENT_SIGN');
  const [listDocumentsAccepted, setListDocumentsAccepted] = useState([]);
  const file = useSelector((state) => state.FileInfo);

  const handleCheckState = useCallback((value) => setCheckState(value), []);
  const handleSetMode = useCallback((newMode) => setMode(newMode), []);

  const getStageProperties = useCallback(() => {
    const documentTypes = file.items.Stage.DocumentalCategory.DocumentalGroups.flatMap(
      (group) => group.DocumentTypes?.filter((item) => item.Document != null) || [],
    );

    const newData = documentTypes.map((data, index) => ({
      ...data,
      CurrentIndex: index,
      Captured: null,
    }));
    setListDocumentsAccepted(newData);
  }, [file]);

  const handleAcceptDocument = () => {
    setListDocumentsAccepted((prevList) =>
      prevList.map((item) =>
        item.Document.Id === currentDocumentId ? { ...item, Captured: true } : item,
      ),
    );
  };

  useEffect(() => {
    if (file.items?.StageName === 'Firma de documentos') {
      getStageProperties();
    }
  }, [file.items, getStageProperties]);

  useEffect(() => {
    if (listDocumentsAccepted.length > 0) {
      sessionStorage.setItem('properties', JSON.stringify(listDocumentsAccepted));

      const pendingDocuments = listDocumentsAccepted.filter((item) => item.Captured === null);
      const currentDocument = pendingDocuments[0]?.Document;

      if (currentDocument) {
        setCurrentDocumentId(currentDocument.Id);
        if (checkState && ctrlCheckRef.current) {
          ctrlCheckRef.current.click();
        }
      } else {
        handleSetMode('SIGN_HELP');
      }
    }
  }, [listDocumentsAccepted, checkState, setCurrentDocumentId, handleSetMode]);

  return {
    currentDocumentId,
    mode,
    checkState,
    handleSetMode,
    handleCheckState,
    handleAcceptDocument,
    ctrlCheckRef,
  };
};
