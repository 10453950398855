import { Accordion, AccordionDetails, AccordionSummary, styled } from '@mui/material';

export const OBAccordionContainer = styled(Accordion)(({ theme }) => ({
  width: '100%',
  boxShadow: '0',
}));

export const OBAccordionSummary = styled(AccordionSummary)(({ theme, borderbottomprops}) => ({
  padding: '0px 2rem',
  fontSize: '1.3rem',
  borderTop: '0px',
  borderBottom: borderbottomprops || `2px solid ${theme.palette.primary.main}`
}));

export const OBAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: '8px 0px',
}));
