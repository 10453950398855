import axios from 'axios';
import { AppUrls, } from './Globals';
import { Config } from './Config';
import { dataUrlToFile } from  'utils';

export const documentsRequestsAsync = {}

documentsRequestsAsync.DownloadDocumentAsync = async (data) => {
  return await axios.post(
    AppUrls.DownloadDocument,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

documentsRequestsAsync.GetOcrData = async (data) => {
  return axios.post(
    AppUrls.GetOcrData,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

let handleResponse = async (response) => {
  let respObject = response.data;//BodySecurityResponse(response.data);
  if (!respObject.IsOK) {
    let responseObject = { message: respObject.Messages + ' ' + (respObject.TansactionId ? respObject.TansactionId : '') + ' ' + (respObject.statusText ? respObject.statusText : '') };
    let error = (respObject.Body || responseObject);
    return Promise.reject(error);
  }
  return respObject;
}

documentsRequestsAsync.GetFaceDetection = async (data) => {
  console.log("GetFaceDetection******: ", data)
  let contentData = await dataUrlToFile(data.B64Data.split(',')[1], data.fileName, data.B64Data.split(',')[0])
  console.log("GetFaceDetection****contentData-------**: ", contentData)
  let formData = new FormData();
  formData.append('File_id', data.fileId);
  formData.append('ImageContent', contentData);
  // formData.append('ImageContent', data.B64Data, data.fileName);
  formData.append('Authorization', Config.getTokenCurrentUser());
  return axios({
    method: 'post',
    url: AppUrls.GetFaceDetection + "?Authorization=" + Config.getTokenCurrentUser(),
    data: formData,
    headers: new Headers({
      'Authorization': `Basic ${Config.getTokenCurrentUser()}`,
      'Accept': "application/json",
      "Content-Type": "undefined",
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
      "Access-Control-Allow-Credentials": "true",
    })
  }).then(handleResponseContent)
}

// NUEVA API PARA TOMAR EL IDRD
documentsRequestsAsync.GetFaceDetectionIDRND = async (data) => {
  // console.log("GetFaceDetectionIDRND", data)
  let contentData = await dataUrlToFile(data.B64Data.split(',')[1], data.fileName, data.B64Data.split(',')[0])
  console.log("GetFaceDetectionIDRND****contentData-------**: ", contentData)
  let formData = new FormData();
  formData.append('File_id', data.fileId);
  formData.append('ImageContent', contentData);
  // formData.append('ImageContent', data.B64Data, data.fileName);
  formData.append('Authorization', Config.getTokenCurrentUser());
  return axios({
    method: 'post',
    url: AppUrls.GetFaceDetectionIDRND + "?Authorization=" + Config.getTokenCurrentUser(),
    data: formData,
    headers: new Headers({
      'Authorization': `Basic ${Config.getTokenCurrentUser()}`,
      'Accept': "application/json",
      "Content-Type": "undefined",
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
      "Access-Control-Allow-Credentials": "true",
    })
  }).then(handleResponseContent)
}

documentsRequestsAsync.GetFaceFeeling = async (data) => {
  // console.log("GetFaceFeeling", data)
  let contentData = await dataUrlToFile(data.B64Data.split(',')[1], data.fileName, data.B64Data.split(',')[0])
  console.log("GetFaceFeeling****contentData-------**: ", contentData)
  let formData = new FormData();
  formData.append('File_id', data.fileId);
  formData.append('FeelingToValidate', data.uid);
  formData.append('FaceDetectedId', data.faceDetectedId);
  formData.append('ImageContent', contentData);
  // formData.append('ImageContent', data.B64Data, data.fileName);
  formData.append('Authorization', Config.getTokenCurrentUser());
  return axios({
    method: 'post',
    url: AppUrls.GetFaceFeeling + "?Authorization=" + Config.getTokenCurrentUser(),
    data: formData,
    headers: new Headers({
      'Authorization': `Basic ${Config.getTokenCurrentUser()}`,
      'Accept': "application/json",
      "Content-Type": "undefined",
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
      "Access-Control-Allow-Credentials": "true",
    })
  }).then(handleResponseContent)
}

// NUEVA API PARA TOMAR EL IDRD
documentsRequestsAsync.GetFaceDetectionIDRND = async (data) => {
  // console.log("GetFaceDetectionIDRND", data)
  let contentData = await dataUrlToFile(data.B64Data.split(',')[1], data.fileName, data.B64Data.split(',')[0])
  console.log("GetFaceDetectionIDRND****contentData-------**: ", contentData)
  let formData = new FormData();
  formData.append('File_id', data.fileId);
  formData.append('ImageContent', contentData);
  // formData.append('ImageContent', data.B64Data, data.fileName);
  formData.append('Authorization', Config.getTokenCurrentUser());
  return axios({
    method: 'post',
    url: AppUrls.GetFaceDetectionIDRND + "?Authorization=" + Config.getTokenCurrentUser(),
    data: formData,
    headers: new Headers({
      'Authorization': `Basic ${Config.getTokenCurrentUser()}`,
      'Accept': "application/json",
      "Content-Type": "undefined",
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
      "Access-Control-Allow-Credentials": "true",
    })
  }).then(handleResponseContent)
}

let handleResponseContent = async (response) => {
  console.log("response***********Asynck: ", response)
  let respObject1 = response.data?.split('\r\n')[4]
  // console.log("respObject1 --- ", respObject1)
  let respObject = JSON.parse(respObject1);
  console.log("response***********Asynck respObject: ", respObject)
  // let respObject = response.data;

  if (response.status != 200) {
    let responseObject = { message: respObject.Messages + ' ' + (respObject.TansactionId ? respObject.TansactionId : '') + ' ' + (respObject.statusText ? respObject.statusText : '') };
    let error = (respObject.Body || responseObject);
    console.log('error', error)
    return Promise.reject(error);
  }
  return respObject;
}

// function dataURLtoFile(dataurl, filename) {
//   const exp = '/:(.*?);/'
//   let arr = dataurl.split(','),
//     mime = arr[0].match(exp)[1],
//     bstr = atob(arr[1]),
//     n = bstr.length,
//     u8arr = new Uint8Array(n);
//   while (n--) {
//     u8arr[n] = bstr.charCodeAt(n);
//   }

//   return new File([u8arr], filename, { type: mime });
// }
// documentsRequestsAsync.CargaDocumentoWithSection = async (data) => {
//   return await axios.post(
//     AppUrls.CargaDocumentoWithSection,
//     Config.SetParameters(data, '', '', ''),
//     Config.ConfigAuth()
//   ).then(handleResponse)
// }
