import VisorPDF from 'components/Visor/VisorPDF';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { documentActions } from 'store/actions';
import { b64toUrl, GetMimeType } from 'utils';

const DocumentViewer = ({ documentId }) => {
  const [blobUrl, setBlobUrl] = useState();

  const [currentDocumentName, setCurrentDocumentName] = useState('Visor PDF');
  const pdfDoc = useSelector((state) => state.DownloadDocument);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (pdfDoc.items?.B64Content) {
        const blobUrl = await b64toUrl(`${GetMimeType('.pdf')},${pdfDoc.items.B64Content}`);
        setBlobUrl(blobUrl);
      }
      if (pdfDoc.items?.FileName) {
        const fileName = pdfDoc.items.FileName;
        const fileNameWithoutExtension = fileName.toLowerCase().endsWith('.pdf')
          ? fileName.slice(0, -4)
          : fileName;
        setCurrentDocumentName(fileNameWithoutExtension);
      }
    };
    fetchData();
  }, [pdfDoc.items]);

  useEffect(() => {
    if (documentId > 0) {
      dispatch(documentActions.DownloadDocument(documentId));
    }
  }, [dispatch, documentId]);

  return <VisorPDF pdfName={currentDocumentName} pdfUrl={blobUrl} />;
};

export default DocumentViewer;
