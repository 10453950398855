import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fileActions, validationActions } from "../../store/actions";
import protect from "../../utils/protect";
import { Typography, useTheme } from "@mui/material";
// import Empty from "assets/DOC/img/NoResults.png";
import Empty from "assets/Metlife/img/NoResults.png";
import {
  UseImage,
  CustomLoadingOverlay,
  GenericHeaderActions,
} from "../../components";

import { useStyles } from "./HomeStyles";
import { mainStyles } from "../../theme/mainStyles";
import { useTranslation } from "react-i18next";
import { MainTablePagination } from "components/table/MainTablePagination";
import { IsDevice } from "utils";

function HomeComponent(props) {
  const theme = useTheme();
  const localStyles = useStyles(theme);
  const noInfo = mainStyles.noDataSections();
  const [searching, setSearching] = useState("");
  const [search, setSearch] = useState("");

  const [pageData, setPageData] = useState([]);
  const [pageSize, setPageSize] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const [currentSort, setCurrentSort] = useState(null)
  const [order, setOrder] = React.useState("asc");

  const { t } = useTranslation();
  const { IsMobile } = IsDevice();

  const info = useSelector((state) => state.SearchByUser);
  const dispatch = useDispatch();

  const getTableInfo = () => {
    dispatch(
      fileActions.GetUserPaginationSearch({
        SearchText: search,
        Page: currentPage,
      }),
    );
  };

  useEffect(() => {
    // dispatch(fileActions.GetSearchByUser({ SearchText: search }));
    getTableInfo();
    setFirstLoad(false);
    dispatch(validationActions.PostFileValidation({}));
  }, []);

  useEffect(() => {
    // dispatch(fileActions.GetSearchByUser({ SearchText: search }));
    if (!firstLoad) {
      getTableInfo();
    }
  }, [search, currentPage]);

  useEffect(() => {
    
    if (info.items !== undefined) {
      // setInfoItems(info.items);
      console.log("info.items",info.items)
      setPageData(info.items.PageData);
      setPageSize(info.items.PageSize);
      setTotalRecords(info.items.TotalRecords);
    }
  }, [info?.items]);

  /*   const handleInputChange = (event) => {
    setSearching(event.target.value);
  }; */

  return (
    <div style={{ padding: IsMobile ? "0.5rem" : "2rem" }}>
      <form className={localStyles.form}>
        <GenericHeaderActions
          PersistButtons={true}
          onChangeTextField={(event) => {
            event.target.value === "" && setSearch("");
            setSearching(event.target.value);
          }}
          placeholderTextField={t("search_assignments")}
          onClickConfirm={(event) => {
            event.preventDefault();
            setSearch(searching);
          }}
          ConfirmButtonText={t("general_search")}
          CancelButtonText={t("search_clean")}
          onClickCancel={() => {
            setSearch("");
            setSearching("");
          }}
        />
      </form>
      {info.error && <p>{"Error de red favor de actualizar la pagina"}</p>}
      {info.loading ? (
        <CustomLoadingOverlay
          active={info.loading}
          text={t("general_loading_message")}
        />
      ) :  /* !!pageData && pageData.length > 0 */!!info.items?.PageData && info.items?.PageData.length > 0 ? (
        <MainTablePagination
          data={info.items?.PageData}
          PageSize={/* pageSize */info.items?.PageSize}
          CurrentPage={currentPage}
          TotalRecords={/* totalRecords */info.items?.TotalRecords}
          SetCurrentPage={(p) => {
            setCurrentPage(p);
          }}
          SetCurrentSort={setCurrentSort}
          CurrentSort={currentSort}
          SetOrder={setOrder}
          Order={order}
        />
      ) : (
        <div className={noInfo.noResult}>
          <UseImage src={Empty} type="img" style={{ maxWidth: 300 }} />
          <Typography variant="h6" className={noInfo.h6}>
            {t("general_not_request")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default protect(HomeComponent);
