import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useForm, Controller } from "react-hook-form";
import { styled, useTheme } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import EsIcon from "../../assets/icons/lang/es.png";
import EnIcon from "../../assets/icons/lang/en.png";
import PtIcon from "../../assets/icons/lang/pt.png";
import JaIcon from "../../assets/icons/lang/ja.png";
import {
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  TextField as InputText,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const UploadButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.primary.main}`,
  "&:hover": {
    backgroundColor: theme.palette.background.paper,
  },
}));

const TextField = styled(InputText)(({ theme }) => ({
  "& .MuiOutlinedInput-input": {
    height: 25,
    padding: "10px 15px",
    [theme.breakpoints.down("sm")]: {
      height: 18,
      padding: "8px 10px",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    fontSize: 16,
    width: "100%",
    "& fieldset legend span": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
      borderRadius: 8,
    },
  },
  "& .MuiInputLabel-root": {
    color: "#000",
    position: "relative",
    transform: "none",
    fontSize: "16px",
    marginBottom: 5,
    marginTop: 5,
    display: "flex",
    alignItems: "center",
    pointerEvents: "auto",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    // fontFamily: '"Montserrat",sans-serif',
    [theme.breakpoints.down("sm")]: {
      fontWeight: 400,
      fontSize: "9px",
      lineHeight: "144%",
    },
  },
  "& label.Mui-focused": {
    color: theme.palette.primary.main,
  },
  "& label.Mui-error": {
    color: "#DC1717 !important",
    // #DC1717
  },
  "& .MuiSelect-select": {
    height: 25,
    "& .notranslate": {
      display: "block",
      margin: "2.45px 0",
    },
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC1717 !important",
  },
  "& .MuiInputBase-multiline": {
    padding: "0",
  },
  input: {
    "&::placeholder": {
      [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
        fontSize: "10px",
      },
    },
  },
  "& .MuiFormLabel-asterisk": {
    color: "#DC1717 !important",
  },
}));

function Preferences({ formControl, onSubmit, RolList }) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = formControl;

  const theme = useTheme();
  const currentLang = getValues("Language");
  const { t } = useTranslation();

  const languageList = [
    { code: "es", label: "Español", icon: EsIcon },
    { code: "en", label: "Ingles", icon: EnIcon },
    { code: "pt", label: "Portugues", icon: PtIcon },
    { code: "ja", label: "Japones", icon: JaIcon },
  ];

  const viewTypeList = [
    { name: "table", label: "Tabla" },
    { name: "list", label: "Lista" },
  ];

  const [iconLangSelected, setIconLangSelected] = useState(
    languageList.find((lang) => lang.code == currentLang).icon,
  );

  return (
    <Box>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ maxWidth: "400px", margin: "0 auto" }}
      >
        <Controller
          name="Language"
          control={control}
          rules={{ required: "Idioma es requerido" }}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label={t("profile_settings_language")}
              variant="outlined"
              margin="dense"
              fullWidth
              error={!!errors.Language}
              helperText={errors.Language ? errors.Language.message : ""}
              select
              InputProps={{
                startAdornment: (
                  <img
                    src={iconLangSelected}
                    style={{ width: "20px", marginRight: "10px" }}
                  />
                ),
              }}
              // onChange={(e)=>{console.log('lang e:', e.target.value)}}
            >
              {languageList.map((lang, index) => (
                <MenuItem
                  key={`${lang?.code}${index}`}
                  value={lang?.code}
                  onClick={(e) => {
                    setIconLangSelected(lang.icon);
                  }}
                >
                  {lang.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="ViewType"
          control={control}
          rules={{ required: "Tipo de vista es requerido" }}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label={
                <Box
                  component="div"
                  sx={{
                    whiteSpace: "normal",
                    display: "flex",
                    alignItems: "center",
                    fontStyle: "normal",
                    fontWeight: 400,
                    flexWrap: "wrap",
                    fontSize: "16px",
                    lineHeight: "140%",
                    [theme.breakpoints.down("sm")]: {
                      fontWeight: 400,
                      fontSize: "10px",
                      lineHeight: "144%",
                    },
                  }}
                >
                  <Typography>{t("profile_settings_view")}</Typography>
                </Box>
              }
              variant="outlined"
              margin="dense"
              fullWidth
              error={!!errors.ViewType}
              helperText={errors.ViewType ? errors.ViewType.message : ""}
              select
            >
              {viewTypeList.map((view, index) => (
                <MenuItem key={`${view?.name}${index}`} value={view?.name}>
                  {view.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Stack sx={{ my: 4 }}>
          <Typography sx={{ fontWeight: 600 }}>{t("profile_settings_roles")}</Typography>
          {RolList.map((rol, index) =>
            rol.Active ? (
              <Typography variant="body1">• {rol.Description}</Typography>
            ) : null,
          )}
        </Stack>
        {/* <Controller
          control={control}
          name={"Roles"}
          rules={{ required: "Seleccione al menos una opción" }}
          render={({ field }) => (
            <Box sx={{ width: "100%", my: 1 }}> {console.log('Radio value: ',field)}
              <Typography sx={{ fontWeight: 600 }}>Roles activos</Typography>
              <RadioGroup {...field}>
                {RolList?.map((rv) => (
                  <FormControlLabel
                    key={rv.Id}
                    value={rv.Name}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          ml: 1,
                          fontStyle: "normal",
                          fontWeight: 400,
                          // lineHeight: "16px",
                        }}
                      >
                        {rv.Description}
                      </Box>
                    }
                  />
                ))}
              </RadioGroup>
            </Box>
          )}
        /> */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
        >
          {t("general_save")}
        </Button>
      </Box>
    </Box>
  );
}

export default Preferences;
