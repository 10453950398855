const GET_DOWNLOAD_BATCH_DATA = `flujomasivo/GetDownloadBatchData`;

const DOWNLOAD_TEMPLATE = `flujomasivo/DownloadTemplate`

const REGISTER_DOWNLOAD_BATCH = `flujomasivo/RegisterDownloadBatch`

const GET_DOWNLOAD_BATCH_FILE_DATA = `flujomasivo/GetDownloadBatchFileData`

const DOWNLOAD_BATCH_FILE = `flujomasivo/DownloadBatchFile`

export {
  GET_DOWNLOAD_BATCH_DATA,
  DOWNLOAD_TEMPLATE,
  REGISTER_DOWNLOAD_BATCH,
  GET_DOWNLOAD_BATCH_FILE_DATA,
  DOWNLOAD_BATCH_FILE,
}