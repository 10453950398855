import { useTheme } from '@emotion/react';
import { Button, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { AlertModal } from 'components/common/Modals/Views/AlertModal';
import { CustomLoadingOverlay } from 'components/CustomLoadingOverlay';
import { useStyles } from 'components/NoShares';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { documentActions, fileActions } from 'store/actions';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SignaturePad from 'react-signature-canvas';
import { getActualDateTime, obtenerDireccionIP } from 'utils';
import { createLoadingSelector } from 'store/selectors/LoadingSelectors';
import Geolocation from 'components/common/Geolocation';
import { useParams } from 'react-router-dom';

const loadingStates = ['AUTOGRAPH_SIGN', 'DOCUMENTS_EVIDENCE'];

export const SignPad = () => {
  const workFlowInstanceId = sessionStorage.getItem('WorkFlowInstanceId');
  const {id: fileId} = useParams()
  const matches = useMediaQuery('(min-width:650px)');
  const [hidden, setHidden] = useState(false);
  const [finFirma, setFinFirma] = useState();
  const [inicioFirma, setInicioFirma] = useState();
  const [latitude, setLatitude] = useState('11111111111');
  const [longitude, setLongitude] = useState('22222222222');
  const [ipDirection, setIpDirection] = useState(null);
  const containerSignDetectRef = useRef(null);
  const [isWriteSign, setIsWriteSign] = useState(false);
  const [estaFirmando, setEstaFirmando] = useState(false);
  const [isLoadingGeo, setIsLoadingGeo] = useState(true);

  const theme = useTheme();
  const localStyles = useStyles();
  const canvasRef = useRef(null);
  const file = useSelector((state) => state.FileInfo);
  const isLoading = useSelector(createLoadingSelector(loadingStates));
  const dispatch = useDispatch();

  useEffect(() => {
    obtenerDireccionIP((ip) => {
      setIpDirection(ip);
    });
  }, []);

  const clearCanvas = () => {
    canvasRef.current.clear();
    setFinFirma(null);
    setInicioFirma(null);
    setHidden(false);
  };

  const setPlaceholder = () => {
    setIsWriteSign(true);
    if (!inicioFirma) {
      setInicioFirma(new Date().toISOString());
    }
    setHidden(true);
  };

  const finaliza = () => {
    setIsWriteSign(false);
    setFinFirma(new Date().toISOString());
  };

  const handleGetCanvas = () => {
    if (canvasRef.current.isEmpty()) {
      AlertModal('Error', 'Para continuar debes proporcionar tu firma', 'error');
    } else {
      AlertModal(
        'Confirmación de la firma',
        'Acabas de firmar y autorizar los documentos. ¿Deseas continuar?',
        'warning',
      ).then(() => handleModalButton());
    }
  };

  const handleModalButton = async () => {
    let cnv = canvasRef.current.getSignaturePad().toDataURL('image/png');
    let points = canvasRef.current.toData();
    let signSplitted = cnv.split(',');
    let sign = signSplitted[1];
    const allDocumentTypes = file.items?.Stage?.DocumentalCategory.DocumentalGroups.flatMap(
      (group) => group.DocumentTypes.filter((item) => item.Document != null),
    );
    let obj = {};
    let VideoEvidence = {};
    let AudioEvidence = {};
    let TrackingData = {};
    VideoEvidence.B64Content = '';
    VideoEvidence.Extension = '';
    VideoEvidence.DeferLoad = false;
    VideoEvidence.SkipActor = false;
    AudioEvidence.B64Content = '';
    AudioEvidence.Extension = '';
    AudioEvidence.DeferLoad = false;
    AudioEvidence.SkipActor = false;
    TrackingData.IMEI = '';
    TrackingData.Reference = '';
    const promises = allDocumentTypes.map((it) => {
      let actualDate = getActualDateTime();
      let dateSplitted = actualDate.split('|');
      obj.ObjectId = it.Document.Id;
      obj.Signature = sign;
      obj.TimeStamp = `${dateSplitted[0]} ${dateSplitted[1]}`;
      obj.Latitude = latitude;
      obj.Longitude = longitude;
      obj.signetAtFSimple = new Date().toISOString();
      obj.MacAddress = ipDirection;
      obj.Width = 490;
      obj.Height = 550;
      obj.StartedAt = inicioFirma;
      obj.EndedAt = finFirma;
      obj.PuntosControl = points;
      obj.TrackingData = TrackingData;
      obj.ContinueWorkFlow = false;
      return dispatch(documentActions.AutographSign(obj));
    });

    Promise.all(promises)
      .then(() => {
        dispatch(fileActions.GetFileInfo({
          FileId: fileId,
          WorkFlowInstanceId: workFlowInstanceId,
        }));
      })
      .catch(() => {
        AlertModal('Error', 'No se pudo completar la firma, intente Nuevamente', 'error');
      })
  };

  const handleMouseEnter = () => {
    setEstaFirmando(true);
  };

  const handleTouchMove = (event) => {
    try {
      if (!estaFirmando) return;

      const rect = containerSignDetectRef.current.getBoundingClientRect();
      const touch = event.touches[0];

      if (
        touch.clientX < rect.left ||
        touch.clientX > rect.right ||
        touch.clientY < rect.top ||
        touch.clientY > rect.bottom
      ) {
        handleMouseLeave();
      }
    } catch (e) {}
  };

  const handleMouseLeave = () => {
    if (estaFirmando && isWriteSign) {
      AlertModal(
        'Advertencia',
        'Detectamos que tu firma se plasmó fuera de los límites, por favor, dibuja tu grafo sin salirte de los bordes.',
        'warning',
      );
      clearCanvas();
    }
    setEstaFirmando(false);
  };

  return (
    <>
      {' '}
      <Geolocation
        SuccessFN={(latitude, longitude) => {
          setLatitude(latitude);
          setLongitude(longitude);
          setIsLoadingGeo(false);
        }}
        ErrorFN={(error) => {
          setIsLoadingGeo(false);
        }}
      />
      <Typography variant="subtitle1">
        Esta firma autógrafa en mensaje de datos sirve para autorizar los documentos leídos
        anteriormente.
      </Typography>
      <Box
        sx={{
          textAlign: 'center',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
            height: '280px',
            justifyContent: 'center',
            border: `1px solid ${theme.palette.primary.main}`,
            position: 'relative',
            borderRadius: '8px',
          }}
          ref={containerSignDetectRef}
          onTouchStart={handleMouseEnter}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onTouchEnd={handleMouseLeave}
          onTouchMove={handleTouchMove}
        >
          <SignaturePad
            ref={canvasRef}
            backgroundColor="rgba(255,255,255, 0.0)"
            penColor="black"
            canvasProps={{ style: { width: '100%', height: '100%' } }} // Asegura que el canvas ocupe el 100% del contenedor
            onBegin={setPlaceholder}
            onEnd={finaliza}
          />
          {hidden ? null : (
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                top: matches ? '45%' : '35%',
              }}
            >
              <Typography variant="body1" gutterBottom className={localStyles.signPlaceholder}>
                Firma aquí
              </Typography>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignContent: 'flex-end',
            alignItems: 'flex-start',
            overflow: 'hidden',
            marginTop: '16px',
            textAlign: 'right',
            justifyContent: 'flex-end',
          }}
        >
          <DeleteOutlineIcon
            className={hidden ? localStyles.clearIconActive : localStyles.clearIcon}
            onClick={clearCanvas}
          />
        </Box>
      </Box>
      <Button variant="contained" onClick={handleGetCanvas} sx={{ width: '80%' }}>
        Continuar
      </Button>
      <CustomLoadingOverlay
        active={isLoading || isLoadingGeo}
        text="Espere un momento por favor..."
      />
    </>
  );
};
