import { Box, Stack } from '@mui/material';
import { CustomLoadingOverlay, RoundedButton } from 'components';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { getDownloadBatchFileData } from 'api/massiveDownload/massiveDownloadRequests';
import { OBTableCell, OBTableRow } from 'components/common/Table/Table.styled';
import React from 'react';
import MainTable from 'components/common/Table';
import { Icon } from '@iconify/react';

export const BatchFileData = ({ handleMode, batchId }) => {
  const { t } = useTranslation();

  const { data: batchFileData, isValidating } = useSWR(
    'GetDownloadBatchFileData',
    () => getDownloadBatchFileData(batchId),
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const columns = [
    { id: 'Folio de descarga', label: t("batch_file_data_table_header_download_folio"), minWidth: 170 },
    { id: 'Folio del expediente', label: t("batch_file_data_table_header_record_folio"), minWidth: 170 },
    { id: 'GEID', label: t("batch_file_data_table_header_geid"), minWidth: 170 },
    { id: 'Tipo de documento', label: t("batch_file_data_table_header_doc_type"), minWidth: 170 },
    { id: 'Documento localizado', label: t("batch_file_data_table_header_located_doc"), minWidth: 170 },
  ];

  const handleContinue = () => {
    batchFileData?.DownloadBatchFile?.some((file) => file?.Correcto === 0)
      ? handleMode('DETAILS_FORMAT_UPLOAD')
      : handleMode('GENERATING_ZIP_ALERT');
  };

  const render = (row) => (
    <OBTableRow hover role="checkbox">
      <OBTableCell>{row['Folio de descarga']}</OBTableCell>
      <OBTableCell>{row['Folio del expediente']}</OBTableCell>
      <OBTableCell>{row['GEID']}</OBTableCell>
      <OBTableCell>{row['Tipo de documento']}</OBTableCell>
      <OBTableCell>
        {row['Correcto'] === 1 ? (
          <Icon icon={'bx:check-circle'} fontSize={'2rem'} color={'#6FB300'}></Icon>
        ) : (
          <Icon icon={'mdi:cross-circle-outline'} fontSize={'2rem'} color={'#E41E1E'}></Icon>
        )}
      </OBTableCell>
    </OBTableRow>
  );

  return (
    <Stack spacing={'2rem'}>
      <CustomLoadingOverlay active={isValidating}></CustomLoadingOverlay>
      <Box display={'flex'} gap={'3rem'}>
        <RoundedButton
          variant={'contained'}
          sx={{ minWidth: '15%', ml: 'auto' }}
          onClick={handleContinue}
        >
          {t("general_continue")}
        </RoundedButton>
      </Box>
      <MainTable
        rows={batchFileData?.DownloadBatchFile}
        columns={columns}
        render={render}
        rowsSize={'small'}
        rowPerPage={10}
      />
    </Stack>
  );
};
