import React from 'react';
import { Box, Typography, styled, useTheme } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import PersonAdd from '@mui/icons-material/PersonAdd';
import PhotoCameraOutlined from '@mui/icons-material/PhotoCameraOutlined';
import Group from '@mui/icons-material/Group';
import Description from '@mui/icons-material/Description';
import { Icon } from '@iconify/react';
// import { ReactComponent as IneIcon } from '../assets/icons/icINE.svg';

const ContainerStatus = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
}));

const ContainerItems = styled(Box)(({ theme }) => ({
  display: 'flex !important',
  flexDirection: 'column !important',
  alignItems: 'center !important',
  justifyContent: 'center !important',
}));

export const GenericStatusElement = (props) => {
  const theme = useTheme();

  return (
    <ContainerStatus>
      <ContainerItems
        sx={{
          borderRadius: '50%',
          height: '50px',
          width: '60px',
          backgroundColor: theme.palette.secondary.main100,
        }}
      >
        <Box
          sx={{
            display:'grid',
            placeContent:'center',
            height: '100%',
            width: '100%',
            borderRadius: '5rem',
            backgroundColor: theme.palette.primary.main,
          }}
        >
          {/*{RenderIcon[props.icon]}*/}
          <Icon icon={props.icon} color={'white'} fontSize={'1.5rem'}></Icon>
        </Box>
        {/* {RenderIcon(props)} */}
      </ContainerItems>
      <ContainerItems
        sx={{
          width: '100%',
        }}
      >
        <Typography variant="subtitle2" display="block">
          {props.text}
        </Typography>
        <Typography
          variant="subtitle1"
          display="block"
          sx={{ color: props.isRejected ? GetColorStatus(2) : GetColorStatus(1) }}
        >
          {props.isRejected
            ? 'Rechazado'
            : props.isRejected !== undefined
              ? 'Leído y autorizado'
              : ''}
        </Typography>
        {props.description && (
          <Typography variant="subtitle1" gutterBottom>
            {props.description}
          </Typography>
        )}
        {props.descuento && (
          <Typography variant="subtitle1" gutterBottom>
            {`Descuento: ${props.Descuento}`}
          </Typography>
        )}
        {props.status && (
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ color: GetColorStatus(props.statusColor) }}
          >
            {props.delete && <DeleteIcon sx={{ color: theme.palette.primary.main }} />}
            {props.status}
          </Typography>
        )}
      </ContainerItems>
      <ContainerItems
        sx={{
          borderRadius: '4px',
          height: '40px',
          width: '40px',
        }}
      >
        {!props.noArrow && <ArrowForwardIosIcon />}
      </ContainerItems>
    </ContainerStatus>
  );
};

const GetColorStatus = (statusColor) => {
  const theme = useTheme();
  console.log('statusColor', statusColor);
  if (statusColor === 1) {
    return theme.palette.success.main;
  } else if (statusColor === 2) {
    return theme.palette.error.main;
  } else if (statusColor === 3) {
    return theme.palette.info.main;
  } else if (statusColor === 4) {
    return theme.palette.primary.main;
  } else {
    return '#ffffff';
  }
};

const RenderIcon = {
  Camera: <PhotoCameraIcon />,
  'Document Upload': <AttachFileIcon />,
};

// const RenderIcon = (props) => {
//   const theme = useTheme();
//   if (props?.text?.includes('visita') || props?.text?.includes('Visita')) {
//     return <LocationOnIcon style={{ color: theme.palette.primary.color }} />;
//   } else if (props?.text === 'INE / IFE*') {
//     return null;
//   } else if (
//     props?.text === 'INE / IFE*' ||
//     props?.text === 'Pasaporte*' ||
//     props?.text === 'Comprobante de domicilio*'
//   ) {
//     return <PhotoCameraOutlined style={{ color: theme.palette.primary.color }} />;
//   } else if (props.capture === 'Document Upload, Camera') {
//     return <ReceiptIcon style={{ color: theme.palette.primary.color }} />;
//   } else if (props.capture === 'Camera') {
//     return <PhotoCameraIcon style={{ color: theme.palette.primary.color }} />;
//   } else if (props.capture === 'Document Upload') {
//     return <AttachFileIcon style={{ color: theme.palette.primary.color }} />;
//   } else if (props?.text?.includes('Personal') || props?.text === 'Presidente') {
//     return <AccountBoxIcon style={{ color: theme.palette.primary.color }} />;
//   } else if (props?.text === 'Cobranza') {
//     return <AttachMoneyIcon style={{ color: theme.palette.primary.color }} />;
//   } else if (props?.text === 'Biométricos') {
//     return <AccessibilityNewIcon style={{ color: theme.palette.primary.color }} />;
//   } else if (props?.text?.includes('Grupal')) {
//     return <Group style={{ color: theme.palette.primary.color }} />;
//   } else if (props?.text === 'Tesorero') {
//     return <MonetizationOnIcon style={{ color: theme.palette.primary.color }} />;
//   } else if (props?.text === 'Secretario') {
//     return <PermPhoneMsgIcon style={{ color: theme.palette.primary.color }} />;
//   } else if (props?.text === 'Socio' || props?.text === 'Soy cliente') {
//     return <PersonIcon style={{ color: theme.palette.primary.color }} />;
//   } else if (props?.text === 'Nuevo cliente') {
//     return <PersonAdd style={{ color: theme.palette.primary.color }} />;
//   } else if (props.newItem) {
//     return <AddIcon style={{ color: theme.palette.primary.color }} />;
//   } else {
//     let imgNameCuston = GetImageName(props);
//     if (imgNameCuston === '') {
//       return <Description style={{ color: theme.palette.primary.color }} />;
//     } else {
//       return <useImage type="Image" src={imgNameCuston} />;
//     }
//   }
// };

const GetImageName = (props) => {
  if (props?.text === 'Cobro realizado') {
    return 'ic_cobro_sin_exito.png';
  } else if (props.icon?.includes('IC_SUB_')) {
    return `${props.icon}.svg`;
  } else if (props.icon === 'ICONO_02') {
    return 'ICONO_02.svg';
  } else if (props.icon === 'ICONO_01') {
    return 'ICONO_01.svg';
  } else {
    return '';
  }
};
