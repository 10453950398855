import { AppBar, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { useStyles } from 'components/tabs/TabsStyle';
import { useState } from 'react';

const TabsComponent = ({ tabs, render }) => {
  const [value, setValue] = useState(0);

  const theme = useTheme();
  const localStyles = useStyles(theme);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{
            indicator: localStyles.indicator,
          }}
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {tabs.map((tab) => {
            return (
              <Tab
                label={<Typography sx={{ textTransform: 'none' }}>{tab.label}</Typography>}
                classes={{ root: localStyles.tabRoot, selected: localStyles.selectedTab }}
                sx={{height:'4rem'}}
              />
            );
          })}
        </Tabs>
      </AppBar>
      {render(value)}
    </>
  );
};

export default TabsComponent;
