import { fetcher } from "../fetcher";
import { Config } from "../../services/Config";

export const getConfigurations = async (PAYLOAD) =>
  await fetcher("configuration/GetConfigurations", PAYLOAD, Config.ConfigAuth());

export const getMetadata = async (PAYLOAD) =>
  await fetcher("configuration/GetMetadata", PAYLOAD, Config.ConfigAuth());

export const getMetadataGroup = async (PAYLOAD) =>
  await fetcher("configuration/GetMetadataGroup", PAYLOAD, Config.ConfigAuth());

export const insertMetadata = async (PAYLOAD) =>
  await fetcher("configuration/InsertMetadata", PAYLOAD);

export const updateMetadata = async (PAYLOAD) =>
  await fetcher("configuration/UpdateMetadata", PAYLOAD);

export const deleteMetadata = async (PAYLOAD) =>
  await fetcher("configuration/DeleteMetadata", PAYLOAD);
