import { Box, Button, Grid, Paper, Avatar, Tooltip, Typography, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Alert, UseImage, UploadDcumentsControl } from '../../components'
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import img from '../../assets/DownloadExcel.png';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react'

import { documentRequests } from '../../services';
import { fileMasiveActions } from '../../store/actions';
// import { TableDataUsers } from './TableDataUsers';
import { flujoMasivoActions } from '../../store/actions';
import { MainTableDinamyc } from "../../components/table/MainTableDinamyc";
import { useTranslation } from 'react-i18next';

export default function GEIDDownload(props) {
    const dispatch = useDispatch();
    const dataUsers = useSelector((state) => state.GetUsersInformationMetadadaMasive);
    // const workFlowId = useSelector((state) => state.Authentication?.items?.WorkFlowData?.WorkFlowId);
    const workFlowListState = useSelector((state) => state.Authentication?.items?.WorkFlowListData);

    const [currentWorkFlow, setCurrentWorkFlow] = useState(false);

    const [loadView, setloadView] = useState(false);
    const [dataUsersTable, setDataUsersTable] = useState();
    const [activeDownloadTemplate, setActiveDownloadTemplate] = useState(false);
    const formMethods = useForm({ mode: "onChange" });
    const navigation = useNavigate();
    const [documentsUpload, setDocumentsUpload] = useState();
    const [data, setData] = useState([]);

    const { t } = useTranslation();

    const batchFileGEIDResponse = useSelector((state) => state.UploadBatchFileGEIDData);
    const getListGEIDDataResponse = useSelector((state) => state.GetListGEIDData);

    useEffect(() => {
        console.log("........workFlowListState.............: ", workFlowListState)
        if (workFlowListState?.length > 1) {
            setCurrentWorkFlow(workFlowListState)
            dispatch(flujoMasivoActions.GetListGEID(workFlowListState[0]?.WorkFlowId))
        } else if (workFlowListState?.length > 0) {
            setCurrentWorkFlow(workFlowListState[0])
            dispatch(flujoMasivoActions.GetListGEID(workFlowListState[0]?.WorkFlowId))
        }
    }, [workFlowListState])

    // useEffect(() => {
    //     console.log("........workFlowId.............: ", workFlowId)
    //     if (workFlowId > 0) {
    //         dispatch(flujoMasivoActions.GetListGEID(workFlowId))
    //     }
    // }, [workFlowId])


    useEffect(() => {
        console.log("getListGEIDDataResponse.............: ", getListGEIDDataResponse)
        if (getListGEIDDataResponse.items) {
            setData(getListGEIDDataResponse.items)
        }
    }, [getListGEIDDataResponse])

    useEffect(() => {
        // setLoadView(batchFileGEIDResponse.loading)
        console.log("************batchFileGEIDResponse---------********: ", batchFileGEIDResponse)
        if (batchFileGEIDResponse.items != null && batchFileGEIDResponse.items.length > 0) {
            setData(batchFileGEIDResponse.items)
        } else if (batchFileGEIDResponse.error != null) {
            setData([])
        }
    }, [batchFileGEIDResponse])

    const DowloadTemplate = async () => {
        console.log("DowloadTemplate - currentWorkFlow: ", currentWorkFlow?.WorkFlowId)
        setActiveDownloadTemplate(true)
        const responseDoc = await documentRequests.DownloadTemplateGEIDContent(currentWorkFlow?.WorkFlowId);
        console.log('responseDoc', responseDoc)
        const url = window.URL.createObjectURL(new Blob([responseDoc]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Template Datos Usuarios.xlsx");
        document.body.appendChild(link);
        link.click()
        setActiveDownloadTemplate(false)
    }

    const newData = data?.map((col) => {
        return {
            Folio: col.Folio,
            NombreCargo: col.NombreCargo,
            Compania: col.Compania,
            TipoContratacion: col.TipoContratacion,
            TipoEmpleado: col.TipoEmpleado,
            FechaIngreso: col.FechaIngreso,
        };
    });

    const columns = [
        { id: "Folio", label: t("table_geid_folio"), minWidth: 170 },
        { id: "NombreCargo", label: t("table_geid_job_name"), minWidth: 170 },
        { id: "Compania", label: t("table_geid_company"), minWidth: 170 },
        { id: "TipoContratacion", label: t("table_geid_type_contract"), minWidth: 170 },
        { id: "TipoEmpleado", label: t("table_geid_type_employee"), minWidth: 170 },
        { id: "FechaIngreso", label: t("table_geid_admision_date"), minWidth: 170 },
    ];

    const createData = (
        Folio,
        NombreCargo,
        Compania,
        TipoContratacion,
        TipoEmpleado,
        FechaIngreso
    ) => {
        return {
            Folio,
            NombreCargo,
            Compania,
            TipoContratacion,
            TipoEmpleado,
            FechaIngreso
        };
    };

    const rows = newData?.map((r) => {
        return createData(
            r.Folio,
            r.NombreCargo,
            r.Compania,
            r.TipoContratacion,
            r.TipoEmpleado,
            r.FechaIngreso
        );
    });

    return (
        <Grid container alignContent='center' alignItems='center' justifyContent='center'>
            {currentWorkFlow?.length > 0 ?
                currentWorkFlow.map((item) =>
                    <Grid lg={11}>
                        <Paper elevation={4} sx={{ m: 2 }} onClick={() => {
                            console.log("Workflow seleccionado: ", item)
                            setCurrentWorkFlow(item)
                            dispatch(flujoMasivoActions.GetListGEID(item.WorkFlowId))
                        }} >
                            <Box sx={{ p: 4, display: 'flex' }}>
                                <Box sx={{ p: 2, display: "flex", alignContent: 'center', alignItems: 'center' }}>
                                    <Avatar
                                        style={{
                                            color: "primary.mainLyrics",
                                            backgroundColor: "primary.main",
                                            fontWeight: 600,
                                            width: 50,
                                            height: 50,
                                            fontSize: 3,
                                            mt: 0.7,
                                        }}
                                        alt={'user'}
                                    />
                                </Box>
                                <Box sx={{ mt: 1 }}>
                                    <Box>
                                        <Typography variant='h4'>
                                            {item.WorkFlowName}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant='subtitle'>
                                            {item.WorkFlowDescription}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                ) :
                <>
                    <Grid item lg={10} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={workFlowListState?.length > 1 ? 3 : 4}>
                            </Grid>
                            <Grid item xs={workFlowListState?.length > 1 ? 3 : 4}>
                                <Tooltip title={rows?.length <= 0 ? t("geid_not_information_for_download") : t("geid_download_template")} placement="top">
                                    <Button disabled={rows?.length <= 0 ? activeDownloadTemplate ? true : true : false} sx={{
                                        width: '100%',
                                        height: 45,
                                        px: 10,
                                        // margin: '1em',
                                        borderRadius: '25px',
                                        fontSize: 16,
                                        fontWeight: 500,
                                        maxWidth: 300,
                                    }} variant='contained' color="primary" onClick={() => DowloadTemplate()}>
                                        {activeDownloadTemplate ? <span><CircularProgress size={24} color="inherit" /> {t("geid_downloading")}...</span> : t("geid_download_report")}
                                    </Button>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={workFlowListState?.length > 1 ? 3 : 4}>
                                <Tooltip title={t("update_geid_metadata")} placement="top">
                                    <Button sx={{
                                        width: '100%',
                                        height: 45,
                                        px: 5,
                                        // margin: '1em',
                                        borderRadius: '25px',
                                        fontSize: 16,
                                        fontWeight: 500,
                                        maxWidth: 300,
                                    }} variant='contained' color="primary" onClick={() => {
                                        navigation('/inbox/UploadGEID', { state: { WorkFlowId: currentWorkFlow?.WorkFlowId } })
                                        // navigation('/inbox/UploadGEID')
                                    }}>
                                        {t("geid_upodate_geid")}
                                    </Button>
                                </Tooltip>
                            </Grid>
                            {workFlowListState?.length > 1 ?
                                <Grid item xs={3}>
                                    <Button
                                        variant="outlined"
                                        // startIcon={<Icon fontSize={25} icon="ph:users-three" />}
                                        sx={{ height: 45, px: 10, maxWidth: 300 }}
                                        onClick={() => setCurrentWorkFlow(workFlowListState)} >
                                        {t("login_return")}
                                    </Button>
                                </Grid>
                                : null}
                        </Grid>
                    </Grid>

                    <Box sx={{ width: "100%", marginTop: '1em' }}>
                        <MainTableDinamyc
                            Rows={rows}
                            Columns={columns}
                        // OnClickData={OnClickData}
                        />
                    </Box>
                </>
            }
        </Grid>
    )
}
