import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import { StatusDot, StyledBox } from "./ElasticSerch.styled";
import ExpIcon from "assets/icons/folder.svg";
import DocIcon from "assets/icons/filedoc.svg";
import ContDocIcon from "assets/icons/filecontent.svg";

const TYPE_DOCUMENT = {
  EXP: ExpIcon,
  DOC: DocIcon,
  CONTENT_DOC: ContDocIcon,
};

const STATUS_COLOR = {
  AC: "#43A122",
  EX: "#FE2727",
  EXSN: "#FCC915",
};

export const ElasticResultList = ({
  searchResults = [],
  onResultClick,
  searched = "",
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const render = searchResults?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box sx={{px: { xs: 0, md: "1rem" }}}>
      <Box padding={{ height: "60vh", overflow: "auto" }}>
        {render.map((result, index) => (
          <ListContentResult
            key={index}
            result={result}
            onResultClick={onResultClick}
            searched={searched}
          />
        ))}
      </Box>
      <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        count={searchResults?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        labelRowsPerPage="Filas por página"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`
        }
      />
    </Box>
  );
};

const ListContentResult = ({ result, onResultClick, searched }) => {
  const [showMore, setShowMore] = useState(null);
  const nameDocument =
    result.ItemResult !== "EXP" && `Documento: ${result.DocumentType}`;

  const handleOpen = (e, data) => {
    e.stopPropagation();
    setShowMore(data);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setShowMore(null);
  };

  return (
    <StyledBox
      sx={{ justifyContent: "space-around" }}
      onClick={() => onResultClick(result)}
    >
      <img
        src={TYPE_DOCUMENT[result.ItemResult]}
        alt={`${result.ItemResult} icon`}
        width={60}
        height={60}
      />
      <Box sx={{ width: "100%" }}>
        <Box display="flex" alignItems="center" gap=".5rem">
          <Typography variant="subtitle2">{`${result.Folio} - ${result.FullName}`}</Typography>
          <StatusDot color={STATUS_COLOR[result?.StepperStatus?.SubStatus]} />
        </Box>
        <Box>
          <Typography variant="subtitle2" style={{ fontSize: 14 }}>
            {nameDocument}
          </Typography>
          {result?.MetadatosResult.map((metadata, index) => {
            const { Name, Result } = metadata;
            const text = Result.length > 1 ? Result.join("... ") : Result[0];
            return (
              <Box key={index}>
                <Box display="flex" gap={1}>
                  <Typography
                    sx={{
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                    }}
                  >
                    {`${Name}: `}
                    <Highlight text={text} highlight={searched} />
                  </Typography>

                  {/* {metadata?.Result.slice(0, 1).map((result, index) => (
                          <Box key={index} display="block">
                            <Typography>
                              <Highlight
                                text={`${result}${
                                  metadata?.Result.length > 1 ? "..." : ""
                                }`}
                                highlight={searched}
                              />
                            </Typography>
                          </Box>
                        ))} */}
                </Box>
              </Box>
            );
          })}
          {(result?.MetadatosResult.length > 3 ||
            result?.MetadatosResult.some(
              (metadata) =>
                metadata.Name === "Contenido" && metadata.Result.length > 3
            )) && (
            <Button
              variant="text"
              onClick={(e) => handleOpen(e, result?.MetadatosResult)}
            >
              Ver más resultados
            </Button>
          )}
        </Box>
        <Dialog
          open={showMore && true}
          fullWidth
          maxWidth="sm"
          onClose={handleClose}
        >
          <DialogContent>
            <DialogTitle sx={{ padding: 0 }}>
              {`${result.Folio} - ${result.FullName}`}
            </DialogTitle>
            <DialogContentText>
              {nameDocument || "Expediente"}
            </DialogContentText>
            {result?.MetadatosResult.map((metadata, index) => {
              return (
                <Box key={index} mt={1}>
                  <Box>
                    <Typography>
                      <b>{metadata.Name}</b>
                    </Typography>

                    {metadata?.Result.map((result, index) => (
                      <Box key={index} display="block">
                        <Typography>
                          <Highlight
                            text={` • ${result}`}
                            highlight={searched}
                          />
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </StyledBox>
  );
};

const Highlight = ({ text, highlight }) => {
  if (!highlight) return <span>{text}</span>;

  const parts = text.split(new RegExp(`(${highlight})`, "gi"));

  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <mark key={index}>{part}</mark>
        ) : (
          <span key={index}>{part}</span>
        )
      )}
    </span>
  );
};
