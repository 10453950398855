const GET_BATCH_DATA_PATH = `flujomasivo/GetBatchData`;

const GET_ALL_DOCUMENTS_PATH = `flujomasivo/GetAllDocuments`;

const GENERATE_TEMPLATE_PATH = `flujomasivo/GenerateTemplate`;

const GET_BATCH_FILE_DATA_PATH = `flujomasivo/GetBatchFileData`;

const REGISTER_BATCH_PATH = `flujomasivo/RegisterBatch`;

const UPLOAD_BATCH_PATH = `flujomasivo/UploadBatch`;


export {
  GET_BATCH_DATA_PATH,
  GET_ALL_DOCUMENTS_PATH,
  GENERATE_TEMPLATE_PATH,
  GET_BATCH_FILE_DATA_PATH,
  REGISTER_BATCH_PATH,
  UPLOAD_BATCH_PATH
};
