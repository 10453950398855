
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";
import PersonalData from "./PersonalData";
import Preferences from "./Preferences";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
// import { userActions } from "store/actions";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { getProfileData } from "api/procedures/proceduresRequests";
import { useEffect } from "react";
import { CustomLoadingOverlay } from "components";
import { AlertModal } from "components/common/Modals/Views/AlertModal";
import { useState } from "react";
import { updateProfileData } from "api/user/userRequest";
import { useTranslation } from "react-i18next";
import { sendLanguageMetadata } from "api/language/languageRequests";
import {
  setAvatarAction,
  setDefaultLanguageAction,
  setNameAction,
} from "store/actions/GenericActions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`user-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ flex: 1 }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, pt: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `user-tabpanel-${index}`,
  };
}

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  alignItems: "flex-start",
  textTransform: "none",
  minHeight: "40px!important",
  padding: "12px 24px",
  "&.Mui-selected": {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
  "&.MuiButtonBase-root": {
    borderRadius: "0px!important",
  },
}));

export default function UserConfig() {
  // const profileData = useSelector(state => state.GetProfileData);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const langCode = useSelector((state) => state.SetDefualtLanguage?.lang);
  const [value, setValue] = useState(0);
  const [initialValues, setInitialValues] = useState({});
  const [rolList, setRolList] = useState([]);
  const [currentAvatar, setCurrentAvatar] = useState(null);

  const {
    data: ProfileData,
    mutate,
    isValidating,
  } = useSWR("ProfileData", getProfileData, {
    refreshInterval: 0,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const { trigger, isMutating } = useSWRMutation(
    "UpdateProfileData",
    (url, payload) => updateProfileData(payload),
    {
      populateCache: true,
      revalidate: false,
    },
  );

  const formControl = useForm({
    defaultValues: {
      Name: "",
      FatherLastName: "",
      MotherLastName: "",
      Email: "",
      Password: "",
      Phone: "",
      Language: "",
      Roles: "",
      ViewType: "",
      Picture: "",
    },
  });

  const onSubmit = async (data) => {
    const changedData = Object.keys(data).reduce((acc, key) => {
      if (data[key] !== initialValues[key]) {
        acc[key] = data[key];
      }
      return acc;
    }, {});

    /*  const changeRol = !changedData.Roles
      ? {}
      : {
          Roles: {
            ...rolList.find((rol) => rol.Name === changedData.Roles),
            Active: true,
          },
        }; */

    const requestUpload = { ...changedData /* , ...changeRol  */ };
    console.log("Data form::: ", requestUpload, data);
    try {
      await trigger(requestUpload);
      await mutate();
      AlertModal(
        t("profile_settings_alert_success_title"),
        t("profile_settings_alert_success_text"),
        "success",
      );
      if (!!changedData?.Language) await changeLanguage(changedData.Language);
      if (!!changedData?.Picture) {
        dispatch(setAvatarAction(changedData?.Picture || 0));
        sessionStorage.setItem("avatar", changedData?.Picture);
      }
      if (
        !!changedData?.Name ||
        !!changedData?.FatherLastName ||
        !!changedData?.MotherLastName
      ) {
        dispatch(
          setNameAction(
            `${data?.Name} ${data?.FatherLastName} ${data?.MotherLastName}`,
          ),
        );
        sessionStorage.setItem(
          "userName",
          `${data?.Name} ${data?.FatherLastName} ${data?.MotherLastName}`,
        );
      }

      /* if(!!changedData?.Name){
        
      } */
    } catch (err) {
      AlertModal("Error", err.message, "error");
    } finally {
      setValue(0);
    }
  };

  const changeLanguage = async (language) => {
    localStorage.setItem("lang", language || langCode);

    i18n.changeLanguage(language || langCode);
    await sendLanguageMetadata([
      {
        GroupName: "System Language",
        Name: "Code",
        Value: language || langCode,
      },
    ]);
    dispatch(setDefaultLanguageAction(language || langCode));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (ProfileData) {
      const { PersonalData, Preferences } = ProfileData;
      setRolList(Preferences.Roles);
      setCurrentAvatar(Preferences?.Picture);
      setInitialValues({
        Name: PersonalData.Name,
        FatherLastName: PersonalData.FatherLastName,
        MotherLastName: PersonalData.MotherLastName,
        Email: PersonalData.Email,
        Password: PersonalData.Password,
        Phone: PersonalData.Phone,
        Language: Preferences.Language,
        Roles: Preferences.Roles.find((rol) => rol.Active)?.Name,
        ViewType: Preferences.ViewType,
        Picture: Preferences.Picture,
      });
      formControl.reset({
        Name: PersonalData.Name,
        FatherLastName: PersonalData.FatherLastName,
        MotherLastName: PersonalData.MotherLastName,
        Email: PersonalData.Email,
        Password: PersonalData.Password,
        Phone: PersonalData.Phone,
        Language: Preferences.Language,
        Roles: Preferences.Roles.find((rol) => rol.Active)?.Name,
        ViewType: Preferences.ViewType,
        Picture: Preferences.Picture,
      });
    }
  }, [ProfileData]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        height: "100%",
      }}
    >
      <CustomLoadingOverlay active={isValidating || isMutating} />
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Tabs user config"
        sx={{ borderRight: 1, borderColor: "divider", pt: 5 }}
      >
        <StyledTab
          label={t("profile_settings_personal_data")}
          {...a11yProps(0)}
        />
        <StyledTab
          label={t("profile_settings_preferences")}
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <PersonalData
          formControl={formControl}
          onSubmit={onSubmit}
          CurrentAvatar={currentAvatar}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Preferences
          formControl={formControl}
          onSubmit={onSubmit}
          RolList={rolList}
        />
      </TabPanel>
    </Box>
  );
}
