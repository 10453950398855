import { useEffect, useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { OBAccordionContainer, OBAccordionDetails, OBAccordionSummary } from './Accordion.styled';

export const Accordion = ({
  title,
  children,
  handleChange = null,
  isExpanded = null,
  borderBottomProps,
  onClick,
}) => {
  const [expanded, setExpanded] = useState(isExpanded);

  const handleToggle = () => {
    const newIsExpanded = !expanded;
    setExpanded(newIsExpanded);
    if (handleChange) {
      handleChange(newIsExpanded);
    }
  };

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  return (
    <OBAccordionContainer sx={{ boxShadow: 0 }} expanded={expanded} onChange={handleToggle}>
      <OBAccordionSummary
        expandIcon={<ExpandMore />}
        borderbottomprops={borderBottomProps}
        onClick={onClick}
      >
        {' '}
        {title || ''}
      </OBAccordionSummary>
      <OBAccordionDetails>{children}</OBAccordionDetails>
    </OBAccordionContainer>
  );
};

export default Accordion;
