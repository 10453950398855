import { Box, Grid, Stack, Typography } from '@mui/material';
import { ReactComponent as FileIcon } from 'assets/icons/fileIcon.svg';
import { formatDateByLocale } from 'utils';
import { StyledBox } from './SearchResultsList.styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DotIcon } from 'assets/DOC/Icons/dot.svg';

export const SearchResultsList = ({ searchResults, onResultClick }) => {
  const { t } = useTranslation();
  const STATUS_COLORS = {
    AC: '#43A122',
    EX: '#FCC915',
  };
  console.log('searchResults:::',searchResults)
  return (
    <Box padding={'2rem'}>
      {searchResults.Data?.map((result, index) => (
        <StyledBox
          id={index}
          sx={{ justifyContent: 'space-around' }}
          onClick={() => onResultClick(result)}
        >
          <FileIcon />
          <Box sx={{ width: '100%' }}>{console.log('LIST: ', result)}
            <Typography variant="subtitle2">{result.Folio}</Typography>
            <Box display="flex" gap="3rem">
              <Grid container display="flex" gap="1rem" alignItems="center">
                <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="body2">{t('search_result_list_name')}</Typography>
                  <Typography variant="span">{result.Metadatos[0]["Nombre Completo"]}</Typography>
                </Grid>
                <DotIcon />
                <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="body2">{t("search_result_list_area")}</Typography>
                  <Typography variant="span">{result.Metadatos[0].Area ?? "NA"}</Typography>
                </Grid>
                <DotIcon />
                <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="body2">{t("search_result_list_geid")}</Typography>
                  <Typography variant="span">{result.Metadatos[0].GEID}</Typography>
                </Grid>
                <DotIcon />
                <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="body2">{t("search_result_list_date")}</Typography>
                  <Typography variant="span">{formatDateByLocale(result.Metadatos[0].Fecha)}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Typography
            variant="subtitle2"
            sx={{ color: STATUS_COLORS[result?.StepperStatus?.SubStatus] }}
          >
            {result?.StepperStatus?.SubStatus === 'AC'
              ? t('search_result_list_current')
              : t('search_result_list_expired')}
          </Typography>
        </StyledBox>
      ))}
    </Box>
  );
};
