import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  styled,
  useTheme,
  Typography,
  Box,
  Tooltip,
  tooltipClasses,
  autocompleteClasses
} from '@mui/material';
import { UseImage, GenericHeaderActions, CustomLoadingOverlay } from '../index';
import profilePicture from '../../assets/generalInfo/profilePicture.svg';
import actor2 from '../../assets/generalInfo/actor2.svg';
import actor3 from '../../assets/generalInfo/actor3.svg';
import actor4 from '../../assets/generalInfo/actor4.svg';
import actor5 from '../../assets/generalInfo/actor5.svg';

import { useStyles } from "./TabsStyle";
import { useDispatch, useSelector } from "react-redux";
import { fileActions } from "../../store/actions";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import { VideoTutorialModal } from "components/common/Modals/Views/VideoTutorialModal";

const TableCellCustom = styled(TableCell)(({ theme }) => ({
  color: `${theme.palette.secondary.dark} !important`,
  fontWeight: 'bold !important',
}));

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  },
  [`& .${autocompleteClasses.noOptions}`]: {
    backgroundColor: "red",
  },
}));

export function GeneralInfoNew(props) {
  const {
    data
  } = props;
  const dataStateInfo = useSelector(state => state.GetEstatusProceso)
  const theme = useTheme();
  const dispatch = useDispatch();
  const localStyles = useStyles(theme);
  const [rows, setRows] = useState([]);
  const [userSeleted, setUserSeleted] = useState(null);
  const [userSeletedPosition, setUserSeletedPosition] = useState(null);
  const [showTutorial, setShowTutorial] = useState(
    localStorage.getItem("showTutorial") || true,
  );
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fileActions.GetEstatusProceso({ File_Id: id }))
  }, [])

  const createData = (name, details) => {
    return { name, details };
  }

  const handleClose = () => {
    setShowTutorial(false);
    localStorage.setItem('showTutorial','false');
  }

  const getSomeMetadata = (array) => {
    const arrayData = array.filter((data) => data.Name === "Estatus"
      || data.Name === "Usuario asignado" || data.Name === "RFC")
    arrayData.forEach(element => {
      setRows(rows => [...rows, createData(`${element.Name}`, element.Value?.toUpperCase())])
    });
  }

  useEffect(() => {
    const dataRows = [];
    dataRows.push(data.Folio && { name: t("general_info_folio"), details: data.Folio.toUpperCase() });
    dataRows.push(data.FullName && { name: t("general_info_name"), details: data.FullName });
    dataRows.push(data.PhoneNumber && { name: t("general_info_phone"), details: data.PhoneNumber });
    dataRows.push(data.Email && { name: t("general_info_email"), details: data.Email });
    dataRows.push(data.CreationDateFormatted && { name: t("general_info_date"), details: data.CreationDateFormatted });
    setRows(dataRows);
    if (data.Metadata.length > 0) {
      getSomeMetadata(data.Metadata)
    }
  }, []);

  const imagesUsers = (index) => {
    switch (index) {
      case 0:
        return <UseImage src={profilePicture} className={localStyles.img} />
      case 1:
        return <UseImage src={actor5} className={localStyles.img} />
      case 2:
        return <UseImage src={actor2} className={localStyles.img} />
      case 3:
        return <UseImage src={actor3} className={localStyles.img} />
      case 4:
        return <UseImage src={actor4} className={localStyles.img} />
    }
  }

  const imagesUsersLarge = (userSeletedPosition) => {
    switch (userSeletedPosition) {
      case "Candidato":
        return <UseImage src={profilePicture} height="190px" />
      case "Oficial de cumplimiento":
        return <UseImage src={actor5} height="190px" />
      case "Representante Legal":
        return <UseImage src={actor2} height="190px" />
      case "Talent Acquisition":
        return <UseImage src={actor3} height="190px" />
      case "vendors.co":
        return <UseImage src={actor4} height="190px" />
    }
  }

  const reloadPage = () => {
    dispatch(fileActions.GetEstatusProceso({ File_Id: id }))
  }

  return (
    <Grid container spacing={1}>
      {dataStateInfo.loading == true ?
        <CustomLoadingOverlay
          active={dataStateInfo.loading} text={t("general_loading_message")}
        /> : null}
      <Grid item xs={4}>
        <GenericHeaderActions LabelTitle={t("request_general")} />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
          <UseImage src={profilePicture} className={localStyles.img} />
        </Box>
        <TableContainer className={localStyles.table}>
          <Table>
            <TableBody>
              {rows.map((row) => (
                <TableRow>
                  <TableCellCustom
                    align="left"
                    style={{
                      padding: 10,
                      border: "1px solid #D2D2D2",
                      justifyContent: "center"
                    }}
                    width="45%"
                  >
                    <Typography >
                      {row?.name}
                    </Typography>
                  </TableCellCustom>
                  <TableCell
                    align="left"
                    style={{
                      border: "1px solid #D2D2D2",
                      padding: 10
                    }}
                    width="50%"
                  >
                    <Typography>
                      {row?.details}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={8}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <GenericHeaderActions LabelTitle={t("processing_status")} onClickConfirm={() => reloadPage()}
            ConfirmButtonText={t("request_update")}
            CancelButtonText={t("login_return")} onClickCancel={() => { setUserSeleted(null); setUserSeletedPosition(null) }} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: "center" }}>
          <Box sx={{ display: 'flex', alignItems: "center", m: 1 }}>
            <Icon
              icon="material-symbols:circle"
              style={{
                mb: "-1px",
                mr: 1,
                fontSize: 18,
                color: "#0FA958",
              }}
            />
            <Typography sx={{ ml: 1 }}>
              {t("finalized")}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: "center", m: 1 }}>
            <Icon
              icon="material-symbols:circle"
              style={{
                mb: "-1px",
                mr: 1,
                fontSize: 18,
                color: "#FFD233",
              }}
            />
            <Typography sx={{ ml: 1 }}>
              {t("in_progress")}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: "center", m: 1 }}>
            <Icon
              icon="material-symbols:circle"
              style={{
                mb: "-1px",
                mr: 1,
                fontSize: 18,
                color: "#D3D3D3",
              }}
            />
            <Typography sx={{ ml: 1 }}>
              {t("pending")}
            </Typography>
          </Box>

        </Box>
        <Grid container>
          {userSeleted != null ? (
            <Grid container>
              <Grid item xs={5}>
                <Box sx={{ height: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Typography variant="h5">
                        {userSeleted.GroupName}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", mt: 3, justifyContent: "center", alignItems: "center" }}>
                      {imagesUsersLarge(userSeleted.GroupName)}
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} >
                <Box sx={{ height: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <TableContainer className={localStyles.table}>
                    <Table>
                      <TableBody>
                        {userSeleted?.EstatusProcesos.map((row) => (
                          <TableRow>
                            <TableCellCustom
                              align="left"
                              style={{
                                padding: 10,
                                border: "1px solid #D2D2D2",
                                justifyContent: "center",
                              }}
                              width="30%"
                            >
                              <Typography>
                                <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                  <Tip
                                    title={row.ProcessName}
                                    placement="top"
                                    arrow
                                    enterTouchDelay={0}
                                  >
                                    <Icon
                                      icon="material-symbols:circle"
                                      style={{
                                        mb: "-1px",
                                        mr: 1,
                                        fontSize: 25,
                                        color: row.Estatus == 'Finalizado' ? "#0FA958" : row.Estatus == 'En curso' ? "#FFD233" : "#D3D3D3",
                                      }}
                                    />
                                  </Tip>
                                </Box>
                              </Typography>
                            </TableCellCustom>
                            <TableCell
                              align="left"
                              style={{
                                padding: 10,
                                border: "1px solid #D2D2D2",
                              }}
                              width="70%"
                            >
                              <Typography>
                                {row.ProcessName}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Grid>
          ) :
            dataStateInfo.items?.GruposProcesos?.map((item, index) =>
              <Grid item xs={2.2}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                  <Box onClick={() => { setUserSeleted(item); setUserSeletedPosition(index) }}>
                    {imagesUsers(index)}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", height: '50px', justifyContent: "center", alignItems: "center" }}>
                  {item.GroupName}
                </Box>
                <Box sx={{ mt: 2 }}>
                  {item.EstatusProcesos.map((itemProces) =>
                    <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                      <Tip
                        title={itemProces.ProcessName}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <Icon
                          icon="material-symbols:circle"
                          style={{
                            mb: "-1px",
                            mr: 1,
                            fontSize: 45,
                            color: itemProces.Estatus == 'Finalizado' ? "#0FA958" : itemProces.Estatus == 'En curso' ? "#FFD233" : "#D3D3D3",
                          }}
                        />
                      </Tip>
                    </Box>
                  )}
                </Box>
              </Grid>
            )
          }
        </Grid>
      </Grid>
      <VideoTutorialModal open={showTutorial === true} onClose={handleClose} />
    </Grid>
  );
}
