import { useCallback, useState } from 'react';
import { B64toBlob } from 'utils';

export const useGetDocumentLinks = () => {
  const [blobUrl, setBlobUrl] = useState(null);
  const [linkCode, setLinkCode] = useState(null);

  const validateIdOnSuccess = useCallback((data) => {
    const { B64QR: DOCUMENT_QR_CODE_B64, Link: DOCUMENT_LINK_CODE } = data;
    const blob = B64toBlob(DOCUMENT_QR_CODE_B64, 'image/.png');
    const blobUrl = URL.createObjectURL(blob);
    setBlobUrl(blobUrl);
    setLinkCode(DOCUMENT_LINK_CODE);
  }, []);

  return {
    blobUrl,
    linkCode,
    validateIdOnSuccess,
  };
};
