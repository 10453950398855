import React, { useState } from "react";
import { ModalContainer } from "../Modal.styles";
import {
  Box,
  Button,
  TextField,
  Typography,
  Divider,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import AssignedTasksIcon from "assets/notificationsIcons/Asignados.png";
import {
  blockNotifications,
  sendNotification,
  updateEmailsNotification,
} from "api/notifications/notificationsRequests";
import EmailIcon from "assets/notificationsIcons/Mail.png";
import ReadedNotificationIcon from "assets/notificationsIcons/Notificaciónleida.png";
import AttendedNotificationIcon from "assets/notificationsIcons/Notificaciónatendida.png";
import NoReadedNotification from "assets/notificationsIcons/Notificaciónnoleida.png";
import NoAttendedNotification from "assets/notificationsIcons/notificacionnoatendida.png";
import PausedNotificationIcon from "assets/notificationsIcons/Detenciondenotificacion.png";
import StatusNotificationIcon from "assets/notificationsIcons/estatus.svg";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { IsDevice } from "utils";

const NotificationStatus = ({
  icon,
  text,
  description,
  ActionButton,
  ButtonText,
}) => (
  <>
    <Box sx={styles.statusContainer}>
      <img src={icon} alt="" />
      <Box sx={styles.statusTextContainer}>
        <Typography variant="h6">{text}</Typography>
        {description && <Typography variant="span">{description}</Typography>}
      </Box>
    </Box>
    {!!ActionButton && (
      <Box sx={{ width: "100%" }}>
        <Button
          variant="contained"
          sx={{ width: "15em", ml: 5 }}
          onClick={ActionButton}
        >
          {ButtonText}
        </Button>
      </Box>
    )}
  </>
);

const NotificationHeader = ({ icon, folio, nombre, description }) => (
  <Box sx={styles.headerContainer}>
    <img src={icon} alt="" />
    <Box sx={styles.headerTextContainer}>
      <Typography variant="h6" sx={{ padding: "0px" }}>
        {folio} - {nombre}
      </Typography>
      <Typography variant="span">{description}</Typography>
    </Box>
  </Box>
);

const NotificationEmailInput = ({
  inputMode,
  currentEmail,
  setInputMode,
  handleOnBlurInput,
  handleSendNotification,
  theme,
  t,
  IsMobile,
}) => (
  <Box sx={styles.emailContainer}>
    <img src={EmailIcon} alt="" />
    <Box sx={styles.emailTextContainer}>
      <Typography variant="h6">{t("notifications_send_to")}</Typography>
      <Box sx={styles.emailInputContainer}>
        <TextField
          onBlur={handleOnBlurInput}
          sx={styles.emailTextField}
          defaultValue={currentEmail}
          onKeyDown={(e) => {
            e.code === "Enter" && handleOnBlurInput(e);
          }}
          InputProps={{
            style: { height: "100%" },
            endAdornment: <EditIcon fontSize="small" sx={{ color: "grey" }} />,
          }}
        />
        <Button
          variant="contained"
          onClick={handleSendNotification}
          sx={{ backgroundColor: theme.palette.main, height: "100%" }}
        >
          {t("notification_send")}
        </Button>
      </Box>
    </Box>
  </Box>
);

export const NotificationsModal = ({
  open,
  mutate,
  notificationId,
  handleClose,
}) => {
  const { t } = useTranslation();
  const { IsMobile } = IsDevice();
  const { data: notifications } = useSWR("NotifyData");
  const notification = notifications.NotifyData[0].Solicitudes.find(
    (solicitud) => solicitud.Notify_Id === notificationId,
  );
  const [inputMode, setInputMode] = useState(false);
  const [currentEmail, setCurrentEmail] = useState(notification.Emails);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const getNotificationData = (isRead, isAttended, readUser, attendUser) => ({
    read: {
      text: isRead
        ? `${t("notification_read_by")} ${readUser}`
        : t("notification_status_unread"),
      icon: isRead ? ReadedNotificationIcon : NoReadedNotification,
    },
    attend: {
      text: isAttended
        ? `${t("notification_attended_by")} ${attendUser}`
        : t("notification_not_attended"),
      icon: isAttended ? AttendedNotificationIcon : NoAttendedNotification,
    },
    block: {
      text: t("notification_stopping"),
      icon: PausedNotificationIcon,
    },
    status: {
      text: t("notification_status"),
      icon: StatusNotificationIcon,
    },
  });

  const handleOnBlurInput = (e) => {
    setInputMode(false);
    if (e.target.value) setCurrentEmail(e.target.value);
  };

  const handleBlockNotification = () => {
    enqueueSnackbar(
      notification?.Block
        ? "Notificaciones activadas"
        : "Notificaciones desactivadas",
      { variant: "success" },
    );
    handleClose();
    blockNotifications(notification.Notify_Id).then(() => mutate());
  };

  const handleSendNotification = () => {
    enqueueSnackbar(t("alert_sended_email"), { variant: "success" });
    sendNotification(notification.Notify_Id).then(() => mutate());
  };

  const handleUpdateEmails = () => {
    enqueueSnackbar(t("alert_updated_email"), { variant: "success" });
    handleClose();
    updateEmailsNotification({
      Notify_Id: notification.Notify_Id,
      Emails: [currentEmail],
    }).then(() => mutate());
  };

  const { read, attend, block, status } = getNotificationData(
    notification?.Readed,
    notification?.Attended,
    notification?.ReadUser,
    notification?.AttendUser,
  );

  const getBlockNotificationData = () => {
    return notification?.Block
      ? {
          buttonText: t("notification_enable_notification"),
          description: t("notification_disabled"),
        }
      : {
          buttonText: t("notification_disable_notification"),
          description: t("notification_enabled"),
        };
  };

  return (
    <ModalContainer open={open} onClose={handleClose}>
      <NotificationHeader
        icon={AssignedTasksIcon}
        folio={notification.Folio}
        nombre={notification.Nombre}
        description={notification?.Description}
      />
      <Divider sx={{ width: "100%" }} />
      {
        /* !notification?.Block */ true ? ( //Commented for new design
          <NotificationEmailInput
            inputMode={inputMode}
            currentEmail={currentEmail}
            setInputMode={setInputMode}
            handleOnBlurInput={handleOnBlurInput}
            handleSendNotification={handleSendNotification}
            theme={theme}
            t={t}
            IsMobile={IsMobile}
          />
        ) : (
          <NotificationStatus
            icon={block.icon}
            text={block.text}
            description={block?.Readed}
          />
        )
      }
      <NotificationStatus
        icon={read.icon}
        text={read.text}
        description={notification?.Readed}
      />
      <NotificationStatus
        icon={attend.icon}
        text={attend.text}
        description={notification?.Attended}
      />
      <NotificationStatus
        icon={status.icon}
        text={status.text}
        description={getBlockNotificationData().description}
        ActionButton={handleBlockNotification}
        ButtonText={getBlockNotificationData().buttonText}
      />
      <Box sx={styles.buttonContainer}>
        {currentEmail !== notification.Emails ? (
          <>
            <Button
              variant="outlined"
              onClick={() => setCurrentEmail(notification.Emails)}
              sx={styles.cancelButton}
            >
              {t("general_cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={handleUpdateEmails}
              sx={{ backgroundColor: theme.palette.primary.main, width: "35%" }}
            >
              {t("general_save")}
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{ backgroundColor: theme.palette.primary.main, width: "35%" }}
          >
            {t("general_close")}
          </Button>
        )}
      </Box>
    </ModalContainer>
  );
};

const styles = {
  headerContainer: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
  },
  headerTextContainer: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
  },
  statusContainer: {
    display: "flex",
    gap: "1rem",
    alignItems: "self-start",
    width: "100%",
  },
  statusTextContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    alignItems: "self-start",
  },
  emailContainer: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    width: "100%",
  },
  emailTextContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    width: "100%",
  },
  emailInputContainer: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    width: "100%",
    height: "30px",
  },
  emailTextField: {
    flexGrow: "1",
    height: "100%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "1rem",
    width: "100%",
  },
  cancelButton: {
    width: "35%",
  },
};
