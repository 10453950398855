import { Box, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { clearCacheData } from 'utils';
import videoUbicacion from 'assets/VideoUbicacion.mp4';
import { ToastNotification } from 'components/ToastNotification';
import { Alert } from 'components/Alert';

const Geolocation = (props) => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  useEffect(() => {
    requestLocation();
  }, []);

  useEffect(() => {
    if (props.ReloadGeoLocation) {
      if (navigator.geolocation) {
        const watchId = navigator.geolocation.watchPosition(
          (position) => {
            handleGeoLocationSuccess(position);
          },
          (error) => {
            handleGeoLocationError(error);
          },
        );

        return () => {
          navigator.geolocation.clearWatch(watchId);
        };
      } else {
        console.error('La geolocalización no es compatible con este navegador');
      }
    }
  }, [props.ReloadGeoLocation]);

  const requestLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleGeoLocationSuccess, handleGeoLocationError, {
        enableHighAccuracy: true,
        timeout: 20000,
        maximumAge: 1000,
      });
    } else {
      console.log('La geolocalización no es compatible con este navegador.');
    }
  };

  const handleGeoLocationSuccess = (position) => {
    const { latitude, longitude } = position.coords;
    setLatitude(latitude);
    setLongitude(longitude);
    props.SuccessFN(latitude, longitude);
    ToastNotification({
      icon: 'success',
      text: 'Se obtuvo la ubicación de manera correcta.',
    });
  };

  const handleGeoLocationError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        Alert(
          {
            icon: 'error',
            okbtntext: 'Aceptar',
            title: 'Verifica tu ubicación',
            html: renderToString(
              <Box>
                <Typography
                  textAlign={'center'}
                  variant="body1"
                  sx={{ mt: 1, textAlign: 'center', maxWidth: 400, mx: 'auto' }}
                >
                  El usuario ha denegado el permiso de geolocalización.
                </Typography>
                <Box sx={{ width: '100%' }}>
                  <video className="videoUbi" src={videoUbicacion} autoPlay loop muted />
                </Box>
                <Divider sx={{ maxWidth: 300, mt: 1, mx: 'auto' }} />
              </Box>,
            ),
          },
          (event) => {
            sessionStorage.setItem('refreshFlag', null);
            clearCacheData();
          },
        );
        props.ErrorFN('El usuario ha denegado el permiso de geolocalización.');
        break;
      case error.POSITION_UNAVAILABLE:
        Alert(
          {
            icon: 'error',
            okbtntext: 'Aceptar',
            title: 'Verifica tu ubicación',
            html: renderToString(
              <Box>
                <Typography
                  textAlign={'center'}
                  variant="body1"
                  sx={{ mt: 1, textAlign: 'center', maxWidth: 400, mx: 'auto' }}
                >
                  La información de ubicación no está disponible.
                </Typography>
                <Box sx={{ width: '100%' }}>
                  <video className="videoUbi" src={videoUbicacion} autoPlay loop muted />
                </Box>
                <Divider sx={{ maxWidth: 300, mt: 1, mx: 'auto' }} />
              </Box>,
            ),
          },
          (event) => {
            sessionStorage.setItem('refreshFlag', null);
            clearCacheData();
          },
        );
        props.ErrorFN('La información de ubicación no está disponible.');
        break;
      case error.TIMEOUT:
        Alert(
          {
            icon: 'error',
            okbtntext: 'Aceptar',
            title: 'Verifica tu ubicación',
            html: renderToString(
              <Box>
                <Typography
                  textAlign={'center'}
                  variant="body1"
                  sx={{ mt: 1, textAlign: 'center', maxWidth: 400, mx: 'auto' }}
                >
                  Se ha excedido el tiempo de espera para obtener la ubicación.
                </Typography>
                <Box sx={{ width: '100%' }}>
                  <video className="videoUbi" src={videoUbicacion} autoPlay loop muted />
                </Box>
                <Divider sx={{ maxWidth: 300, mt: 1, mx: 'auto' }} />
              </Box>,
            ),
          },
          (event) => {
            sessionStorage.setItem('refreshFlag', null);
            clearCacheData();
          },
        );
        props.ErrorFN('Se ha excedido el tiempo de espera para obtener la ubicación.');
        break;
      case error.UNKNOWN_ERROR:
        Alert(
          {
            icon: 'error',
            okbtntext: 'Aceptar',
            title: 'Verifica tu ubicación',
            html: renderToString(
              <Box>
                <Typography
                  textAlign={'center'}
                  variant="body1"
                  sx={{ mt: 1, textAlign: 'center', maxWidth: 400, mx: 'auto' }}
                >
                  Se ha producido un error desconocido.
                </Typography>
                <Box sx={{ width: '100%' }}>
                  <video className="videoUbi" src={videoUbicacion} autoPlay loop muted />
                </Box>
                <Divider sx={{ maxWidth: 300, mt: 1, mx: 'auto' }} />
              </Box>,
            ),
          },
          (event) => {
            sessionStorage.setItem('refreshFlag', null);
            clearCacheData();
          },
        );
        props.ErrorFN('Se ha producido un error desconocido.');
        break;
      default:
        Alert(
          {
            icon: 'error',
            okbtntext: 'Aceptar',
            title: 'Verifica tu ubicación',
            html: renderToString(
              <Box>
                <Typography
                  textAlign={'center'}
                  variant="body1"
                  sx={{ mt: 1, textAlign: 'center', maxWidth: 400, mx: 'auto' }}
                >
                  Revisa que la ubicación de tu equipo o dispositivo este activada
                </Typography>
                <Box sx={{ width: '100%' }}>
                  <video className="videoUbi" src={videoUbicacion} autoPlay loop muted />
                </Box>
                <Divider sx={{ maxWidth: 300, mt: 1, mx: 'auto' }} />
              </Box>,
            ),
          },
          (event) => {
            sessionStorage.setItem('refreshFlag', null);
            clearCacheData();
          },
        );
        props.ErrorFN(`No controlado - Code: ${error.code}`);
        setTimeout(() => {
          requestLocation();
        }, 3000);
        break;
    }
  };

  return (
    <div style={{ display: 'none' }}>
      {latitude && longitude ? (
        <p>
          Latitud: {latitude}, Longitud: {longitude}
        </p>
      ) : (
        <p>Obteniendo la geolocalización...</p>
      )}
    </div>
  );
};

export default Geolocation;
