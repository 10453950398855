import React, { useState } from 'react';
import { Box } from '@mui/material';
import { DetailsFormatUpload } from 'components/MassiveFlow/MassiveDownload/DetailsFormatUpload/DetailsFormatUpload';
import { DownloadBatchData } from 'components/MassiveFlow/MassiveDownload/DownloadBatchData/DownloadBatchData';
import { GeneratingZipAlert } from 'components/MassiveFlow/MassiveDownload/GeneratingZipAlert/GeneratingZipAlert';
import { DownloadZipView } from 'components/MassiveFlow/MassiveDownload/DownloadZipView/DownloadZipView';
import { BatchFileData } from 'components/MassiveFlow/MassiveDownload/BatchFileData/BatchFileData';

  const MassiveDownload = () => {
  const [mode, setMode] = useState('DOWNLOAD_BATCH_DATA');
  const [batchId, setBatchId] = useState();
  const [zipFileUrl, setZipFileUrl] = useState();

  const handleMode = (mode) => {
    setMode(mode);
  };

  const handleSendTemplateFile = (value) => {
    setBatchId(value);
    setMode('BATCH_FILE_DATA');
  };

  const handleZipSuccess = (zipFileUrl) => {
    setZipFileUrl(zipFileUrl);
    setMode('DOWNLOAD_ZIP_VIEW');
  };

  const RENDER_COMPONENT_BY_MODE = {
    DOWNLOAD_BATCH_DATA: <DownloadBatchData handleMode={handleMode} />,
    DETAILS_FORMAT_UPLOAD: <DetailsFormatUpload onSendTemplateFile={handleSendTemplateFile} />,
    BATCH_FILE_DATA: <BatchFileData handleMode={handleMode} batchId={batchId} />,
    GENERATING_ZIP_ALERT: <GeneratingZipAlert batchId={batchId} onZipSuccess={handleZipSuccess} />,
    DOWNLOAD_ZIP_VIEW: <DownloadZipView zipFileUrl={zipFileUrl} handleMode={handleMode} />,
  };

  return <Box padding={'2rem'}>{RENDER_COMPONENT_BY_MODE[mode]}</Box>;
};

export default MassiveDownload;
