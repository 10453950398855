import { useState } from 'react';
import { BulkLoad } from 'components/MassiveFlow/MassiveUploadment/BulkLoad/BulkLoad';
import { MassiveUploader } from 'components/MassiveFlow/MassiveUploadment/MassiveUploader/MassiveUploader';
import { UploadStatus } from 'components/MassiveFlow/MassiveUploadment/UploadStatus/UploadStatus';
import { ZipUploader } from 'components/MassiveFlow/MassiveUploadment/ZipUploader/ZipUploader';

const MassiveUploadment = () => {
  const [mode, setMode] = useState('BULK_LOAD');
  const [templateFile, setTemplateFile] = useState(null);

  const handleMode = (value) => setMode(value);
  const handleSetTemplateFile = (value) => {
    setTemplateFile(value);
    setMode('ZIP_UPLOADER');
  };

  const render = () => {
    switch (mode) {
      case 'BULK_LOAD':
        return <BulkLoad handleMode={handleMode} />;
      case 'MASSIVE_UPLOADER':
        return (
          <MassiveUploader handleMode={handleMode} OnSetTemplateFile={handleSetTemplateFile} />
        );
      case 'ZIP_UPLOADER':
        return <ZipUploader handleMode={handleMode} templateFile={templateFile} />;
      case 'UPLOAD_STATUS':
        return <UploadStatus handleMode={handleMode} />;
      default:
        return;
    }
  };
  return <div style={{ padding: '2rem', height: '100%' }}>{render()}</div>;
};

export default MassiveUploadment