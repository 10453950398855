import React, { useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import { mutate } from "swr";
import { MetadataForm } from "./MetadataForm";
import { insertMetadata } from "api/configuration/configurationRequests";
import { useTranslation } from "react-i18next";

export const AddMetadata = ({ handleView }) => {
  const configId = JSON.parse(sessionStorage.getItem("configurationData")).Id;
  const { t } = useTranslation()

  const [formData, setFormData] = useState({
    ConfigurationID: configId,
    GroupId: "",
    TagCapture: "",
    DataType: "",
    Requeried: "true",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSendForm = async () => {
    try {
      await insertMetadata(formData);
      await mutate("GetMetadata");
      handleView("table");
    } catch (error) { }
  };

  const isButtonDisabled = Object.entries(formData).some(
    ([key, value]) => key !== "Requeried" && !value,
  );

  return (
    <Box display="flex" flexDirection={"column"} gap="1rem">
      <Typography variant="span">
        {t("configuration_metadata_add_instructions")}
      </Typography>
      <MetadataForm formData={formData} onChange={handleChange} onSendForm={handleSendForm} />
      <Box display="flex" justifyContent="center" gap="2rem" height="3rem">
        <Button
          sx={{ borderRadius: "2rem", minWidth: "20%" }}
          variant="outlined"
          onClick={() => {
            handleView("table");
          }}
        >
          {t("general_back")}
        </Button>
        <Button
          sx={{ borderRadius: "2rem", minWidth: "20%" }}
          variant="contained"
          onClick={handleSendForm}
          disabled={isButtonDisabled}
        >
          {t("general_save")}
        </Button>
      </Box>
    </Box>
  );
};
