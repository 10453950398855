import React, { useState } from "react";

import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  styled,
} from "@mui/material";

import { formatDateByLocale } from "../../utils";
import { useTranslation } from "react-i18next";

const TableRowCustom = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: `${theme.palette.primary.tableHover} !important`,
  },
}));

export function SearchResultsListMobile({ data, onResultClick }) {
  const { t } = useTranslation();

  const newData = data?.Data?.map((col) => {
    return {
      folio: col.Folio,
      fullName: col.Metadatos[0]["Nombre Completo"],
      date: col.Metadatos[0].Fecha,
      area: col.Metadatos[0].Area,
      geid: col.Metadatos[0].GEID,
      stepperStatus: col?.StepperStatus,
    };
  });

  const columns = [
    {
      id: "folio",
      label: t("search_advanced_table_header_folio"),
      minWidth: 170,
    },
    {
      id: "fullName",
      label: t("search_advanced_table_header_name"),
      minWidth: 170,
    },
    {
      id: "date",
      label: t("search_advanced_table_header_date"),
      minWidth: 170,
    },
    { id: "area", label: t("general_area"), minWidth: 170 },
    {
      id: "geid",
      label: t("search_advanced_table_header_geid"),
      minWidth: 170,
    },
    {
      id: "stepperStatus",
      label: t("search_advanced_table_header_status"),
      minWidth: 170,
    },
  ];

  const createData = (folio, fullName, date, area, geid, stepperStatus) => {
    return {
      folio,
      fullName,
      date,
      area,
      geid,
      stepperStatus,
    };
  };

  const rows = newData?.map((r) => {
    return createData(
      r.folio,
      r.fullName,
      r.date,
      r.area,
      r.geid,
      r.stepperStatus,
    );
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <div /* style={{ margin: "0 1em 0 1em" }} */>
      <TableContainer sx={{ width: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableBody>
            {rows
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRowCustom
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    onClick={() => {
                      const assigment = data.find((dt) => dt.Folio === row.folio);

                      onResultClick({ ...row, Id: assigment?.Id, WorkflowInstanceId: assigment?.WorkflowInstanceId });
                      /* console.log("event table1: ", path);
                      console.log("event table2: ", paths);
                      sessionStorage.removeItem("WorkFlowInstanceId");
                      if (path === paths.search) {
                        console.log("rowidOfFolio: ", row.idOfFolio);
                        history(`${routes.busqueda}/${row.idOfFolio}`);
                        sessionStorage.setItem(
                          "WorkFlowInstanceId",
                          row.WorkFlowInstanceId
                        );
                      } else if (
                        path === paths.homeComponentSingle ||
                        path === paths.homeComponent
                      ) {
                        history(`${routes.asignacion}/${row.idOfFolio}`);
                        sessionStorage.setItem(
                          "WorkFlowInstanceId",
                          row.WorkFlowInstanceId
                        );
                        console.log("rowidOfFolio: 0", row);
                      } */
                    }}
                  >
                    <TableCell sx={{ borderBottom: "none", p: 1, py: 2 }}>
                      <CustomAssigmentCardMobile
                        data={row}
                        columns={columns}
                        t={t}
                      />
                    </TableCell>
                  </TableRowCustom>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          width: "85% !important",
          overflow: "hidden !important",
          "&.MuiTablePagination-toolbar": {
            overflow: "hidden !important",
          },
        }}
        rowsPerPageOptions={[10]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
      />
    </div>
  );
}

const MainStack = styled(Stack)(({ theme }) => ({
  border: "1px solid lightgray",
  borderRadius: "10px",
  minHeight: "12em",
  padding: "18px",
  paddingLeft: "24px",
  paddingRight: "24px",
  userSelect: "none",
}));
const BoxRow = styled(Box)(({ theme }) => ({
  borderBottom: "1px solid lightgray",
  paddingTop: "4px",
  paddingBottom: "4px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));
const TypographyColumn = styled(Typography)(({ theme }) => ({
  maxWidth: "50%",
  textAlign: "left",
  fontWeight: 550,
}));
const TypographyData = styled(Typography)(({ theme }) => ({
  maxWidth: "50%",
  textAlign: "right",
}));
const STATUS_COLORS = {
  AC: "#43A122",
  EX: "#FCC915",
};

const CustomAssigmentCardMobile = ({ data, columns, t }) => {
  return (
    <MainStack>
      {columns?.map((col) => {
        return col.id === "folio" ? (
          <Box sx={{ borderBottom: "1px solid lightgray", pb: 1 }}>
            <Typography variant="h5">{data.folio}</Typography>
          </Box>
        ) : (
          <BoxRow>
            <TypographyColumn>{col.label ?? ""}</TypographyColumn>
            <TypographyData
              variant="body1"
              color={
                col.id === "stepperStatus" && data[col.id] &&
                STATUS_COLORS[data[col.id]?.SubStatus]
              }
            >
              {col.id === "date"
                ? formatDateByLocale(data[col.id])
                : col.id === "stepperStatus"
                  ? data[col.id]?.SubStatus === "AC"
                    ? t("search_result_list_current")
                    : t("search_result_list_expired")
                  : data[col.id]}
            </TypographyData>
          </BoxRow>
        );
      })}
    </MainStack>
  );
};
