import { Box, styled } from '@mui/material';
import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import DashboardComponent from './dashboard/DashboardComponent';
import DetailsComponent from './details/DetailsComponent';
import RegistrationCandidatesComponent from './RegistrationCandidates/RegistrationCandidatesComponent';
import HomeComponent from './home/HomeComponent';
import UploadDocumentUsers from './BulkLoad/RegisterBulkUsers/UploadDocumentUsers';
import HomeMasive from './BulkLoad/HomeMasive';
import TableUsersSigners from './TableUsersSigners';
import Reports from './Reports/Reports';
import RegistrationBulkUsersComponent from './RegistrationCandidates/RegistrationBulkUsersComponent';
import InboxDocumentsMasive from './RegistrationDocumentsMasive/inboxDocumentsMasive';
import UploadDocumentsMasive from './RegistrationDocumentsMasive/UploadDocuments';
import UsersAdministrator from './UserManagement/UsersAdministrator';
import UploadGEIDMassive from './GEID/UploadGEIDMassive';
import GEIDDownload from './GEID/GEIDDownload';
import BulkMetadata from './BulkMetadata/BulkMetadata';
import BulkMetadataUpload from './BulkMetadata/BulkMetadataUpload';
import Charts from './Charts/Charts';
import NewReportsData from './Reports/NewReportsData';
import InboxReportsFilter from './Reports/InboxReportsFilter';
import { ProfilesManagement } from './ProfilesManagement';
import { SearchView } from '../components/search';
import { Configuration } from './configuration/Configuration';
import { ConfigTabs } from './configuration/ConfigTabs';
import Notifications from './Notifications';
import MassiveUploadment from './MassiveUploadment';
import MassiveDownload from './MassiveDownload';
import UserConfig from './UserConfig';

const RootStyle = styled(Box)(({ theme }) => ({
  height: '100%',
  /* backgroundColor: "lightgreen", */
}));

export const Inbox = (props) => {
  const location = useLocation();

  return (
    <RootStyle>
      <Routes>
        <Route index element={<Navigate to="files" />} />
        <Route path="dashboards" element={<DashboardComponent />} />
        <Route path="homeComponent" element={<HomeComponent />} />
        <Route path="homeComponent/masive" element={<HomeMasive />} />
        <Route path="homeComponent/single" element={<HomeComponent />} />
        <Route path="new-file" element={<RegistrationCandidatesComponent />} />
        <Route path="bulkUsers" element={<RegistrationBulkUsersComponent />} />
        <Route path="bulkDocuments" element={<InboxDocumentsMasive />} />
        <Route path="uploadDocumentsMasive" element={<UploadDocumentsMasive />} />
        <Route path="bulkMetadataUpload" element={<BulkMetadataUpload />} />
        {/*<Route path="search" element={<SearchComponent />} />*/}
        <Route path="search" element={<SearchView />} />
        <Route path="busqueda/:id" element={<DetailsComponent />} />
        <Route path="asignacion/:id" element={<DetailsComponent />} />
        <Route path="bulkLoadUsers" element={<UploadDocumentUsers />} />
        <Route path="tableUsersSigners" element={<TableUsersSigners />} />
        <Route path="report/prestamos" element={<Reports />} />
        <Route path="report/contrataciones" element={<Reports />} />
        <Route path="reportsData" element={<NewReportsData />} />
        <Route path="inboxReportsFilter" element={<InboxReportsFilter />} />
        <Route path="bulkGEID" element={<GEIDDownload />} />
        <Route path="UploadGEID" element={<UploadGEIDMassive />} />
        <Route path="BulkMetadata" element={<BulkMetadata />} />
        <Route path="UserManagement" element={<UsersAdministrator />} />
        <Route path="notifications" element={<Notifications />} />
        <Route
          path="config"
          element={
            <Configuration key={location.pathname}>
              <ConfigTabs />
            </Configuration>
          }
        />
        <Route
          path="profileManagement"
          element={
            <Configuration key={location.pathname}>
              <ProfilesManagement />
            </Configuration>
          }
        />
        <Route path="Charts" element={<Charts />} />
        <Route path="massiveFlow" element={<MassiveUploadment />} />
        <Route path="massiveDownload" element={<MassiveDownload />} />
        <Route path="userConfig" element={<UserConfig />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </RootStyle>
  );
};
