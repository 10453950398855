import { Box, Button, Typography } from '@mui/material';
import { InputField } from 'components';
import { InputTypeMappings } from 'mappings/InputTypeMappings';
import { useFormContext } from 'react-hook-form';
import { Accordion } from 'components/common/Accordion/Accordion';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const DynamicForm = ({
  questionsGroups = [],
  onBlur,
  handleSave,
  TextButton,
  defaultValues,
  handleBack,
  isMobileView
}) => {
  const { control, register, formState, handleSubmit } = useFormContext() || {};
  const { t } = useTranslation();
  const descriptionStage = useSelector((state) => state.FileInfo.items)?.Stage?.Properties?.find(prop=>prop.Name === "textSubTittle")?.Value;

  if (questionsGroups.length === 0) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '1.5rem',
        width: '100%',
      }}
    >
      <Typography>{descriptionStage}</Typography>
      {questionsGroups?.map((questionGroup, index) => {
        return (
          <Accordion
            isExpanded={index === 0}
            key={questionGroup.llave}
            title={<Typography variant="p">{questionGroup?.groupTitle}</Typography>}
          >
            {questionGroup.Questions.map((question) => {
              const InputType = InputTypeMappings[question.QuestionType?.toUpperCase()];
              return (
                <InputField
                  key={question.Id}
                  inputType={InputType}
                  control={control}
                  register={register}
                  name={`${question.Id}`}
                  label={question.Description}
                  defaultValue={
                    defaultValues?.find((value) => value.Name === question.Name)?.Value ?? null
                  }
                  validations={{ required: question.Required }}
                  error={!!formState.errors[`${question.Id}`]}
                  helperText={formState.errors[`${question.Id}`]?.message}
                  required={question.Required}
                  radioValues={question.Options}
                  optionsList={question.Options}
                  maxDate={
                    question.QuestionType?.toUpperCase() === 'FECHAPREVIA' ? new Date() : null
                  }
                  disabled={question.Disabled}
                  onSelect={onBlur}
                  value={question?.Value ? question.Value : null}
                />
              );
            })}
          </Accordion>
        );
      })}
      <Box sx={{ display: 'flex', justifyContent: 'center', gap:'1rem', flexDirection: isMobileView ? 'column' : 'row' }}>
        {handleBack && (
          <Button variant="outlined" onClick={handleBack} sx={{ width: isMobileView? '100%' : '25%' }}>
            {TextButton || t('general_back')}
          </Button>
        )}
        {handleSave && (
          <Button variant="contained" onClick={handleSubmit(handleSave)} sx={{ width: isMobileView? '100%' : '25%' }}>
            {TextButton || t('general_save')}
          </Button>
        )}
      </Box>
    </Box>
  );
};
