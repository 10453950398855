import { fetcher } from '../fetcher';
import {
  DOWNLOAD_BATCH_FILE,
  DOWNLOAD_TEMPLATE,
  GET_DOWNLOAD_BATCH_DATA,
  GET_DOWNLOAD_BATCH_FILE_DATA,
  REGISTER_DOWNLOAD_BATCH,
} from './urls';

export const getDownloadBatchData = async (PAYLOAD) =>
  await fetcher(GET_DOWNLOAD_BATCH_DATA, PAYLOAD);

export const downloadTemplate = async (PAYLOAD) =>
  await fetcher(DOWNLOAD_TEMPLATE, PAYLOAD);

export const registerDownloadBatch = async (PAYLOAD) =>
  await fetcher(REGISTER_DOWNLOAD_BATCH, PAYLOAD);

export const getDownloadBatchFileData = async (PAYLOAD) =>
  await fetcher(GET_DOWNLOAD_BATCH_FILE_DATA, PAYLOAD);

export const downloadBatchFile = async (PAYLOAD) =>
  await fetcher(DOWNLOAD_BATCH_FILE, PAYLOAD);
