import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableCell from "@mui/material/TableCell";
import { Icon } from "@iconify/react";
import {
  ExpirationBox,
  TableCellHead,
  TableRowCustom,
} from "./RelationTableAdvanced.styled";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/en";
import "dayjs/locale/pt";
import "dayjs/locale/ja";
import { useTranslation } from "react-i18next";

const formats = {
  es: "[Cargado el] DD [de] MMMM [del] YYYY",
  en: "[Loaded on] MMMM D, YYYY",
  pt: "[Carregado em] D [de] MMMM [de] YYYY",
  ja: "YYYY年M月D日",
};

const formatDate = (inputDate) => {
  const locale = localStorage.getItem("lang");
  dayjs.locale(locale);
  const date = dayjs(inputDate);
  return date.format(formats[locale] || formats["en"]);
};

export const RelationTableAdvanced = ({ tableData, onClickView }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderDirection, setOrderDirection] = useState("");
  const [valueToOrderBy, setValueToOrderBy] = useState("");

  const columns = [
    { id: "Folio", label: t("relation_tab_table_folio"), minWidth: 170, textAlign: "left" },
    { id: "Name", label: t("relation_tab_table_name"), minWidth: 170, textAlign: "left" },
    {
      id: "Environment",
      label: t("relation_tab_table_enviroment"),
      minWidth: 170,
      textAlign: "left",
    },
    {
      id: "CreationDate",
      label: t("relation_tab_table_date"),
      minWidth: 170,
      textAlign: "center",
    },
    { id: "Status", label: t("relation_tab_table_status"), minWidth: 200, textAlign: "center" },
  ];

  const rows = tableData?.map((row) => {
    return {
      ...row,
      Folio: row.Metadatos[0]?.Folio,
      Name: row.Metadatos[0]?.["NombreCompleto"],
      Environment: row.Metadatos[0]?.Ambiente,
      CreationDate: row.Metadatos[0]?.["FechaCreacion"],
      Status: row.StepperStatus[0]?.Status,
    };
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handlerSort = (event, property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };

  const createSortHandler = (property) => (event) => {
    handlerSort(event, property);
  };

  return (
    <Stack p={'2rem'}>
      <TableContainer
        sx={{
          width: "100%",
          borderRadius: "1rem",
          border: "1px solid #CCCCCC",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCellHead
                  key={column.id}
                  style={{
                    minWidth: column.minWidth,
                    textAlign: column.textAlign,
                  }}
                  onClick={createSortHandler(column.label)}
                >
                  {column.label}
                </TableCellHead>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRowCustom
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    onClick={() => {
                      onClickView(row);
                    }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      // console.log("row Date", row, value);
                      return (
                        <TableCell key={column.id} align={column.textAlign}>
                          {column.id !== "Status" &&
                            (column.format && typeof value === "number"
                              ? column.format(value)
                              : value)}
                          {column.id === "Status" && (
                            <ExpirationBox
                              textAlign={"center"}
                              expirationStatus={row.StepperStatus[0]?.SubStatus}
                            >
                              {row.Status}
                            </ExpirationBox>
                          )}

                        </TableCell>
                      );
                    })}
                  </TableRowCustom>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        count={tableData?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
      />
    </Stack>
  );
};
